import React, { useCallback, useEffect, useState } from "react";
import {
  makeStyles
} from '@material-ui/core/styles';
import { baseURL } from '../../api';
import API from '../../api';
import { useParams } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { parse as parseQs } from 'querystring';

export default function TrackingLabelScreen(props) {
  const [delivery, setDelivery] = useState(null);
  const [loading, setIsLoading] = useState(true);
  const [imageQr, setImageQr] = useState(null);
  const [imageBarcode, setImageBarcode] = useState(null);
  const queryParams = props?.location?.search;

  const params = useParams();
  const classes = useStyles();

  const handleLoadQr = useCallback(() => {
    setImageBarcode(
      API.instance().getBarcode128Url(delivery.trackingNumber, {
        width: 320,
        height: 80
      })
    );
  }, [delivery]);

  const handleLoadBarcode = useCallback(e => {
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (params.id) {
      async function load() {
        try {
          const d = await API.instance().getDeliveryById(params.id, {
            include: ['products']
          });
          setImageQr(API.instance().getQrUrl(d.trackingNumber));
          setDelivery(d);
        } catch (e) {
          alert("croquetas o pescadito: " + e.message);
        }
      }

      load();
    }
  }, [params.id, imageQr]);

  useEffect(() => {
    if (!loading) {
      const params = parseQs(queryParams.substring(1));
      console.log(params);
      if (!params.noAutoprint) {
        window.print();
      }
    }
  }, [loading, queryParams]);

  return (
    <div className={classes.main} onMouseDown={(e) => e.preventDefault()}>
      <div className={classes.inner}>
        {loading ? (
          <div style={{position: "absolute", backgroundColor: "rgba(255, 255, 255, 0.9)", width: 344, height: 300, boxSizing: "content-box"}}>
            <div className={classes.loading}>
              <CircularProgress color="inherit" />
              <div className={classes.loadingText}>Cargando<br />etiqueta</div>
            </div>
          </div>
        ) : null}

        <div style={{borderBottom: "solid 4px black", padding: 4, display: "flex"}}>
          <img src="/images/das-big.png" style={{width: 48, height: 48, filter: "saturate(0) brightness(0)", marginRight: 16}} />
          <div>
            <div style={{fontWeight: "bold", lineHeight: "15px"}}>DAS Envíos</div>
            <div style={{fontSize: 12, lineHeight: "11px"}}>Av Enrique Quijada 612</div>
            <div style={{fontSize: 12, lineHeight: "11px"}}>Col. Choyal</div>
            <div style={{fontSize: 12, lineHeight: "11px"}}>CP 83130, Hermosillo, Sonora.</div>
          </div>
        </div>
        <div style={{padding: 0, textAlign: "center", borderBottom: "solid 4px black"}}>
          <div style={{height: 50, overflow: "hidden", marginBottom: 4, padding: 2}}>
            <img src={imageBarcode} onLoad={handleLoadBarcode} />
          </div>
          <div style={{fontWeight: "bold", lineHeight: "15px"}}>
            Guía: {delivery?.trackingNumber}
          </div>
          {(delivery?.products?.length ?? 0) > 0 ? (
            <div style={{fontSize: 14, lineHeight: "15px", flexGrow: 1, fontWeight: "bold"}}>
              SKU: {delivery?.products[0].sku}
            </div>
          ) : null}
        </div>
        <div style={{padding: 8, textAlign: "left", borderBottom: "solid 4px black", display: "flex", alignItems: "center"}}>
          <img src={imageQr} onLoad={handleLoadQr} style={{width: "110px", height: "110px"}} />
          <div style={{padding: 0, fontSize: 14, marginLeft: 8, lineHeight: "13px", fontWeight: "bold"}}>
            <div>Zona {delivery?.destinationZone?.name}</div>
            <div style={{fontWeight: "bold"}}>{delivery?.destination?.name ?? ""}</div>
            <div>Col. {delivery?.destination?.colonia}</div>
            <div>CP. {delivery?.destination?.postalCode}, Hermosillo, Sonora</div>
            <div style={{marginTop: 8, fontWeight: "bold"}}>Entregar a</div>
            <div>{delivery?.destination?.personName ?? "Destinatario"}</div>
            <div>Cel. {delivery?.destination?.phone ?? "Sin teléfono"}</div>
          </div>
        </div>
        {(delivery?.products?.length ?? 0) > 0 ? (
          <div style={{padding: 4, paddingTop: 4, fontSize: 11, lineHeight: "13px", flexGrow: 1, fontWeight: "bold", overflow: "hidden"}}>
            {delivery?.products[0].quantity} x {delivery?.products[0].title}
          </div>
        ) : null}
      </div>
    </div>
  )
}

const useStyles = makeStyles({
  main: {
    boxSizing: "border-box",
    padding: 16,
    display: "flex",
    width: 384,
    height: 384
  },
  inner: {
    border: "solid 4px black",
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
  },
  loading: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  },
  loadingText: {
    color: "black",
    marginTop: 16,
    textAlign: "center",
    fontWeight: "bold",
  },
  nanoImg: {
    width: 1,
    height: 1
  }
});