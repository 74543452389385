import React, { useState, useEffect } from 'react';
import {
  Container,
  CardContent,
  Card,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
  Grid,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
} from '@material-ui/core';
import API from '../api';

const api = API.instance();

function ParametersScreen(props) {
  const [rates, setRates] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [parameters, setParameters] = useState({});
  const [defaultRateId, setDefaultRateId] = useState(null);

  const saveRate = () => {
    if (isSaving) {
      return;
    }

    setIsSaving(true);

    api.saveParameters([
      {name: "DefaultRateId", value: defaultRateId.toString()}
    ]).then(r => {
      setIsSaving(false);
    }).catch(err => {
      setIsSaving(false);
      alert("Algo salió mal: " + err.message);
    });
  };

  useEffect(() => {
    api.getRates().then(r => {
      setRates(r.result);
    });
    api.getParameters().then(r => {
      const theMap = {};
      r.result.forEach(r => {
        theMap[r.name] = r;
      });
      setParameters(theMap);
      setDefaultRateId(parseInt(theMap.DefaultRateId.value));
      console.log(theMap);
    });
  }, []);
  
  return (
    <Container component="main">
      <h1>Parámetros</h1>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1">
                Esta taria es la que funcionará de manera predeterminada en todo
                el sistema si no se determina una tarifa más acotada a una zona
                o algún otro factor.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth={true}>
              <InputLabel id="default-rate">Tarifa</InputLabel>
              <Select value={defaultRateId || 0}
                labelId="default-rate"
                label="Tarifa"
                onChange={(e) => setDefaultRateId(e.target.value)}
              >
                {rates.map(r =>
                  <MenuItem value={r.id} key={r.id}>{r.description}</MenuItem>
                )}
              </Select>
            </FormControl>
            </Grid>
            <Grid item xs={12} alignItems="center">
              <Button variant="contained"
                color="primary"
                onClick={saveRate}
              >
                {isSaving
                  ? (<CircularProgress size={24} color="white" />)
                  : "Guardar"
                }
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <h2>Otros parámetros</h2>
      <Card>
        <CardContent>
          <List>
            {parameters ? Object.keys(parameters).map(pkey =>
              <ListItem key={pkey}>
                <ListItemText
                  primary={parameters[pkey]?.name || "No"}
                />
                <ListItemSecondaryAction>
                  {parameters[pkey]?.value || "No"}
                </ListItemSecondaryAction>
              </ListItem>
            ) : null}
          </List>
        </CardContent>
      </Card>
    </Container>
  );
}

export default ParametersScreen;