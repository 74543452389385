import React, { useEffect, useState, } from 'react';
import {
  Grid,
  Card,
  CardContent,
  List,
  ListItem,
  Container,
} from '@material-ui/core';
import API from '../api';
import { PaymentStatus, PaymentType } from '../models/payment';
import DepositsList from '../components/deposits-list';

const api = API.instance();

export default function Deposit() {
  const [deposits, setDeposits] = useState([]);

  useEffect(() => {
    async function load() {
      try {
        const deposits = await api.getPayments({
          status: PaymentStatus.depositRequested,
          type: PaymentType.deposit,
          getAll: true,
          // counterOnly: true
        });
        setDeposits(deposits);
      } catch (e) {
        console.log(`[loadDepositRequests] Error: ${e.message}`);
      }
    }
    load();
  }, []);

  return (
    <React.Fragment>
      <Container component="main">
        <Grid container direction="column">
          <Grid item>
            <h1>Solicitudes de depósito</h1>
          </Grid>
          <Grid item>
            <Card>
              <CardContent>
                <DepositsList />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  )
}