import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { withRouter } from "react-router-dom";
import PackageTypeSelect from '../components/packagetype-select';
import API, { baseURL } from '../api';

import SubtitlesIcon from '@material-ui/icons/Subtitles';
import AddIcon from '@material-ui/icons/Add';

import {
  Select,
  MenuItem,
  CircularProgress,
 } from '@material-ui/core';

//const MAPS_URL = 'https://www.google.com/maps';
const MAPS_URL = 'https://www.google.com/maps/search/';

const api = API.instance();

class PackageAddScreen extends React.Component {
  state = {
    'user' : '',
    'password': '',
    address: '',
    place: null,
    guiaOriginal: null,
    senders: [],
    nombreRecibe: '',
    telefonoRecibe: '',
    wasSaved: false,
    model: null,
    packageTypes: [],
    tipoPaqueteId: 0,

    alto: 0,
    ancho: 0,
    largo: 0,
    peso: 0,

    isGettingGeocode: false,
    isSaving: false,
    isLoading: true
  }

  async save() {
    const {
      place,
      isSaving,
      sender,
      peso,
      nombreRecibe,
      telefonoRecibe,
      guiaOriginal,
      tipoPaqueteId,
    } = this.state;

    if (isSaving) {
      return;
    }

    this.setState({
      isSaving: true
    });

    try {
      const paquete = await api.savePaquete({
        direccion: place.name,
        direccionLarga: place.formatted_address,
        latitud: place.geometry.location.lat,
        longitud: place.geometry.location.lng,
        // alto, ancho, largo,
        alto: null,
        ancho: null,
        largo: null,
        tipoPaqueteId: tipoPaqueteId.tipoPaqueteId,
        peso,
        senderId: sender.senderId,
        nombreRecibe,
        telefonoRecibe,
        guiaOriginal,
      });

      this.setState({
        isSaving: false,
        model: paquete,
        wasSaved: true
      }, () => {
        // this.props.history.push('/');
      });
    } catch (e) {
      console.error(e);
      this.setState({
        isSaving: false,
      });
    }
  }

  async searchAddress() {
    const {
      address,
      isGettingGeocode,
    } = this.state;

    if (isGettingGeocode) {
      return;
    }

    this.setState({
      isGettingGeocode: true
    });

    try {
      const url = `${baseURL}/routes/locations?location=${address}`;
      const response = await fetch(url);
      const data = await response.json();
      
      this.setState({
        isGettingGeocode: false,
        place: data.result[0],
      }, () => {
        // this.props.history.push('/dashboard');
      });
    } catch (e) {
      console.error(e);
      this.setState({
        isGettingGeocode: false
      });
    }
  }

  async load() {
    const senders = await api.getSenders();
    this.setState({
      isLoading: false,
      senders
    });
  }

  async loadPackageTypes() {
    api.getPackagesTypes().then(pt => {
      this.setState({packageTypes: pt});
    });
  }

  componentDidMount() {
    api.attachHistory(this.props.history, '/login');
    this.loadPackageTypes();
    this.load();
  }

  validate() {
    return (this.state.guiaOriginal
      && this.state.sender
      && this.state.largo !== null
      && this.state.ancho !== null
      && this.state.alto !== null
      && this.state.peso !== null
      && this.state.nombreRecibe
      && this.state.telefonoRecibe
      && this.state.place
      && !this.state.isGettingGeocode
      && !this.state.isSaving
    );
  }

  render() {
    const {
      guiaOriginal,
      place,
      peso,
      address,
      nombreRecibe,
      telefonoRecibe,
      senders,
      wasSaved,
      sender,
      isSaving,
      isLoading,
      model,
      packageTypes,
      tipoPaqueteId,
    } = this.state;

    const loc = place != null ? place.geometry.location : {};

    if (wasSaved) {
      return (
        <Container component="main" maxWidth="xs">
          <h2>Paquete creado correctamente</h2>
          <h3>{model.numeroGuia}</h3>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Button
                color="primary"
                variant="contained"
                fullWidth={true}
                startIcon={<SubtitlesIcon />}
                href={`/package/${model.paqueteId}/print`}
                target="_blank"
              >
                Imprimir etiqueta
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                color="secondary"
                variant="contained"
                fullWidth={true}
                startIcon={<AddIcon />}
                onClick={() => {
                  this.setState({
                    wasSaved: false,
                    model: null,
                    guiaOriginal: '',
                    largo: '',
                    ancho: '',
                    alto: '',
                    peso: '',
                    nombreRecibe: '',
                    telefonoRecibe: '',
                    address: null,
                    place: null
                  });
                }}
              >
                Agregar otro paquete
              </Button>
            </Grid>
            
            <Grid item xs={12}>
              <Button
                variant="outlined"
                fullWidth={true}
                onClick={() => {
                  this.props.history.push('/');
                }}
              >
                Regresar al dashboard
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="outlined"
                fullWidth={true}
                onClick={() => {
                  this.props.history.push('/packages');
                }}
              >
                Ir a lista de paquetes
              </Button>
            </Grid>
          </Grid>
        </Container>
      )
    }

    if (isLoading) {
      return (
        <Container component="main">
          <CircularProgress />
        </Container>
      );
    }

    return (
      <Container component="main">
        <h1>Ingresar nuevo paquete</h1>
        <Grid container spacing={2}
          alignItems="flex-end"
          justify="flex-end"
          style={{paddingBottom: 16}}
        >
          <Grid item xs={12}>
            <Select
              label="Paquetería"
              variant="outlined"
              labelId="paqueteria"
              id="paqueteria-select"
              value={sender ? sender : 0}
              style={{minWidth: 300}}
              onChange={
                (e) => this.setState({sender: e.target.value})
              }>
              <MenuItem value={0}>- Ninguna - </MenuItem>
              {senders.map((s, index) =>
                <MenuItem value={s} key={s.id}>{s.name}</MenuItem>
              )}
            </Select>
          </Grid>

          <Grid item xs={12}>
            <PackageTypeSelect
              value={tipoPaqueteId}
              items={packageTypes}
              onChange={tipoPaqueteId => this.setState({tipoPaqueteId})}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              value={guiaOriginal ? guiaOriginal : ''}
              variant="outlined"
              label="Guía original"
              style={{minWidth: 300}}
              onChange={(e) => this.setState({ guiaOriginal: e.target.value })} />
          </Grid>

          {/*
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <TextField
                  value={largo ? largo : ''}
                  variant="outlined"
                  label="Largo (m)"
                  onChange={(e) => this.setState({
                    largo: e.target.value
                  })} />
              </Grid>
              <Grid item>
                <TextField
                  value={ancho ? ancho : ''}
                  variant="outlined"
                  label="Ancho (m)"
                  onChange={(e) => this.setState({
                    ancho: e.target.value
                  })} />
              </Grid>
              <Grid item>
                <TextField
                  value={alto ? alto : ''}
                  variant="outlined"
                  label="Alto (m)"
                  onChange={(e) => this.setState({
                    alto: e.target.value
                  })} />
              </Grid>
              <Grid item>
                {Math.round((alto * ancho * largo) * 1000) / 1000} m<sup>3</sup>
              </Grid>
            </Grid>
          </Grid>
          */}

          <Grid item xs={12}>
            <TextField
              value={peso ? peso : ''}
              variant="outlined"
              label="Peso (kg)"
              onChange={(e) => this.setState({
                peso: e.target.value
              })} />
          </Grid>

          <Grid item xs={12}
            style={{
              borderTop: 'solid 1px #f0f0f0',
              paddingTop: 0,
              marginTop: 8,
            }}
          >
            <h5>Destinatario</h5>
            <form onSubmit={(e) => e.preventDefault()}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12}>
                <TextField variant="outlined"
                  value={nombreRecibe ? nombreRecibe : ''}
                  style={{ minWidth: 300 }}
                  label="Persona que recibe"
                  width={200}
                  onChange={
                    (e) => this.setState({ nombreRecibe: e.target.value })
                  } />
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined"
                  value={telefonoRecibe ? telefonoRecibe : ''}
                  style={{ minWidth: 300 }}
                  label="Teléfono de quién recibe"
                  width={200}
                  onChange={
                    (e) => this.setState({ telefonoRecibe: e.target.value })
                  } />
              </Grid>
              <Grid item>
                <TextField variant="outlined"
                  value={address}
                  style={{minWidth: 300}}
                  label="Dirección de entrega"
                  width={200}
                  onChange={(e) => this.setState({address: e.target.value})} />
              </Grid>
              <Grid item>
                <Button variant="contained"
                  color="secondary"
                  size="large"
                  type="submit"
                  disabled={(isSaving) ? true : false}
                  onClick={this.searchAddress.bind(this)}>
                  Buscar
                </Button>
              </Grid>
            </Grid>
            </form>
          </Grid>

          {place
            ? (
              <Grid item xs={12}
                style={{
                  borderBottom: 'solid 1px #f0f0f0',
                  marginBottom: 16,
                  paddingBottom: 16
                }}>
                <div style={{marginBottom: 8}}>{place.formatted_address}</div>
                <Button variant="outlined"
                  color="primary"
                  href={`${MAPS_URL}?api=1&query=${loc.lat},${loc.lng}`}
                  target="_blank">
                  Ver en Google Maps
                </Button>
              </Grid>
            ) : null
          }
          
          
          <Grid item xs={12}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Button variant="contained" color="primary"
                  disabled={!this.validate()}
                  onClick={this.save.bind(this)}>
                  Guardar paquete
                </Button>
              </Grid>
              <Grid item>
                {isSaving ? <CircularProgress /> : null}
              </Grid>
            </Grid>
            
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withRouter(PackageAddScreen);