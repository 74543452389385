import React, { useEffect, useState } from 'react';
import API from '../api';

const api = API.instance();

export function useDeliveriesStatsUpdater({
  requestOptions,
  refreshInterval = 5000,
  start = true,
}) {
  const [stats, setStats] = useState([]);
  const [byZones, setByZones] = useState([]);

  function updateStats(newStats) {
    setStats([...stats, newStats]);
  }

  useEffect(() => {
    const st = { timer: null };

    async function refresh() {
      try {
        const stats = await api.getDeliveries({
          statGroup: 'MesaControl',
          includeEvents: true,
          ...(requestOptions ?? {}),
        });
        setStats(stats);
        
        const zonesKeys = Object.keys(stats.byZones);
        const byZones = zonesKeys.map(key => stats.byZones[key]);

        setByZones(byZones);
      } catch (e) {
        console.error(`[useStatUpdater] ${e}`);
      }
      st.timer = setTimeout(refresh, refreshInterval);
    };

    st.timer = setTimeout(refresh, 0);

    return () => clearTimeout(st.timer);
  }, []);

  return {stats, byZones, updateStats};
}