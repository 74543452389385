import React from 'react';
import {
  Container
} from '@material-ui/core';
import FeaturesTable from '../../components/features_table';

export default function FeaturesScreen() {
  return (
    <Container>
      <FeaturesTable />
    </Container>
  )
}