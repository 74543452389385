import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { withRouter } from "react-router-dom";
import API from '../api';
import {
  CircularProgress,
} from '@material-ui/core';

const api = API.instance();

class ZoneAddScreen extends React.Component {
  state = {
    'user': '',
    'password': '',
    place: null,
    guia: null,

    isGettingGeocode: false,
    isSaving: false
  }

  async save() {
    const {
      place,
      isSaving,
    } = this.state;

    if (isSaving) {
      return;
    }

    console.log("entra");

    this.setState({
      isSaving: true
    });

    try {
      const paquete = await api.savePaquete({
        direccion: place.name,
        direccionLarga: place.formatted_address,
        latitud: place.geometry.location.lat,
        longitud: place.geometry.location.lng,
      });

      console.log(paquete);
      this.setState({
        isSaving: false,
      }, () => {
        this.props.history.push('/');
      });
    } catch (e) {
      console.error(e);
      this.setState({
        isSaving: false,
      });
    }
  }

  render() {
    const {
      guia,
      place,
      isSaving,
      isGettingGeocode,
    } = this.state;

    return (
      <Container component="main">
        <h1>Crear zona</h1>
        <Grid container spacing={2}
          alignItems="flex-end"
          justify="flex-end"
          style={{ paddingBottom: 16 }}
        >
          <Grid item xs={12}>
            <TextField
              value={guia}
              variant="outlined"
              label="Nombre de zona"
              style={{ minWidth: 300 }}
              onChange={(e) => this.setState({ guia: e.target.value })} />
          </Grid>

          <Grid item xs={12}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item spacing={2}>
                <Button variant="contained" color="primary"
                  disabled={!place || isGettingGeocode}
                  onClick={this.save.bind(this)}>
                  Crear zona
                </Button>
              </Grid>
              <Grid item spacing={2}>
                {isSaving ? <CircularProgress /> : null}
              </Grid>
            </Grid>

          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withRouter(ZoneAddScreen);