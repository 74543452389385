import React, { useMemo } from 'react';

import NumberFormat from 'react-number-format';

import VehicleType from '../models/vehicle-type';
import { DeliveryStatus } from '../models/delivery';

import moment from 'moment-timezone';

import {
  Grid,
  Chip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { blue, pink } from '@material-ui/core/colors';

// Icons
import PhoneIcon from '@material-ui/icons/Phone';
import PersonIcon from '@material-ui/icons/Person';
import FormatShapesIcon from '@material-ui/icons/FormatShapes';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

const estatusEntregaMap = DeliveryStatus.map;

const Money = (props) => {
  return (
    <NumberFormat
      thousandSeparator={true}
      decimalScale={2}
      fixedDecimalScale={2}
      displayType="text"
      prefix="$"
      {...props}
    />
  );
}

export default function DeliveryTile({
  delivery,
  current,
  showBadges = true
}) {
  const now = moment.tz('utc');
  const status = useMemo(
    () => estatusEntregaMap[delivery?.status ?? delivery?.estatusEntregaId],
    [delivery]
  );
  const VehicleIcon = VehicleType.getIcon(delivery?.vehicleTypeId);
  const originZone = useMemo(
    () => delivery?.originZone ?? delivery?.origin?.zone,
    [delivery]
  );
  const destinationZone = useMemo(
    () => delivery?.destinationZone ?? delivery?.destination?.zone,
    [delivery]
  );
  const originName = useMemo(
    () => delivery?.origin?.name ?? delivery?.origenNombre,
    [delivery]
  );
  const destinationName = useMemo(
    () => delivery?.destination?.name ?? delivery?.destinoNombre,
    [delivery]
  );

  const originPhone = useMemo(() => {
    if (delivery?.origin?.phone) {
      console.log("delivery.origin.phone");
      return delivery.origin.phone;
    }

    if (delivery?.origenTelefono) {
      console.log("origenTelefono");
      return delivery.origenTelefono;
    }
  }, [delivery]);

  const destinationPhone = useMemo(() => {
    
  }, [delivery]);

  const receiverName = useMemo(() => {
    if (delivery?.destination?.personName)
      return delivery.destination.personName;
    return (delivery && delivery.receptor) ? delivery.receptor : null;
  }, [delivery]);

  return (
    <Grid container style={{lineHeight: "18px"}}>
      {delivery?.paquete?.numeroGuia ? (
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item style={{ color: 'black', fontWeight: 'bold' }}>#&nbsp;</Grid>
            <Grid item>
              {delivery && delivery.paquete ? delivery.paquete.numeroGuia : null}
            </Grid>
          </Grid>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <Grid container spacing={1} wrap="nowrap">
          <Grid item style={{ color: blue[500], fontSize: 10}}>⬤</Grid>
          <Grid item flex="flex" className="small-chip-container">
            <span style={{marginRight: 8}}>{originName}</span>
            {delivery?.origin?.postalCode ? (
              <span className="small-chip">
                CP {delivery?.origin?.postalCode}
              </span>
            ) : null}
            {delivery?.origin?.colonia ? (
              <span className="small-chip">
                Col. {delivery?.origin?.colonia}
              </span>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1} wrap="nowrap">
          <Grid item style={{ color: pink[400], fontSize: 10 }}>⬤</Grid>
          <Grid item className="small-chip-container">
            <span style={{marginRight: 4}}>{destinationName}</span>
            {delivery?.destination?.postalCode ? (
              <span className="small-chip">
                CP {delivery?.destination?.postalCode}
              </span>
            ) : null}
            {delivery?.destination?.colonia ? (
              <span className="small-chip">
                Col. {delivery?.destination?.colonia}
              </span>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      
      {originZone ? (
        <Grid item xs={12}>
          <Grid container spacing={1} wrap="nowrap">
            <Grid item style={{ color: pink[400], fontSize: 10 }}>
              <FormatShapesIcon style={{ fontSize: 12, color: "black" }} />
            </Grid>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  {originZone.name}
                </Grid>
                {originZone?.id !== destinationZone?.id ? (
                  <React.Fragment>
                    <Grid item>
                      <ArrowForwardIcon style={{ fontSize: 12 }} />
                    </Grid>
                    <Grid item>
                      {destinationZone?.name ?? "Zona sin nombre"}
                    </Grid>
                  </React.Fragment>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : null}

      <Grid item xs={12}>
        <Grid container spacing={1} wrap="nowrap">
          <Grid item style={{ color: 'black', fontSize: 14, fontWeight: 'bold' }}>ID</Grid>
          <Grid item>{delivery?.id ?? delivery?.viajeId}</Grid>
        </Grid>
      </Grid>
      
      {originPhone ? (
        <Grid item xs={12}>
          <Grid container spacing={1} wrap="nowrap">
            <Grid item style={{ color: 'black', fontSize: 14, fontWeight: 'bold'}}>
              <PhoneIcon style={{fontSize: 16}} />
            </Grid>
            <Grid item>{originPhone}</Grid>
          </Grid>
        </Grid>
      ) : null}
      
      <Grid item xs={12}>
        <Grid container spacing={1} wrap="nowrap">
          <Grid item style={{ color: 'black', fontSize: 14, fontWeight: 'bold' }}>
            <PersonIcon style={{ fontSize: 16 }} />
          </Grid>
          <Grid item>
            {receiverName}
            <Typography variant="caption" color="textSecondary"> (recibe)</Typography>
          </Grid>
        </Grid>
      </Grid>
      
      {delivery?.vehicleType?.name ? (
        <Grid item xs={12}>
          <Grid container spacing={1} wrap="nowrap">
            <Grid item style={{ color: 'black', fontSize: 14, fontWeight: 'bold' }}>
              <VehicleIcon style={{ fontSize: 16 }} />
            </Grid>
            <Grid item>
              {delivery?.vehicleType?.name ?? ""}
            </Grid>
          </Grid>
        </Grid>
      ) : null}

      <Grid item xs={12}>
        <Grid container spacing={1} wrap="nowrap">
          <Grid item style={{ color: 'black', fontSize: 14, fontWeight: 'bold' }}>
            <MonetizationOnIcon style={{ fontSize: 16 }} />
          </Grid>
          <Grid item>
            <Money
              value={delivery?.deliveryPrice ?? 0}
            />
            {(
              delivery?.productPrice
              ?? delivery?.paquete?.productPrice
            ) ? (
              <React.Fragment>
                <span style={{margin: '0 4px'}}>+</span>
                <Money
                  value={
                    delivery?.productPrice
                      ?? delivery?.paquete?.productPrice
                  }
                />
                <span style={{margin: '0 4px'}}>=</span>
                <Money
                  value={
                    (delivery?.deliveryPrice ?? 0)
                    + (delivery?.productPrice
                      ?? delivery?.paquete?.productPrice)
                  }
                />
              </React.Fragment>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      {showBadges ? (
        <Badges
          status={status}
          current={current}
          delivery={delivery}
          now={now} />
      ): null}
    </Grid>
  );
};

function Badges({
  status,
  current,
  delivery,
  now,
}) {
  return (
    <Grid item xs={12}>
      <div style={{ height: 8 }} />
      <Grid container spacing={1}>
        <Grid item>
          <Chip
            size="small"
            label={status ? status.title : 'Estatus desconocido'}
            style={{
              backgroundColor: current
                ? "black" : (status ? status.color : 'black'),
              color: 'white'
            }}
          />
        </Grid>
        {delivery?.distanciaAprox ? (
          <Grid item>
            <Chip
              size="small"
              label={
                `${Math.round(delivery.distanciaAprox * 10) / 10} km`
              }
            />
          </Grid>
        ) : null}
        {
          delivery.fechaActualizacion
            ? <Grid item>
              <Chip
                size="small"
                label={
                  delivery.fechaActualizacion
                    ? `Hace ${moment.duration(
                      now.diff(moment.tz(delivery.fechaActualizacion, 'utc'))
                    ).humanize()
                    }`
                    : null
                }
              />
            </Grid>
            : null
        }
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles({
  smallChip: {
    padding: 0,
  }
});