import React, { useState, useEffect } from 'react';
import {
  Container,
  CardContent,
  Card,
  Grid,
  CircularProgress,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import API from '../api';

const api = API.instance();
const Money = (props) => {
  return (
    <NumberFormat
      thousandSeparator={true}
      decimalScale={2}
      fixedDecimalScale={2}
      displayType="text"
      prefix="$"
      {...props}
    />
  );
}
const Distance = (props) => {
  return (
    <NumberFormat
      thousandSeparator={true}
      displayType="text"
      suffix=" km"
      {...props}
    />
  );
}

function RatesScreen(props) {
  const [rates, setRates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    api.getRates({withDetails: 1}).then(r => {
      setRates(r.result);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return (
      <Container component="main">
        <CircularProgress />
      </Container>
    );
  }
  
  return (
    <Container component="main">
      <h1>Tarifas</h1>
      <Grid container spacing={3}>
        {rates.map(r =>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item>
                    {r.description}
                  </Grid>
                  <Grid item>
                    {r.isDefault
                      ? <Chip color="primary" label="Predeterminada" variant="outlined" />
                      : null
                    }
                  </Grid>
                </Grid>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell><strong>Distancia</strong></TableCell>
                      <TableCell><strong>Fija</strong></TableCell>
                      <TableCell><strong>Base</strong></TableCell>
                      <TableCell><strong>Por Km</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      r.details ? r.details.map(d =>
                        <TableRow>
                          <TableCell>
                            De {d.minDistance} a <Distance value={d.maxDistance} />
                          </TableCell>
                          <TableCell><Money value={d.fixed} /></TableCell>
                          <TableCell className={d.base === null ? 'bg-light' : ""}>
                            {d.base !==null ? <Money value={d.base} /> : ""}
                          </TableCell>
                          <TableCell>
                            {
                              d.perDistance !== null
                                ? <Money value={d.perDistance} />
                                : null
                            }
                          </TableCell>
                        </TableRow>
                      ) : null
                    }
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

export default RatesScreen;