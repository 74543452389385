import React from 'react';
import Container from '@material-ui/core/Container';
import { withRouter } from "react-router-dom";
import API from '../api';
import {
  CircularProgress,
} from '@material-ui/core';
import DriverForm from '../components/driver-form';

const api = API.instance();

class DriverEditScreen extends React.Component {
  state = {
    'user': '',
    'password': '',
    nombre: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    driver: null,
    zona: null,
    zonas: [],
    plusType: 'any',

    isSaving: false,
    isLoading: true
  }

  async save() {
    const {
      isSaving,
      nombre,
      apellidoPaterno,
      apellidoMaterno,
      driver,
      phone,
      email,
      zonaId,
    } = this.state;

    if (isSaving) {
      return;
    }

    this.setState({
      isSaving: true
    });

    try {
      await api.saveChofer({
        choferId: driver.choferId,
        zonaId,
        idClientesNavigation: {
          nombre,
          apellidoPaterno,
          apellidoMaterno,
          correo: email,
          numCelular: phone,
        },
      });

      this.setState({
        isSaving: false,
      }, () => {
        this.props.history.push('/drivers');
      });
    } catch (e) {
      console.error(e);
      this.setState({
        isSaving: false,
      });
    }
  }

  async load() {
    try {
      const driver = await api.getChofer(
        this.props.match.params.id
      );

      this.setState({
        driver,
        isLoading: false,
        nombre: driver.cliente.nombre,
        apellidoPaterno: driver.cliente.apellidoPaterno,
        apellidoMaterno: driver.cliente.apellidoMaterno,
        phone: driver.cliente.numCelular,
        email: driver.cliente.correo,
        zonaId: driver.zonaId
      });

    } catch (e) {
      console.error(e);
      this.setState({isLoading: false});
    }
  }

  async loadZones() {
    try {
      const zonas = await api.getZonas({withVertices: false});
      this.setState({zonas});
    } catch (e) {
      console.error(`[loadZones] error: ${e}`);
    }
  }

  componentDidMount() {
    this.loadZones();
    this.load();
  }

  render() {
    const {
      driver,
      isLoading,
    } = this.state;

    if (isLoading) {
      return (
        <Container>
          <CircularProgress />
        </Container>
      );
    }

    return (
      <Container>
        <h1>Editar conductor</h1>
        <DriverForm driver={driver} />
      </Container>
    );
  }
}

export default withRouter(DriverEditScreen);