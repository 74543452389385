import React, { useState, useCallback } from 'react';
import {
  Dialog,
  List,
  ListItem,
  Typography,
  Divider,
  Avatar,
  Grid,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TextField,
  ListItemSecondaryAction,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeliveryFullTile from '../delivery-full-tile';
import { pink, green, grey } from '@material-ui/core/colors';
import Button from '../button';
import API from '../../api';

import {
  Check as CheckIcon
} from '@material-ui/icons';

const api = API.instance();

export default function PhaseModal(props) {
  const [commentingRegistryId, setCommentingRegistryId] = useState(null);
  const [savingFollowing, setSavingFollowing] = useState(false);
  const [comment, setComment] = useState("");
  const styles = useListStyles();
  const { delivery } = props;

  const handleOpenResolve = useCallback(registryId => {
    setCommentingRegistryId(registryId);
  }, []);

  const handleSolve = useCallback(e => {
    e.preventDefault();
    api.saveRegistryFollowing({
      registryId: commentingRegistryId,
      comment,
    });
    setCommentingRegistryId(null);
  }, [commentingRegistryId, comment]);

  const handleCommentChange = useCallback(e => {
    setComment(e.target.value);
  }, []);

  return (
    <Dialog {...props} maxWidth="lg" scroll="body">
      <List className={styles.list}>
        <ListItem>
          <DeliveryFullTile delivery={delivery} />
        </ListItem>
        <Div />
        <ListItem className={styles.itemStyles}>
          <Grid container direction="column" spacing={1}>
            <Grid item component={Typography} variant="h6">
              Actual responsable
            </Grid>
            <Grid item container direction="row" spacing={1} alignItems="center">
              <Grid item>
                <Avatar size={64} src={delivery?.responsible?.imageUrl} />
              </Grid>
              <Grid item>
                {delivery?.responsible?.name ?? "Sin nombre"}
              </Grid>
            </Grid>
          </Grid>
        </ListItem>
        <Div />
        <ListItem disableGutters>
          <Grid container direction="column">
            <Grid item style={{marginLeft: 16}} component={Typography} variant="h6">
              Bitácora
            </Grid>
            <Grid item>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Descripción</TableCell>
                    <TableCell>Fecha y hora</TableCell>
                    <TableCell>Persona</TableCell>
                    <TableCell>Acción</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {delivery?.bitacore?.map(registry =>
                    <React.Fragment>
                      <TableRow selected={registry.id === commentingRegistryId}>
                        <TableCell>
                          {registry.description}
                          {registry?.followingStatus === "attended" ? (
                            <React.Fragment>
                              <Grid container flexDirection="row" spacing={1}>
                                <Grid item>
                                  <CheckIcon style={{width: 16, height: 16, color: green[700]}} />
                                </Grid>
                                <Grid item style={{color: green[700]}}>Se dio seguimiento.</Grid>
                              </Grid>
                              <div style={{color: grey[600]}}>
                                {registry?.followingComment ?? ""}
                              </div>
                            </React.Fragment>
                          ) : null}
                        </TableCell>
                        <TableCell>{registry.timestamp.format('DD/MM/YY hh:mm')}</TableCell>
                        <TableCell>
                          <Grid container direction="row" wrap='nowrap' spacing={1} alignItems="center">
                            <Grid item>
                              <Avatar src={registry?.user?.imageUrl} />
                            </Grid>
                            <Grid item>
                              {registry?.user?.name}
                            </Grid>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Button variant="contained" color="primary"
                            isLoading={savingFollowing}
                            disabled={registry.id === commentingRegistryId}
                            onClick={() => handleOpenResolve(registry?.id)}>Resolver</Button>
                        </TableCell>
                      </TableRow>
                      {registry.id === commentingRegistryId ? (
                        <CommentRow selected
                          onSubmit={handleSolve}
                          comment={comment}
                          onCommentChange={handleCommentChange} />
                      ) : null}
                    </React.Fragment>
                  )}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
          
        </ListItem>
      </List>
    </Dialog>
  )
}

const Div = () => <Divider component="div" style={{ height: 1, backgroundColor: '#ccc' }} />;

const useListStyles = makeStyles({
  root: {
    fontWeight: 'normal',
    backgroundColor: 'blue'
  }
});

function CommentRow(props) {
  return (
    <TableRow {...props}>
      <TableCell colSpan={4} style={{paddingBottom: 16, borderBottom: `1px solid ${pink[500]}`}}>
        <Grid container flexDirection="column" direction="column" spacing={1}>
          <Grid item style={{fontWeight: "bold"}}>
            Extiende aquí el seguimiento al estatus.
          </Grid>
          <Grid item>
            <TextField variant="outlined" multiline
              value={props?.comment ?? ""}
              onChange={props?.onCommentChange}
              color="blue"
              style={{ backgroundColor: "white", width: '100%' }} />
          </Grid>
          <Grid item>
            <Grid container flexDirection="row" spacing={2} alignItems="start" justifyContent="center">
              <Grid item>
                <Button onClick={props?.onSubmit} color="primary">
                  Guardar seguimiento
                </Button>
              </Grid>
              <Grid item>
                Se marcará como resuelto.
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
}