import React from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from '@material-ui/core';
import AssignDeliveryForm from './assign-delivery-form';

export default function RequestDialog({
	open,
	onClose,
	delivery: d,
	drivers,
	isCancelling,
	onSelectDriver,
	onAssign,
	onCancel,
	selectedDriverId,
	assigning,
	cancellable,
	collectProducts = false,
	title = "Asignación de chofer",
	assignTitle = "Asignar",
}) {	
	return (
		<Dialog
      open={open}
      onClose={onClose}
      scroll="body"
      maxWidth="md"
      fullWidth={true}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
			disableEscapeKeyDown
    >
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent className="bg-light">
        <AssignDeliveryForm
					delivery={d}
					drivers={drivers}
					isCancelling={isCancelling}
					onSelectDriver={onSelectDriver}
					onAssign={onAssign}
					onCancel={onCancel}
					selectedDriverId={selectedDriverId}
					assigning={assigning}
					cancellable={cancellable}
					onClose={onClose}
					assignTitle={assignTitle}
					collectProducts={collectProducts}
				/>
      </DialogContent>
			<DialogActions />
    </Dialog>
	);
}