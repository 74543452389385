import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  CircularProgress,
  Chip,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Container,
  Paper,
  Select,
  Grid,
  List,
  ListItem,
  MenuItem,
  IconButton,
  ListItemText,
  ListItemSecondaryAction,
  TableFooter,
  Button,
} from '@material-ui/core';
import API from '../api';
import moment from 'moment';
import momentz from 'moment-timezone';
import NumberFormat from 'react-number-format';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  EmojiPeople as EmojiPeopleIcon,
  AirportShuttle as AirportShuttleIcon,
  HeadsetMic as HeadsetMicIcon,
  Print as PrintIcon,
  OpenInNew as OpenInNewIcon,
} from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';
import { Tabs } from '../components/tabs';
import { parse, encode } from 'querystring';
import { useHistory } from "react-router-dom";

const tz = moment.tz.guess();
const api = API.instance();

const Money = (props) => {
  return (
    <NumberFormat
      thousandSeparator={true}
      decimalScale={2}
      fixedDecimalScale={2}
      displayType="text"
      prefix="$"
      {...props}
    />
  );
}

function transferTargetDigest(tt) {
  switch (tt) {
    case "owner":
      return "Cliente Marketplace";
    case "driver":
      return "Chofer";
    default:
      return tt;
  }
}

function listDescriptionDigest(tt) {
  switch (tt) {
    case "owner":
        return "Productos vendidos de ";
    case "driver":
      return "Entregas completadas por ";
    case "seller":
      return "Ventas hechas por ";
    default:
      return "";
  }
}

function indexForTarget(tt) {
  switch (tt) {
    case "owner":
      return 0;
    case "driver":
      return 1;
    default:
      return 2;
  }
}

function targetForIndex(index) {
  switch (index) {
    case 1: return "driver";
    case 2: return "seller";
    case 0:
    default:
      return "owner";
  }
}

const targets = ["seller", "driver", "owner"];

export default function PaymentScreen({ location }) {
  const history = useHistory();
  const [payouts, setPayouts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [expandedIndexes, setExpandedIndexes] = useState([]);
  const queryParams = useMemo(() => parse(location.search.substring(1)), [location.search]);
  const counterTitle = useMemo(() => {
    switch (queryParams.target) {
      case "seller": return "Ventas hechas";
      case "driver": return "Entregas completadas";
      case "owner":
      default:
        return "Productos vendidos";
    }
  }, [queryParams.target]);
  const selectedTabIndex = useMemo(
    () => indexForTarget(queryParams?.target || 'owner'),
    [queryParams.target]
  );
  const isPrintable = useMemo(() => {
    return queryParams.print ? true : false;
  }, [queryParams]);

  const targetUser = useMemo(() => {
    const index = targets.indexOf(queryParams.target);
    return index > -1 ? targets[index] : "owner";
  }, [queryParams.target, targets]);

  const dateName = useMemo(() => {
    if (targetUser === "seller") {
      return "requestedAt";
    } else if (targetUser === "driver") {
      return "deliveredAt";
    } else {
      return null;
    }
  }, [targetUser]);
  
  const weeks = useMemo(() => {
    const today = moment();
    const firstDay = today.startOf('week');

    return [
      {
        week: firstDay.format("YYYY-MM-DD"),
        title: "Esta semana",
        fromDate: firstDay.format("D MMM"),
        toDate: firstDay.clone().add('6', 'days').format("D MMM"),
      }
    ];
  }, []);
  const [week, setWeek] = useState(weeks[0]);

  const load = useCallback(async () => {
    try {
      const target = queryParams.target;
      const results = await api.getMarketplacePendingPayouts({
        week,
        target: queryParams.target
      });
      if ((queryParams?.target || 'owner') === target) {
        setPayouts(results.map(payout => {
          return {
            ...payout,
            deliveries: payout?.deliveries?.map(delivery => {
              return {
                ...delivery,
                deliveredAt: momentz.tz(delivery.deliveredAt, 'utc').tz(tz),
                requestedAt: moment.tz(delivery.requestedAt, 'utc').tz(tz)
              };
            })
          };
        }));
      }
    } catch (e) {
      console.log(`[PaymentScreen.load] ${e}`);
    }
    setIsLoading(false);
  }, [week, queryParams]);

  const handleSelectWeek = useCallback((e) => {
    setWeek(weeks.filter(i => i.week === e.target.value)[0]);
  }, [weeks]);

  const handleExpand = useCallback((e) => {
    const index = parseInt(e.currentTarget.getAttribute('data-item-index'));
    if (expandedIndexes.indexOf(index) > -1) {
      setExpandedIndexes(expandedIndexes.filter(i => i !== index));
    } else {
      setExpandedIndexes([...expandedIndexes, index]);
    }
  }, [expandedIndexes]);

  const handleChangeTab = useCallback((index) => {
    const target = targetForIndex(index);
    const newParams = {...queryParams, target};
    setPayouts(null);
    history.replace(`${history.location.pathname}?${encode(newParams)}`);
  }, [history, queryParams]);

  const handlePrintTarget = useCallback(() => {
    const params = {...queryParams, print: 1};
    window.open(`/payment/printable?` + encode(params), '_blank');
  }, [queryParams, location]);

  useEffect(() => {
    if (week) { 
      setIsLoading(true);
      load();
    }
  }, [week, queryParams.target]);

  return (
    <Container>
      <h1 style={{fontSize: isPrintable ? 20 : 30}}>Pagos de semana {week.fromDate} - {week.toDate}</h1>
      {!isPrintable ? (
        <React.Fragment>
          <div>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4}>
                <Select variant="outlined" margin="dense" fullWidth value={week.week} onChange={handleSelectWeek}>
                  {weeks?.map(w =>
                    <MenuItem value={w.week} key={w.week}>
                      <ListItemText>{w.title}</ListItemText>
                      <ListItemSecondaryAction style={{marginRight: 24}}>
                        <Chip label={
                          `${w.fromDate} - ${w.toDate}`
                        } size="small" color="default" />
                      </ListItemSecondaryAction>
                    </MenuItem>
                  )}
                </Select>
              </Grid>
              <Grid item xs={8}>
                <Tabs selectedIndex={selectedTabIndex} onChange={handleChangeTab} items={[
                  {label: "Clientes", icon: EmojiPeopleIcon},
                  {label: "Choferes", icon: AirportShuttleIcon},
                  {label: "Vendedores", icon: HeadsetMicIcon},
                ]} />
              </Grid>
            </Grid>
          </div>
        </React.Fragment>
      ) : null}
      
        <TableContainer className={isPrintable ? "printable" : null} component={Paper} style={{borderRadius: 16, marginTop: 16}}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell><strong>Persona / Cuenta</strong></TableCell>
                <TableCell align="center"><strong>{counterTitle}</strong></TableCell>
                <TableCell align="right"><strong>Pago</strong></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={5} style={{padding: 16}}>
                    <Grid container alignItems="center" justifyContent="center" spacing={1}>
                      <Grid item>
                        <CircularProgress size={16} color="black" />  
                      </Grid>
                      <Grid item>
                        Obteniendo resultados...
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              ) : (!payouts || !payouts.length ? (
                <TableRow>
                  <TableCell colSpan={4} style={{padding: 16, textAlign: "center"}}>
                    No se encontraron resultados.
                  </TableCell>
                </TableRow>
              ) : null)}
              
              {payouts?.map((payout, index) => {
                return (
                  <React.Fragment key={index}>
                    <TableRow>
                      <TableCell>{payout.name}</TableCell>
                      <TableCell align="center">
                        {payout.totalCount}
                      </TableCell>
                      <TableCell align="right">
                        <Money value={payout.transfer} />
                      </TableCell>
                      <TableCell align="right">
                        {!isPrintable ? <IconButton size="small" onClick={handleExpand} data-item-index={index}>
                          {expandedIndexes.indexOf(index) > -1 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton> : null}
                      </TableCell>
                    </TableRow>
                    {expandedIndexes.indexOf(index) > -1 || isPrintable ? (
                      <TableRow>
                        <TableCell colSpan={5} style={{backgroundColor: !isPrintable ? grey[200] : "transparent "}}>
                          {listDescriptionDigest(payout.transferTarget)} <strong>{payout.name}</strong>
                          <TableContainer component={Paper} elevation={0}
                            className={isPrintable ? "printable" : null}
                            style={{marginTop: 8, marginBottom: 8, borderRadius: 16, marginBottom: 8}}>
                            {targetUser === "driver" ? (
                              <DriverTable deliveries={payout?.deliveries} />
                            ) : (targetUser === "owner" ? (
                              <MarketClientTable products={payout?.products} />
                            ) : (targetUser === "seller" ? (
                              <SellerDetailTable deliveries={payout?.deliveries} />
                            ) : null))}
                          </TableContainer>
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
          {!isPrintable ? (
            <div style={{paddingBottom: 16}}>
              <div style={{padding: 16, paddingBottom: 0, textAlign: "right"}}>
                <Button startIcon={<PrintIcon />}
                  color="black"
                  variant="contained"
                  disabled={isLoading}
                  onClick={handlePrintTarget}
                  disableElevation>Imprimir</Button>
              </div>
            </div>
          ) : null}
        </TableContainer>
    </Container>
  )
}

function SellerDetailTable({ deliveries }) {
  return (
    <Table
      elevation={0}
      size="small"
    >
      <TableHead>
        <TableRow>
          <TableCell><strong>Producto vendido</strong></TableCell>
          <TableCell><strong>Fecha</strong></TableCell>
          <TableCell align="center"><strong>Cantidad vendida</strong></TableCell>
          <TableCell align="right"><strong>Pago</strong></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {deliveries?.map(delivery =>
          <TableRow key={delivery.id}>
            <TableCell>{delivery.description}</TableCell>
            <TableCell>{delivery.requestedAt.format('DD/MM/YYYY HH:mm')}</TableCell>
            <TableCell align="center">{delivery.productsQuantity}</TableCell>
            <TableCell align="right"><Money value={delivery.transfer * delivery.productsQuantity} /></TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}

function MarketClientTable({ products }) {
  return (
    <Table elevation={0} size="small">
      <TableHead>
        <TableRow>
          <TableCell><strong>Nombre de producto</strong></TableCell>
          <TableCell align="right"><strong>Precio de venta</strong></TableCell>
          <TableCell align="right"><strong>Comisión (15% + $25)</strong></TableCell>
          <TableCell align="right"><strong>Cantidad vendida</strong></TableCell>
          <TableCell align="right"><strong>Pago</strong></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {products?.map(product =>
          <TableRow>
            <TableCell>{product.title} <span style={{color: "#333"}}>{product?.sku}</span></TableCell>
            <TableCell align="right"><Money value={product.unitPrice} /></TableCell>
            <TableCell align="right"><Money value={product.commission} /></TableCell>
            <TableCell align="right">{product.quantity}</TableCell>
            <TableCell align="right"><Money value={product.ownerReceive} /></TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

function DriverTable({ deliveries }) {
  return (
    <Table elevation={0} size="small">
      <TableHead>
        <TableRow>
          <TableCell><strong>Entrega</strong></TableCell>
          <TableCell align="left"><strong>Entregado</strong></TableCell>
          <TableCell align="right"><strong>Pago</strong></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {deliveries?.map(product =>
          <TableRow>
            <TableCell>{product.description} <span style={{color: "#333"}}>{product?.sku}</span></TableCell>
            <TableCell align="left">{product?.deliveredAt?.format('dddd, hh:mm A')}</TableCell>
            <TableCell align="right"><Money value={product.transfer} /></TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}