import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
  Container,
  Grid,
  Typography,
  Paper,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Button,
  Dialog,
  DialogContent,
  CircularProgress,
} from '@material-ui/core';
import API from '../api';
import UserSelectorDialog from '../components/user_selector_dialog';
import SpaceDetailDialog from '../components/space_detail_dialog';

const api = new API();
const letters = ['A', 'B', 'C', 'D', 'E', 'F'];

export default function SpacesScreen() {
  const [racks, setRacks] = useState([]);
  const [showSelectDialog, setShowSelectDialog] = useState(false);
  const [loadingSpaces, setLoadingSpaces] = useState([]);
  const [choosingSpace, setChoosingSpace] = useState(null);
  const [chosenUser, setChosenUser] = useState(null);
  const [chosenSpace, setChosenSpace] = useState(null);
  const [showSpaceDetail, setShowSpaceDetail] = useState(false);

  const handleCloseSelectDialog = useCallback(e => {
    setShowSelectDialog(false);
  }, []);
  
  const handleShowSelectDialog = useCallback(e => {
    setShowSelectDialog(true);
  }, []);

  const handleSelectSpace = useCallback(space => {    
    if (loadingSpaces.indexOf(space) > -1) {
      return;
    }

    if (space.id) {
      setShowSpaceDetail(true);
      setChosenSpace(space);
    } else {
      setChoosingSpace(space);
      handleShowSelectDialog(true);
    }
  }, [loadingSpaces]);

  const handleCloseSpaceDetail = useCallback(e => {
    setShowSpaceDetail(false);
  }, []);

  const handleChangeUser = useCallback(async (user) => {
    setChosenUser(user);
    setLoadingSpaces([...loadingSpaces, choosingSpace]);
    setShowSelectDialog(false);

    try {
      const rack = racks.filter(r => r.id === choosingSpace.rackId)[0];
      const space = await api.assignRackSpace({
        rackName: rack.name,
        line: choosingSpace.line,
        column: choosingSpace.column,
        userId: user.id
      });
      const newRacks = racks.map(r => r.id === space.rackId ? {
        ...r,
        lines: r.lines.map((l, idx) => (idx + 1) === space.line ? {
          ...l,
          spaces: l.spaces.map(sp => sp.column === space.column ? space : sp)
        } : l)
      } : r);
      setRacks(newRacks);
    } catch (e) {
      alert(e.message);
    }

  }, [loadingSpaces, choosingSpace, chosenUser, racks]);
  
  useEffect(() => {
    async function load() {
      try {
        const results = await api.getRacks({
          include: ['spaces', 'spaces.owner.products']
        });
        setRacks(normalizeRacks(results));
      } catch (e) {
        console.error(e);
        alert("Algo salió mal: " + e.message);
      }
    }
    load();
  }, []);

  return (
    <Container>
      <h1>Espacios</h1>
      <Grid container spacing={3}>
        {racks.map((rack, index) => {
          return (
            <Grid item xs={6} key={index}>
              <Card style={{borderRadius: 16}}>
                <CardHeader style={{backgroundColor: "#e9e9e9"}} title={<div style={{textAlign: "center"}}>Rack {rack.name}</div>} />
                <CardContent>
                  {rack.lines.map((line, lineIndex) => {
                    return (
                      <Grid container spacing={2} key={lineIndex}>
                        {line.spaces.map((space, indexSpace) => {
                          const loading = loadingSpaces.indexOf(space.code) > -1;
                          return (
                            <Grid item key={indexSpace} xs={3}>
                              <Button fullWidth
                                style={{
                                  backgroundColor: (loading || space.id) ? "#f0f0f0" : "#e0e0e0",
                                  borderRadius: 8,
                                  height: 100, display: "flex", alignItems: "center", justifyContent: "center"
                                }}
                                data-space-code={space.code}
                                onClick={handleSelectSpace.bind(null, space)}
                              >
                                <div style={{textAlign: "center", fontSize: 10, lineHeight: "14px", display: "flex", flexDirection: "column", alignItems: "center"}}>
                                  {loading ? (
                                    <Fragment>
                                      <CircularProgress size={16} color="default" style={{marginBottom: 4}} />
                                      <div>Asignando</div>
                                    </Fragment>
                                  ) : (
                                    <Fragment>
                                      <div style={{marginBottom: 8, fontSize: 16, fontWeight: "bold"}}>
                                        {space.code}
                                      </div>
                                      {space?.owner ? (
                                        <React.Fragment>
                                          {space.owner.name?.split(' ')[0]}
                                          <div style={{color: "#666"}}>
                                            {space.productsCounter ?? 0} productos
                                          </div>
                                        </React.Fragment>
                                      ) : (
                                        <div>Sin asignar</div>
                                      )}
                                    </Fragment>
                                  )}
                                  
                                </div>
                              </Button>
                            </Grid>
                          );
                        })}
                      </Grid>
                    );
                  })}
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      <UserSelectorDialog
        open={showSelectDialog}
        onClose={handleCloseSelectDialog}
        onChange={handleChangeUser}
        title={`Asignar espacio ${choosingSpace?.code} a...`}
        emptyDescription={
          "Busca un usuario en el cuadro de búsqueda y da click sobre uno"
          + "para asignarle el espacio del rack."
        }
      />
      <SpaceDetailDialog
        space={chosenSpace}
        open={showSpaceDetail}
        onClose={handleCloseSpaceDetail} />
    </Container>
  );
}

function normalizeRacks(racks) {
  racks.forEach(rack => {
    const lines = [];
    for (let y = 1; y <= rack.lines; y++) {
      const line = {spaces: []};
      for (let x = 0; x < rack.columns; x++) {
        const col = letters[x];
        const space = rack.spaces?.filter(s => s.line === y && s.column === col);
        if ((space?.length ?? 0) > 0) {
          line.spaces.push({...space[0]});
        } else {
          line.spaces.push({
            column: letters[x],
            line: y,
            code: `${rack.name}${y}${letters[x]}`,
            rackId: rack.id,
          });
        }
      }
      lines.push(line);
    }
    rack.lines = lines;
  });

  return racks;
}