import React, {useState, useCallback, Fragment} from 'react';
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  IconButton,
  FormControl,
  OutlinedInput,
  InputAdornment,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
} from '@material-ui/core';
import {
  Search as SearchIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
} from '@material-ui/icons';
import API from '../api';

const api = new API();

export default function UserSelector({
  open,
  onClose,
  user,
  onChange,
  onClear,
  emptyDescription,
  title,
}) {
  const [value, setValue] = useState('');
  const [users, setUsers] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  const handleSubmit = useCallback(e => {
    e.preventDefault();

    setIsSearching(true);

    const options = {
      search: value,
      resultsPerPage: 10,
    };

    api.getUsers(options).then(u => {
      setUsers(u);
      setIsSearching(false);
    }).catch(err => {
      console.log(`submit: ${err}`);
      setIsSearching(false);
    });

    return false;
  }, [value]);

  const handleSelect = useCallback(user => {
    setUsers([]);
    setValue(user?.name ?? "");
    if ('function' === typeof onChange) {
      onChange(user);
    }
  }, [onChange]);

  const handleChange = useCallback(e => {
    const val = e.target.value;
    setValue(val);

    if (!val || val.trim() === '') {
      if ('function' === typeof onClear) {
        onClear();
      }
    }
  }, []);

  return (
    <Dialog open={open} onClose={onClose} size="lg">
      {title ? (
        <DialogTitle>
          {title}
        </DialogTitle>
      ) : null}

      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container direction="row" alignItems="center" spacing={1}>
            <Grid item>
              <FormControl
                variant="outlined"
                label="Usuario"
                fullWidth
              >
                <OutlinedInput
                  margin="dense"
                  value={value}
                  onChange={handleChange}
                  placeholder="Busca un usuario"
                  endAdornment={
                    <InputAdornment position="end">
                      <Grid container>
                        <IconButton type="submit" TouchRippleProps={{type: "submit"}} >
                          {isSearching
                            ? <CircularProgress size={16} />
                            : <SearchIcon />}
                        </IconButton>
                      </Grid>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            {user ? (
              <Grid item>
                <CheckCircleOutlineIcon />
              </Grid>
            ) : null}
          </Grid>
        </form>
      </DialogContent>
      
      {users && users.length > 0 ? (
        <List>
          {users?.map(u =>
            <ListItem button key={u.id} dense={true} onClick={() => handleSelect(u)}>
              <ListItemIcon>
                <Avatar src={u.imageUrl} />
              </ListItemIcon>
              <ListItemText
                primary={`${u.name} (ID: ${u.id})`} />
            </ListItem>
          )}
        </List>
      ) : (
        emptyDescription ? (
          <DialogContent>{emptyDescription}</DialogContent>
        ) : null
      )}
      <DialogActions>
        <Button onClick={onClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  )
}