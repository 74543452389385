import React, { useReducer, useEffect, useCallback } from 'react';
import API from '../api';
import { DeliveryStatus } from '../models/delivery';
import useSound from 'use-sound';
import NumberFormat from "react-number-format";
import RequestCard from '../components/request-card';

import {
  CircularProgress,
  Container,
  Grid,
} from '@material-ui/core';

const api = API.instance();

function reducer(state, action) {
  switch (action.name) {
    case "updateDeliveries":
      return {
        ...state,
        deliveries: action.deliveries
      }

    case "selectDriver":
      const st = {...state};
      st.selectedDriverIds[action.deliveryId] = action.driverId;
      return st;

    case "loadDrivers":
      return {
        ...state,
        drivers: action.drivers
      };

    case "popDelivery":
      return {
        ...state,
        deliveries: state.deliveries
          .filter(d => d.id !== action.deliveryId)
      };

    case "assigned":
      return {
        ...state,
        deliveries: state.deliveries
          .filter(d => d.id !== action.deliveryId),
        assigningIds: state.assigningIds
          .filter(d => d.id !== action.deliveryId)
      };

    case "startCancelling":
      return {
        ...state,
        cancellingIds: [
          ...state.cancellingIds
            .filter(id => id !== action.deliveryId),
          action.deliveryId
        ] 
      }

    case "startAssigning":
      return {
        ...state,
        assigningIds: [
          ...state.assigningIds
            .filter(id => id !== action.deliveryId),
          action.deliveryId
        ]
      }
  }

  return state;
}

const initialState = {
  deliveries: [],
  isReloading: false,
  assigningIds: [],
  selectedDriverIds: {"vacio": "si"},
  cancellingIds: [],
  drivers: []
};

export default function SolicitudesScreen() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [playAlert] = useSound('swiftly.mp3');

  const handleSelectDriver = useCallback((driverId, deliveryId) => {
    dispatch({name: 'selectDriver', driverId, deliveryId});
  }, []);

  const handleAssign = useCallback(async (deliveryId) => {
    dispatch({name: 'startAssigning', deliveryId});
    try {
      const response = await api.assignDelivery({
        deliveryId,
        driverId: state.selectedDriverIds[deliveryId]
      });

      if (response.isSuccess) {
        dispatch({name: "assigned", deliveryId});
      }
    } catch (e) {
      console.log(`[handleAssign/${deliveryId}] error: ${e}`);
    }
  }, []);

  const handleCancel = useCallback(async (deliveryId) => {
    dispatch({name: 'startCancelling', deliveryId});
    try {
      const response = await api.cancelDeliveryAsAdmin(deliveryId);
      if (response.isSuccess) {
        dispatch({name: "popDelivery", deliveryId});
      }
    } catch (e) {
      console.log(`[handleCancel/${deliveryId}] error: ${e}`);
    }
  }, []);

  const loadDrivers = useCallback(async () => {
    try {
      const response = await api.getDrivers({ activeState: 1 });
      dispatch({
        name: 'loadDrivers',
        drivers: response.result
      });
      /*estimateDriverDistances({
        drivers: r.result,
        delivery: item
      });*/
    } catch (e) {
      console.log(`[loadDrivers] ${e}`);
    }
  }, []);

  useEffect(() => {
    const t = {timeout: null};
    async function reload() {
      let willPlay = false;

      try {
        const response = await api.getDeliveries({
          status: DeliveryStatus.requested,
          includePackage: true,
          includeUser: true,
          includeZones: true,
        });

        if (response.isSuccess) {
          if (response.result.length > 0
            && state.deliveries.length === 0) {
            willPlay = true;
          }
          dispatch({
            name: 'updateDeliveries',
            deliveries: response.result
          });
        } else {
          console.log(`[reload] error: ${response.message ?? ""}`);
        }
      } catch (e) {
        console.log(`[reload] error: ${e}`);
      }
      if (willPlay) {
        playAlert();
        console.log("playing");
      }
      t.timeout = setTimeout(reload, 4000);
    }

    loadDrivers();
    reload();

    return () => {
      if (t.timeout) {
        clearInterval(t.timeout);
      }
    };
  }, []);

  const {
    cancellingIds,
    selectedDriverIds,
    assigningIds,
    drivers,
  } = state;

  return (
    <Container>
      <h1>Solicitudes <CircularProgress size={16} /></h1>

      <Grid container spacing={2}>
        {state.deliveries.map(d =>
          <Grid item key={d.id}>
            <RequestCard
              delivery={d}
              isCancelling={cancellingIds.indexOf(d.id) > -1}
              onSelectDriver={handleSelectDriver}
              onAssign={handleAssign}
              onCancel={handleCancel}
              selectedDriverId={selectedDriverIds[d.id] || 0}
              assigning={assigningIds.indexOf(d.id) > -1}
              drivers={drivers}
              cancellable={true}
            />
          </Grid>
        )}
      </Grid>
    </Container>
  );
}