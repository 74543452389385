import React, {
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react';
import {
  Container,
  Grid,
  Card,
  CardContent,
  TextField,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import API from '../../api';
import ButtonWithMessage from '../../components/button-with-message';

const api = API.instance();

const initialState = {
  isLoading: true,
  isSaving: false,
  isSaved: false,
  error: null,
  
  category: null,

  // Fields
  name: '',
  slug: '',
};

const Action = {
  LOAD: 1,
  SELECT_CATEGORY: 2,
  REPLACE_IMAES: 3,
  DELETE_IMAGE: 4,
  UPDATE_FIELD: 5,
  START_SAVING: 6,
  SAVE_SUCCESS: 7,
  SAVE_ERROR: 8
};

function reducer(state, action) {
  switch (action.type) {
    case Action.LOAD:
      const { category: c } = action;
      return { ...state,
        category: c,
        isLoading: false,
        
        // Fields
        name: c.name,
        slug: c.slug,
      };
    case Action.SELECT_CATEGORY:
      return { ...state,
        selectedCategories: action.selected,
      };
    case Action.REPLACE_IMAGES:
      return { ...state,
        category: { ...state.category,
          imageUrl: action.images.imageUrl,
          thumbnailImageUrl: action.images.thumbnailImageUrl 
        }
      };
    case Action.DELETE_IMAGE:
      return { ...state,
        category: { ...state.category,
          images: state.category.images.filter(
            i => i.id !== action.imageId
          )
        }
      };
    case Action.START_SAVING:
      return { ...state, isSaving: true, isSaved: false, error: null };
    case Action.UPDATE_FIELD:
      const { field, value } = action;
      return { ...state, [field]: value };
    case Action.SAVE_SUCCESS:
      return { ...state, isSaving: false, isSaved: true };
    case Action.SAVE_ERROR:
        return { ...state, isSaving: false, error: action.error };
    default:
      return state;
  }
}

function CategoryScreen({ match }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleSave = useCallback(async () => {
    if (state.isSaving) {
      return;
    }
    
    dispatch({ type: Action.START_SAVING });

    try {
      const response = await api.saveCategory(state.category?.id, {
        name: state.name,
        slug: state.slug,
      });

      if (response.isSuccess) {
        dispatch({ type: Action.SAVE_SUCCESS });
      } else {
        dispatch({
          type: Action.SAVE_ERROR,
          error: response.message
        });
      }
    } catch (e) {
      console.log(`[handleSave] Error: ${e}`);
      window.alert(e.toString());
    }
  }, [state]);

  useEffect(() => {
    async function getCategory(op) {
      const category = await api.getCategoryById(match.params.id);
      dispatch({
        type: Action.LOAD,
        category,
      });
    }
    getCategory();
  }, [match.params.id]);

  const { category } = state;

  if (state.isLoading) {
    return ('Loading');
  }

  return (
    <Container>
      <h1>Categoría</h1>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    variant="outlined"
                    label="Título"
                    fullWidth={true}
                    defaultValue={state.name}
                    onBlur={e => dispatch({
                      type: Action.UPDATE_FIELD,
                      field: 'name',
                      value: e.target.value,
                    })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    variant="outlined"
                    label="Slug"
                    fullWidth={true}
                    defaultValue={state.slug}
                    onBlur={e => dispatch({
                      type: Action.UPDATE_FIELD,
                      field: 'slug',
                      value: e.target.value,
                    })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    variant="outlined"
                    label="Descripción"
                    multiline
                    rows={3}
                    fullWidth={true}
                    defaultValue={state.description}
                    onBlur={e => dispatch({
                      type: Action.UPDATE_FIELD,
                      field: 'description',
                      value: e.target.value,
                    })}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5">Imágenes</Typography>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <ImageManager
                url={state.imageUrl}
                thumbnailUrl={category.thumbnailImageUrl}
                categoryId={category?.id}
                onSelectImage={(images) => dispatch({
                  type: Action.REPLACE_IMAGES,
                  images,
                })}
                onDeleteImage={(imageId) => dispatch({
                  type: Action.DELETE_IMAGE,
                  imageId,
                })}
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} container align="center">
          <ButtonWithMessage
            color="primary"
            onClick={handleSave}
            isSaving={state.isSaving}
            message={state.isSaved ? "Guardado" : state.error}
            messageColor={state.error ? 'error' : null}
          >Guardar categoría</ButtonWithMessage>
        </Grid>
      </Grid>
    </Container>
  );
}

const defaultImageToUpload = () => ({
  image: null,
  isUploading: false,
  hash: Math.random().toString(36).substring(2, 10)
});

function ImageManager({
  onSelectImage,
  onDeleteImage,
  categoryId,
  url,
  thumbnailUrl,
}) {
  const [imagesToUpload, setImagesToUpload] = useState(
    [defaultImageToUpload()]
  );

  const handleFileChange = useCallback(e => {
    const hash = e.target.getAttribute('datahash');

    if (e.target.files.length === 0) {
      return;
    }

    setImagesToUpload([
      ...imagesToUpload.map(i => i.hash !== hash ? i : (
        { ...i, isUploading: true }
      )),
      defaultImageToUpload(),
    ]);

    api.putCategoryImage({
      categoryId,
      file: e.target.files[0]
    }).then(images => {
      setImagesToUpload(imagesToUpload.filter(i => i.hash !== hash));
      if ('function' === typeof onSelectImage) {
        onSelectImage(images);
      }
    });
  }, [categoryId, imagesToUpload, onSelectImage]);

  const handleDeleteFile = useCallback(e => {
    if (window.confirm('¿Eliminar esta imagen?')) {
      const id = parseInt(e.target.getAttribute('dataimageid'));
      api.deleteCategoryImage(id).then(r => {
        if ('function' === typeof onDeleteImage) {
          onDeleteImage(id);
        }
      }).catch(err => {
        window.alert(err.toString());
      });
    }
  }, [onDeleteImage]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} container spacing={1}>
        <Grid item >
          <button
            onClick={handleDeleteFile}
            style={{position: 'absolute'}}
          >X</button>
          <img src={thumbnailUrl} alt="category thumbnail" style={{width: 128, height: 128}} />
        </Grid>
      </Grid>
      <Grid item xs={12} container>
        {imagesToUpload.map(i =>
          <Grid item xs={12} key={i.hash}>
            {i.isUploading ? (
              <Grid container spacing={1}>
                <Grid item><CircularProgress size={16} /></Grid>
                <Grid item>Cargando...</Grid>
              </Grid>
            ) : (
              <input type="file"
                onChange={handleFileChange}
                datahash={i.hash} />
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default CategoryScreen;