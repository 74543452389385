import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import {
  AppBar as AppBarBase,
  Toolbar,
  Badge,
  IconButton,
  Menu,
  MenuItem,
  Drawer,
  Divider,
  List,
  ListSubheader,
  ListItem,
  ListItemIcon,
  ListItemText,
  CssBaseline,
  Collapse,
  Hidden,
  Typography,
  Avatar,
  Button,
  Popover,
  Grid,
  Card,
  CardContent,
} from '@material-ui/core';
import {
  createStyles,
  makeStyles,
  useTheme,
} from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import { UserRoleType } from '../models/user';

import {
  Notifications as NotificationsIcon,
  EditAttributes as EditAttributesIcon,
  Storage as StorageIcon,
  ViewColumn as ViewColumnIcon,
  NoteAdd as NoteAddIcon,
  Store as StoreIcon,
} from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';
import ClassIcon from '@material-ui/icons/Class'; 
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import GpsOffIcon from '@material-ui/icons/GpsOff';
import pkg from '../../package.json';
import RequestsList from './delivery/requests-list'

import {
  LocalAtm as LocalAtmIcon,
  Input as InputIcon,
  AccountBalance as AccountBalanceIcon,
  Map as MapIcon,
  Tune as TuneIcon,
  Dashboard as DashboardIcon,
  Category as CategoryIcon,
  Business as BusinessIcon,
  DriveEta as DriveEtaIcon,
  EmojiPeople as EmojiPeopleIcon,
  SupervisorAccount as SupervisorAccountIcon,
  Inventory as InventoryIcon,
  AllInbox as AllIboxIcon,
  MoveToInbox as MoveToInboxIcon,
  SyncAlt as SyncAltIcon,
  Explore as ExploreIcon,
  AssignmentTurnedIn as AssignmentTurnedInIcon
} from '@material-ui/icons';

import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';

import { useHistory } from "react-router-dom";
import ReceiptIcon from '@material-ui/icons/Receipt';
import API from '../api';

const iconsMap = {
  dashboard: DashboardIcon,
  storage: StorageIcon,
  explore: ExploreIcon,
  assignment: AssignmentTurnedInIcon
}
const AnIcon = ({name = null, big = false}) => {
  const Comp = iconsMap[name];
  const size = big ? 64 : 24;
  if (Comp) {
    return <Comp fontSize="small" style={{width: size, height: size}} />;
  }
};
const drawerWidth = 250;
const defaultPages = [
  {
    title: "Entregas",
    items: [
      {icon: "dashboard", title: 'Mesa de control', route: '/mesa-control',
        roles: [UserRoleType.ACCOUNT_OWNER, UserRoleType.BRANCH_ADMIN]
      },
      {icon: "storage", title: 'Almacén', route: '/almacen',
        roles: [UserRoleType.STORER]
      },
      {icon: <MapIcon fontSize="small" />, title: 'Mapa', route: '/mapa',
        roles: [UserRoleType.SUPERADMIN]
      },
      {icon: "explore", title: 'Trackeo', route: '/mapa',
        roles: [UserRoleType.ACCOUNT_OWNER, UserRoleType.BRANCH_ADMIN]
      },
      {icon: <AllIboxIcon fontSize="small" />, title: 'Envíos', route: '/deliveries',
        roles: [UserRoleType.ACCOUNT_OWNER, UserRoleType.BRANCH_ADMIN]
      },
      {icon: <MoveToInboxIcon fontSize="small" />, title: 'Capturar entradas', route: '/deliveries/reception',
        roles: [UserRoleType.STORER]
      },
      {icon: "assignment", title: 'Salidas', route: '/sucursal/salidas',
        roles: [UserRoleType.BRANCH_OPERATOR]
      },
      // {icon: <RoomIcon />, title: 'Rutas', route: '/routes'},
      {icon: <InputIcon fontSize="small" />, title: 'Solicitudes', route: '/solicitudes', roles: [UserRoleType.ZONE_MONITOR]},
    ]
  },
  {
    title: "Reparto",
    items: [
      {icon: <EmojiPeopleIcon fontSize="small" />, title: 'Conductores', route: '/drivers', roles: []},
      {icon: <DriveEtaIcon fontSize="small" />, title: 'Vehículos', route: '/vehicles', roles: []},
    ]
  },
  {
    title: "DAS Plus",
    items: [
      {icon: <BusinessIcon fontSize="small" />, title: 'Clientes', route: '/clients', roles: [UserRoleType.USER_MANAGER]},
      {icon: <AccountBalanceIcon fontSize="small" />, title: 'Depósitos', route: '/deposits', roles: []},
      {icon: <ReceiptIcon fontSize="small" />, title: 'Pagos', route: '/payment', roles: []},
    ]
  },
  {
    title: "Marketplace",
    items: [
      {icon: <LocalOfferIcon fontSize="small" />, title: 'Productos', route: '/products',
        roles: [
          UserRoleType.STORER,
          UserRoleType.BRANCH_ADMIN,
          UserRoleType.ACCOUNT_OWNER
        ]
      },
      {icon: <NoteAddIcon fontSize="small" />, title: 'Importador', route: '/products-importer',
        roles: [
          UserRoleType.STORER,
          UserRoleType.BRANCH_ADMIN,
          UserRoleType.ACCOUNT_OWNER
        ]
      },
      {icon: <SyncAltIcon fontSize="small" />, title: 'Entradas', route: '/productos/entradas',
        roles: [UserRoleType.STORER, UserRoleType.BRANCH_ADMIN]
      },
      {icon: <ClassIcon fontSize="small" />, title: 'Categorías', route: '/categories',
        roles: [UserRoleType.STORER]
      },
      {icon: <EditAttributesIcon />, title: "Características", route: '/features',
        roles: [UserRoleType.STORER]
      },
      {icon: <ViewColumnIcon />, title: "Espacios", route: '/storage',
        roles: [UserRoleType.STORER]
      },
      {icon: <StoreIcon />, title: "Sucursales", route: '/sucursales',
        roles: [UserRoleType.STORER, UserRoleType.BRANCH_ADMIN, UserRoleType.ACCOUNT_OWNER]
      },
    ]
  },
  {
    title: "Configuración",
    items: [
      {icon: <LocalAtmIcon fontSize="small" />, title: 'Tarifas', route: '/rates', roles: [UserRoleType.USER_MANAGER]},
      {icon: <TuneIcon fontSize="small" />, title: 'Parámetros', route: '/parameters', roles: [UserRoleType.USER_MANAGER]},
      {icon: <GpsOffIcon fontSize="small" />, title: 'Zonas', route: '/zones', roles: []},
      {icon: <DriveEtaIcon fontSize="small" />, title: 'Tipos de vehículos', route: '/vehicles/types', roles: []},
      {icon: <CategoryIcon fontSize="small" />, title: 'Tipos de paquetes', route: '/packages/types', roles: []},
      {icon: <SupervisorAccountIcon fontSize="small" />, title: 'Usuarios de sistema', route: '/users',
        roles: [UserRoleType.USER_MANAGER, UserRoleType.ACCOUNT_OWNER]
      },
    ]
  }
];

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0
      },
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-center'
    },
    appBar: {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    // necessary for content to be below app bar
    toolbar: {
      ...theme.mixins.toolbar
    },
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    title: {
      flexGrow: 1,
    },
    smallIcon: {
      minWidth: "28px",
      color: "#d0d0d0"
    },
    menuItem: {
      paddingTop: "0px",
      paddingBottom: "0px",
      color: "#d5d5d5"
    },
    menuItemSelected: {
      '&.Mui-selected': {
        backgroundColor: "#222",
        color: "white"
      }
    },
    smallAvatar: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    leftList: {
      backgroundColor: "#333",
      color: "white"
    }
  }),
);

export default function AppBar(props) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { window } = props;
  const api = API.instance();
  const state = props?.state;
  const dispatch = props?.dispatch;
  const userInfo = useMemo(() => {
    try {
      return JSON.parse(localStorage.getItem('UserInfo'));
    } catch (e) {
      return {};
    }
  }, []);

  const userRoles = useMemo(() => API.instance().getSessionRoles(), []);
  const pages = useMemo(() => defaultPages
    .map(p => ({...p,
      items: userRoles.indexOf(UserRoleType.SUPERADMIN) === -1 ? p.items.filter(
        i => !i.roles || i.roles.find(r => userRoles.indexOf(r) > -1)
      ) : p.items
    }))
    .filter(p => p.items.length > 0)
  , [userRoles]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [notifEl, setNotifEl] = useState(null);
  const [cancellingIds, setCancellingIds] = useState([]);
  const [assigningIds, setAssigningIds] = useState([]);

  const open = Boolean(anchorEl);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleNotificationClick = useCallback((event) => {
    setNotifEl(event.currentTarget);
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseNotif = useCallback(() => {
    setNotifEl(null);
  }, []);

  const handleCerrarSesion = () => {
    if (api && api.dropSessionToken) {
      api.dropSessionToken();
      history.push('/login');
    }
  };

  const container = window !== undefined
    ? () => window().document.body
    : undefined;

  const drawer = (
    <div>
      <div className={classes.drawerHeader}>
        <img src="/das-web-header.png"
          alt="DAS logotipo"
          style={{height: '100%'}}
          srcSet='
            /das-web-header.png 1x,
            /das-web-header@2x.png 2x,
            /das-web-header@3x.png 3x,
          '
        />
      </div>
      <Divider />
      <List className={classes.leftList}>
        {pages.map((group, groupIndex) =>
          <Collapse in={true} key={groupIndex} style={{ marginBottom: 12 }}>
            <List subheader={
              <ListSubheader inset={false} style={{lineHeight: "30px", fontWeight: "bold"}}>
                <Typography style={{textTransform: 'uppercase', fontSize: "10px", color: grey[500]}}>
                  {group.title}
                </Typography>
              </ListSubheader>
            }>
              {group.items.map((item, index) =>
                <ListItem
                  selected={history?.location?.pathname?.startsWith(item.route)}
                  disable="true" button
                  classes={{
                    root: classes.menuItem,
                    selected: classes.menuItemSelected
                  }}
                  key={index}
                  dense={true}
                  onClick={() => {
                    if (item.route) {
                      history.push(item.route);
                    }
                  }}>
                  <ListItemIcon className={classes.smallIcon}>
                    {"string" === typeof item.icon ? (
                      <AnIcon name={item.icon} />
                    ) : item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.title} />
                </ListItem>
              )}
            </List>
          </Collapse>
        )}
        <ListItem>
          Versión: {pkg.version}
        </ListItem>
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBarBase
        className={classes.appBar}
        elevation={1}
        color="inherit"
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap className={classes.title}>
            {props.title ? props.title : ''}
          </Typography>
          
            <IconButton onClick={handleNotificationClick}>
              <Badge badgeContent={state.notificationCount}
                color="error"
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
              >
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <Popover anchorEl={notifEl} open={Boolean(notifEl)}
              onClose={handleCloseNotif}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            >
              <Card>
                <RequestsList />
              </Card>
            </Popover>
          
          <div>
            <Button variant="outlined" onClick={handleMenu}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  {userInfo?.nombre}
                </Grid>
                <Grid item>
                  <Avatar className={classes.smallAvatar} src={""} />
                </Grid>
              </Grid>
            </Button>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>Perfil</MenuItem>
              <MenuItem onClick={handleCerrarSesion}>Cerrar sesión</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBarBase>
      
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>

        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main style={{width: '100%'}}>
        <div className={classes.toolbar} />
        {props.content}
      </main>
    </div>
  );
}