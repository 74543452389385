import React from 'react';
import {
  Grid,
  Chip,
  Avatar,
  Divider,
} from '@material-ui/core';
import {
  blue, pink, green,
} from '@material-ui/core/colors';
import { DeliveryStatus, DeliveryPaymentOrigin } from '../models/delivery';
import moment from 'moment-timezone';
import { PaymentMethod } from '../models/payment';
import PlusChip from './pluschip';
import {
  ArrowForwardIos as ArrowForwardIosIcon,
  ArrowForward as ArrowForwardIcon,
  Person as PersonIcon,
  Phone as PhoneIcon,
  AttachMoney as AttachMoneyIcon,
  Flare as FlareIcon,
  Check as CheckIcon,
  CreditCard as CreditCardIcon,
  Event as EventIcon,
  ShoppingCart as ShoppingCartIcon,
  Schedule as ScheduleIcon,
  AccountBalance as AccountBalanceIcon,
  LocalAtm as LocalAtmIcon,
  FormatShapes as FormatShapesIcon,
} from '@material-ui/icons';

const estatusEntregaMap = DeliveryStatus.map;

export default function DeliveryFullTile({
  delivery,
  showBadges,
  showDriver,
  showClient,
  showPaymentInfo,
  separators = true,
  paymentLayout = "default",
  current,
}) {
  const now = moment.tz('utc');
  const status = estatusEntregaMap[delivery.estatusEntregaId];

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item>
        <Place
          name={delivery?.origin?.name || delivery?.origin?.address || delivery?.origenNombre}
          personName={delivery?.origin?.personName || delivery?.origenNombrePersona}
          phone={delivery?.origin?.phone || delivery?.origenTelefono}
          checked={delivery?.collectedAt || false}
          zone={delivery?.originZone}
          checkedLabel="Recogido"
        />
      </Grid>
      <Grid item>
        <ArrowForwardIcon size={32} />
      </Grid>
      <Grid item>
        <Place
          name={delivery?.destination?.name || delivery?.destination?.address || delivery?.destinoNombre}
          personName={delivery?.destination?.personName || delivery?.receptor}
          color={pink[500]}
          phone={delivery?.destination?.phone || delivery?.telefonoReceptor}
          zone={delivery?.destinationZone}
          kind="destination"
        />
      </Grid>
      
      {showBadges ? (
        <Grid item xs={12}>
          <Badges
            status={status}
            current={current}
            delivery={delivery}
            now={now} />
        </Grid>
      ): null}

      {showPaymentInfo ? (
        <Grid item container xs={12} spacing={1}
          style={{
            borderTop: separators ? 'solid 1px #d0d0d0' : null,
            marginTop: separators ? 8 : 0
          }}
        >
          <Grid item xs={paymentLayout === "default" ? 4 : null}>
            <div style={{fontFamily: 'oswald', fontWeight: 'bold'}}>
              {
                delivery?.estimation?.distance
                  ?? delivery?.distanciaAprox
              } km
            </div>
            <div>Distancia</div>
          </Grid>

          <Grid item xs={paymentLayout === "default" ? 4 : null}>
            <div style={{fontFamily: 'oswald', fontWeight: 'bold'}}>
              {Math.round((
                delivery?.estimation?.duration
                  ?? delivery?.estimatedDuration
                  ?? 0
              ) / 60)} min
            </div>
            <div>Tiempo</div>
          </Grid>

          <Grid item xs={paymentLayout === "default" ? 4 : null}>
            <div style={{fontFamily: 'oswald', fontWeight: 'bold'}}>
              ${delivery?.estimation?.price?.price
                ?? delivery?.deliveryPrice
                ?? 0
              } MXN
            </div>
            <div>Costo</div>
          </Grid>
          {delivery?.paymentOrigin === DeliveryPaymentOrigin.atDelivery ? (
            <React.Fragment>
              <PlaceItem left={
                <FlareIcon style={{ width: 12, height: 12, color: pink[500] }} />
              }>
                <strong style={{ color: pink[500] }}>Pago contra entrega</strong>
              </PlaceItem>
              {delivery?.collectDescription ? (
                <PlaceItem left={<ShoppingCartIcon style={{ width: 12, height: 12 }} />}>
                  {delivery?.collectDescription}
                </PlaceItem>
              ) : null}
              <PlaceItem left={<AttachMoneyIcon style={{ width: 12, height: 12 }} />}>
                Envío: <strong>${
                  (delivery?.estimation?.price?.price
                    ?? delivery?.deliveryPrice) || 0
                } MXN</strong>
              </PlaceItem>
              <PlaceItem left={<AttachMoneyIcon style={{ width: 12, height: 12 }} />}>
                Producto: <strong>${
                  (delivery?.productPrice
                    ?? delivery?.paquete?.productPrice) || 0
                } MXN</strong>
              </PlaceItem>
              <PlaceItem left={<AttachMoneyIcon style={{ width: 12, height: 12 }} />}>
                Total a cobrar:
                <strong>
                  ${
                    (delivery?.productPrice || delivery?.paquete?.productPrice || 0)
                      + (
                        delivery?.estimation?.price?.price
                          ?? delivery?.deliveryPrice
                          ?? 0
                      )
                  } MXN
                </strong>
              </PlaceItem>
              {delivery?.depositCard ? (
                <PlaceItem left={<CreditCardIcon style={{ width: 12, height: 12 }} />}>
                  {delivery?.depositCard}
                </PlaceItem>
              ) : null}
            </React.Fragment>
          ) : null}
          
          <React.Fragment>
            <PlaceItem
              left={
                delivery?.paymentMethodId === PaymentMethod.accountBalance
                  ? (
                    <AccountBalanceIcon style={{ width: 12, height: 12 }} />
                  ) : (
                    <LocalAtmIcon style={{ width: 12, height: 12 }} />
                  )
              }
            >
              Método de pago: {PaymentMethod.getTitle(delivery?.paymentMethodId)}
            </PlaceItem>
          </React.Fragment>
          
          {delivery?.timestamp?.format ? (
            <PlaceItem left={<EventIcon style={{ width: 12, height: 12 }} />}>
              {delivery.timestamp.format('D MMM yy, hh:mm a')}
            </PlaceItem>
          ) : null}
          {delivery?.collectedAt?.format ? (
            <PlaceItem left={<EventIcon style={{ width: 12, height: 12 }} />}>
              Recogido: {delivery.collectedAt.format('D MMM yy, hh:mm a')}
            </PlaceItem>
          ) : null}
          {delivery?.timestamp?.format ? (
            <PlaceItem left={<ScheduleIcon style={{ width: 12, height: 12 }} />}>
              Transcurrido: {
                delivery.timestamp
                  ? `${moment.duration(now.diff(delivery.timestamp)).humanize()}`
                  : null
              }
            </PlaceItem>
          ) : null}
        </Grid>
      ) : null}

      {showDriver || showClient
        ? (
          <Grid item container direction="row" wrap="nowrap" style={{
            borderTop: 'solid 1px #d0d0d0',
            marginTop: 16,
            paddingTop: 8
          }}>
            {showDriver ? (
              <Grid item container spacing={1} nowrap="nowrap">
                <Grid item>
                  <Avatar size={24} src={delivery?.driver?.imageUrl} />
                </Grid>
                <Grid item>
                  <div>
                    Conductor
                  </div>
                  <div>
                    {delivery?.driver?.name
                      ?? delivery?.driver?.nombre
                      ?? delivery?.driver?.name
                      ?? ""
                    }
                  </div>
                  <div>
                    {delivery?.chofer?.phone ?? ""}
                  </div>
                </Grid>
              </Grid>
            ) : null}

            {delivery?.driver?.vehicle ? (
              <Grid item container spacing={1} wrap="nowrap">
                <Grid item>
                  <Avatar size={24} src={delivery?.user?.imageUrl} />
                </Grid>
                <Grid item>
                  <div>
                    Cliente
                  </div>
                  <div>
                    {delivery?.user?.fullName ?? delivery?.user?.name}
                  </div>
                  <div>
                    {delivery?.user?.phone ?? ""}
                  </div>
                </Grid>
              </Grid>
            ) : null}

            {showClient ? (
              <React.Fragment>
                <Grid item container spacing={1} wrap="nowrap">
                  <Grid item>
                    <Avatar size={24} src={delivery?.user?.imageUrl} />
                  </Grid>
                  <Grid item>
                    <div>
                      <span style={{marginRight: 8}}>Cliente</span>
                      {delivery?.user?.isPlus ? <PlusChip /> : null}
                    </div>
                    <div>
                      {delivery?.user?.fullName ?? delivery?.user?.name}
                    </div>
                    <div>
                      {delivery?.user?.phone ?? ""}
                    </div>
                  </Grid>
                </Grid>
              </React.Fragment>
            ) : null}

          </Grid>
        ) : null
      }
      
    </Grid>
  )
}

function Place({
  name,
  color = blue[500],
  kind = 'origin',
  checked = false,
  checkedLabel = '',
  personName,
  phone,
  zone,
}) {
  return (
    <Grid container direction="column" spacing={0}>
      <PlaceItem
        left={
          <img
            src={`/circle-${kind}@2x.png`}
            alt="blue circle" style={{ width: 12, height: 12 }}
          />
        }
      >
        {name || ""}
      </PlaceItem>

      <PlaceItem left={<FormatShapesIcon style={{ width: 12, height: 12, color: "black" }} />}>
        Zona "{ zone?.nombre ?? zone?.name }"
      </PlaceItem>

      <PlaceItem left={<PersonIcon style={{ width: 12, height: 12 }} />}>
        {personName || ""}
      </PlaceItem>

      <PlaceItem left={<PhoneIcon style={{ width: 12, height: 12 }} />}>
        {phone || ""}
      </PlaceItem>

      {checked ? (
        <PlaceItem left={<CheckIcon style={{ width: 12, height: 12, color: green[600] }} />}>
          {checkedLabel || "Listo"}
        </PlaceItem>
      ) : null}
      
    </Grid>
  )
}

function PlaceItem({
  left = null,
  content = null,
  children,
}) {
  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item>{left || null}</Grid>
      <Grid item>{children}</Grid>
    </Grid>
  );
}

function Badges({
  status,
  current,
  delivery,
  now,
}) {
  return (
    <Grid item xs={12}>
      <div style={{ height: 8 }} />
      <Grid container spacing={1}>
        <Grid item>
          <Chip
            size="small"
            label={status?.title || 'Estatus desconocido'}
            style={{
              backgroundColor: current
                ? "black" : (status ? status.color : 'black'),
              color: 'white'
            }}
          />
        </Grid>
        <Grid item>
          <Chip
            size="small"
            label={
              `${Math.round(delivery.distanciaAprox * 10) / 10} km`
            }
          />
        </Grid>
        {
          delivery.fechaActualizacion
            ? <Grid item>
              <Chip
                size="small"
                label={
                  delivery.fechaActualizacion
                    ? `Hace ${moment.duration(
                      now.diff(moment.tz(delivery.fechaActualizacion, 'utc'))
                    ).humanize()
                    }`
                    : null
                }
              />
            </Grid>
            : null
        }
      </Grid>
    </Grid>
  );
}