import React from 'react';
import {
  Paper,
  Grid,
  makeStyles,
} from '@material-ui/core';
import {
  grey,
} from '@material-ui/core/colors';

export default function StatPanel({
  items = [],
  description,
  icons,
  pre = null,
  component = Paper,
  padding = null,
}) {
  const Component = component;
  const classes = useStyles();
  const styles = {};
  // const colSize = 12 / (items?.length ?? 1);
  const colSize = 100 / (items?.length ?? 1);

  if (padding) styles.padding = padding;

  return (
    <Component component={Grid} className={classes.statCard} style={styles}>
      {pre ? (
        <div>
          {pre}
        </div>
      ) : null}
      <div className={classes.mainContainer}>
        <div className={classes.left}>
          <Grid container direction="row">
            {items.map((item, index) => {
              if (!item) {
                return (
                  <Grid item
                    className={classes.emptyContainer}
                    style={{width: `${colSize}%`}}
                    key={index}
                  >
                    <div className={classes.emptyInner}></div>
                  </Grid>
                );
              }

              return (
                <Grid item style={{width: `${colSize}%`}} key={index}>
                  <div className={classes.statValue}>{item?.value}</div>
                  <div className={classes.statLabel}>{item?.label}</div>
                </Grid>
              );
            })}

            {description ? (
              <Grid item xs={12} className={classes.description}>
                {description}
              </Grid>
            ) : null}
          </Grid>
        </div>

        {icons && icons.length > 0 ? (
          <div className={classes.iconsContainer}>
            {icons}
          </div>
        ) : null}
      </div>
    </Component>
  );
}

const useStyles = makeStyles({
  statCard: {
    padding: 8,
    paddingLeft: 16,
    paddingRight: 8,
    borderRadius: "16px"
  },
  statLabel: {
    fontSize: 12,
    color: grey[700],
    fontWeight: 300
  },
  statValue: {
    fontSize: 20,
    lineHeight: "20px",
    fontWeight: 700
  },
  emptyContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start'
  },
  emptyInner: {
    width: 24,
    height: 8,
    backgroundColor: "#e0e0e0",
    borderRadius: 16
  },
  description: {
    paddingTop: 4,
    color: "#777",
    fontSize: 12,
    lineHeight: "12px"
  },
  mainContainer: {
    display: 'flex',
    paddingRight: 0
  },
  left: {
    paddingRight: 8,
    flexGrow: 1
  },
  iconsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderLeft: "solid 1px #f2f2f2",
    paddingLeft: 8
  }
});