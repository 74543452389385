import moment from 'moment';
import momentz from 'moment-timezone';

const tz = moment.tz.guess();
export const baseHost = process.env.REACT_APP_API_URL ?? "http://192.168.1.78:4550";
console.log("baseHost: " + baseHost);
export const baseURL = `${baseHost}/api`;
export const wwwRoot = `${baseHost}/wwwroot`;

class API {
  static _shared;

  _history = null;
  _loginRouteName = null;

  static instance() {
    if (!this._shared) {
      this._shared = new API();
    }
    return this._shared;
  }

  attachHistory(history, routeName) {
    this._history = history;
    this._loginRouteName = routeName;
  }

  isLoggedIn() {
    const item = this.getSessionToken();
    return item != null;
  }

  getSessionToken() {
    return localStorage.getItem('SessionToken');
  }

  getSessionClaims() {
    const parts = this.getSessionToken()?.split(".");
    if (parts) {
      return JSON.parse(window.atob(parts[1]));
    }
    return null;
  }

  getSessionRoles() {
    const claims = this.getSessionClaims();
    if (claims.role && claims.role.length > 0) {
      let roles;
      if (Array.isArray(claims.role)) {
        roles = claims.role;
      } else {
        roles = [claims.role];
      }
      return roles;
    } else {
      return [];
    }
  }

  dropSessionToken() {
    return localStorage.removeItem('SessionToken');
  }

  getDefaultHeaders() {
    const token = this.getSessionToken();
    return {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };
  }

  async logIn(email, password) {
    return fetch(`${baseURL}/Clientes/SignIn`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "Email": email,
        "Password": password
      })
    })
    .then(r => r.json())
    .then(r => {
      if (r.isSuccess) {
        localStorage.setItem('SessionToken', r.result.accessToken);
        localStorage.setItem('UserInfo', JSON.stringify(r.result.cliente));
      }
      
      return r;
    });
  }

  async savePaquete(paquete) {
    return fetch(`${baseURL}/Paquete/Save`, {
      method: 'POST',
      headers: this.getDefaultHeaders(),
      body: JSON.stringify(paquete)
    })
    .then(r => r.json())
    .then(r => r.result);
  }

  async saveRegistryFollowing(data) {
    const response = await this.fetch(`/deliveries/registries`, {
      method: 'put',
      body: JSON.stringify(data)
    });

    return response;
  }

  async saveManyCollects(data) {
    return this.fetch("/Collect/SaveMany", {
      method: 'POST',
      body: JSON.stringify(data)
    });
  }

  async getUser(uid, options) {
    let url = `/users/${uid}?`;

    if (options?.include)
      url += options?.include?.map(i => `include=${i}`).join('&') + '&';

    const response = await this.fetch(url);
    const model = response.result;

    if (model) {
      model.createdAt = momentz.tz(model.createdAt, 'utc').tz(tz);
      model.confirmedAt = moment.tz(model.confirmedAt, 'utc').tz(tz);

      if (model.deposits) {
        model.deposits = model.deposits.map(d => ({
          ...d,
          createdAt: momentz.tz(d.createdAt, 'utc').tz(tz),
          confirmedAt: momentz.tz(d.confirmedAt, 'utc').tz(tz)
        }));
      }
    }

    return model;
  }

  async saveUser(uid, user) {
    let url = "/system-users";
    
    return await this.fetchResult(url + (uid ? `/${uid}` : ''), {
      method: uid ? "PUT" : "POST",
      body: JSON.stringify(user)
    });
  }

  async getUserState({
    include,
  }) {
    let url = '/user/state?' + (include ?? [])?.map(
      e => `include=${e}&`
    ).join('');

    console.log(`url = ${url}`);

    const response = await this.fetch(url)
    return response.result;
  }

  async getValues() {
    const response = await this.fetch('/values');
    return response.result;
  }

  async getRoles() {
    const response = await this.fetch('/roles');
    return response.result;
  }

  async getUsers({
    search,
    roles,
    include,
    page = 1,
    resultsPerPage = 20,
    whereHas,
  }) {
    let url = '/users?';

    if (search) url += `search=${encodeURI(search)}&`;
    if (roles) url += `roles=${roles}&`;
    if (page !== null && page !== undefined) url += `page=${page}&`;
    if (resultsPerPage) url += `resultsPerPage=${resultsPerPage}&`;
    
    url += (include?.map(i => `include=${i}`)?.join('&') + '&') ?? '';
    url += (whereHas?.map(i => `has=${i}`)?.join('&') + '&') ?? '';

    const response = await this.fetch(url);
    return response.result;
  }

  async getProducts(o) {
    let url = '/market/product?';

    if (o?.userId) url += `userId=${o.userId}&`;
    if (o?.limit) url += `limit=${o.limit}&`;
    if (o?.sortName) url += `sortName=${o.sortName}&`;
    if (o?.search) url += `search=${o.search}&`;
    if (o?.accountId) url += `accountId=${o.accountId}&`;
    if (o?.branchId) url += `branchId=${o.branchId}&`;

    const response = await this.fetch(url);
    return response.result.products;
  }

  async getManageableBranches(op) {
    let url = '/branches/manageable';
    if (op?.accountId) url += `accountId=${op.accountId}&`;

    const response = await this.fetch(url);
    return response.result;
  }

  async getDefaultUserBranch() {
    return (await this.fetch('/branches/users-default'))?.result;
  }

  async getCategories() {
    let url = '/market/category?';
    const response = await this.fetch(url);
    return response.result.categories;
  }

  async getProductById(id) {
    let url = `/market/product/${id}`;
    const response = await this.fetch(url);
    return response.result.product;
  }

  async getRacks(o) {
    return await this.fetchResult(`/racks?`
      + (o?.include?.map(i => `include=${i}&`)?.join('') ?? '')
    );
  }

  async assignRackSpace(o) {
    return await this.fetchResult(`/racks/assign`, {
      method: 'POST',
      body: JSON.stringify({
        rackName: o?.rackName,
        line: o?.line,
        column: o?.column,
        userId: o?.userId,
      })
    });
  }

  async getCategoryById(id) {
    let url = `/market/category/${id}`;
    const response = await this.fetch(url);
    return response.result.category;
  }

  async putProductImage({
    productId,
    file,
  }) {
    const url = `${baseURL}/market/product-image/${productId}`;
    const formData = new FormData();
    formData.append('files', file);

    const response = await fetch(url, {
      method: 'PUT',
      body: formData,
      headers: {
        Authorization: `Bearer ${this.getSessionToken()}`
      }
    });

    return (await response.json()).result.images[0];
  }

  async putProductVariantImage({
    variantId,
    file,
  }) {
    const url = `${baseURL}/market/products-variants/${variantId}/images`;
    const formData = new FormData();
    formData.append('files', file);

    const response = await fetch(url, {
      method: 'PUT',
      body: formData,
      headers: {
        Authorization: `Bearer ${this.getSessionToken()}`
      }
    });

    return (await response.json()).result.images[0];
  }

  async putCategoryImage({
    categoryId,
    file,
  }) {
    const url = `${baseURL}/market/category/${categoryId}/image`;
    const formData = new FormData();
    formData.append('file', file);

    const response = await fetch(url, {
      method: 'PUT',
      body: formData,
      headers: {
        Authorization: `Bearer ${this.getSessionToken()}`
      }
    });

    return (await response.json()).result;
  }

  async saveProduct(id, product, options) {
    const response = await this.fetch(`/market/product/${id}`, {
      method: 'PUT',
      body: JSON.stringify({
        product,
        categoriesIds: options?.categoriesIds ?? null,
      })
    });

    return response;
  }

  async saveCategory(id, category) {
    const response = await this.fetch(`/market/category/${id}`, {
      method: 'PUT',
      body: JSON.stringify({ category })
    });

    return response;
  }

  async createCategory() {
    const response = await this.fetch(`/market/category`, {
      method: 'POST'
    });

    return response.result.category;
  }

  async createProduct(o) {
    const response = await this.fetch(`/market/product`, {
      method: 'POST',
      body: JSON.stringify({
        categoryId: o?.categoryId ?? null
      })
    });

    return response.result.product;
  }

  async createClient(data) {
    return await this.fetchResult(`/users`, {
      method: 'POST',
      body: JSON.stringify(data)
    });
  }

  async deleteProductImage(id) {
    const response = await this.fetch(`/market/product-image/${id}`, {
      method: 'DELETE'
    });
    return response.success;
  }

  async deleteProductVariantImage(id) {
    const response = await this.fetch(`/market/products-variants/images/${id}`, {
      method: 'DELETE'
    });
    return response.success;
  }

  async deliverDelivery(deliveryId, options) {
    let query = '';

    if (options?.asRole)
      query += `asRole=${encodeURIComponent(options.asRole)}&`;

    if (options?.entregaExitosa)
      query += `entregaExitosa=${options.entregaExitosa ? "1" : ""}&`;

    return this.fetch(`/viajes/${deliveryId}/Deliver`, {
      method: "PUT",
      body: query,
      headers: {
        ...this.getDefaultHeaders(),
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      }
    });
  }

  async collectDelivery(deliveryId, options) {
    let query = `id=${deliveryId}&`;

    if (options?.asRole)
      query += `asRole=${encodeURIComponent(options.asRole)}&`;

    if (options?.success)
      query += `success=${options.success ? "1" : ""}&`;

    return this.fetch(`/collect/Confirm`, {
      method: "PUT",
      body: query,
      headers: {
        ...this.getDefaultHeaders(),
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      }
    });
  }

  async confirmProductInput({accountId, barcode, branchId}) {
    const response = await this.fetch(`/market/product/input-variant`, {
      method: 'PUT',
      body: JSON.stringify({
        accountId,
        barcode,
        branchId,
      })
    });
    if (!response?.isSuccess) {
      throw new Error(response?.message ?? "Algo salió mal, intente más tarde");
    }
    return response.result;
  }

  async getDeliveryByTrackingNumber(trackingNumber) {
    const response = await this.fetch(`/deliveries/by-tracking/${trackingNumber}`);
    return response.result.delivery;
  }

  async putReceiveInCentre(trackingNumber) {
    const response = await this.fetch(
      `/deliveries/by-tracking/${trackingNumber}/receive-in-centre`, {
        method: 'PUT'
      }
    );
    
    return response;
  }

  async getDeliveryById(id, options) {
    let url = `/deliveries/${id}?`;

    if (options?.include) {
      url += options.include
        .map(i => `include=${i}`)
        .join('&') + '&';
    }

    const response = await this.fetch(url);
    const delivery = response?.result;

    return delivery ? {
      ...delivery,
      bitacore: delivery?.bitacore?.map(
        registry => ({
          ...registry,
          timestamp: momentz.tz(registry.timestamp, 'utc').tz(tz)
        })
      )
    } : null;
  }

  async getDeliveries(o) {
    let ep = `/Delivery/GetMany?`;

    if (o && o.lastDate) {
      ep += `lastDate=${o.lastDate}&`;
    }
    
    if (o && o.includePackage) {
      ep += `include=package&`;
    }

    if (o && o.includeUser) {
      ep += `include=user&`;
    }

    if (o && o.includeZones) {
      ep += `include=zones&`;
    }

    if (o?.includeEvents) ep += `include=events&`;
    if (o?.groupMode) ep += `groupMode=${o.groupMode}&`;
    if (o?.includeDriver) ep += `include=driver&`;
    if (o?.onlyPlus) ep += `onlyPlus=1&`;
    if (o?.scope) ep += `scope=${o.scope}&`;

    if (o?.rawStats) {
      ep += o.rawStats
        .map(st => `stats=${st.join(',')}`)
        .join('&') + '&';
    }

    if (o.status && o.status) {
      if ("number" === typeof o.status || "string" === typeof o.status)
        ep += `status=${o.status}&`;
      else if ("object" === typeof o.status)
        ep += o.status?.map(i => `status=${i}`)?.join('&') + '&';
    }

    if (o?.includeVehicleType) ep += `include=vehicleType&`;
    if (o?.sortBy) ep += `sortBy=${o.sortBy}&`;

    if (o?.include) {
      ep += o.include
        .map(i => `include=${i}`)
        .join('&') + '&';
    }

    if (o?.zonesIds) {
      ep += o.zonesIds.map(zid => `zonesIds=${zid}`).join('&') + '&';
    }

    if (o?.statGroup) ep += `statGroup=${o.statGroup}&`;

    return this.fetch(ep).then(response => {
      if (o?.statGroup) {
        return response.result;
      } else if (response.result && response.result.length > 0) {
        response.result = response.result.map(d => {
          const phases = {};
          
          if (d.phases) {
            Object.keys(d.phases).forEach(k => {
              if (d.phases[k]) {
                phases[k] = {
                  ...d.phases[k],
                  timestamp: momentz.tz(d.phases[k].timestamp, 'utc').tz(tz)
                }
              }
            })
          }

          return {
            ...d,
            events: d.events?.map(e => ({
              ...e,
              timestamp: momentz.tz(e.timestamp, 'utc').tz(tz)
            })),
            phases: phases,
          };
        });
      }
      return response;
    });
  }

  async saveZona(zona) {
    return this.fetch('/Zona', {
      method: 'PUT',
      body: JSON.stringify(zona)
    }).then(r => r.result);
  }

  async saveZonaVertices(zonaId, vertices) {
    return this.fetch(`/Zona/${zonaId}/Vertices`, {
      method: 'PUT',
      body: JSON.stringify(vertices)
    });
  }

  async markDepositAsPaid(id) {
    return await this.fetch(`/deliveries/${id}/deposit/paid`, {
      method: 'PUT'
    });
  }

  async getPaquete(id) {
    return this.fetch(`/Paquete/${id}`)
               .then(r => r.result);
  }

  async groupToPay() {
    return this.fetch('/Viajes/GroupToPay').then(r => r.result);
  }

  async getMarketplacePendingPayouts({ week, target = "owner" }) {
    return await this.fetchResult(
      `/deliveries/marketplace-pending-payouts?week=${week.week}&target=${target}`
    );
  }

  getQrUrl(data) {
    return `${baseURL}/Util/QR/${data}.png`;
  }
  
  getBarcode128Url(data, options) {
    let url = `${baseURL}/util/barcode-128/${data}.png?`;

    if (options?.width)
      url += `width=${options.width}&`;

    if (options?.height)
      url += `height=${options.height}&`;

    return url;
  }

  async getBranches(o) {
    let url = '/branches?';

    if (o?.accountId) url += `accountId=${o.accountId}&`;

    return await this.fetchResult(url);
  }

  async getPaquetes(o) {
    let url = '/Paquetes?';

    if (o && o.zoneId) {
      url += 'zoneId=' + o.zoneId.toString() + '&';
    }

    if (o && o.top) {
      url += 'top=' + o.top.toString() + '&';
    }

    if (o && o.tipoPaqueteId) {
      url += 'tipoPaqueteId=' + o.tipoPaqueteId.toString() + '&';
    }

    if (o && o.tipoVehiculoId) {
      url += 'tipoVehiculoId=' + o.tipoVehiculoId.toString() + '&';
    }

    if (o && o.search) {
      url += 'search=' + encodeURIComponent(o.search) + '&';
    }

    if (o && o.asignados !== null && o.asignados !== undefined) {
      url += 'asignados=' + o.asignados + '&';
    }

    if (o && o.senderId) {
      url += `senderId=${o.senderId}&`;
    }

    if (o && o.estatusEntrega && o.estatusEntrega.length > 0) {
      url += o.estatusEntrega.map(
        i => `estatusEntrega=${i}`
      ).join('&') + '&';
    }

    if (o && o.driverId) {
      url += `driverId=${o.driverId}&`;
    }

    if (o && o.fromDate) url += `fromDate=${o.fromDate}&`;
    if (o && o.toDate) url += `toDate=${o.toDate}&`;
    if (o && o.fromDeliverDate) url += `fromDeliverDate=${o.fromDeliverDate}&`;
    if (o && o.toDeliverDate) url += `toDeliverDate=${o.toDeliverDate}&`;

    if (o && o.sortBy) url += `sortBy=${o.sortBy}&`;

    return this.fetch(url)
               .then(r => r.result);
  }

  async getZonas(o) {
    let ep = '/Zonas?';

    if (o && (o.withVertices === true || o.withVertices === false)) {
      ep += `withVertices=${o.withVertices ? '1' : '0'}&`;
    }

    if (o?.withRoles === true) ep += `withRoles=true&`;

    if (o?.excludeChannels) {
      ep += o.excludeChannels
        .map(ic => `excludeChannels=${ic}`)
        .join('&') + '&';
    }

    if (o?.expiration) ep += `expiration=${o.expiration}&`;

    return this.fetch(ep).then(r => r.result);
  }

  async getRutasEntrega(o) {
    let ep = '/RutasEntrega?';

    if (o && o.status) {
      ep += `estatus=${o.status}&`;
    }

    if (o && o.deliveryStatus) {
      ep += o.deliveryStatus
        .map(ds => `deliveryStatus=${ds}&`)
        .join('');
    }

    return this.fetch(ep).then(r => r.result);
  }

  async getChoferes() {
    return fetch(`${baseURL}/Chofer/Lista`, {
      headers: this.getDefaultHeaders()
    }).then(r => r.json())
    .then(r => r.result);
  }

  async getDrivers(o) {
    let url = "/Drivers?";

    if (o && o.activeState) {
      url += `activeState=${o.activeState}&`;
    }

    if (o && o.include) {
      for (let index = 0; index < o.include.length; index++) {
        url += `include=${o.include[index]}&`;
      }
    }

    if (o?.plusType)
      url += `plusTypes=${o.plusType}&`;

    if (o?.vehicleTypeId)
      url += `vehicleTypeId=${o.vehicleTypeId}&`;

    return this.fetch(url, {
      headers: this.getDefaultHeaders()
    });
  }

  async assignDelivery({
    deliveryId,
    driverId,
    vehicleId,
  }) {
    const data = {};
    const url = `/delivery/${deliveryId}/assign`;

    if (driverId) {
      data.driverId = driverId;
    }

    if (vehicleId)
      data.vehicleId = vehicleId;

    const response = await this.fetch(url, {
      method: "PUT",
      body: JSON.stringify(data)
    });

    return response;
  }
  
  async getChofer(choferId) {
    return this.fetch(`/Chofer/${choferId}`).then(r => r.result);
  }

  async deleteUser(id) {
    return this.fetch(`/users/${id}`, {
      method: 'DELETE'
    }).then((r) => r.result);
  }

  async createChofer(chofer) {
    return this.fetch('/Chofer', {
      method: 'POST',
      body: JSON.stringify(chofer)
    }).then((r) => r.result.driver);
  }

  async saveChofer(chofer) {
    return this.fetch('/Chofer', {
      method: 'PUT',
      body: JSON.stringify(chofer)
    }).then(r => r.result);
  }

  async saveDriver(formData) {
    return this.fetch('/Chofer/Save', {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${this.getSessionToken()}`
      }
    });
  }

  async getVehicles() {
    return this.fetch('/Vehiculos')
               .then(r => r.result);
  }

  async getVehicle(id) {
    return this.fetch(`/Vehiculo/${id}`)
               .then(r => r.result);
  }

  async asignarVehiculo(choferId, placa) {
    return this.fetch('/Vehiculo/Asignar', {
      method: 'PUT',
      body: JSON.stringify({placa, choferId})
    });
  }

  async createVehicle(data) {
    return this.fetch('/Vehiculo', {
      method: 'POST',
      body: JSON.stringify(data)
    }).then(r => r.result);
  }

  async saveVehicle(id, form) {
    return fetch(`${baseURL}/vehicles/${id}`, {
      method: 'PUT',
      body: form,
      headers: {
        Authorization: `Bearer ${this.getSessionToken()}`,
        // 'Content-Type': 'multipart/form-data',
      }
    }).then(r => r.result);
  }

  async deleteVehicle(id) {
    return this.fetch(`/vehicles/${id}`, {
      method: 'DELETE'
    });
  }

  async getVehicleTypes(o) {
    let url = '/Vehiculo/Tipos?';

    if (o && o.showInactive) {
      url += 'showInactive=1';
    }

    return this.fetch(url).then(r => r.result);
  }

  async getVehicleType(id) {
    return this.fetch(`/Vehiculo/Tipo/${id}`)
               .then(r => r.result);
  }

  async getCollectEstimation(fromLat, fromLng, toLat, toLng) {
    let url = "/Collect/EstimatePrice?";

    url += `fromLatitude=${fromLat}&`;
    url += `fromLongitude=${fromLng}&`;
    url += `toLatitude=${toLat}&`;
    url += `toLongitude=${toLng}&`;

    return this.fetch(url);
  }

  async saveVehicleType(vehicleType) {
    return this.fetch('/Vehiculo/Tipo', {
      method: 'PUT',
      body: JSON.stringify(vehicleType)
    }).then(r => r.result);
  }

  async createSender(sender) {
    return this.fetch('/Sender', {
      method: 'POST',
      body: JSON.stringify(sender)
    }).then(r => r.result);
  }

  async markDeliveryAsPrinted(id) {
    const response = await this.fetch(`/deliveries/${id}/mark-as-printed`, {
      method: 'PUT'
    });
    return response.isSuccess;
  }

  async putDeliveryInZone({trackingNumber = null, items = []}) {
    const response = await this.fetch(`/deliveries/by-tracking/${trackingNumber}/put-in-zone`, {
      method: 'PUT',
      body: JSON.stringify({ items }),
    });
    return response.result;
  }
  
  async cancelDeliveryAsAdmin(id) {
    return this.fetch(`/delivery/${id}/cancel-as-admin`, {
      method: 'PUT'
    });
  }

  async getSenders() {
    return this.fetch('/Senders')
               .then(r => r.result);
  }

  async getSender(id) {
    return this.fetch(`/Sender/${id}`)
               .then(r => r.result);
  }

  async saveSender(sender) {
    return this.fetch('/Sender', {
      method: 'PUT',
      body: JSON.stringify(sender)
    }).then(r => r.result);
  }

  getFeature = async (id) => await this.fetchResult(`/market/features/${id}`);

  async saveFeature(id, feature, options) {
    return await this.fetchResult(`/market/features/${id}`, {
      method: "PUT",
      body: JSON.stringify({
        feature,
        newOptions: options.newOptions
      })
    });
  }

  async createFeature() {
    return await this.fetchResult(`/market/features`, {
      method: "POST"
    });
  }

  async getFeatures(opt) {
    let ep = `/market/features?`;

    if (opt?.categoriesIds)
      ep += opt.categoriesIds
        .filter(i => parseInt(i) > 0)
        .map(cid => `categoryId=${cid}`)
        .join('&') + '&';

    ep += opt?.search ? `search=${encodeURIComponent(opt.search)}&` : '';

    if (opt?.include)
      ep += opt.include.map(cid => `include=${cid}`).join('&') + '&';

    if ((opt?.ignoreIds?.length ?? 0 > 0))
      ep += opt.ignoreIds.map(cid => `ignoreId=${cid}`).join('&') + '&';

    const resp = await this.fetch(ep);
    if (resp.isSuccess)
      return resp.result;
    throw new Error(resp?.message ?? "Algo salió mal");
  }

  async saveProductFeatures(productId, features) {
    const resp = await this.fetch(`/market/product/${productId}/features`, {
      method: "PUT",
      body: JSON.stringify({features})
    });
    if (resp.isSuccess)
      return resp.result;
    throw new Error(resp?.message ?? "Algo salió mal"); 
  }

  async previewProductsImports({accountId, file}) {
    const url = `${baseURL}/products-importer/preview`;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('accountId', accountId);

    const response = await fetch(url, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${this.getSessionToken()}`
      }
    });

    return (await response.json())?.result;
  }

  async getInputableBranches() {
    const response = await this.fetch(`/branches/inputable`, {
      
    });

    if (!response.isSuccess) {
      // throw new Error(result?.message ?? "Algo salió mal");
    }

    return response.result;
  }

  async importProducts({accountId, filehash}) {
    const result = await this.fetch("/products-importer/import", {
      method: "PUT",
      body: JSON.stringify({
        accountId,
        filehash,
      })
    });
    if (result.isSuccess) {
      return result.result;
    } else {
      throw new Error(result?.message ?? "Algo salió mal");
    }
  }

  async importProductsImages({accountId, file, onProgress, onReadyStateChange}) {
    var xhr = new XMLHttpRequest();
    const url = `${baseURL}/products-importer/import-images`;
    const formData = new FormData();
    formData.append('accountId', accountId);
    formData.append('file', file);

    return new Promise((resolve, reject) => {
      xhr.open('POST', url, true);
      xhr.setRequestHeader('Authorization', `Bearer ${this.getSessionToken()}`);
      xhr.upload.onprogress = (evt) => {
        const percent = evt.loaded / evt.total;
        if (onProgress) {
          onProgress(percent);
        }
      };
      xhr.onreadystatechange = onReadyStateChange;
      xhr.onload = function() {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response.isSuccess) {
            resolve(response.result);
          } else {
            reject(response);
          }
        } catch (e) {
          reject(e);
        }
      };
      xhr.onerror = function(e) {
        reject(e);
      };
      xhr.send(formData);
    });
  }

  async saveVariantFeatures(variantId, features) {
    const resp = await this.fetch(`/market/products-variants/${variantId}/features`, {
      method: "PUT",
      body: JSON.stringify({features})
    });
    if (resp.isSuccess)
      return resp.result;
    throw new Error(resp?.message ?? "Algo salió mal"); 
  }

  async deleteProductFeatures(productId, featureId) {
    const resp = await this.fetch(
      `/market/product/${productId}/features/${featureId}`, {
        method: "DELETE"
      }
    );
    if (resp.isSuccess)
      return resp.result;
    throw new Error(resp?.message ?? "Algo salió mal");
  }

  async deleteVariantFeature(variantId, featureId) {
    const resp = await this.fetch(
      `/market/products-variants/${variantId}/features/${featureId}`, {
        method: "DELETE"
      }
    );
    if (resp.isSuccess)
      return resp.result;
    throw new Error(resp?.message ?? "Algo salió mal");
  }

  async saveProductVariant(variant) {
    const resp = await this.fetch(
      `/market/products-variants/${variant.id}`, {
        method: "PUT",
        body: JSON.stringify(variant)
      }
    );
    if (resp.isSuccess)
      return resp.result;
    throw new Error(resp?.message ?? "Algo salió mal");
  }

  async createProductVariant(productId) {
    const resp = await this.fetch(
      `/market/products-variants?productId=${productId}`, {
        method: "POST"
      }
    );
    if (resp.isSuccess)
      return resp.result;
    throw new Error(resp?.message ?? "Algo salió mal");
  }

  async getFeatureOptions({ featureId }) {
    const resp = await this.fetch(`/market/features/${featureId}/options`);
    if (resp.isSuccess)
      return resp.result;
    throw new Error(resp?.message ?? "Algo salió mal");
  }

  async getCategoryFeatures(categoryId) {
    const resp = await this.fetch(`/market/category/${categoryId}/features`);
    if (resp.isSuccess)
      return resp.result;
    throw new Error(resp?.message ?? "Algo salió mal");
  }

  async createZone(zona) {
    return this.fetch('/Zona', {
      method: 'POST',
      body: JSON.stringify(zona)
    }).then(r => r.result);
  }

  async createRoute(choferId, paquetesIds) {
    return this.fetch(`/Paquetes/CreateRoute`, {
      method: 'POST',
      body: JSON.stringify({
        choferId,
        paquetesIds
      })
    }).then(r => r.result);
  }

  async getRates(o) {
    let url = "/Rates?";

    if (o && o.withDetails === 1) {                                                                                                                              
      url += "withDetails=1&";
    }

    return this.fetch(url);
  }

  async getParameters() {
    return this.fetch('/Parameters');
  }

  async saveParameters(params) {
    return this.fetch('/Parameters', {
      method: 'PUT',
      body: JSON.stringify(params)
    })
  }

  async getPackagesTypes() {
    return this.fetch('/Paquetes/Tipos')
               .then(r => r.result);
  }

  async getPackageType(id) {
    return this
      .fetch(`/Paquete/Tipo/${id}`)
      .then(r => r.result);
  }

  async savePackageType(packageType) {
    return this.fetch('/Paquete/Tipo', {
      method: 'PUT',
      body: JSON.stringify(packageType)
    }).then(r => r.result);
  }

  async getRequestedDeliveriesCounter() {
    const response = await this.fetch('/monitor/requested-deliveries-counter');
    return response.result;
  }

  async updateDeliveryStatus(id, status) {
    return this.fetch(`/viajes/${id}/UpdateStatus/${status}`, {
      method: 'PUT'
    });
  }

  async createPackageType() {
    return this.fetch('/Paquete/Tipo', {
      method: 'POST'
    }).then(r => r.result);
  }

  async getPayments({
    getAll,
    type,
    status,
    counterOnly,
    include,
  }) {
    let url = "/payment?";

    if (getAll) url += "all=1&";
    if (status) url += `status=${status}&`;
    if (type)   url += `type=${type}&`;
    if (counterOnly) url += "counterOnly=1&";

    if (include)
      url += include.map(i => `include=${i}&`).join('')

    const response = await this.fetch(url);

    if (response.isSuccess) {
      return response.result;
    } else {
      throw new Error(response.message);
    }
  }

  async confirmDeposit({
    paymentId,
    amount,
    action,
  }) {
    let url = `/payment/${paymentId}/confirm-deposit`;

    const response = await this.fetch(url, {
      method: "PUT",
      body: JSON.stringify({
        amount,
        action
      })
    });

    if (response.isSuccess) {
      return response.result;
    } else {
      throw new Error(response.Message);
    }
  }

  async fetch(endpoint, options) {
    const url = `${baseURL}${endpoint}`;
    const headers = this.getDefaultHeaders();

    if (options?.isUpload === true)
      headers['Content-Type'] = 'multipart/form-data';

    return fetch(url, {
      headers,
      ...options
    })
    .then((response) => {
      if (response.status === 401) {
        if (this._history !== null && this._loginRouteName) {
          this.dropSessionToken();
          this._history.push(this._loginRouteName);
          return null;
        }
      }
      return response.json();
    });
  }

  async fetchResult(endpoint, options) {
    const resp = await this.fetch(endpoint, options);
    if (resp.isSuccess)
      return resp.result;
    throw new Error(resp?.message ?? "Algo salió mal"); 
  }
}

export default API;