import React, {useEffect, useRef, useState, useCallback} from 'react';
import {
  Typography,
  TextField,
  Button,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import FeaturesList from './features_list';
import API from '../api';
import {
  Add as AddIcon,
  Publish as PublishIcon,
  Check as CheckIcon,
} from '@material-ui/icons';
import {makeStyles} from '@material-ui/core/styles';
import ImagesUploaderManager from './images_uploader';
import ImagesUploader from './images_uploader';

const api = new API();

export default function VariantEditor(props) {
  const {variant: v, product, onChange} = props;
  const [name, setName] = useState(v?.name ?? "");
  const [quantity, setQuantity] = useState(v?.availableQuantity ?? 0);
  const [unitPrice, setUnitPrice] = useState(v?.unitPrice);
  const [isSaving, setIsSaving] = useState(false);
  const [succeeded, setSucceeded] = useState(false);
  const [variant, setVariant] = useState(v);
  const [newFeatures, setNewFeatures] = useState([]);

  const handleName = useCallback(e => setName(e.target.value), []);
  const handleQuantity = useCallback(e => setQuantity(e.target.value), []);
  const handlePrice = useCallback(e => setUnitPrice(e.target.value), []);

  const saveVariantFeatures = useCallback(async (parentId, features) => {
    return await api.saveVariantFeatures(parentId, features);
  }, [newFeatures]);

  const deleteVariantFeature = useCallback(async (parentId, featureId) => {
    return await api.deleteVariantFeature(parentId, featureId);
  }, []);

  const handleUploadImage = useCallback(async ({ parentId, file }) => {
    return await api.putProductVariantImage({variantId: parentId, file});
  }, []);

  const deleteImage = useCallback(async (id) => {
    return await api.deleteProductVariantImage(id);
  }, []);

  const handleDeletedImage = useCallback((id) => {
    const updated = {
      ...variant,
      images: variant.images.filter(i => i.id !== id)
    };
    setVariant(updated);
    if ('function' === typeof onChange)
      onChange(updated);
  }, [variant]);

  const handleImageSelected = useCallback((image) => {
    const updated = {
      ...variant,
      images: [...variant.images, image]
    };
    setVariant(updated);
    if ('function' === typeof onChange)
      onChange(updated);
  }, [variant]);

  const handleSaveForm = useCallback(async (e) => {
    e.preventDefault();

    if (isSaving)
      return;

    setSucceeded(false);
    setIsSaving(true);

    try {
      await api.saveProductVariant({
        id: variant.id,
        name,
        availableQuantity: quantity,
        unitPrice,
      });
      setSucceeded(true);

      const newVariant = {
        ...variant,
        name,
        availableQuantity: quantity,
        unitPrice,
      };
      setVariant(newVariant);
      if ('function' === typeof onChange)
        onChange(newVariant);
    } catch (e) {
      alert(`Algo salió mal: ${e.message}`);
    }

    setIsSaving(false);
  }, [isSaving, name, quantity, unitPrice, variant]);

  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={4}>
        <form style={{padding: 16}} onSubmit={handleSaveForm}>
          <TextField label="Nombre de variante" variant="outlined"
            defaultValue={name}
            onBlur={handleName}
            fullWidth
            margin="dense"
          />
          <TextField label="Unidades disponibles" variant="outlined"
            defaultValue={quantity}
            onBlur={handleQuantity}
            fullWidth
            margin="dense"
          />
          <TextField label="Precio unitario" variant="outlined"
            defaultValue={unitPrice}
            onBlur={handlePrice}
            fullWidth
            margin="dense"
          />
          <div style={{paddingTop: 8}}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Button variant="contained" color="default" type="submit" disabled={isSaving}>
                  Guardar
                </Button>
              </Grid>
              <Grid item>
                {succeeded ? (
                  <Grid container spacing={1} justifyContent="center" alignItems="center">
                    <Grid item><CheckIcon /></Grid>
                    <Grid item>Guardado</Grid>
                  </Grid>
                ) : null}
                {isSaving ? <CircularProgress size={24} /> : null}
              </Grid>
            </Grid>
          </div>
        </form>
      </Grid>
      <Grid item xs={8} style={{borderLeft: "solid 1px #e0e0e0"}}>
        <FeaturesList
          product={product}
          features={variant?.features}
          mode="variants"
          defaultImageUrl={product?.mainImage?.thumbnailImageUrl}
          saverFunction={saveVariantFeatures}
          deleteFunction={deleteVariantFeature}
          parentId={variant.id}
        />
      </Grid>
      <Grid item xs={12} className={classes.imagesContainer}>
        <ImagesUploader
          uploadFunction={handleUploadImage}
          deleteFunction={deleteImage}
          onDeleteImage={handleDeletedImage}
          onSelectImage={handleImageSelected}
          parentId={variant.id}
          images={variant.images}
        />
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles({
  imagesContainer: {
    borderTop: "solid 1px #e0e0e0",
    minHeight: 100,
    display: "flex",
    alignItems: "center",
    paddingLeft: 16,
    paddingTop: 16,
    paddingBottom: 16
  }
});