import React from 'react';
import Container from '@material-ui/core/Container';
import { withRouter } from "react-router-dom";
import API from '../api';
import {
  List,
  Paper,
  Grid,
  Button,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
} from '@material-ui/core';

import {
  Motorcycle as MotorcycleIcon,
  Add as AddIcon,
  Edit as EditIcon,
  AirportShuttle as AirportShuttleIcon,
  DriveEta as DriveEtaIcon,
  DeleteForever as DeleteForeverIcon
} from '@material-ui/icons';

const api = API.instance();

const typeIcons = {
  1: AirportShuttleIcon,
  2: MotorcycleIcon
};

class VehiclesScreen extends React.Component {
  state = {
    items: [],
    isLoading: true
  }

  componentDidMount() {
    this.load();
  }

  async load() {
    const update = { isLoading: false };

    try {
      update.items = await api.getVehicles();
    } catch (e) {
      console.error(e);
    }

    this.setState(update);
  }

  async toggleShowInactive() {
    this.setState({
      isLoading: true,
    }, () => {
      this.load();
    });
  }

  render() {
    const {
      items,
      isLoading,
    } = this.state;
    let child;

    if (isLoading) {
      child = (
        <CircularProgress container={<div style={{ padding: 32 }} />} />
      );
    } else {
      child = (
        <React.Fragment>
          <List>
            {
              items.map((vtype, index) => {
                const Icon = typeIcons[vtype.tipoVehiculoId] || DriveEtaIcon;
                return (
                  <ListItem divider={true} dense={false} key={index}>
                    <ListItemIcon>
                      <Icon />
                    </ListItemIcon>
                    <ListItemText
                      secondary={
                        vtype.capacidadM3
                          ? <span>
                            Capacidad de caja: {vtype.capacidadM3}
                            m<sup>3</sup>
                          </span>
                          : ''
                      }>
                      {vtype.placa ? (<strong>{vtype.placa}</strong>) : 'Sin placa'}
                      {vtype.modelo ? (<span style={{marginLeft: 24}}>{vtype.modelo}</span>) : 'Sin modelo'}
                    </ListItemText>
                    <ListItemSecondaryAction>
                      <IconButton variant="outlined"
                        onClick={
                          () => {
                            console.log(`/vehicle/${vtype.vehiculoId}`);
                            this.props.history.push(`/vehicle/${vtype.vehiculoId}`)
                          }
                        }>
                        <EditIcon />
                      </IconButton>
                      <IconButton variant="outlined"
                        onClick={
                          () => {
                            if (window.confirm(
                              `¿Confirmas que quieres borrar ${vtype.modelo}?`
                            )) {
                              api.deleteVehicle(vtype.vehiculoId).then((r) => {
                                if (r.isSuccess) {
                                  this.setState({
                                    items: items
                                      .filter(v => v.vehiculoId !== vtype.vehiculoId)
                                  })
                                }
                              }).catch(err => {
                                alert(err.toString());
                              });
                            }
                          }
                        }>
                        <DeleteForeverIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              })
            }
          </List>
        </React.Fragment>
      );
    }

    return (
      <Container component="main">
        <Grid container>
          <Grid item sm={6}>
            <h1>Vehículos</h1>
          </Grid>
          <Grid item sm={6} style={{ textAlign: 'right' }}>
            <Button
              variant="contained" startIcon={<AddIcon />}
              color="primary"
              onClick={() => {
                const { items } = this.state;
                const self = this;

                api.createVehicle({nombre: 'Vehículo'}).then((r) => {
                  items.push(r);
                  self.setState({items});
                  this.props.history.push(`/vehicle/${r.vehiculoId}`);
                });
              }}>
              Crear
            </Button>
          </Grid>
        </Grid>
        <Paper>
          {child}
        </Paper>
        {items
          ? <div style={{ paddingTop: 16, color: 'grey', textAlign: 'center' }}>
            {`${items.length} carros`}
          </div> : null}
      </Container>
    );
  }
}

export default withRouter(VehiclesScreen);
