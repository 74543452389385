import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { withRouter } from "react-router-dom";
import NumberFormat from "react-number-format";
import { getDistance } from 'geolib';
import API, { baseURL } from '../api';
import Scroll from 'react-scroll';
import moment from 'moment';

import SubtitlesIcon from '@material-ui/icons/Subtitles';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckIcon from '@material-ui/icons/Check';

import {
  Select,
  MenuItem,
  IconButton,
  CircularProgress,
  FormControl,
  InputLabel,
  OutlinedInput,
  Card,
  CardContent,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
  Chip,
  ListItemText,
  ListItemSecondaryAction,
 } from '@material-ui/core';

//const MAPS_URL = 'https://www.google.com/maps';
const MAPS_URL = 'https://www.google.com/maps/search/';

const Km = (props) => (
  <NumberFormat
    thousandSeparator={true}
    decimalScale={1}
    suffix="km"
    displayType="text"
    {...props}
  />
);

const api = API.instance();
const scroll = Scroll.animateScroll;
const makeDestination = () => {
  return {
    receiverName: "",
    receiverPhone: "",
    place: null,
    address: "",
    productDescription: "",
    productPrice: "",
    distance: null,
    duration: null,
    price: null,
    deliveryPrice: "0",
    deliveryIncluded: false,
    changedFirstDestination: false,

    // Status
    payAtDeliver: false,
    isEstimating: false,
    addressError: "",

    // Reference
    element: null,
    firstFieldElement: null
  };
};

class CollectAddScreen extends React.Component {
  state = {
    'user' : '',
    'password': '',
    address: '',
    place: null,
    guiaOriginal: null,
    senders: [],
    nombreRecibe: '',
    telefonoRecibe: '',
    wasSaved: false,
    model: null,
    packageTypes: [],
    tipoPaqueteId: 0,

    alto: 0,
    ancho: 0,
    largo: 0,
    peso: 0,

    // Collect
    collectPlace: null,
    collectPlaceError: "",

    // Addresses
    isSearchingAddress: [false],

    isSaving: false,
    isLoading: true,
    sourcePhone: "",
    drivers: [],
    sourcePersonName: "",
    isSearchingOrigin: false,
    destinations: [makeDestination()],
  }

  async loadDrivers() {
    try {
      const response = await api.getDrivers({
        activeState: 1
      });
      if (response.result && response.result.length > 0) {
        this.setState({
          drivers: response.result.map(d => ({
            ...d,
            distance: null
          }))
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  async save() {
    const {
      collectPlace,
      isSaving,
      description,
      sourcePhone,
      driver,
      sourcePersonName,
    } = this.state;

    if (isSaving) {
      return;
    }

    this.setState({
      isSaving: true
    });

    try {
      const response = await api.saveManyCollects({
        driverId: driver ? driver.id : null,
        sourcePhone: sourcePhone,
        sourceAddress: collectPlace ? collectPlace.name : null,
        sourceLatitude: collectPlace ? collectPlace.geometry.location.lat : null,
        sourceLongitude: collectPlace ? collectPlace.geometry.location.lng : null,
        sourceLongAddress: collectPlace ? collectPlace.formatted_address : null,
        sourcePersonName,
        description,
        products: this.state.destinations.map(p => {
          let productPriceFinal = null;
          let productPrice = null;
          let deliveryPrice = p.price
            ? p.price.price
            : parseFloat(p.deliveryPrice);

          if (isNaN(deliveryPrice)) deliveryPrice = 0;

          if (p.payAtDeliver) {
            productPrice = parseFloat(p.productPrice);

            if (isNaN(productPrice)) productPrice = 0;

            if (p.deliveryIncluded) {
              productPriceFinal = productPrice - deliveryPrice;
            } else {
              productPriceFinal = productPrice;
            }
          }

          return {
            receiverName: p.receiverName,
            receiverPhone: p.receiverPhone,
            destinationAddress: p.place ? p.place.name : null,
            destinationLongAddress: p.place ? p.place.formatted_address : null,
            destinationLatitude: p.place ? p.place.geometry.location.lat : null,
            destinationLongitude: p.place ? p.place.geometry.location.lng : null,
            description: p.productDescription,
            deliveryPrice: parseFloat(p.deliveryPrice),
            paymentOrigin: p.payAtDeliver ? 1 : 2,
            price: productPriceFinal,
          };
        })
      });

      if (response.isSuccess) {
        alert("Recolección guardada");
        this.props.history.push('/');
        
        this.setState({
          isSaving: false,
          // model: response.result,
          // wasSaved: true
        }, () => {
          // 
        });
      } else {
        alert(response.message);
        this.setState({isSaving: false});
      }
    } catch (e) {
      console.error(e);
      this.setState({
        isSaving: false,
      });
    }
  }

  async searchCollectAddress() {
    const {
      collectAddress,
      isSearchingOrigin,
      destinations,
    } = this.state;

    if (isSearchingOrigin) {
      return;
    }

    this.setState({
      isSearchingOrigin: true,
      collectPlaceError: "",
      collectPlace: null
    });

    try {
      const url = `${baseURL}/routes/locations?location=${collectAddress}`;
      const response = await fetch(url);
      const data = await response.json();
      
      this.setState({
        isSearchingOrigin: false,
        collectPlace: data.result.length > 0 ? data.result[0] : null,
        collectPlaceError: "Dirección no encontrada",
      }, () => {
        this.estimateDriverDistances();
        destinations.forEach((d, index) => {
          this.estimatePrice(index);
        });
      });
    } catch (e) {
      console.error(e);
      this.setState({
        isSearchingOrigin: false
      });
    }
  }

  async searchAddress(address, index) {
    const { destinations } = this.state;
    const d = destinations[index];

    if (d.isSearchingAddress) {
      return;
    }
    
    d.price = null;
    d.distance = null;
    d.duration = null;
    d.isSearchingAddress = true;
    d.addressError = "";

    this.setState({destinations});

    try {
      const url = `${baseURL}/routes/locations?location=${address}`;
      const response = await fetch(url);
      const data = await response.json();
      
      if (index !== null && index !== undefined) {
        const { destinations } = this.state;
        const destination = destinations[index];
        destination.place = data.result[0];
        destination.isSearchingAddress = false;

        if (!data.result || data.result.length === 0) {
          destination.addressError = "No existe la dirección";
        }

        this.setState({destinations}, () => {
          this.estimatePrice(index, true);
        });
      } else {
        d.isSearchingAddress = false;
        this.setState({
          place: data.result[0],
        }, () => {
          // this.props.history.push('/dashboard');
        });
      }
    } catch (e) {
      console.error(e);
      this.setState({
        isGettingGeocode: false
      });
    }
  }

  async estimatePrice(index, isDelivery) {
    const { collectPlace, destinations } = this.state;
    const d = destinations[index];

    if (collectPlace && d.place) {
      const src = collectPlace.geometry.location;
      const dest = d.place.geometry.location;

      d.isEstimating = true;
      d.addressError = "";
      d.price = null;
      d.distance = null;
      d.duration = null;
      this.setState({ destinations });

      api.getCollectEstimation(
        src.lat, src.lng,
        dest.lat, dest.lng
      ).then((r) => {
        d.price = r.result.price;
        d.distance = r.result.distance;
        d.duration = r.result.duration;
        d.isEstimating = false;
        this.setState({ destinations });
      });
    } else if (isDelivery) {
      d.addressError = "Para estimar la distancia, tiempo y costo, "
        + "introduce la dirección de recolección.";
      this.setState({ destinations });
    }
  }

  async estimateDriverDistances() {
    const { drivers, collectPlace } = this.state;

    drivers.forEach(d => {
      if (d.state && d.state.lastPosition) {
        d.distance = getDistance(
          {
            latitude: collectPlace.geometry.location.lat,
            longitude: collectPlace.geometry.location.lng
          },
          {
            latitude: d.state.lastPosition.latitude,
            longitude: d.state.lastPosition.longitude
          }
        );
      }
    });

    this.setState({
      drivers: drivers.sort((a, b) => a.distance - b.distance)
    });
  }

  async load() {
    const senders = await api.getSenders();
    this.setState({
      isLoading: false,
      senders
    });
  }

  async loadPackageTypes() {
    api.getPackagesTypes().then(pt => {
      this.setState({packageTypes: pt});
    });
  }

  componentDidMount() {
    api.attachHistory(this.props.history, '/login');
    this.loadDrivers();
    this.loadPackageTypes();
    this.load();
  }

  validate() {
    return (this.state.guiaOriginal
      && this.state.sender
      && this.state.largo !== null
      && this.state.ancho !== null
      && this.state.alto !== null
      && this.state.peso !== null
      && this.state.nombreRecibe
      && this.state.telefonoRecibe
      && this.state.place
      && !this.state.isGettingGeocode
      && !this.state.isSaving
    );
  }

  render() {
    const {
      place,
      wasSaved,
      isSaving,
      isLoading,
      model,
      description,
      collectAddress,
      collectPlace,
      collectPlaceError,
      sourcePhone,
      destinations,
      drivers,
      driver,
      sourcePersonName,
      isSearchingOrigin,
      changedFirstDestination,
    } = this.state;

    const loc = place != null ? place.geometry.location : {};
    const collectLoc = collectPlace != null ? collectPlace.geometry.location : {};

    if (wasSaved) {
      return (
        <Container component="main" maxWidth="xs">
          <h2>Paquete creado correctamente</h2>
          <h3>{model.numeroGuia}</h3>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Button
                color="primary"
                variant="contained"
                fullWidth={true}
                startIcon={<SubtitlesIcon />}
                href={`/package/${model.paqueteId}/print`}
                target="_blank"
              >
                Imprimir etiqueta
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                color="secondary"
                variant="contained"
                fullWidth={true}
                startIcon={<AddIcon />}
                onClick={() => {
                  this.setState({
                    wasSaved: false,
                    model: null,
                    guiaOriginal: '',
                    largo: '',
                    ancho: '',
                    alto: '',
                    peso: '',
                    nombreRecibe: '',
                    telefonoRecibe: '',
                    address: null,
                    place: null
                  });
                }}
              >
                Agregar otro paquete
              </Button>
            </Grid>
            
            <Grid item xs={12}>
              <Button
                variant="outlined"
                fullWidth={true}
                onClick={() => {
                  this.props.history.push('/');
                }}
              >
                Regresar al dashboard
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="outlined"
                fullWidth={true}
                onClick={() => {
                  this.props.history.push('/packages');
                }}
              >
                Ir a lista de paquetes
              </Button>
            </Grid>
          </Grid>
        </Container>
      )
    }

    if (isLoading) {
      return (
        <Container component="main">
          <CircularProgress />
        </Container>
      );
    }

    return (
      <Container component="main">
        <h1>Ingresar nueva recolección</h1>
        <Grid container spacing={2}
          alignItems="flex-end"
          justify="flex-end"
          style={{paddingBottom: 16}}
        >
          <Grid item xs={12}>
            <Typography variant="h5">
              Origen de recolección
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <form onSubmit={(e) => e.preventDefault()}>
            <Card>
              <CardContent>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <TextField variant="outlined"
                      value={description ? description : ''}
                      label="Descripción"
                      multiline
                      rows={3}
                      fullWidth
                      onChange={
                        (e) => {
                          const update = {description: e.target.value};
                          if (!changedFirstDestination) {
                            destinations[0].productDescription = description;
                          }
                          this.setState(update);
                        }
                      } />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField variant="outlined"
                      value={sourcePersonName ? sourcePersonName : ""}
                      style={{ minWidth: 300 }}
                      label="Nombre de persona"
                      width={200}
                      onChange={
                        (e) => this.setState({ sourcePersonName : e.target.value })
                      } />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField variant="outlined"
                      value={sourcePhone ? sourcePhone : ''}
                      style={{ minWidth: 300 }}
                      label="Teléfono de contacto"
                      width={200}
                      onChange={
                        (e) => this.setState({ sourcePhone: e.target.value })
                      } />
                  </Grid>
                  <Grid item>
                    <FormControl variant="outlined" color="blue">
                      <InputLabel
                        htmlFor="origin-search">Dirección</InputLabel>
                      <OutlinedInput
                        id="origin-search"
                        value={collectAddress ? collectAddress : ""}
                        style={{ minWidth: 300 }}
                        label="Dirección"
                        width={200}
                        endAdornment={collectPlace ? <CheckIcon /> : null}
                        onChange={(e) => this.setState({
                          collectAddress: e.target.value,
                          collectPlace: null,
                          collectPlaceError: ""
                        })} />
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <Button variant="contained"
                      color="secondary"
                      size="large"
                      type="submit"
                      disabled={(isSaving || isSearchingOrigin) ? true : false}
                      startIcon={isSearchingOrigin
                        ? <CircularProgress size={24} color="white" />
                        : <SearchIcon />
                      }
                      loading={isSearchingOrigin}
                      onClick={this.searchCollectAddress.bind(this)}>
                      {isSearchingOrigin
                        ? (
                          <Grid container>
                            <Grid item>
                              
                            </Grid>
                            <Grid item>
                              Buscando dirección
                            </Grid>
                          </Grid>
                        )
                        : "Buscar"
                      }
                    </Button>
                  </Grid>
                  {collectPlace
                    ? (
                      <Grid item xs={12}
                        style={{
                          marginBottom: 16,
                          paddingBottom: 16
                        }}>
                        <div style={{marginBottom: 8}}>{collectPlace.formatted_address}</div>
                        <Button variant="outlined"
                          color="primary"
                          href={`${MAPS_URL}?api=1&query=${collectLoc.lat},${collectLoc.lng}`}
                          target="_blank">
                          Ver en Google Maps
                        </Button>
                      </Grid>
                    ) : (
                      collectPlaceError
                        ? (
                          <Grid item xs={12}>
                            <Grid container spacing={1} alignItems="center">
                              <Grid item>
                                <ErrorOutlineIcon />
                              </Grid>
                              <Grid item>
                                {collectPlaceError}
                              </Grid>
                            </Grid>
                          </Grid>
                        )
                        : null
                    )
                  }
                </Grid>
              </CardContent>
              </Card>
            </form>
          </Grid>
          
          <Grid item xs={12}>
            <Typography variant="h5">
              Entregas
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <form onSubmit={(e) => e.preventDefault()}>
              <Grid container spacing={2}>
                {destinations.map((dest, index) => {
                  let deliveryPrice = null;
                  let productPrice = parseFloat(dest.productPrice);
                  let total = 0;

                  if (dest.price) {
                    deliveryPrice = dest.price.price;
                  } else {
                    deliveryPrice = parseFloat(dest.deliveryPrice);
                  }

                  if (isNaN(deliveryPrice)) deliveryPrice = 0;
                  if (isNaN(productPrice)) productPrice = 0;

                  if (dest.payAtDeliver) {
                    if (dest.deliveryIncluded) {
                      total = productPrice;
                    } else {
                      total = productPrice + deliveryPrice;
                    }
                  } else {
                    total = deliveryPrice;
                  }

                  return (
                    <Grid item xs={12} key={index} ref={e => dest.element = e}>
                      <Card>
                        <CardContent>
                          <Grid container spacing={2}>

                            <Grid item xs={8}>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={dest.payAtDeliver}
                                      onChange={(e) => {
                                        const d = destinations[index];
                                        d.payAtDeliver = e.target.checked;
                                        this.setState({destinations});
                                      }}
                                    />
                                  }
                                  label="Pago en entrega"
                                />
                              </FormGroup>
                            </Grid>

                            {dest.payAtDeliver
                              ? (
                                <Grid item xs={4}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Switch
                                          checked={dest.deliveryIncluded}
                                          onChange={(e) => {
                                            const d = destinations[index];
                                            d.deliveryIncluded = e.target.checked;
                                            this.setState({ destinations });
                                          }}
                                        />
                                      }
                                      label="Costo de envío incluido"
                                    />
                                  </FormGroup>
                                </Grid>
                              ) : null
                            }

                            <Grid item xs={8}>
                              <TextField variant="outlined"
                                value={dest.productDescription}
                                label="Descripción de producto"
                                fullWidth
                                inputRef={e => dest.firstFieldElement = e}
                                onChange={(e) => {
                                  const destinations = this.state.destinations;
                                  const destination = destinations[index];
                                  destination.productDescription = e.target.value;
                                  this.setState({destinations, changedFirstDestination: true});
                                }} />
                            </Grid>

                            {dest.payAtDeliver
                              ? (
                                <Grid item xs={4}>
                                  <TextField variant="outlined"
                                    value={dest.productPrice}
                                    label="Precio de venta"
                                    fullWidth
                                    onChange={(e) => {
                                      const destinations = this.state.destinations;
                                      const destination = destinations[index];
                                      destination.productPrice = e.target.value;
                                      this.setState({ destinations });
                                    }} />
                                </Grid>
                              ) : null
                            }
                            
                            <Grid item xs={12} md={8}>
                              <TextField variant="outlined"
                                value={dest.receiverName}
                                label="Nombre de quién recibe"
                                fullWidth={true}
                                onChange={(e) => {
                                  const destinations = this.state.destinations;
                                  const destination = destinations[index];
                                  destination.receiverName = e.target.value;
                                  this.setState({destinations});
                                }} />
                            </Grid>

                            <Grid item xs={12} md={4}>
                              <TextField variant="outlined"
                                value={dest.receiverPhone}
                                label="Teléfono de quien recibe"
                                fullWidth
                                onChange={
                                  (e) => {
                                    const destinations = this.state.destinations;
                                    const destination = destinations[index];
                                    destination.receiverPhone = e.target.value;
                                    this.setState({destinations});
                                  }
                                } />
                            </Grid>

                            <Grid item xs={8}>
                              <FormControl variant="outlined" label="Dirección" fullWidth>
                                <InputLabel htmlFor={`address-search-${index}`}>Dirección</InputLabel>
                                <OutlinedInput
                                  id={`address-search-${index}`}
                                  label="Dirección"
                                  value={dest.address}
                                  onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                      e.preventDefault();
                                      this.searchAddress(e.target.value, index);
                                    }
                                  }}
                                  fullWidth
                                  onChange={(e) => {
                                    const destinations = this.state.destinations;
                                    const destination = destinations[index];
                                    destination.address = e.target.value;
                                    destination.place = null;
                                    destination.price = null;
                                    destination.distance = null;
                                    destination.duration = null;
                                    this.setState({destinations});
                                  }}
                                  endAdornment={
                                    <React.Fragment>
                                      {
                                        dest.isSearchingAddress
                                          ? <CircularProgress size={24} />
                                          : null
                                      }
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => {
                                          this.searchAddress(dest.address, index);
                                        }}
                                        onMouseDown={null}
                                        edge="end"
                                      >
                                        <SearchIcon />
                                      </IconButton>
                                    </React.Fragment>
                                  }
                                />
                              </FormControl>
                            </Grid>

                            {dest.place
                              ? (
                                <Grid item xs={12}>
                                  <div style={{marginBottom: 8}}>{dest.place.formatted_address}</div>
                                  <Button variant="outlined"
                                    color="primary"
                                    href={`${MAPS_URL}?api=1&query=${loc.lat},${loc.lng}`}
                                    target="_blank">
                                    Ver en Google Maps
                                  </Button>
                                </Grid>
                              ) : null
                            }

                            {
                              dest.addressError
                                ? (
                                  <Grid item xs={12}>
                                    <Grid container spacing={1} alignItems="center">
                                      <Grid item>
                                        <ErrorOutlineIcon />
                                      </Grid>
                                      <Grid item>
                                        {dest.addressError}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                )
                                : null
                            }

                            {dest.isEstimating
                              ? (
                                <Grid item xs={12}>
                                  <Grid container alignItems="center" justify="flex-start" spacing={2}>
                                    <Grid item>
                                      <CircularProgress />
                                    </Grid>
                                    <Grid item>
                                      Estimando distancia, tiempo y costo.
                                    </Grid>
                                  </Grid>
                                </Grid>
                              ) : null
                            }

                            {dest.distance !== null
                              ? (
                                <React.Fragment>
                                  <Grid item xs={3}>
                                    <Typography color="textSecondary">
                                      Distancia
                                    </Typography>
                                    <Typography variant="h5">
                                      {dest.distance ? Math.round(dest.distance * 100) / 100 : null} km
                                    </Typography>
                                  </Grid>

                                  <Grid item xs={3}>
                                    <Typography color="textSecondary">
                                      Tiempo estimado
                                    </Typography>
                                    <Typography variant="h5">
                                      {dest.duration
                                        ? moment.duration(dest.duration, 'seconds').humanize()
                                        : null}
                                    </Typography>
                                  </Grid>

                                  <Grid item xs={3}>
                                    {dest.price
                                      ? (
                                        <Typography color="textSecondary">
                                          Costo
                                        </Typography>
                                      ) : null
                                    }
                                    <Typography variant="h5">
                                      {dest.price
                                        ? `$${dest.price.price}`
                                        : (
                                          <TextField variant="outlined"
                                            value={dest.deliveryPrice}
                                            label="Costo de entrega"
                                            fullWidth
                                            onChange={
                                              (e) => {
                                                const destinations = this.state.destinations;
                                                const destination = destinations[index];
                                                destination.deliveryPrice = e.target.value;
                                                this.setState({destinations});
                                              }
                                            } />
                                        )
                                      }
                                    </Typography>
                                  </Grid>

                                  <Grid item xs={3}>
                                    <Typography color="textSecondary">
                                      {dest.payAtDeliver
                                        ? "Cobrar en entrega"
                                        : "Cobrar en recolección"
                                      }
                                    </Typography>
                                    <Typography variant="h5">
                                      <NumberFormat
                                        displayType="text"
                                        renderText={t => <span>{t}</span>}
                                        prefix="$"
                                        thousandSeparator={true}
                                        value={total}
                                      />
                                    </Typography>
                                  </Grid>
                                </React.Fragment>
                              ) : null
                            }

                            <Grid item xs={12}>
                              <Grid container alignItems="flex-end" justify="flex-end">
                                {
                                  destinations.length > 1
                                    ? (
                                      <Grid item>
                                        <IconButton variant="contained"
                                          color="secondary"
                                          size="medium"
                                          type="submit"
                                          disabled={(isSaving) ? true : false}
                                          onClick={() => {
                                            destinations.splice(index, 1);
                                            this.setState({destinations});
                                          }}>
                                          <DeleteIcon />
                                        </IconButton>
                                      </Grid>
                                    ) : null
                                }
                              </Grid>
                            </Grid>

                          </Grid> 
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </form>
          </Grid>

          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                const d = makeDestination();
                const newIndex = destinations.length;
                destinations.push(d);

                this.setState({destinations}, () => {
                  scroll.scrollTo(d.element.offsetTop - 80,
                    {duration: 500}
                  );
                  d.firstFieldElement.focus();
                });
              }}
              startIcon={<AddIcon />}
            >Agregar entrega</Button>
          </Grid>

          {place
            ? (
              <Grid item xs={12}
                style={{
                  borderBottom: 'solid 1px #f0f0f0',
                  marginBottom: 16,
                  paddingBottom: 16
                }}>
                <div style={{marginBottom: 8}}>{place.formatted_address}</div>
                <Button variant="outlined"
                  color="primary"
                  href={`${MAPS_URL}?api=1&query=${loc.lat},${loc.lng}`}
                  target="_blank">
                  Ver en Google Maps
                </Button>
              </Grid>
            ) : null
          }

          <Grid item xs={12}>
            <Typography variant="h5">
              Chofer
            </Typography>
          </Grid>
          
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <FormControl variant="outlined" label="Asignar a chofer" fullWidth={true}>
                      <InputLabel htmlFor="driver-select">Asignar a chofer</InputLabel>
                      <Select
                        value={driver ? driver : 0}
                        label="Asignar a chofer"
                        onChange={e => this.setState({driver: e.target.value})}
                        labelId="driver-select"
                      >
                        <MenuItem value={0}>- Elige un chofer -</MenuItem>
                        {drivers.map(driver => (
                          <MenuItem value={driver} key={driver.id}>
                            <ListItemText>
                              {driver.name}
                            </ListItemText>
                            <ListItemSecondaryAction>
                              {
                                driver.distance
                                  ? (
                                    <Chip
                                      label={
                                        <Km value={driver.distance / 1000} prefix={"A "} />
                                      }
                                      style={{marginRight: 16}}
                                    />
                                  ) : null
                              }
                            </ListItemSecondaryAction>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          
          <Grid item xs={12}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Button variant="contained" color="primary"
                  onClick={this.save.bind(this)}>
                  Guardar recolección
                </Button>
              </Grid>
              <Grid item>
                {isSaving ? <CircularProgress /> : null}
              </Grid>
            </Grid>
            
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withRouter(CollectAddScreen);