import React, {
  useState,
  useRef,
} from 'react';
import { withRouter, usehistory } from "react-router-dom";
import {
  Container,
  TextField,
  Grid,
  Divider,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  FormGroup,
  Paper,
  Card,
  FormControlLabel,
  CardContent,
  Typography,
} from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';

function DriverRegisterScreen(props) {
  const [isSending, setIsSending] = useState(false);
  const [nombre, setNombre] = useState('');
  const [apellidoPaterno, setApellidoPaterno] = useState('');
  const [apellidoMaterno, setApellidoMaterno] = useState('');
  const [telefono, setTelefono] = useState('');
  const [direccion, setDireccion] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [licencia, setLicencia] = useState('');
  const [marca, setMarca] = useState('');
  const [modelo, setModelo] = useState('');
  const [year, setYear] = useState('');
  const [color, setColor] = useState('');
  const [placas, setPlacas] = useState('');
  const [poliza, setPoliza] = useState('');

  const [acceptTOS, setAcceptTOS] = useState(false);

  const comprobanteUploader = useRef(null);
  const frenteLicenciaUploader = useRef(null);
  const reversoLicenciaUploader = useRef(null);
  const circulacionFrenteUploader = useRef(null);
  const circulacionReversoUploader = useRef(null);

  return (
    <Container style={{paddingBottom: 48}}>
      <h1>Registro de Chofer</h1>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card fullWidth={true}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xl={4} lg={4} xs={12}>
                  <TextField fullWidth={true}
                    value={nombre}
                    onChange={(e) => setNombre(e.target.value)}
                    variant="outlined"
                    label="Nombre" />
                </Grid>
                <Grid item xl={4} lg={4} xs={12}>
                  <TextField fullWidth={true}
                    value={apellidoPaterno}
                    onChange={(e) => setApellidoPaterno(e.target.value)}
                    variant="outlined"
                    label="Apellido paterno" />
                </Grid>
                <Grid item xl={4} lg={4} xs={12}>
                  <TextField fullWidth={true}
                    value={apellidoMaterno}
                    onChange={(e) => setApellidoMaterno(e.target.value)}
                    variant="outlined"
                    label="Apellido materno" />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField fullWidth={true}
                    value={telefono}
                    onChange={(e) => setTelefono(e.target.value)}
                    variant="outlined"
                    label="Número de celular" />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        
        <Grid item container spacing={2}>
            <Grid item md={6} xs={12}>
              <Card>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h6" butterBottom>
                        Domicilio
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField fullWidth={true}
                      value={direccion}
                      onChange={(e) => setDireccion(e.target.value)}
                      variant="outlined"
                      label="Calle, número y colonia" />
                    </Grid>
                    
                    <Grid item xs={12}>
                      <FormControl variant="outlined">
                        <FormLabel htmlFor="file">
                          Comprobante de domicilio
                        </FormLabel>
                        <input type="file"
                          id="comprobanteDomicilio"
                          ref={comprobanteUploader} />
                      </FormControl>
                    </Grid> 
                  </Grid>
                </CardContent>
              </Card>
            </Grid>       

            <Grid item md={6} xs={12}>
              <Card fullWidth={true} style={{height: '100%'}}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h6" butterBottom>
                        Licencia
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField fullWidth={true}
                        value={licencia}
                        onChange={(e) => setLicencia(e.target.value)}
                        variant="outlined"
                        label="Licencia de conducir" />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl variant="outlined">
                        <FormLabel htmlFor="comprobanteDomicilio">
                          Licencia de frente
                        </FormLabel>
                        <input type="file"
                          id="comprobanteDomicilio"
                          ref={frenteLicenciaUploader} />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl variant="outlined">
                        <FormLabel htmlFor="reversoLicencia">
                          Reverso de licencia
                        </FormLabel>
                        <input type="file"
                          id="reversoLicencia"
                          ref={reversoLicenciaUploader} />
                      </FormControl>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

          </Grid>

        <Grid item xs={12} >
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    Vehículo
                  </Typography>
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField fullWidth={true}
                    value={telefono}
                    onChange={(e) => setMarca(e.target.value)}
                    variant="outlined"
                    label="Marca" />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField fullWidth={true}
                    value={direccion}
                    onChange={(e) => setModelo(e.target.value)}
                    variant="outlined"
                    label="Modelo" />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField fullWidth={true}
                    value={color}
                    onChange={(e) => setColor(e.target.value)}
                    variant="outlined"
                    label="Color" />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField fullWidth={true}
                    value={color}
                    onChange={(e) => setColor(e.target.value)}
                    variant="outlined"
                    label="Año" />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField fullWidth={true}
                    value={placas}
                    onChange={(e) => setPlacas(e.target.value)}
                    variant="outlined"
                    label="Placas" />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField fullWidth={true}
                    value={poliza}
                    onChange={(e) => setPlacas(e.target.value)}
                    variant="outlined"
                    label="Póliza" />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField fullWidth={true}
                    value={color}
                    onChange={(e) => setColor(e.target.value)}
                    variant="outlined"
                    label="Tarjeta de circulación" />
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl variant="outlined">
                    <FormLabel htmlFor="file">
                      Frente de tarjeta de circulación
                    </FormLabel>
                    <input type="file"
                      id="circulacionFrente"
                      ref={circulacionFrenteUploader} />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl variant="outlined">
                    <FormLabel htmlFor="file">
                      Reverso de tarjeta de circulación
                    </FormLabel>
                    <input type="file"
                      id="circulacionReverso"
                      ref={circulacionReversoUploader} />
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    Inicio de sesión
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth={true}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    variant="outlined"
                    label="Correo electrónico" />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth={true}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    variant="outlined"
                    label="Contraseña" />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        
        <Grid item xs={12} style={{paddingTop: 24}}>
          <Button
            variant="contained"
            disabled={isSending}
            color="primary"
            fullWidth={true}
            onClick={() => {
              setIsSending(true);
            }}>
            Completar Registro
          </Button>
        </Grid>

      </Grid>
    </Container>
  )
}

export default withRouter(DriverRegisterScreen);