import React from 'react';
import Container from '@material-ui/core/Container';
import { withRouter } from "react-router-dom";
import API from '../api';
import EditIcon from '@material-ui/icons/Edit';
import CategoryIcon from '@material-ui/icons/Category';
import AddIcon from '@material-ui/icons/Add';
import {
  List,
  Paper,
  Grid,
  Button,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
} from '@material-ui/core';

const api = API.instance();

class PackageTypesScreen extends React.Component {
  state = {
    items: [],
    isLoading: true,
    isCreating: false
  }

  componentDidMount() {
    this.load();
  }

  async load() {
    const update = { isLoading: false };

    try {
      update.items = await api.getPackagesTypes();
      console.log(update.items);
    } catch (e) {
      console.error(e);
    }

    this.setState(update);
  }

  async toggleShowInactive() {
    this.setState({
      isLoading: true,
    }, () => {
      this.load();
    });
  }

  async create() {
    const { isCreating } = this.state;

    if (isCreating) {
      return;
    }

    this.setState({isCreating: true});

    try {
      const item = await api.createPackageType();
      const { items } = this.state;
      items.push(item);

      this.setState({
        items,
        isCreating: false
      });
    } catch (e) {
      console.error(e);
      alert("Algo salió mal: ${e.message}");
      this.setState({isCreating: false});
    }
  }

  render() {
    const {
      items,
      isLoading,
      isCreating,
    } = this.state;
    let child;

    if (isLoading) {
      child = (
        <CircularProgress container={<div style={{ padding: 32 }} />} />
      );
    } else {
      child = (
        <React.Fragment>
          <List>
            {
              items.map((vtype, index) => {
                return (
                  <ListItem divider={true} dense={false} key={index}>
                    <ListItemIcon>
                      <CategoryIcon />
                    </ListItemIcon>
                    <ListItemText
                      secondary={
                        vtype.volumenMinimo !== null && vtype.volumenMaximo !== null
                          ? <span>
                            Volumen: {vtype.volumenMinimo} - {vtype.volumenMaximo}
                            m<sup>3</sup> 
                          </span>
                          : ''
                      }>
                      {vtype.nombre}
                    </ListItemText>
                    <ListItemSecondaryAction>
                      <IconButton variant="outlined"
                        onClick={
                          () => {
                            this.props.history.push(`/package/type/${vtype.tipoPaqueteId}`)
                          }
                        }>
                        <EditIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              })
            }
          </List>
        </React.Fragment>
      );
    }

    return (
      <Container component="main">
        <Grid container>
          <Grid item sm={6}>
            <h1>Tipos de paquetes</h1>
          </Grid>
          <Grid item sm={6} style={{ textAlign: 'right' }}>
            <Button
              color="primary"
              variant="contained" 
              startIcon={isCreating ? <CircularProgress /> : <AddIcon />}
              onClick={this.create.bind(this)}
            >Crear</Button>
          </Grid>
        </Grid>
        <Paper>
          {child}
        </Paper>
        {items
          ? <div style={{ paddingTop: 16, color: 'grey', textAlign: 'center' }}>
            {`${items.length} tipos de vehículos`}
          </div> : null}
      </Container>
    );
  }
}

export default withRouter(PackageTypesScreen);
