import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { withRouter } from "react-router-dom";
import API from '../api';
import AddIcon from '@material-ui/icons/Add';
import GpsNotFixedIcon from '@material-ui/icons/GpsNotFixed';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import {
  Map,
  Marker,
  TileLayer,
  Polygon
} from 'react-leaflet';
import L from 'leaflet'
import {
  List,
  Paper,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Box,
  TextField
} from '@material-ui/core';

const position = [29.087069, -110.960013];
const api = API.instance();

export const MarkedIcon = new L.Icon({
  iconUrl: '/vertice-move.png',
  iconRetinaUrl: '/vertice-move.png',
  iconAnchor: [12, 12],
  // popupAnchor: [10, -44],
  iconSize: [24, 24],
  // shadowUrl: '../assets/marker-shadow.png',
  shadowSize: [68, 95],
  shadowAnchor: [20, 92],
})

export const VerticeAppendIcon = new L.Icon({
  iconUrl: '/vertice-append.png',
  iconRetinaUrl: '/vertice-append.png',
  iconAnchor: [12, 12],
  // popupAnchor: [10, -44],
  iconSize: [24, 24],
  // shadowUrl: '../assets/marker-shadow.png',
  shadowSize: [68, 95],
  shadowAnchor: [20, 92],
})

class ZonesScreen extends React.Component {
  
  constructor(props) {
    super();
    this.state = {
      'user': '',
      'password': '',
      redirect: null,
      address: '',
      place: null,
      guia: null,
      zonas: [],
      viewport: props.viewport,
      zona: null,
      changedZones: [],
      zoneSaving: null,
      zoneName: '',

      isCreatingZone: false,
      isLoading: true,
      isSavingZoneChanges: false
    }

    this.map = null;
  }

  componentDidMount() {
    api.attachHistory(this.props.history, '/login');
    this.load();
  }

  async load() {
    const update = { isLoading: false };

    try {
      update.zonas = await api.getZonas({
        withRoles: true,
        expiration: "valid"
      });
      update.zonas.forEach((zona) => {
        zona.zonaVertice = zona.zonaVertice.sort(
          (a, b) => (
            a.index > b.index
              ? 1 : (b.index > a.index ? -1 : 0)
          )
        );
      });
    } catch (e) {
      console.error(e);
    }

    this.setState(update);
  }

  async createZone() {
    const {
      isCreatingZone
    } = this.state;

    if (isCreatingZone) {
      return;
    }

    const center = this.map.leafletElement.getCenter();
    const update = {isCreatingZone: false};

    this.setState({isCreatingZone: true});

    try {
      const zona = await api.createZone({
        zonaVertice: [
          {
            latitud: center.lat - 0.005,
            longitud: center.lng - 0.005,
            index: 0
          },
          {
            latitud: center.lat - 0.005,
            longitud: center.lng + 0.005,
            index: 1
          },
          {
            latitud: center.lat + 0.005,
            longitud: center.lng - 0.005,
            index: 2
          }
        ]
      });
      const zonas = this.state.zonas;

      zonas.push(zona);
      update.zonas = zonas;
    } catch (e) {
      console.log(e);
    }

    this.setState(update);
  }

  async saveZoneChanges() {
    const {
      isSavingZoneChanges,
      changedZones,
    } = this.state;

    if (isSavingZoneChanges) {
      return;
    }

    this.setState({isSavingZoneChanges: true});

    let zone;

    try {
      zone = changedZones.shift();
      while (zone) {
        try {
          this.setState({zoneSaving: zone});
          await api.saveZona(zone);
        } catch (e) {
          console.error(e);
        }
        this.setState({ changedZones, zoneSaving: null });
        zone = changedZones.shift();
      }
      this.setState({isSavingZoneChanges: false});
    } catch (e) {
      console.error(e);
    }
  }

  renderZonesList() {
    const {
      zonas,
      zoneName,
      changedZones,
      zoneSaving
    } = this.state;

    if (zonas && zonas.length > 0) {
      return (
        <div style={{maxHeight: 500, overflow: 'auto'}}>
        <List>
          {
            zonas.map((zona, index) => {
              return (
                <ListItem button divider={true}
                  onClick={() => this.setState({zona, zoneName: zona.nombre})}
                  selected={zona === this.state.zona}
                  key={index}>
                  <ListItemIcon><GpsNotFixedIcon /></ListItemIcon>
                  <ListItemText>
                    {zona === this.state.zona && zoneName
                      ? zoneName
                      : (
                        zona.nombre
                          ? `${zona.nombre} (ID: ${zona.zonaId})`
                          : `ID de zona: ${zona.zonaId}`
                      )
                    }
                  </ListItemText>
                  <ListItemSecondaryAction>
                    {changedZones.indexOf(zona) !== -1
                      ? <NewReleasesIcon color="secondary" />
                      : (zoneSaving === zona
                        ? <CircularProgress />
                        : null
                      )
                    }
                  </ListItemSecondaryAction>
                </ListItem>
              )
            })
          }
        </List>
        </div>
      );
    } else {
      return (
        <div style={{
          display: 'flex',
          height: '400px',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <span>No se han creado Zonas.</span>
        </div>
      );
    }
  }

  renderZoneVertices() {
    const { zona, changedZones } = this.state;
    if (zona && zona.zonaVertice.length > 0) {
      return zona.zonaVertice.map((v, index) => {
        return (
          <Marker draggable={true}
            key={index}
            width={16}
            icon={MarkedIcon}
            onclick={() => {
              console.log(index);
            }}
            ondragend={(e) => {
              const pos = e.target.getLatLng();
              zona.zonaVertice[index].latitud = pos.lat;
              zona.zonaVertice[index].longitud = pos.lng;

              if (changedZones.indexOf(zona) === -1) {
                changedZones.push(zona);
              }

              this.setState({zona, changedZones});
            }}
            position={[v.latitud, v.longitud]}
          />
        );
      });
    }
  }

  renderZoneAppends() {
    const { zona, changedZones } = this.state;

    if (zona && zona.zonaVertice && zona.zonaVertice.length > 1) {
      const appends = [];
      let minlat;
      let minlng;
      let a;
      let b;
      let aindex;
      let bindex;

      for (let idx = 0; idx < zona.zonaVertice.length; idx++) {
        if (idx === zona.zonaVertice.length - 1) {
          aindex = idx;
          bindex = 0;
        } else {
          aindex = idx;
          bindex = idx + 1;
        }

        a = zona.zonaVertice[aindex];
        b = zona.zonaVertice[bindex];

        minlat = a.latitud < b.latitud ? a.latitud : b.latitud;
        minlng = a.longitud < b.longitud ? a.longitud : b.longitud;

        appends.push({
          lat: Math.abs(((a.latitud - minlat) - (b.latitud - minlat)) / 2) + minlat,
          lng: Math.abs(((a.longitud - minlng) + (b.longitud - minlng)) / 2) + minlng,
          index: [aindex, bindex]
        });
      }

      return appends.map((a, index) => {
        return (
          <Marker
            key={index}
            icon={VerticeAppendIcon}
            position={[a.lat, a.lng]}
            onclick={() => {
              zona.zonaVertice.splice(a.index[0] + 1, 0, {
                latitud: a.lat,
                longitud: a.lng,
                zonaId: zona.zonaId
              });
              if (changedZones.indexOf(zona) === -1) {
                changedZones.push(zona);
              }
              this.setState({ zona, changedZones });
            }} />
        );
      });
    }
  }

  renderMapZones() {
    return this.state.zonas.map((zone, index) => {
      if (zone.zonaVertice && zone.zonaVertice.length > 0) {
        return (
          <Polygon
            color="black"
            key={index}
            onclick={() => {
              this.setState({
                zona: zone,
                zoneName: zone.nombre
              });
            }}
            positions={zone.zonaVertice.map(v => [v.latitud, v.longitud])}
          />
        );
      }
      return null;
    });
  }

  render() {
    const {
      zona,
      zonas,
      zoneName,
      isLoading,
      viewport,
      isCreatingZone,
      changedZones,
      isSavingZoneChanges
    } = this.state;
    let child;

    const mapOptions = {
      center: position,
      zoom: 13,
      viewport,
      style: {
        height: '400px'
      },
      editable: true,
      ref: (ref) => this.map = ref
    };

    if (isLoading) {
      child = (
        <CircularProgress container={<div style={{ padding: 32 }} />} />
      );
    } else {
      child = (
        <React.Fragment>
          <Grid container>

            <Grid item xs={12} sm={6} >
              <div className="leafleft-container" style={{ borderRadius: 16 }}>
                <Map {...mapOptions} editable={true}>
                  <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                  {this.renderMapZones()}
                  {this.renderZoneVertices()}
                  {this.renderZoneAppends()}
                </Map>
              </div>
              {zona
                ? (
                  <div style={{padding: 16}}>
                    <TextField
                      value={zoneName ? zoneName : ''}
                      variant="outlined"
                      fullWidth={true}
                      label="Nombre de zona"
                      onChange={(e) => {
                        if (changedZones.indexOf(zona) === -1) {
                          changedZones.push(zona);
                        }
                        this.setState({zoneName: e.target.value});
                        zona.nombre = e.target.value;
                      }}
                    />
                    <h4>Roles</h4>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell><strong>Usuario</strong></TableCell>
                          <TableCell><strong>Rol</strong></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {zona?.roles?.map(role => {
                          return (
                            <TableRow>
                              <TableCell>{role.user.name}</TableCell>
                              <TableCell>{role.role}</TableCell>
                            </TableRow>
                          );
                        }) ?? null}
                      </TableBody>
                    </Table>
                  </div>
                ) : null
              }
            </Grid>

            <Grid item xs={12} sm={6}>
              {this.renderZonesList()}
              <Grid item style={{padding: 16}}>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={changedZones.length === 0 || isSavingZoneChanges}
                  onClick={this.saveZoneChanges.bind(this)}
                >
                  Guardar Cambios  
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      );
    }

    return (
      <Container component="main">
        <Box display="flex" flexWrap="nowrap" alignItems="center" justifyContent="center">
          <Box>
            <h1>Zonas</h1>
          </Box>
          <Box style={{textAlign: 'right'}} flex={1}>
            <Button variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={this.createZone.bind(this)}>
              {isCreatingZone ? <CircularProgress /> : "Nueva zona"}
            </Button>
          </Box>
        </Box>
        <Paper>
          {child}
        </Paper>
        <Grid container>
          <Grid item xs={6}>
            {zonas
              ? <div style={{ paddingTop: 16, color: 'grey', textAlign: 'center' }}>
                {`${zonas.length} zonas encontradas`}
              </div> : null}
          </Grid>
          {/*<Grid item flex={true} xs={6} style={{justifyContent: 'center'}}>
            <NewReleasesIcon color="secondary" />
            Pendientes de guardar
            </Grid>*/}
        </Grid>
      </Container>
    );
  }
}

export default withRouter(ZonesScreen);