import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Grid,
  Paper,
  TextField,
  IconButton,
  ListItem
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Search as SearchIcon,
} from '@material-ui/icons';
import TitleAndDescription from './title_and_description';

import API from '../api';
const api = new API();

export default function FeatureSearcher(props) {
  const {
    categoriesIds,
    onSelect,
    preload,
    ignoreIds,
  } = props;
  const classes = useStyles();
  const inputRef = useRef();

  const [openDropdown, setOpenDropdown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [features, setFeatures] = useState([]);

  const fetchItems = useCallback(async () => {
    if (isLoading) return;

    setOpenDropdown(true);
    setIsLoading(true);

    try {
      const ft = await api.getFeatures({
        categoriesIds,
        search: inputRef.current?.value,
        include: ['options'],
        ignoreIds: ignoreIds
      });
      setFeatures(ft);
    } catch (e) {
      console.error(`[FeatureSearcher.loadItems] ${e}`);
    }

    setIsLoading(false);
  }, [inputRef, categoriesIds, isLoading, features]);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();

    if(isLoading)
      return;

    fetchItems();
  }, [inputRef, isLoading]);

  const handleSelectItem = useCallback((e) => {
    setOpenDropdown(false);
    if ('function' === typeof onSelect)
      onSelect(e);
  }, []);

  const handleOnFocus = useCallback((e) => {
    
  }, []);

  useEffect(() => {
    if (preload) {
      fetchItems();
    }
  }, []);

  let clsDialog = classes.dropdownDialog + 
    ` ${openDropdown ? classes.showDialog : classes.hideDialog}`;

  return (
    <form onSubmit={handleSubmit}>
      <Grid container alignItems="center">
        <Grid item>
          <PaddinglessTextField variant="outlined" margin="dense"
            disabled={isLoading}
            onFocus={handleOnFocus}
            inputRef={inputRef}>
          </PaddinglessTextField>
          <Paper className={clsDialog} elevation={5} style={{maxHeight: 160, overflowY: "auto"}}>
            {isLoading ? (
              <div className={classes.loading}>Cargando...</div>
            ) : (features?.map((f, index) =>
                <ListItem button key={index}
                  onClick={handleSelectItem.bind(null, f)}
                >
                  <TitleAndDescription title={f.name} description={f.description} />
                </ListItem>
              )
            )}
          </Paper>
        </Grid>
        <Grid item>
          <IconButton type="submit">
            <SearchIcon />
          </IconButton>
        </Grid>
      </Grid>
    </form>
  );
}

const PaddinglessTextField = withStyles({
  root: { margin: 0 }
})(TextField);

const useStyles = makeStyles({
  dropdownDialog: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    position: "absolute",
    zIndex: 1000,
    marginBottom: 0, 
    minWidth: 300,
    maxHeight: 400
  },
  showDialog: {
    display: "block"
  },
  hideDialog: {
    display: "none"
  },
  loading: {
    padding: 8
  }
});