import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import NumberFormat from "react-number-format";
import { green, red, orange } from '@material-ui/core/colors';
import DeliveryTile from './delivery-tile';
import Btn from '../components/button';
import API from '../api';
import {
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  Typography,
  Chip,
	Avatar,
  Grid,
  MenuItem,
  ListItemText,
	ListItem,
  ListItemSecondaryAction,
	List,
	ListItemAvatar,
	Divider,
	TextField,
} from '@material-ui/core';

import {
  Phone as PhoneIcon,
  Person as PersonIcon,
  MailOutline as MailOutlineIcon,
  Close as CloseIcon,
  Check as CheckIcon,
  DeleteForever as DeleteForeverIcon,
	RadioButtonUnchecked as RadioButtonUncheckedIcon,
	CheckCircleOutline as CheckCircleOutlineIcon,
	CropFree as CropFreeIcon,
	CheckCircle as CheckCircleIcon,
} from '@material-ui/icons';

const Km = (props) => (
  <NumberFormat
    thousandSeparator={true}
    decimalScale={1}
    suffix="km"
    displayType="text"
    {...props}
  />
);

const api = API.instance();

export default function AssignDeliveryForm({
	delivery: d,
	drivers = null,
	isCancelling,
	onAssign,
	onCancel,
	onClose,
	assigning,
	cancellable,
	collectProducts = false,
	assignTitle = "Asignar",
}) {
	const [driverId, setDriverId] = useState(d?.driver?.id || d?.chofer?.choferId);
	const [isSaving, setIsSaving] = useState(false);
	const [theDrivers, setDrivers] = useState([]);
	const [scannedSku, setScannedSku] = useState('');
	const [scannedProductIds, setScannedProductIds] = useState({});
	const scanFieldRef = useRef();

	const handleSelectDriver = useCallback(e => {
		console.log(`[handleSelectDriver] driverId: ${e.target.value}`);
		setDriverId(e.target.value);
	}, []);

	const checkedAllProducts = useMemo(() => {
		return Object.keys(scannedProductIds).length === d.products?.length;
	}, [scannedProductIds]);

	const readyToAssign = useMemo(() => {
		return !assigning && !isSaving && driverId
			&& (!collectProducts || checkedAllProducts);
	}, [assigning, isSaving, driverId, collectProducts, d, checkedAllProducts]);

	const handleConfirmAssignation = useCallback(async () => {
		if (isSaving) return;

    setIsSaving(true);

    try {
      const response = await api.assignDelivery({
        deliveryId: d?.id || d?.viajeId,
        driverId: driverId
      });

			if (response.isSuccess) {
				onAssign && onAssign();
				onClose && onClose();
			} else {
				alert(response.message);
			}
      setIsSaving(false);	
    } catch (e) {
      console.log(`[handleConfirmAssignation] error: ${e}`);
      alert(e.message);
      setIsSaving(false);
    }
	}, [d, driverId]);

	const handleSubmitScan = useCallback(e => {
		e.preventDefault();
		const foundProduct = d.products?.filter(p => p.sku === scannedSku);
		setScannedProductIds({
			...scannedProductIds,
			[foundProduct[0].id]: true
		})
		setScannedSku("");
	}, [scannedSku, scannedProductIds, d]);

	const load = useCallback(async () => {
		try {
			const r = await api.getDrivers({activeState: 1});
			setDrivers(r.result);

			if (collectProducts) {
				scanFieldRef.current?.focus();
			}
		} catch (e) {
			alert("Error al carga los conductores.");
		}
	}, [scanFieldRef, collectProducts]);

	useEffect(() => {
		console.log(scannedProductIds);
	}, [scannedProductIds]);

	useEffect(() => {
		if (!drivers) {
			load();
		}
	}, []);

	return (
		<Grid container spacing={2}>
			<Grid item>
				<Grid container spacing={1}>
				<Grid item xs={6}>
					<Typography color="textSecondary">Información de Entrega</Typography>
					<DeliveryTile delivery={d} />
				</Grid>
				<Grid item xs={6}>
					<Grid container>
					<Grid item>
						<Typography color="textSecondary">Información de {d?.requestedBy === "seller" ? "Vendedor" : "Cliente"}</Typography>
					</Grid>
	
					<Grid item xs={12}>
						<Grid container spacing={1}>
						<Grid item>
							<PersonIcon style={{ fontSize: 16 }} />
						</Grid>
						<Grid item>
							{d?.user?.name ?? ""} {d?.user?.lastName ?? ""}
						</Grid>
						</Grid>
					</Grid>
	
					{d?.user?.email ? (
						<Grid item xs={12}>
							<Grid container spacing={1}>
							<Grid item>
								<MailOutlineIcon style={{ fontSize: 16 }} />
							</Grid>
							<Grid item>
								{d.user.email}
							</Grid>
							</Grid>
						</Grid>
					) : null}
	
					<Grid item xs={12}>
						<Grid container spacing={1}>
						<Grid item>
							<PhoneIcon style={{ fontSize: 16 }} />
						</Grid>
						<Grid item>
							{d?.user?.phone ? d.user.phone : "Teléfono no especificado"}
						</Grid>
						</Grid>
					</Grid>

					</Grid>
				</Grid>
				</Grid>
			</Grid>

			{collectProducts ? (
				<Grid item xs={12}
					style={{
						borderRadius: 16,
						backgroundColor: "#e9e9e9",
						marginTop: 16,
						marginBottom: 16,
						padding: 0,
						paddingTop: 8,
						paddingBottom: 8
					}}>
					<div style={{paddingLeft: 16, marginBottom: 8}}>
						<Typography style={{marginBottom: 8}}>Recolectar productos de estantes</Typography>
						<form onSubmit={handleSubmitScan}>
							<TextField
								variant="outlined"
								margin="dense"
								value={scannedSku}
								onChange={e => setScannedSku(e.currentTarget.value)}
								placeholder="Escanee un código de barra" inputRef={scanFieldRef}
								/>
						</form>
					</div>
					<ProductsCheckList products={d?.products} checked={scannedProductIds} />
				</Grid>
			) : null}
	
			<Grid item xs={12} style={{padding: 0, paddingTop: 8, paddingBottom: 8}}>
				<FormControl variant="outlined" fullWidth={true}>
				<InputLabel htmlFor="driver-select">Choferes activos</InputLabel>
				<Select
					value={driverId || 0}
					labelId="driver-select"
					label="Asignar a chofer"
					margin="dense"
					onChange={handleSelectDriver}
				>
					<MenuItem value={0}>- Elige un chofer -</MenuItem>
					{(drivers || theDrivers)?.map(driver => (
					<MenuItem value={driver.id} key={driver.id}>
						<ListItemText>
							{driver.name}
						</ListItemText>
						<ListItemSecondaryAction>
						{
							driver.distance
							? (
								<Chip
								size="small"
								label={
									<Km value={driver.distance / 1000} prefix={"A "} />
								}
								style={{
									color: "white",
									marginRight: 16,
									fontWeight: "bold",
									backgroundColor: driver.distance < 4000
									? green[400]
									: (driver.distance < 10000
										? orange[400]
										: red[400]
									)
								}}
								/>
							) : null
						}
						</ListItemSecondaryAction>
					</MenuItem>
					))}
				</Select>
				<FormHelperText>Distancia entre el chofer y el punto de recolección.</FormHelperText>
				</FormControl>
			</Grid>
			<Grid item>
				<Btn color="success"
					isLoading={assigning || isSaving}
					onClick={handleConfirmAssignation}
					disabled={!readyToAssign}
					startIcon={<CheckIcon />}
					>
					{(assigning || isSaving) ? "Asignando" : assignTitle}
				</Btn>
			</Grid>
			{cancellable
				? (
					<Grid item>
						<Btn color="error"
						isLoading={isCancelling}
						onClick={() => onCancel(d.id)}
						disabled={isCancelling}
						startIcon={<DeleteForeverIcon />}
						>
						{(assigning || isSaving) ? "Cancelando" : "Cancelar"}
						</Btn>
					</Grid>
				) : null
			}
			<Grid item>
				<Btn
					isLoading={assigning}
					onClick={onClose}
					disabled={assigning}
					startIcon={<CloseIcon />}
					>Cerrar</Btn>
			</Grid>
		</Grid>
	);
}

function ProductsCheckList({
	products,
	checked,
}) {
	return (
		<List>
			{products?.map((p, index) =>
				<React.Fragment key={index}>
					<ProductCheckItem product={p} checked={checked[p.id]} />
					<Divider />
				</React.Fragment>
			)}
		</List>
	)
}

function ProductCheckItem({
	product,
	checked,
}) {
	const textColor = useMemo(() => {
		return checked ? "white" : "black";
	}, [checked]);

	return (
		<ListItem dense style={{backgroundColor: checked ? green[500] : "transparent"}}>
			<ListItemAvatar>
				<Avatar src={product?.mainImage?.thumbnailUrl} />
			</ListItemAvatar>
			<ListItemText secondary={`SKU: ${product.sku}`} style={{color: textColor}}>
				{product?.title}
			</ListItemText>
			<ListItemSecondaryAction>
				<Grid container spacing={1} alignItems="center" justifyContent="center">
					<Grid item style={{marginRight: 16}}>
						<Grid container spacing={2}>
							<Grid item style={{color: textColor}}>
								<Grid container direction="column" alignItems="center">
									<Grid item style={{fontSize: 20, fontWeight: "bold"}}>
										{product?.user?.rackSpaces[0]?.rack?.name}
									</Grid>
									<Grid item style={{fontSize: 10}}>Rack</Grid>
								</Grid>
							</Grid>
							<Grid item style={{color: textColor}}>
								<Grid container direction="column" alignItems="center">
									<Grid item style={{fontSize: 20, fontWeight: "bold"}}>
										{product?.user?.rackSpaces[0]?.line}
									</Grid>
									<Grid item style={{fontSize: 10}}>Línea</Grid>
								</Grid>
							</Grid>
							<Grid item style={{color: textColor}}>
								<Grid container direction="column" alignItems="center">
									<Grid item style={{fontSize: 20, fontWeight: "bold"}}>
										{product?.user?.rackSpaces[0]?.column}
									</Grid>
									<Grid item style={{fontSize: 10}}>Col.</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid item>
						{checked ? (
							<CheckCircleIcon style={{color: "white"}} />
						) : (
							<RadioButtonUncheckedIcon style={{color: "#999"}} />
						)}
					</Grid>
				</Grid>
			</ListItemSecondaryAction>
		</ListItem>
	)
}