import React from 'react';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';
import InboxIcon from '@material-ui/icons/Inbox';
import ErrorIcon from '@material-ui/icons/Error';

import {
  green, pink, orange,
  blue, cyan, lime,
  red,
  grey, pinkAccent,
  purple,
} from '@material-ui/core/colors';

export class DeliveryStatus {
  static requested = 1;
  static collecting = 2;
  static delivering = 3;
  static arriving = 4;
  static inDestination = 5;
  static delivered = 6;
  static receiverNotFound = 7;
  static notDelivered = 7;
  static backInStorage = 8;
  static readyToCollectAgain = 9;
  static mustBePickedUp = 10;
  static wasntPickedUp = 11;
  static assigningToDriver  = 12;
  static NoOneAccepted = 13;
  static CancelledBySystem = 14;
  static requestedOutOfSchedule = 15;
  static cancelledByDriver = -1;
  static TowardDistributionCenter = 17;
  static ReceivedInDistributionCenter = 18;
  static packageInRoute = 20;
  static storedToBeAssigned = 21;
  static preparingDelivery = 21;
  static readyToBeRouted = 22;

  static map = {
    1: {
      id: 1,
      color: cyan[500],
      title: "Solicitado",
      icon: () => <CheckCircleIcon />
    },
    2: {
      id: 2,
      color: "info",
      title: "Recolectando",
      icon: () => <CheckCircleIcon />
    },
    3: {
      id: 3,
      color: blue[500],
      title: "En entrega",
      icon: () => <MoveToInboxIcon />
    },
    4: {
      id: 4,
      color: "purple",
      title: "Llegando",
      icon: () => <CheckCircleIcon />
    },
    5: {
      id: 5,
      color: "purple",
      title: "En destino",
      icon: () => <CheckCircleIcon />
    },
    6: {
      id: 6,
      color: green[500],
      title: "Entregado",
      icon: () => <CheckCircleIcon />
    },
    7: {
      id: 7,
      color: orange[500],
      title: "No entregado",
      icon: () => <ErrorIcon />
    },
    8: {
      id: 8,
      color: cyan[400],
      title: "En bodega",
      icon: () => <ErrorIcon />
    },
    9: {
      id: 9,
      color: lime[400],
      title: "Reservado",
      icon: () => <ErrorIcon />
    },
    10: {
      id: 10,
      color: blue[500],
      title: "Recolectar",
      icon: () => <InboxIcon />
    },
    11: {
      id: 11,
      color: orange[500],
      title: "No recolectado",
      icon: () => <ErrorIcon />
    },
    12: {
      id: 12,
      color: grey[500],
      title: "Asignando",
      icon: () => <ErrorIcon />
    },
    13: {
      id: 13,
      color: orange[500],
      title: "Nadie aceptó",
      icon: () => <ErrorIcon />
    },
    14: {
      id: 14,
      color: red[500],
      title: "Cancelado",
      icon: () => <ErrorIcon />
    },
    15: {
      id: 15,
      color: orange[500],
      title: "Fuera de horario de servicio",
      icon: () => <ErrorIcon />
    },
    17: {
      id: 17,
      colors: blue[500],
      title: "Hacia centro de distribución"
    },
    18: {
      id: 18,
      colors: purple[500],
      title: "En centro de distribución"
    },
    "-1": {
      id: -1,
      color: red[500],
      title: "Cancelado",
      icon: () => <ErrorIcon />
    },
    "-2": {
      id: -1,
      color: red[500],
      title: "Cancelado por chofer",
      icon: () => <ErrorIcon />
    },
    "999999": {
      id: 999999,
      color: "black",
      title: "Estatus desconocido",
      icon: () => <ErrorIcon />
    },
    21: {
      id: 21,
      color: blue[500],
      title: "Se debe tomar producto de almacén"
    },
    22: {
      id: 22,
      color: blue[500],
      title: "Listo para ser enrutado"
    }
  };

  static getList() {
    let statuses = [];
    for (let key in DeliveryStatus.map) {
      statuses.push(DeliveryStatus.map[key]);
    }
    return statuses;
  }

  static getStatusWithId(id) {
    let status = DeliveryStatus.map[id];
    if (!status) {
      status = DeliveryStatus.map[999999];
    }
    return status;
  }
}

export class DeliveryPaymentOrigin {
  static atDelivery = 1;
  static atCollect = 2;
}

export class DeliveryPhase {
  static collect = 1;
  static deliver = 2;

  static isCollecting(status) {
    switch (status) {
      case DeliveryStatus.TowardDistributionCenter:
      case DeliveryStatus.mustBePickedUp:
        return true;
      default:
        return false;
    }
  }

  static isDelivering(status) {
    switch (status) {
      case DeliveryStatus.ReceivedInDistributionCenter:
      case DeliveryStatus.packageInRoute:
      case DeliveryStatus.delivered:
        return true;
      default:
        return false;
    }
  }
}