import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import DriveEtaIcon from '@material-ui/icons/DriveEta';

export default class VehicleType {
	static getIcon(id) {
		switch (id) {
			case 2: return MotorcycleIcon;
			case 21: return AirportShuttleIcon;
			default: return DriveEtaIcon;
		}
	}
}