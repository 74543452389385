import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import {
  Container,
  Card,
  Select,
  TextField,
  Grid,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import API from '../api';
import { UserSelector } from './market/product';
import {
  Check as CheckIcon,
} from '@material-ui/icons';
import { UserRoleType } from '../models/user';

export default function ProductsInoutScreen() {
  const [branches, setBranches] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [account, setAccount] = useState(null);
  const [barcode, setBarcode] = useState('');
  const [input, setInput] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [branch, setBranch] = useState(null);
  const barcodeRef = useRef();
  const roles = useMemo(() => {
    return API.instance().getSessionRoles();
  }, []);

  const loadBranches = useCallback(async () => {
    try {
      // const branches = await API.instance().getInputableBranches();
      // setBranches(branches);
    } catch (e) {
      console.error(`[ProductsInoutScreen] ${e}`);
    }
    setIsLoading(false);
  }, []);

  const preload = useCallback(async () => {
    try {
      const branch = await API.instance().getDefaultUserBranch();
      setBranch(branch);
      setAccount(branch.account);
      console.log(branch);
    } catch (e) {
      console.error(`[ProductsInoutScreen.preload] ${e}`);
    }
  }, []);

  const handleSelectUser = useCallback(e => {
    setUser(e);
    setAccount(e.defaultAccount);
  }, []);

  const handleSubmitInput = useCallback(async (e) => {
    if (isSaving) return;

    setIsSaving(true);
    setInput(null);

    e.preventDefault();
    try {
      const inp = await API.instance().confirmProductInput({
        accountId: account.id,
        barcode: barcodeRef.current.value,
        branchId: branch.id,
      });
      console.log(inp);
      setInput(inp);
    } catch (e) {
      alert(`algo salió mal: ${e.message}`);
    }
    setIsSaving(false);
    barcodeRef.current.value = "";
    barcodeRef.current.focus();
  }, [account, barcodeRef, isSaving, barcodeRef]);

  useEffect(() => {
    preload().then(() => {
      loadBranches();
    });
  }, []);

  return (
    <Container>
      <h1>Entradas</h1>
      <Card style={{borderRadius: 16, padding: 16}}>
        <div style={{marginBottom: 8}}>1. Elige el cliente/cuenta a la que se generará ingreso.</div>
        <div>Sucursal</div>
        <Typography variant='h5'>{branch?.name}</Typography>
        {roles.indexOf(UserRoleType.SUPERADMIN) > -1 ? (
          <UserSelector floating
            user={user}
            onChange={handleSelectUser}
            onClear={() => setUser(null)}
          />
        ) : null}
        {isLoading ? <CircularProgress /> : null}
        {account ? (
          <form style={{marginTop: 16}} onSubmit={handleSubmitInput}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <TextField
                  disabled={isSaving}
                  label="Código escaneado"
                  variant="outlined"
                  autoFocus
                  inputRef={barcodeRef} />
              </Grid>
              {isSaving ? (
                <Grid item>
                  <CircularProgress size={24} />
                </Grid>
              ) : null}
            </Grid>
          </form>
        ) : null}
      </Card>
      {input ? (
        <React.Fragment>
          <Grid container alignItems="center" spacing={1}>
            <Grid item style={{marginTop: 16, marginBottom: 16}}>
              <Typography variant="h5">
                Producto ingresado en <span style={{backgroundColor: "#e0e0e0", padding: "2px 8px", borderRadius: 8}}>{input.branch.name}</span>
              </Typography>
            </Grid>
            <Grid item>
              <CheckIcon style={{fontSize: 32}} />
            </Grid>
          </Grid>
          <Card style={{padding: 16, borderRadius: 16}}>
            <Grid container spacing={2}>
              <Grid item>
                <img
                  src={
                    input?.variant?.images[0]?.thumbnailUrl
                      ?? input?.variant?.product?.mainImage?.thumbnailUrl
                      ?? ""
                  }
                  style={{width: 128, height: 128}}
                />
              </Grid>
              <Grid item>
                <Typography variant="h5">{input.variant?.title}</Typography>
                <Typography>Código de barra: {input.variant.barcode}</Typography>
                <Typography>SKU: {input.variant.sku}</Typography>
                <Typography>Entraron <strong>{input.quantity}</strong> producto.</Typography>
                <Typography variant="h6">Disponibilidad en sucursal: <strong>{input.quantityInBranch}</strong></Typography>
              </Grid>
            </Grid>
          </Card>
        </React.Fragment>
      ) : null}
    </Container>
  )
}