import React from 'react';
import Container from '@material-ui/core/Container';
import { withRouter } from "react-router-dom";
import API, { wwwRoot } from '../api';
import DeliveryInfoModal from '../components/delivery-info-modal';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { PackageDataModal } from '../components/packagedata';
import { DeliveryStatus } from '../models/delivery';
import DeliveryRouteMap from '../components/delivery-route-map';
import moment from 'moment-timezone';
import NumberFormat from 'react-number-format';

import {
  List,
  Grid,
  Button,
  IconButton,
  ListItem,
  ListItemText,
  CircularProgress,
  Card,
  CardActions,
  Avatar,
  CardHeader,
  CardContent,
  Chip,
} from '@material-ui/core';

// App components
import DeliveryTile from '../components/delivery-tile';

import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

const api = API.instance();

class RoutesScreen extends React.Component {
  state = {
    items: [],
    isLoading: true,
    expandedDelivery: null,
    showDelivery: false,
    lastDate: null,
    expandedIds: [],
    showPackageMonitor: false,
    packageIdMonitor: null,
    mapRoute: null,
  }

  componentDidMount() {
    this.load();
    this.timeout = null;
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  async load() {
    const { items, mapRoute } = this.state;
    const update = {isLoading: false, lastDate: null};

    try {
      update.items = await api.getRutasEntrega({
        top: 50
      });
      
      update.items.forEach(re => {
        if (mapRoute && re.rutaEntregaId === mapRoute.rutaEntregaId) {
          update.mapRoute = re;
        }
      });

      update.items = update.items.map(r => {
        return {
          ...r,
          viajes: r.viajes.sort((a, b) => {
            if (a.fechaActualizacion !== null && b.fechaActualizacion === null) {
              return -1;
            } else if (a.fechaActualizacion === null && b.fechaActualizacion !== null) {
              return 1;
            } else if (a.fechaActualizacion > b.fechaActualizacion) {
              return -1;
            } else {
              return a.fechaActualizacion < b.fechaActualizacion;
            }
          })
        };
      });

      console.log(update.items);
    } catch (e) {
      console.error(e);
    }

    this.setState(update);

    if (this.timeout === null) {
      this.timeout = setTimeout(this.loadNew.bind(this), 7000);
    }
  }

  async loadNew() {
    await this.load();
    this.timeout = setTimeout(this.loadNew.bind(this), 7000);
  }

  render() {
    const {
      items,
      isLoading,
      expandedIds,
      showDelivery,
      expandedDelivery,
      showPackageMonitor,
      packageIdMonitor,
      chosenPackageId,
      showDataModal,
      mapRoute,
    } = this.state;
    let child; 

    if (isLoading) {
      child = (
        <CircularProgress container={<div style={{padding: 32}} />} />
      );
    } else {
      child = (
        <Grid container spacing={3}>
          {
            items.map((item, index) => {
              const isExpanded = expandedIds.indexOf(item.id) > -1;

              return (
                <Grid item key={index}
                  xs={12}
                  md={isExpanded ? 12 : 6}
                  lg={isExpanded ? 12 : 4}
                >
                  <Card>
                    <CardHeader
                      title={item.chofer ? item.chofer.nombre : ''}
                      subheader={
                        item.chofer ? `ID: ${item.chofer.choferId}` : ''
                      }
                      avatar={
                        <Avatar color="primary" aria-label="recipe"
                          src={`${wwwRoot}/Imagen/users/${item.chofer.idClientes}.jpg`}
                        />
                      }
                      action={
                        <IconButton onClick={() => {
                          if (expandedIds.indexOf(item.id) === -1) {
                            this.setState({
                              expandedIds: [...expandedIds, item.id]
                            });
                          } else {
                            this.setState({
                              expandedIds: expandedIds.filter(i => i !== item.id)
                            });
                          }
                        }}>
                          {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                      }
                    />
                    <CardContent>
                      {isExpanded
                        ? (
                          <List>
                            {item.viajes.map((delivery) =>
                              <ListItem button divider={true} key={delivery.viajeId}
                                onClick={() => {
                                  this.setState({
                                    showDataModal: true,
                                    chosenPackageId: delivery.paquete.paqueteId
                                  });
                                }}
                              >
                                <ListItemText>
                                  <DeliveryTile
                                    delivery={delivery}
                                    current={item.viajeActual
                                      && delivery.viajeId === item.viajeActual.id
                                    } />
                                </ListItemText>
                              </ListItem>
                            )}
                          </List>
                        ) : (
                          item && item.viajeActual
                          ? (
                              <DeliveryTile
                                delivery={item.viajeActual}
                                current={true} />
                          ) : (
                            item.viajes && item.viajes[0]
                            ? (
                              <DeliveryTile delivery={item.viajes[0]} />
                            ) : "No hay viaje actual ni predeterminado."
                          )
                        )
                      }
                    </CardContent>
                    <CardActions style={{borderTop: 'solid 1px #f0f0f0'}}>
                      <Button size="small" color="default"
                        startIcon={<GpsFixedIcon />}
                        variant="outlined"
                        onClick={() => {
                          this.setState({
                            showPackageMonitor: true,
                            mapRoute: item
                          })
                        }}>
                        Mapa
                      </Button>
                      <Button size="small" color="default"
                        variant="outlined"
                        startIcon={<MoreHorizIcon />}
                        onClick={() => {
                          if (expandedIds.indexOf(item.id) === -1) {
                            this.setState({
                              expandedIds: [...expandedIds, item.id]
                            });
                          } else {
                            this.setState({
                              expandedIds: expandedIds.filter(i => i !== item.id)
                            });
                          }
                        }}>
                        {expandedIds.indexOf(item.id) > -1
                          ? "Ver menos"
                          : "Ver más"}
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              )
            })
          }
          <DeliveryInfoModal
            open={showDelivery}
            driver={null}
            onClose={() => this.setState({showDelivery: false})}
            onAssign={(v) => {
              
            }}
          />
          <PackageDataModal
            open={showDataModal}
            packageId={chosenPackageId}
            onClose={() => this.setState({ showDataModal: false })} />
        </Grid>
      );
    }

    return (
      <Container component="main">
        <h1>Rutas Actuales</h1>
        {child}
        {items
          ? <div style={{ paddingTop: 16, color: 'grey', textAlign: 'center' }}>
            {`${items.length} paquetes encontrados`}
          </div> : null}
        <DeliveryRouteMap
          open={showPackageMonitor}
          mapRoute={mapRoute}
          onClose={() => {
            this.setState({ showPackageMonitor: false }, () => {
              setTimeout(() => this.setState({ packageIdMonitor: null }), 1000);
            })
          }}
          packageId={packageIdMonitor}
          viewport={this.props.viewport} />
      </Container>
    );
  }
}

function Badges({
  status,
  current,
  delivery,
  now,
}) {
  return (
    <Grid item xs={12}>
      <div style={{ height: 8 }} />
      <Grid container spacing={1}>
        <Grid item>
          <Chip
            size="small"
            label={status ? status.title : 'Estatus desconocido'}
            style={{
              backgroundColor: current
                ? "black" : (status ? status.color : 'black'),
              color: 'white'
            }}
          />
        </Grid>
        <Grid item>
          <Chip
            size="small"
            label={
              `${Math.round(delivery.distanciaAprox * 10) / 10} km`
            }
          />
        </Grid>
        {
          delivery.fechaActualizacion
            ? <Grid item>
              <Chip
                size="small"
                label={
                  delivery.fechaActualizacion
                    ? `Hace ${moment.duration(
                      now.diff(moment.tz(delivery.fechaActualizacion, 'utc'))
                    ).humanize()
                    }`
                    : null
                }
              />
            </Grid>
            : null
        }
      </Grid>
    </Grid>
  );
}

export default withRouter(RoutesScreen);