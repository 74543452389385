import React from 'react';
import {
  Popover,
  CircularProgress,
  Grid,
  Avatar,
  Typography,
  Button,
} from '@material-ui/core';
import { wwwRoot } from '../api';

const MAPS_URL = 'https://www.google.com/maps/search/';

export default function DriverPopover(props) {
  const { driver } = props;
  const handleOnClose = () => {
    props.onClose && props.onClose();
  };

  return (
    <Popover
      open={props.open}
      anchorEl={props.anchorEl}
      onClose={handleOnClose}
      PaperProps={
        {
          style: {
            padding: 16, boxShadow: '0 3px 6px #ddd',
            width: '40%'
          }
        }
      }
      elevation={3}
    >
      {false
        ? <CircularProgress />
        : (
          driver
          ? (
            <Grid container spacing={3} padding={16} align="center">
              <Grid item xs={12}>
                <Avatar
                  style={{width: 128, height: 128}}
                  src={`${wwwRoot}/Imagen/users/${driver.idClientes}.jpg`} />
              </Grid>
              <Grid item xs={12}>
                {driver.nombre} {driver.apellidoPaterno} {driver.apellidoMaterno}
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <Typography variant="h5" align="center">
                      {driver.choferId}
                    </Typography>
                    <Typography variant="caption" align="center">
                      ID único
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h5" align="center">
                      {driver.numCelular}
                    </Typography>
                    <Typography variant="caption" align="center">
                      Número de celular
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h5" align="center">
                      {driver.licenciaConducir}
                    </Typography>
                    <Typography variant="caption" align="center">
                      Licencia
                    </Typography>
                  </Grid>
                  {driver.vehiculo
                    ? (
                      <React.Fragment>
                        <Grid item xs={4}>
                          <Typography variant="h5" align="center">
                            {driver.vehiculo.placa}
                          </Typography>
                          <Typography variant="caption" align="center">
                            Número de placa
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="h5" align="center">
                            {driver.vehiculo.noPoliza}
                          </Typography>
                          <Typography variant="caption" align="center">
                            Número de póliza
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="h5" align="center">
                            {driver.vehiculo.modelo}
                          </Typography>
                          <Typography variant="caption" align="center">
                            Modelo
                          </Typography>
                        </Grid>
                      </React.Fragment>
                    ) : "No"
                  }
                  <Grid item xs={12}>
                    <Grid container align="center" alignItems="center" justify="center">
                      {
                        driver.ultimaUbicacion
                          ? ( 
                            <Grid item>
                              <Button
                                variant="outlined"
                                color="primary"
                                href={`${MAPS_URL}?api=1&query=${driver.ultimaUbicacion.latitud},${driver.ultimaUbicacion.longitud}`}
                                target="_blank"
                              >
                                Ver en el mapa
                              </Button>
                            </Grid>
                          ) : null
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : null
        )
      }
    </Popover>
  )
}