import React, { useState, useEffect, useCallback } from 'react';
import {
  List,
  ListItemSecondaryAction,
  Button,
  CircularProgress,
  Typography,
  Grid,
  Avatar,
} from '@material-ui/core';
import API from '../api';
import moment from 'moment';
import momentz from 'moment-timezone';
import { PaymentStatus, PaymentType } from '../models/payment';
import NumberFormat from 'react-number-format';

const Money = (props) => {
  return (
    <NumberFormat
      thousandSeparator={true}
      decimalScale={2}
      fixedDecimalScale={2}
      displayType="text"
      prefix="$"
      {...props}
    />
  );
}
const api = API.instance();
const tz = moment.tz.guess();

function DepositsList() {
  const [isLoading, setIsLoading] = useState(true);
  const [savingIds, setSavingIds] = useState([]);
  const [deposits, setDeposits] = useState([]);

  const loadDeposits = useCallback(
    () => {
      api.getPayments({
        status: PaymentStatus.depositRequested,
        type: PaymentType.deposit,
        getAll: true,
        include: ['user']
      }).then((d) => {
        setDeposits(d);
        setIsLoading(false);
      }).catch(e => {
        console.error(e);
      });
    },
    [api]
  );

  const handleConfirm = useCallback(
    (payment, action) => {
      if (savingIds.indexOf(payment.id) > -1) return;

      setSavingIds([...savingIds, payment.id]);

      api.confirmDeposit({
        paymentId: payment.id,
        amount: payment.amount,
        action,
      }).then(r => {
        setSavingIds(savingIds.filter(id => id !== payment.id));
        setDeposits(deposits.filter(d => d.id !== payment.id));
      }).catch(err => {
        console.error(err);
        setSavingIds(savingIds.filter(id => id !== payment.id));
      });
    },
    [api]
  );

  useEffect(() => {
    loadDeposits();
  }, []);

  if (isLoading) {
    return (
      <CircularProgress />
    );
  }

  return (
    <List>
      {deposits.map((d, index) =>
        <Grid container key={index} spacing={3}>
          <Grid item>
            <a href={d.image} target="_blank">
              <img src={d.image} style={{ height: 100 }} />
            </a>
            <div>
              <Button as="a" variant="outlined" href={d.image} target="_blank">
                Ampliar
              </Button>
            </div>
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <Typography variant="h5">
                  <Money value={d.amount} suffix=" MXN" />
                </Typography>
              </Grid>
              <Grid item container spacing={1}>
                <Grid item>
                  <Avatar src={d.user.imageUrl} />
                </Grid>
                <Grid item>
                  {d.user.name}
                  <div>{d.user.phone}</div>
                </Grid>
              </Grid>
              <Grid item>
                {
                  momentz.tz(d.createdAt, 'utc')
                    .tz(tz)
                    .format('LLL')
                }
              </Grid>
            </Grid>
          </Grid>
          <ListItemSecondaryAction>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleConfirm(d, "accept")}
                >
                  {savingIds.indexOf(d.id) > -1 ? <CircularProgress /> : "Aceptar"}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => handleConfirm(d, "reject")}
                >
                  {savingIds.indexOf(d.id) > -1 ? <CircularProgress /> : "Rechazar"}
                </Button>
              </Grid>
            </Grid>
          </ListItemSecondaryAction>
        </Grid>
      )}
    </List>
  );
}

export default DepositsList;