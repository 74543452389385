import React, { useState, useCallback } from 'react';
import NumberFormat from 'react-number-format';
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  Table,
  TableCell,
  TableRow,
  TableBody,
  DialogContent,
  Avatar,
} from '@material-ui/core';

import {
  Edit as EditIcon
} from '@material-ui/icons';

const Money = (props) => {
  return (
    <NumberFormat
      thousandSeparator={true}
      decimalScale={2}
      fixedDecimalScale={2}
      displayType="text"
      prefix="$"
      {...props}
    />
  );
}

export default function UserDialog({
  user,
  open,
  onClose,
  roles,
}) {
  const [isEditing, setIsEditing] = useState(false);

  const handleToggleEdit = useCallback(() => {
    setIsEditing(true);
  }, []);

  return (
    <Dialog open={open} onClose={onClose} size="lg">
      <DialogContent>
        <Table dense="true">
          <TableBody>
            <TableRow>
              <TableCell rowSpan={10} valign={"top"}>
                <Avatar src={user?.imageUrl} style={{width: 128, height: 128}} />
              </TableCell>
              <TableCell>Nombre: </TableCell>
              <TableCell>{user?.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Correo: </TableCell>
              <TableCell>{user?.email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Teléfono: </TableCell>
              <TableCell>{user?.phone}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Fecha de registro: </TableCell>
              <TableCell>
                {user?.createdAt?.format ? user?.createdAt?.format()
                  : (user?.createdAt ?? 'No especificada')
                }
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Fondos: </TableCell>
              <TableCell>
                <Money value={user?.balance?.balance ?? 0} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <div>
          <h4>Roles</h4>
          <div>
            
          </div>
          <List>
            {roles?.map(role =>
              <ListItem container item="true" xs={12} key={role.id} divider={true} dense={true}>
                <ListItemText>
                  {role.name}
                </ListItemText>
                <ListItemSecondaryAction>
                  <Checkbox checked={user?.roles?.filter(r => r.id === role.id).length > 0} />
                </ListItemSecondaryAction>
              </ListItem>
            )}
          </List>
        </div>
      </DialogContent>
      <DialogActions>
        <Button startIcon={<EditIcon />}>Editar</Button>
        <Button onClick={onClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
}