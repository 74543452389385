import React from 'react';
import API, { baseURL } from '../../api';
import { withRouter } from "react-router-dom";
import {
  Grid
} from '@material-ui/core';

const api = API.instance();

class PackagePrintScreen extends React.Component {
  state = {
    model: null
  }

  async load() {
    const id = this.props.match.params.id;
    api.getPaquete(id).then((model) => {
      this.setState({model});
      setTimeout(() => {
        window.print();
      }, 0);
    });
  }

  componentDidMount() {
    this.load();
  }

  render() {
    const { model } = this.state;

    if (model != null) {
      return (
        <div className="Package-Print-Label">
          <div className="Left">
            <img
              src={`${baseURL}/Util/QR/${model.numeroGuia}.png?scale=10`}
            />
            <strong>{model.numeroGuia}</strong><br />
            <img src="/das-big-negro.png" style={{width: 70, marginTop: 32}} />
          </div>
          {/*<div className="Right">
            <div class="Right-Segment">
              <div>Dirección de entrega</div>
              {model.direccionLarga}
            </div>
            <div class="Right-Segment">
              <div>Contactar a</div>
              {model.nombreRecibe}
              <div>Tel. {model.telefonoRecibe}</div>
            </div>
            <div class="Right-Segment">
              <div>Características de paquete</div>
              <div>
                Medidas:
                  {model.largo}m
                  x {model.alto}m
                  x {model.ancho}m
              </div>
              <div>
                Volumen: {model.largo * model.alto * model.ancho}
                  m<sup>3</sup>
              </div>
              <div>Peso: {model.peso} kg</div>
            </div>
          </div>*/}
        </div>
      )
    } else {
      return <div>Cargando etiqueta...</div>;
    }
  }
}

export default withRouter(PackagePrintScreen);