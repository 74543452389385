import React, { useState, useEffect, useRef, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import useSound from 'use-sound';
import momentz from 'moment-timezone';
import {
  Grid,
  Chip,
  List,
  Badge,
  Dialog,
  DialogTitle,
  FormHelperText,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Avatar,
  ListItem,
  Typography,
  Container,
  ListItemText,
  ListItemIcon,
  ListItemAvatar,
  CircularProgress,
  ListItemSecondaryAction,
} from '@material-ui/core';
import { withRouter } from "react-router-dom";
import DeliveryTile from '../components/delivery-tile';
import { PackageDataModal } from '../components/packagedata';
import { useHistory } from "react-router-dom";
import { DeliveryStatus } from '../models/delivery';
import VehicleType from '../models/vehicle-type';
import { PaymentStatus, PaymentType } from '../models/payment';
import API, { wwwRoot } from '../api';
import { parse, encode } from 'querystring';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { green, red, orange } from '@material-ui/core/colors';
import { getDistance } from 'geolib';
import NumberFormat from "react-number-format";
import Btn from '../components/button';
import DeliveryFullTile from '../components/delivery-full-tile';

import { Map, Marker, TileLayer, Popup } from 'react-leaflet';
import L from 'leaflet'

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {
  Phone as PhoneIcon,
  Person as PersonIcon,
  MailOutline as MailOutlineIcon,
} from '@material-ui/icons';

const api = API.instance();
const position = [29.087069, -110.960013];
const tz = momentz.tz.guess();

const messageDeliverConfirmation = (
  `Lo recomendado al confirmar entregas, es que lo haga el chofer ` +
  `la aplicación de chofer, esta confirmación ignorará datos como ` +
  `la firma, la ubicación y los comentarios de entrega.\n\n` +
  `¿Deseas continuar con la confirmación de entrega?`
);

const messageCollectConfirmation = (
  `Lo recomendado al confirmar recolección, es que lo haga el chofer ` +
  `la aplicación de chofer, esta confirmación ignorará datos como ` +
  `la firma, la ubicación y los comentarios de recoleccións .\n\n` +
  `¿Deseas continuar con la confirmación de recolección?`
);

const blueIcon = new L.Icon({
  iconUrl: '/blue-marker-40.png',
  iconRetinaUrl: '/blue-marker-40@2x.png',
  iconAnchor: [24, 48],
  popupAnchor: [0, -44],
  iconSize: [48, 48],
});

const pinkIcon = new L.Icon({
  iconUrl: '/pink-marker-40.png',
  iconRetinaUrl: '/pink-marker-40@2x.png',
  iconAnchor: [24, 48],
  popupAnchor: [0, -44],
  iconSize: [48, 48],
});

const carIcon = new L.Icon({
  iconUrl: '/car-48-outlined.png',
  iconRetinaUrl: '/car-48-outlined@2x.png',
  iconAnchor: [24, 48],
  popupAnchor: [0, -42],
  iconSize: [48, 48],
});

const GreenBadge = withStyles((t) =>
  createStyles({
    badge: {
      backgroundColor: green[500],
      color: "white"
    }
  })
)(Badge);

const RedBadge = withStyles((t) =>
  createStyles({
    badge: {
      backgroundColor: red[500],
      color: "white"
    }
  })
)(Badge);

const StateBadge = (props) => (props.active
  ? <GreenBadge {...props} />
  : <RedBadge {...props} />
);

const Km = (props) => (
  <NumberFormat
    thousandSeparator={true}
    decimalScale={1}
    suffix="km"
    displayType="text"
    {...props}
  />
);

function DashboardScreen(props) {
  const history = useHistory();
  const params = parse(props.location.search.replace('?', ''));
  const [playAlert] = useSound('swiftly.mp3');
  
  const [items, setItems] = useState([]);

  const [deliveries, setDeliveries] = useState([]);
  const [driverDeliveries, setDriverDeliveries] = useState([]);
  const [lastRequestsCounter, setLastRequestsCounter] = useState(0);

  const [isAssigningDriver, setIsAssigningDriver] = useState(false);
  const [isCancellingAssignation, setIsCancellingAssignation] = useState(false);
  const [showDataModal, setShowDataModal] = useState(false);
  const [showRequested, setShowRequested] = useState(false);
  const [requestedDeliveries, setRequestedDeliveries] = useState([]);
  const [showAssign, setShowAssign] = useState(false);
  const [deliveryToAssign, setShowDeliveryToAssign] = useState(null);

  const [selectableDrivers, setSelectableDrivers] = useState([]);
  const [selectableVehicles, setSelectableVehicles] = useState([]);
  const [selectedVehicleId, setSelectedVehicleId] = useState(0);
  const [selectedDriverId, setSelectedDriverId] = useState(null);

  const [confirmingDeliveries, setConfirmingDeliveries] = useState([]);

  const [chosenPackageId, setChosenPackageId] = useState(null);
  const [chosenDriverId, setChosenDriverId] = useState(
    params.driverId ? parseInt(params.driverId) : null
  );
  const [chosenDeliveryId, setChosenDeliveryId] = useState(
    params.deliveryId ? parseInt(params.deliveryId) : null
  );
  const chosenDriverIdRef = useRef(chosenDriverId);
  const theMap = useRef();
  chosenDriverIdRef.current = chosenDriverId;

  const toggleShowRequested = () => {
    const newValue = !showRequested;
    setShowRequested(newValue);

    if (newValue) {
      setDriverDeliveries(requestedDeliveries);
      setChosenDriverId(null);
    } else {
      setDriverDeliveries([]);
    }
  };

  const handleSelectDriver = (r) => {
    const driverId = r.chofer.choferId === chosenDriverId
      ? null : r.chofer.choferId;

    setChosenDriverId(driverId);

    if (r.id === "requested") {
      return;
    }

    history.replace(
      `${props.location.pathname}?${encode({
        ...params,
        driverId: driverId,
        deliveryId: ''
      })
      }`
    );
    
    if (showRequested) {
      setShowRequested(false);
    }
    setChosenDeliveryId(null);
    setDriverDeliveries(
      deliveries.filter(
        i => i.chofer.choferId === driverId
      )
    );
  }
  
  const handleConfirmAssignation = async () => {
    if (isAssigningDriver) {
      return;
    }

    setIsAssigningDriver(true);

    try {
      const deliveryId = chosenDeliveryId;
      await api.assignDelivery({
        deliveryId: deliveryId,
        driverId: selectedDriverId,
        vehicleId: selectedVehicleId
      });
      setIsAssigningDriver(false);
      setShowAssign(false)

      setRequestedDeliveries(
        requestedDeliveries.filter(
          d => d.id !== deliveryId
        )
      );
    } catch (e) {
      console.log(`[handleConfirmAssignation] error: ${e}`);
      alert(e.message);
      setIsAssigningDriver(false);
    }
  };

  const handleCancelAssignation = useCallback(async (deliveryId) => {
    if (isCancellingAssignation) {
      return;
    }

    setIsCancellingAssignation(true);

    try {
      const response = await api.cancelDeliveryAsAdmin(deliveryId);

      setIsCancellingAssignation(false);
      setShowAssign(false);

      setRequestedDeliveries(
        requestedDeliveries.filter(
          d => d.id !== deliveryId
        )
      );
    } catch (e) {
      console.log(`[handleCancelAssignation] error: ${e}`);
      alert(e.message);
      setIsCancellingAssignation(false);
    }
  }, [isCancellingAssignation, requestedDeliveries]);

  const loadRequests = useCallback(async () => {
    let deliveries = null;

    try {
      const response = await api.getDeliveries({
        status: DeliveryStatus.requested,
        includePackage: true,
        includeUser: true,
        includeZones: true,
        includeVehicleType: true,
      });

      if (response.isSuccess) {
        deliveries = response.result;
      }
    } catch (e) {
      console.log(`[loadRequests] Error: ${e.message}`);
    }

    if (deliveries) {
      setRequestedDeliveries(deliveries);
    }
  }, []);

  const load = async () => {
    loadRequests();

    if (showRequested) {
      return;
    }

    try {
      const chosenDriverId = chosenDriverIdRef.current;
      let deliv = [];
      let items = await api.getRutasEntrega({
        status: 1,
        deliveryStatus: [
          DeliveryStatus.mustBePickedUp,
          DeliveryStatus.delivering,
        ]
      });

      items.forEach(e => {
        e.viajes.forEach(v => {
          deliv.push({
            ...v,
            chofer: e.chofer,
            driver: e.chofer,
            timestamp: momentz.tz(v.fechaHora, 'utc').tz(tz),
            collectedAt: v.fechaRecoleccion
              ? momentz.tz(v.fechaRecoleccion, 'utc').tz(tz)
              : null,
          });
        })
      });

      items = items.map(r => {
        return {
          ...r,
          viajes: r.viajes
            .map(v => ({
              ...v,
              driver: r.chofer,
              timestamp: momentz.tz(v.fechaHora, 'utc').tz(tz),
              /*collectedAt: v.fechaRecoleccion
                ? momentz.tz(v.fechaRecoleccion, 'utc').tz(tz)
                : null,*/
            }))
            .sort((a, b) => {
              if (a.fechaActualizacion !== null && b.fechaActualizacion === null) {
                return -1;
              } else if (a.fechaActualizacion === null && b.fechaActualizacion !== null) {
                return 1;
              } else if (a.fechaActualizacion > b.fechaActualizacion) {
                return -1;
              } else {
                return a.fechaActualizacion < b.fechaActualizacion;
              }
            })
        };
      });

      deliv = deliv.filter(
        d => d.estatusEntregaId !== DeliveryStatus.delivered
      );

      setItems(items);
      setDeliveries(deliv);
      
      if (chosenDriverId) {
        setDriverDeliveries(
          deliv.filter(i => i.chofer.choferId === chosenDriverId)
        );
      }
    } catch (e) {
      console.error(e);
    }
  }

  const estimateDriverDistances = async ({
    drivers,
    delivery,
  }) => {
    drivers.forEach(d => {
      if (d.state && d.state.lastPosition) {
        d.distance = getDistance(
          {
            latitude: delivery.origenLatitud,
            longitude: delivery.origenLongitud
          },
          {
            latitude: d.state.lastPosition.latitude,
            longitude: d.state.lastPosition.longitude
          }
        );
      }
    });
    
    setSelectableDrivers(
      [...drivers.sort((a, b) => a.distance - b.distance)]
    );
  }

  const handleDriverDelivery = (deliveryId) => {
    const id = chosenDeliveryId === deliveryId ? null : deliveryId;
    history.replace(
      `${props.location.pathname}?${encode({
        ...params,
        deliveryId: id,
      })}`
    );
    setChosenDeliveryId(id);
  };

  useEffect(() => {
    let st = {timeout: null};
    
    async function refresh() {
      await load();
      st.timeout = setTimeout(refresh, 7000);
    }

    function stopRefreshing() {
      if (st.timeout) {
        clearTimeout(st.timeout);
        st.timeout = null;
      }
    }

    refresh();

    return stopRefreshing;
  }, []);

  const handleConfirmDelivery = useCallback(async (delivery) => {
    if (window.confirm(messageDeliverConfirmation)) {
      if (confirmingDeliveries.indexOf(delivery.id || delivery.viajeId) > -1) {
        return;
      }

      setConfirmingDeliveries([
        ...confirmingDeliveries,
        delivery.id || delivery.viajeId
      ]);

      try {
        await api.deliverDelivery(
          delivery.id || delivery.viajeId, {
            asRole: "admin",
            entregaExitosa: 1
          }
        );
      } catch (e) {
        console.log(`[handleConfirmDelivery] error: ${e}`);
      }

      setConfirmingDeliveries(
        confirmingDeliveries.filter(
          did => did !== (delivery.id || delivery.viajeId)
        )
      );
    }
  }, []);

  const handleConfirmCollect = useCallback(async (delivery) => {
    if (window.confirm(messageCollectConfirmation)) {
      if (confirmingDeliveries.indexOf(delivery.id || delivery.viajeId) > -1) {
        return;
      }

      setConfirmingDeliveries([
        ...confirmingDeliveries,
        delivery.id || delivery.viajeId
      ]);

      try {
        await api.collectDelivery(
          delivery.id || delivery.viajeId, {
            asRole: "admin",
            success: 1
          }
        );
      } catch (e) {
        console.log(`[handleCollectDelivery] error: ${e}`);
      }

      setConfirmingDeliveries(
        confirmingDeliveries.filter(
          did => did !== (delivery.id || delivery.viajeId)
        )
      );
    }
  }, []);

  useEffect(() => {
    if (requestedDeliveries.length > lastRequestsCounter) {
      if (Notification.permission === "granted") {
        const notif = new Notification(
          "Hay nuevas peticiones de entrega"
        );
        playAlert();
      }
      setLastRequestsCounter(requestedDeliveries.length);
    } else if (lastRequestsCounter > requestedDeliveries) {
      setLastRequestsCounter(0);
    }
  }, [requestedDeliveries, playAlert]);

  const mapProps = {
    center: position,
    zoom: 13,
    viewport: props.viewport,
    style: {
      height: '100vh',
      position: 'fixed',
      right: 0,
      top: 64,
      bottom: 0,
      left: 500 + (chosenDriverId || showRequested ? 250 : 0)
    }
  };

  return (
    <React.Fragment>
      <Container component="main" disableGutters={true}>
        <Map {...mapProps} ref={theMap}>
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          {(chosenDriverId ? driverDeliveries : deliveries).map((item, index) => {
            if (chosenDeliveryId && chosenDeliveryId !== item.viajeId) {
              return null;
            }

            return (
              <React.Fragment key={index}>
                {item.origenLatitud
                  ? <Marker
                      position={[item.origenLatitud, item.origenLongitud]}
                      icon={pinkIcon}
                    >
                      <SpotPopup
                        delivery={item}
                        onDeliver={handleConfirmDelivery}
                        onCollect={handleConfirmCollect}
                        confirmingDeliveries={confirmingDeliveries}
                        onChange={() => {
                          setChosenPackageId(item.paquete.paqueteId);
                          setShowDataModal(true);
                        }}
                      />
                    </Marker>
                  : null
                }
                {item.destinoLatitud
                  ? <Marker
                    position={[item.destinoLatitud, item.destinoLongitud]}
                    icon={blueIcon}
                  >
                    <SpotPopup
                      delivery={item}
                      onDeliver={handleConfirmDelivery}
                      onCollect={handleConfirmCollect}
                      confirmingDeliveries={confirmingDeliveries}
                      onChange={() => {
                        setChosenPackageId(item.paquete.paqueteId);
                        setShowDataModal(true);
                      }}
                    />
                  </Marker>
                  : null
                }
              </React.Fragment>
            );
          })}
          {items.map((item, index) => {
            if (item.chofer.lastPosition) {
              return (
                <Marker
                  position={[
                    item.chofer.lastPosition.latitude,
                    item.chofer.lastPosition.longitude
                  ]}
                  key={index}
                  zIndexOffset={100 + index}
                  icon={carIcon}
                >
                  <Popup minWidth={300}>
                    <Grid container spacing={2} style={{marginBottom: 8}}>
                      <Grid item>
                        <Avatar
                          src={`${wwwRoot}/Imagen/users/${item.chofer.idClientes}.jpg`}
                        />
                      </Grid>
                      <Grid item>
                        {item.chofer.nombre}
                        <br/>
                        ID: {item.chofer.id}
                      </Grid>
                      <Grid item xs={12}>
                        {item.viajeActual
                          ? (
                            <DeliveryTile
                              delivery={item.viajeActual}
                              current={true}
                            />
                          ) : null
                        }
                      </Grid>
                      <Grid item xs={12}>
                        <Button variant="outlined"
                          startIcon={<MoreHorizIcon />}
                          onClick={() => {
                            setChosenPackageId(item.viajeActual.paquete.paqueteId);
                            setShowDataModal(true);
                          }}
                        >Detale de envío</Button>
                      </Grid>
                    </Grid>
                    
                  </Popup>
                </Marker>
              );
            } else {
              return null
            }
          })}        
        </Map>
        <div
          style={{
            backgroundColor: "white",
            position: "fixed",
            padding: 8,
            left: 250,
            bottom: 0,
            top: 64,
            width: chosenDriverId || showRequested ? 500 : 250,
            borderRight: 'solid 1px #e0e0e0'
          }}
        >
          <Grid container spacing={2} style={{height: '100%'}}>
            <Grid item xs={chosenDriverId || showRequested ? 6 : 12} style={{padding: 0}}>
              <List style={{padding: 0}}>
                {
                  requestedDeliveries && requestedDeliveries.length > 0 ? (
                    <ListItem button
                      selected={showRequested}
                      onClick={() => toggleShowRequested()}
                      dense={true}
                      divider={true}
                      style={{backgroundColor: red[400]}}
                    >
                      <ListItemAvatar>
                        <Avatar
                          style={{backgroundColor: "white", color: red[400]}}>
                          {requestedDeliveries.length}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        secondary={
                          <span style={{color: "white"}}>
                            Pendientes de aceptar
                          </span>
                        }
                        style={{color: "white"}}
                      >
                        <strong style={{color: "white"}}>Peticiones</strong>
                      </ListItemText>
                    </ListItem>
                  ) : null
                }

                {items && items.length > 0 ? items.map((r) => {
                  return (
                    <ListItem button
                      selected={chosenDriverId === r.chofer.choferId && !showRequested}
                      onClick={() => handleSelectDriver(r)}
                      key={r.id}
                      dense={true}
                      divider={true}
                    >
                      <ListItemIcon>
                        <StateBadge
                          active="true"
                          badgeContent=""
                        >
                          <Avatar
                            src={
                              `${wwwRoot}/Imagen/users/${r.chofer.idClientes}.jpg`
                            }
                          />
                        </StateBadge>
                      </ListItemIcon>
                      <ListItemText
                        primary={r.chofer.nombre}
                        secondary={`ID: ${r.chofer.id}`}
                      />
                    </ListItem>
                  );
                }) : (
                  <Grid container alignItems="center">
                    <Grid item>
                      <CircularProgress />
                    </Grid>
                  </Grid>
                )}
              </List>
            </Grid>

            {
              showRequested
                ? (
                  <Grid item xs={6}
                    style={{
                      borderLeft: 'solid 1px #dedede',
                      height: '100%',
                      overflowY: 'auto',
                      padding: 0
                    }}
                  >
                    <List style={{ height: '100%', padding: 0 }}>
                      {requestedDeliveries && requestedDeliveries.length ? requestedDeliveries.map((item) => {
                        return (
                          <ListItem button
                            style={{ borderBottom: 'solid 1px #e0e0e0' }}
                            key={item.viajeId}
                            onClick={() => {
                              setChosenDeliveryId(item.viajeId);
                              setShowAssign(true);
                              setShowDeliveryToAssign(item);

                              api.getVehicles().then((vehicles) => {
                                setSelectableVehicles(vehicles);

                                api.getDrivers({
                                  activeState: 1,
                                  vehicleTypeId: item.vehicleTypeId,
                                  include: ['vehicle']
                                }).then(r => {
                                  let selectVehicleId = 0;
                                  let selectDriverId = 0;

                                  if (r.result && r.result.length > 0) {
                                    const first = r.result[0];

                                    selectDriverId = first.id;

                                    // Case A. The driver has an assigned vehicle and
                                    // the vehicle assigned is actually non-deleted.
                                    if (first?.vehicle?.id
                                      && vehicles.find(v => v.vehiculoId === first.vehicle.id)
                                    ) {
                                      selectVehicleId = first.vehicle.id;
                                    }

                                    // Case B. The driver doesn't have an assigned vehicle
                                    // or the vehicle assigned is deleted. We choose the first
                                    // element from the vehicles list.
                                    else {
                                      selectVehicleId = vehicles[0].vehiculoId;
                                    }

                                    setSelectedDriverId(selectDriverId);
                                    setSelectedVehicleId(selectVehicleId);
                                    setSelectableDrivers(r.result);
                                    
                                    estimateDriverDistances({
                                      drivers: r.result,
                                      delivery: item
                                    });
                                  }
                                });
                              });
                            }}
                            selected={item.viajeId === chosenDeliveryId}
                          >
                            <DeliveryTile delivery={item} key={item.viajeId} />
                          </ListItem>
                        )
                      }) : (
                          "No hay peticiones pendientes."
                        )}
                    </List>
                  </Grid>
                ) : null
            }

            {
              (chosenDriverId && !showRequested)
                ? (
                  <Grid item xs={6}
                    style={{
                      borderLeft: 'solid 1px #dedede',
                      height: '100%',
                      overflowY: 'auto',
                      padding: 0
                    }}
                  >
                    <List style={{height: '100%', padding: 0}}>
                      {driverDeliveries && driverDeliveries.length ? driverDeliveries.map((item) => {
                        return (
                          <ListItem button
                            style={{borderBottom: 'solid 1px #e0e0e0'}}
                            key={item.viajeId}
                            value={item.viajeId}
                            onClick={() => handleDriverDelivery(item.viajeId)}
                            selected={item.viajeId === chosenDeliveryId}
                          >
                            <DeliveryTile delivery={item} key={item.viajeId} />
                          </ListItem>
                        )
                      }) : (
                        "No hay nada aquí."
                      )}
                    </List>
                  </Grid>
                ) : null
            }
          </Grid>
        </div>
        {/*<div
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            position: "fixed",
            right: 0,
            padding: 8,
            left: 250
          }}
        >
          <Grid container spacing={1} alignItems="flex-end">
            <Grid item>
              <Button variant="contained"
                startIcon={<RoomIcon />}
                color="primary"
                onClick={() => history.push('/route/add')}>
                Crear ruta
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="secondary"
                startIcon={<AddIcon />}
                onClick={() => history.push('/collect/add')}
              >
                Recolección
              </Button>
            </Grid>
          </Grid>
        </div>*/}
        <PackageDataModal
          open={showDataModal}
          packageId={chosenPackageId}
          onClose={() => setShowDataModal(false) } />
      </Container>

      <Dialog scroll="body" open={showAssign} onClose={() => setShowAssign(false)}>
        <DialogTitle>
          Asigna entrega a chofer
        </DialogTitle>
        <DialogContent dividers={true}>
          <Grid container spacing={2}>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Typography color="textSecondary">Información de Entrega</Typography>
                  <DeliveryTile delivery={deliveryToAssign} />
                </Grid>
                <Grid item xs={6}>
                  <Grid container>
                    <Grid item>
                      <Typography color="textSecondary">Información de Cliente</Typography>
                    </Grid>

                    <Grid item>
                      <Grid container spacing={1}>
                        <Grid item>
                          <PersonIcon style={{ fontSize: 16 }} />
                        </Grid>
                        <Grid item>
                          {deliveryToAssign?.user?.name ?? ""} {deliveryToAssign?.user?.lastName ?? ""}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid container spacing={1}>
                        <Grid item>
                          <MailOutlineIcon style={{ fontSize: 16 }} />
                        </Grid>
                        <Grid item>
                          {deliveryToAssign?.user?.email ?? ""}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item>
                          <PhoneIcon style={{fontSize: 16}} />
                        </Grid>
                        <Grid item>
                          {deliveryToAssign?.user?.phone ?? ""}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth={true}>
                <InputLabel htmlFor="driver-select">
                  Choferes activos
                </InputLabel>
                <Select
                  value={selectedDriverId || 0}
                  labelId="driver-select"
                  label={
                    `Choferes activos en ${deliveryToAssign?.vehicleType?.name ?? "cualquier vehículo"}`
                  }
                  onChange={e => {
                    setSelectedDriverId(e.target.value);
                  }}
                >
                  <MenuItem value={0}>
                    - Elige un chofer en {deliveryToAssign?.vehicleType?.name ?? "cualquier vehículo"}-
                  </MenuItem>
                  {selectableDrivers.map(driver => (
                    <MenuItem value={driver.id} key={driver.id}>
                      <ListItemText>
                        {driver.name}
                      </ListItemText>
                      <ListItemSecondaryAction>
                        {
                          driver.distance
                            ? (
                              <Chip
                                size="small"
                                label={
                                  <Km value={driver.distance / 1000} prefix={"A "} />
                                }
                                style={{
                                  color: "white",
                                  marginRight: 16,
                                  fontWeight: "bold",
                                  backgroundColor: driver.distance < 4000
                                    ? green[400]
                                    : (driver.distance < 10000
                                      ? orange[400]
                                      : red[400]
                                    )
                                }}
                              />
                            ) : null
                        }
                      </ListItemSecondaryAction>
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>Distancia entre el chofer y el punto de recolección.</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth={true}>
                <InputLabel htmlFor="driver-select">
                  Vehículo con que se hará el servicio
                </InputLabel>
                <Select
                  value={selectedVehicleId || 0}
                  labelId="driver-select"
                  label="Vehículo con que se hará el servicio"
                  onChange={e => setSelectedVehicleId(e.target.value)}
                >
                  <MenuItem value={0}>
                    - Elige un vehículo -
                  </MenuItem>
                  {selectableVehicles.map(v => {
                    const VehicleIcon = VehicleType.getIcon(v.tipoVehiculoId);
                    return (
                      <MenuItem value={v.vehiculoId} key={v.vehiculoId}>
                        <ListItemIcon>
                          <VehicleIcon />
                        </ListItemIcon>
                        <ListItemText>
                          [{v.placa}] {v.model ?? v.modelo ?? ""}
                        </ListItemText>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <Btn color="success"
                isLoading={isAssigningDriver}
                onClick={handleConfirmAssignation}
                disabled={isAssigningDriver}
                startIcon={<CheckIcon />}
              >
                {isAssigningDriver ? "Asignando" : "Asignar"}
              </Btn>
            </Grid>
            <Grid item>
              <Btn color="error"
                isLoading={isCancellingAssignation}
                onClick={() => handleCancelAssignation(chosenDeliveryId)}
                disabled={isCancellingAssignation}
                startIcon={<DeleteForeverIcon />}
              >
                {isAssigningDriver ? "Cancelando" : "Cancelar"}
              </Btn>
            </Grid>
            <Grid item>
              <Button color="default"
                onClick={() => setShowAssign(false)}
                startIcon={<CloseIcon />}
                variant="contained"
              >Cerrar</Button>
            </Grid>
          </Grid>
          
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );  
}

function SpotPopup(props) {
  const {
    onChange,
    onDeliver,
    onCollect,
    confirmingDeliveries,
  } = props;
  const d = props.delivery;
  const driver = props.driver || d.chofer || {};

  return (
    <Popup minWidth={400}>
      <Grid container spacing={2}>
        <Grid item>
          <DeliveryFullTile
            delivery={d}
            showBadges={true}
            showDriver={true}
            showClient={true}
            showPaymentInfo={true}
          />
        </Grid>
        <Grid item>
          <Button variant="outlined"
            startIcon={<MoreHorizIcon />}
            onClick={() => onChange()}
          >Detalle de envío</Button>
        </Grid>
        {
          (d.status ?? d.estatusEntregaId) === DeliveryStatus.delivering ? (
            <Grid item>
              <Button variant="outlined"
                startIcon={<MoreHorizIcon />}
                onClick={() => onDeliver(d)}
              >
                {confirmingDeliveries.indexOf(d.id || d.viajeId) > -1 ? (
                  <CircularProgress />
                ) : (
                  "Confirmar entrega"
                )}
              </Button>
            </Grid>
          ) : null
        }
        {
          (d.status ?? d.estatusEntregaId) === DeliveryStatus.mustBePickedUp ? (
            <Grid item>
              <Button variant="outlined"
                startIcon={<MoreHorizIcon />}
                onClick={() => onCollect(d)}
              >
                {confirmingDeliveries.indexOf(d.id || d.viajeId) > -1 ? (
                  <CircularProgress />
                ) : (
                  "Confirmar recolección"
                )}
              </Button>
            </Grid>
          ) : null
        }
      </Grid>
    </Popup> 
  );
}

export default withRouter(DashboardScreen);