import React, {useState, useCallback, useRef} from 'react';
import {
  Grid,
  Button,
  CircularProgress
} from '@material-ui/core';
import {
  makeStyles
} from '@material-ui/core/styles';
import API from '../api';
import {
  Publish as PublishIcon,
} from '@material-ui/icons';

const api = new API();

const defaultImageToUpload = () => ({
  image: null,
  isUploading: false,
  hash: Math.random().toString(36).substring(2, 10)
});

export default function ImagesUploader({
  images,
  onSelectImage,
  onDeleteImage,
  productId,
  imageSize,
  parentId,
  uploadFunction,
  deleteFunction,
}) {
  const classes = useStyles();
  const [imagesToUpload, setImagesToUpload] = useState(
    [defaultImageToUpload()]
  );

  const handleFileChange = useCallback(async (e) => {
    const hash = e.target.getAttribute('datahash');

    if (e.target.files.length === 0) {
      return;
    }

    setImagesToUpload([
      ...imagesToUpload.map(i => i.hash !== hash ? i : (
        { ...i, isUploading: true }
      )),
      defaultImageToUpload(),
    ]);

    try {
      let image;
      const theFile = e.currentTarget.files[0];
      if (uploadFunction && parentId) {
        image = await uploadFunction({parentId, file: theFile});
      } else {
        image = await api.putProductImage({productId, file: theFile});
      }
      setImagesToUpload(imagesToUpload.filter(i => i.hash !== hash));
      if ('function' === typeof onSelectImage) {
        onSelectImage(image);
      }
    } catch (e) {
      alert("Error: " + e.toString());
    }
  }, [imagesToUpload, onSelectImage, productId, uploadFunction, parentId]);

  const handleDeleteFile = useCallback(async (e) => {
    if (window.confirm('¿Eliminar esta imagen?')) {
      const id = parseInt(e.target.getAttribute('dataimageid'));
      try {
        let r;
        if (deleteFunction) {
          r = await deleteFunction(id);
        } else {
          r = await api.deleteProductImage(id);
        }
        
        if ('function' === typeof onDeleteImage) {
          onDeleteImage(id);
        }
      } catch (e) {
        window.alert(e.toString());
      }
    }
  }, [onDeleteImage]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} container spacing={1}>
        {images?.map((i, index) =>
          <Grid item key={index}>
            <button
              onClick={handleDeleteFile}
              style={{position: 'absolute'}}
              dataimageid={i.id}
            >X</button>
            <img src={i?.thumbnailUrl} alt="product thumbnail"
              className={classes.thumbnail} />
          </Grid>
        )}
        <Grid item>
          {imagesToUpload.map((i, index) =>
            <UploadGridItem i={i} key={index} onFileChange={handleFileChange} />
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

function UploadGridItem(props) {
  const {i, onFileChange} = props;
  const fileRef = useRef();

  const handleFileSelect = useCallback(e => {
    fileRef.current.click();
  }, [fileRef]);

  return (
    <Grid item xs={12}>
      {i.isUploading ? (
        <Grid container spacing={1}>
          <Grid item><CircularProgress size={16} /></Grid>
          <Grid item>Subiendo...</Grid>
        </Grid>
      ) : (
        <Grid item>
          <input type="file"
            onChange={onFileChange}
            style={{display: "none"}}
            ref={fileRef}
            datahash={i.hash} />
          <Button style={{width: 128, height: 128}} startIcon={<PublishIcon />} variant="outlined" onClick={handleFileSelect}>
            Subir
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

const useStyles = makeStyles({
  thumbnail: {
    width: 128,
    height: 128,
  }
});