import React, {
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react';
import {
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Card,
  FormControl,
  OutlinedInput,
  InputAdornment,
  CircularProgress,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  ListItemSecondaryAction,
  TableFooter,
  Menu,
  MenuItem,
  TextField,
  DialogTitle,
  Select,
} from '@material-ui/core';
import API from '../api';
import { useHistory } from "react-router-dom";
import { parse, encode } from 'querystring';
import NumberFormat from 'react-number-format';
import PlusChip from '../components/pluschip';
import {
  Search as SearchIcon,
  MoreHoriz as MoreHorizIcon,
  ArrowDropDown as ArrowDropDownIcon,
  PersonAdd as PersonAddIcon,
} from '@material-ui/icons';
import { PaymentType } from '../models/payment';
import BaseButton from '../components/button';

const Money = (props) => {
  return (
    <NumberFormat
      thousandSeparator={true}
      decimalScale={2}
      fixedDecimalScale={2}
      displayType="text"
      prefix="$"
      {...props}
    />
  );
}

const resultsPerPage = 50;
const api = API.instance();
const defaultOptions = {
  include: ['balance'],
  roles: ['client'],
  resultsPerPage,
};

const initialState = {
  users: [],
  isSearching: false,
  search: null,
  searchText: '',
  showDetail: false,
  user: null,
  page: 1,
  lastOptions: null,
  endOfPages: false,
  openMainMenu: false,
  mainMenuAnchor: null,
  openNew: false
};

function reducer(state, action) {
  switch (action.type) {
    case 'inputSearch':
      return { ...state, searchText: action.text };
    case 'startSearching':
      return {
        ...state,
        isSearching: true,
        search: state.searchText,
      };
    case 'replaceUsers':
      return {
        ...state,
        users: action.users,
        isLoading: false,
        page: 1,
        isSearching: false,
        endOfPages: false
      };
    case 'appendUsers':
      return {
        ...state,
        users: [ ...(state.users), ...(action.users) ],
        isLoading: false,
        page: state.page + 1,
        endOfPages: action?.users?.length === 0
      };
    case 'closeDetail':
      return { ...state, showDetail: false };
    case 'openDetail':
      return { ...state, showDetail: true };
    case 'loadUserDetail':
      return { ...state, user: action.user };
    case 'openMenu':
      return { ...state, openMainMenu: true, mainMenuAnchor: action.anchor };
    case 'closeMenu':
      return { ...state, openMainMenu: false };
    case 'openNew':
      return { ...state, openNew: true, openMainMenu: false };
    case 'closeNew':
      return { ...state, openNew: false };
    default:
      return state;
  }
}

function ClientsScreen({
  location,
}) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();
  const params = parse(location.search.replace('?', ''));

  const getUsers = (op) => {
    const nextPage = op?.next === true;
    let page = nextPage ? (state.page + 1) : 1;
    const options = {
      search: state.search,
      page,
      include: ['balance'],
      roles: ['client'],
      resultsPerPage,
    };

    console.log(`page: ${page}`);

    api.getUsers(options).then((u) => {
      if (nextPage) {
        dispatch({
          type: 'appendUsers',
          users: u,
          page,
        });
      } else {
        dispatch({ type: 'replaceUsers', users: u });
      }
    }).catch((e) => {
      console.error(e);
    });
  };

  const onSubmit = useCallback((e) => {
    dispatch({ type: 'startSearching' });
    e.preventDefault();
    history.replace(`${location.pathname}?${
      encode({...params, search: state.searchText})
    }`);
    // getUsers();
  }, []);

  const handleLoadMore = useCallback(() => {
    if (state.isLoading) {
      return;
    }
    getUsers({ next: true });
  }, [state]);

  const handleOpenMainMenu = useCallback(e => {
    dispatch({type: 'openMenu', anchor: e.currentTarget});
  }, []);

  const handleCloseMainMenu = useCallback(e => {
    dispatch({type: 'closeMenu'});
  }, []);

  const handleOpenNew = useCallback(e => {
    dispatch({type: 'openNew'});
  }, []);

  const handleCloseNew = useCallback(e => {
    dispatch({type: 'closeNew'});
  }, []);

  const openDetail = useCallback((user) => {
    dispatch({ type: 'openDetail' });

    api.getUser(user.id, {include: ['balance', 'lastDeposits']})
      .then((u) => {
        dispatch({ type: 'loadUserDetail', user: u });
      }).catch(e => {
        console.log(`[openDetail] error: ${e}`);
      })
  }, []);

  useEffect(() => getUsers(), []);
  useEffect(() => getUsers(), [state.search])

  const {
    user,
    users,
    isLoading,
    showDetail,
    isSearching,
    endOfPages,
  } = state;

  return (
    <Container>
      <Grid container alignItems="center">
        <Grid item style={{flexGrow: 1}}>
          <h1>Clientes</h1>
        </Grid>
        <Grid item>
          <Button variant="contained"
            onClick={handleOpenNew}
            endIcon={<PersonAddIcon />  }>Crear cliente</Button>
        </Grid>
        <Menu open={state.openMainMenu} anchorEl={state.mainMenuAnchor} onClose={handleCloseMainMenu}>
          <MenuItem dense onClick={handleOpenNew}>
            <ListItemIcon><PersonAddIcon /></ListItemIcon>
            <ListItemText>Cliente nuevo</ListItemText>
          </MenuItem>
        </Menu>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <form onSubmit={onSubmit}>
            <FormControl
              variant="outlined"
              label="Asignar a zona"
              fullWidth={true}
            >
              <OutlinedInput
                style={{ backgroundColor: 'white' }}
                placeholder="Escribe un criterio de búsqueda"
                variant="outlined"
                startAdornment={<InputAdornment><SearchIcon /></InputAdornment>}
                value={state.searchText ?? ''}
                onChange={(e) => dispatch({
                  type: 'inputSearch',
                  text: e.target.value
                })}
                endAdornment={
                  <InputAdornment>
                    <Button
                      variant={isSearching ? "outlined" : "contained"}
                      color="primary"
                      disableElevation
                    >
                      {isSearching ? (
                        <CircularProgress size={24} />
                      ) : "Buscar"}
                    </Button>
                  </InputAdornment>
                }
              />
            </FormControl>
          </form>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell><strong>Nombre</strong></TableCell>
                  <TableCell><strong>Fondos</strong></TableCell>
                  <TableCell><strong>ID</strong></TableCell>
                  <TableCell><strong>Contacto</strong></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users?.map((u, idx) =>
                  <TableRow key={idx}>
                    <TableCell width={40}><Avatar src={u.imageUrl} /></TableCell>
                    <TableCell>
                      {u.fullName}
                      <div>{u.isPlus ? <PlusChip /> : null}</div>
                    </TableCell>
                    <TableCell>
                      <Money value={u?.balance?.balance ?? 0} />
                    </TableCell>
                    <TableCell>
                      {u.id}
                    </TableCell>
                    <TableCell>
                      <div>{u.email}</div>
                      <div>{u.phone}</div>
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => openDetail(u)}>
                        <MoreHorizIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={6}>
                    <Button variant="outlined" color="primary"
                      disabled={endOfPages ? true : false}
                      onClick={!endOfPages ? handleLoadMore : null}
                    >
                      {isLoading
                        ? <CircularProgress size={24} />
                        : (
                          endOfPages
                            ? "No hay más clientes"
                            : `Cargar ${resultsPerPage} más`
                        )}
                    </Button>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </Card>
        </Grid>
      </Grid>
      <Dialog open={showDetail} onClose={() => dispatch({ type: 'closeDetail' })} size="lg">
        <DialogContent>
          <Table dense="true">
            <TableBody>
              <TableRow>
                <TableCell rowSpan={10} valign={"top"}>
                  <Avatar src={user?.imageUrl} style={{width: 128, height: 128}} />
                </TableCell>
                <TableCell>Nombre: </TableCell>
                <TableCell>{user?.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Correo: </TableCell>
                <TableCell>{user?.email}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Teléfono: </TableCell>
                <TableCell>{user?.phone}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Fecha de registro: </TableCell>
                <TableCell>
                  {user?.createdAt?.format ? user?.createdAt?.format()
                    : (user?.createdAt ?? 'No especificada')
                  }
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Fondos: </TableCell>
                <TableCell>
                  <Money value={user?.balance?.balance ?? 0} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <div>
            <h4>Últimos depósitos</h4>
            <List>
              {user?.deposits?.map(d =>
                <ListItem container item="true" xs={12} key={d.id} divider={true} dense={true}>
                  <ListItemText secondary={PaymentType.getTitle(d.status)}>
                    {d.createdAt.format('LLL')}
                  </ListItemText>
                  <ListItemSecondaryAction>
                    <Money value={d.amount} />
                  </ListItemSecondaryAction>
                </ListItem>
              )}
            </List>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            //onClick={() => setShowDetail(false)}
            onClick={() => dispatch({ type: 'closeDetail' })}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      <ClientDialog open={state.openNew} onClose={handleCloseNew} />
    </Container>
  );
}

function ClientDialog({
  open,
  onClose,
  title = "Crear cliente nuevo",
}) {
  const [fields, setFields] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    repeatPassword: ""
  });

  const updateField = useCallback(e => {
    const name = e.currentTarget.getAttribute('name');
    const value = e.currentTarget.value;
    setFields({...fields, [name]: value});
  }, [fields, setFields]);

  const handleSave = useCallback(async (e) => {
    e.preventDefault();
    try {
      const client = await API.instance().createClient({...fields});
      alert("Cliente creado correctamente.");
      if (onClose) onClose();
    } catch (e) {
      alert(e.toString());
    }
  }, [fields]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSave}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field label="Nombre completo" autoFocus
                name="name" onBlur={updateField} />
            </Grid>
            <Grid item xs={6}>
              <Field label="Correo electrónico"
                name="email" onBlur={updateField} />
            </Grid>
            <Grid item xs={6}>
              <Field label="Teléfono"
                name="phone" onBlur={updateField} />
            </Grid>
            <Grid item xs={6}>
              <Field label="Contraseña" type="password"
                name="password" onBlur={updateField} />
            </Grid>
            <Grid item xs={6}>
              <Field label="Repite la contraseña" type="password"
                name="repeatPassword" onBlur={updateField} />
            </Grid>
            <Grid item xs={12}>
              <Field label="Espacio asignado" />
            </Grid>
            <Grid item xs={12} style={{paddingBottom: 16, textAlign: "right"}}>
              <Button variant="contained" color="primary" type="submit">Guardar cliente</Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  )
}

function Field(props) {
  return <TextField
    variant="outlined"
    margin="dense"
    fullWidth
    {...props}
  />;
}

export default ClientsScreen;