import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { withRouter } from "react-router-dom";
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { Map, Marker, TileLayer, Polygon } from 'react-leaflet';
import L from 'leaflet'
import API from '../api';
import 'leaflet/dist/leaflet.css'

import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Card,
  CardContent,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
 } from '@material-ui/core';
import { DeliveryStatus } from '../models/delivery';

const api = API.instance();
const position = [29.087069, -110.960013];

const markerIcons = {
  "1": {
    normal: new L.Icon({
      iconUrl: '/package-24-a-normal.png',
      iconRetinaUrl: '/package-24-a-normal.png',
      iconAnchor: [5, 48],
      popupAnchor: [10, -44],
      iconSize: [48, 48],
      // shadowUrl: '../assets/marker-shadow.png',
      shadowSize: [68, 95],
      shadowAnchor: [20, 92],
    }),
    marked: new L.Icon({
      iconUrl: '/package-24-a-chosen.png',
      iconRetinaUrl: '/package-24-a-chosen.png',
      iconAnchor: [5, 48],
      popupAnchor: [10, -44],
      iconSize: [48, 48],
      // shadowUrl: '../assets/marker-shadow.png',
      shadowSize: [68, 95],
      shadowAnchor: [20, 92],
    })
  },
  "2": {
    normal: new L.Icon({
      iconUrl: '/package-24-b-normal.png',
      iconRetinaUrl: '/package-24-b-normal.png',
      iconAnchor: [5, 48],
      popupAnchor: [10, -44],
      iconSize: [48, 48],
      // shadowUrl: '../assets/marker-shadow.png',
      shadowSize: [68, 95],
      shadowAnchor: [20, 92],
    }),
    marked: new L.Icon({
      iconUrl: '/package-24-b-chosen.png',
      iconRetinaUrl: '/package-24-b-chosen.png',
      iconAnchor: [5, 48],
      popupAnchor: [10, -44],
      iconSize: [48, 48],
      // shadowUrl: '../assets/marker-shadow.png',
      shadowSize: [68, 95],
      shadowAnchor: [20, 92],
    })
  },
  "3": {
    normal: new L.Icon({
      iconUrl: '/package-24-c-normal.png',
      iconRetinaUrl: '/package-24-c-normal.png',
      iconAnchor: [5, 48],
      popupAnchor: [10, -44],
      iconSize: [48, 48],
      // shadowUrl: '../assets/marker-shadow.png',
      shadowSize: [68, 95],
      shadowAnchor: [20, 92],
    }),
    marked: new L.Icon({
      iconUrl: '/package-24-c-chosen.png',
      iconRetinaUrl: '/package-24-c-chosen.png',
      iconAnchor: [5, 48],
      popupAnchor: [10, -44],
      iconSize: [48, 48],
      // shadowUrl: '../assets/marker-shadow.png',
      shadowSize: [68, 95],
      shadowAnchor: [20, 92],
    })
  }
}

const typeIcons = {
  "1": "/package-24-a-normal.png",
  "2": "/package-24-b-normal.png",
  "3": "/package-24-c-normal.png"
}

export const MarkedIcon = new L.Icon({
  iconUrl: '/package-marker.png',
  iconRetinaUrl: '/package-marker.png',
  iconAnchor: [5, 48],
  popupAnchor: [10, -44],
  iconSize: [48, 48],
  // shadowUrl: '../assets/marker-shadow.png',
  shadowSize: [68, 95],
  shadowAnchor: [20, 92],
})

export const UnmarkedIcon = new L.Icon({
  iconUrl: '/package-marker-disabled.png',
  iconRetinaUrl: '/package-marker-disabled.png',
  iconAnchor: [5, 48],
  popupAnchor: [10, -44],
  iconSize: [48, 48],
  // shadowUrl: '../assets/marker-shadow.png',
  shadowSize: [68, 95],
  shadowAnchor: [20, 92],
})

class RouteAddScreen extends React.Component {
  constructor(props) {
    super();
    this.state = {
      drivers: [],
      driver: null,
      paquetes: [],
      driverId: null,
      viewport: props.viewport,
      isLoadingDrivers: true,
      isLoadingPackages: true,
      chosenPackages: [],
      zones: [],
      showZones: true,
      mostrarTiposPaquetes: [],
      totalVolume: 0,
      asignacion: 0
    }
  }

  componentDidMount() {
    this.loadZones();
    this.loadDrivers();
    this.loadPackages();
  }

  async signIn() {
    if (this.state.isSending) {
      return;
    }

    this.setState({
      isSending: true
    });

    this.props.history.push('')
  }

  async loadDrivers() {
    const update = {isLoadingDrivers: false};

    try {
      const drivers = await api.getChoferes();
      update.drivers = drivers;
    } catch (e) {
      console.log(e);
    }

    this.setState(update);
  }

  async loadZones() {
    const update = {isLoadingZones: false};

    try {
      const zones = await api.getZonas();
      zones.forEach((zona) => {
        zona.zonaVertice = zona.zonaVertice.sort(
          (a, b) => (
            a.index > b.index
              ? 1 : (b.index > a.index ? -1 : 0)
          )
        );
      });
      update.zones = zones;
    } catch (e) {
      console.log(e);
    }

    this.setState(update);
  }

  async loadPackages() {
    const update = { isLoadingPackages: false };
    const filters = {};

    if (this.state.asignacion === 1) {
      filters.estatusEntrega = [DeliveryStatus.mustBePickedUp];
      filters.asignados = 0;
    }

    try {
      update.paquetes = await api.getPaquetes(filters);
    } catch (e) {
      console.log(e);
    }

    this.setState(update);
  }

  calculateVolume() {
    const { chosenPackages } = this.state;
    let total = 0;

    if (chosenPackages && chosenPackages.length) {
      for (let idx = 0; idx < chosenPackages.length; idx++) {
        const pkg = chosenPackages[idx];
        total += pkg.ancho * pkg.alto * pkg.largo;
      }
    }

    return total;
  }

  async save() {
    const {
      driver,
      isSaving,
      chosenPackages,
    } = this.state;

    if (isSaving) {
      return;
    }

    this.setState({isSaving: true});

    try {
      await api.createRoute(
        driver.choferId,
        chosenPackages.map((p) => p.paqueteId)
      );
      this.props.history.push('/');
    } catch (e) {
      console.error(e);
      this.setState({isSaving: false});
    }
  }
  
  renderMapZones() {
    return this.state.zones.map((zone, index) => {
      if (zone.zonaVertice && zone.zonaVertice.length > 0) {
        return (
          <Polygon
            color={
              zone.color ? zone.color : 'black'
            }
            key={index}
            onclick={() => {
              api.getPaquetes({zoneId: zone.zonaId}).then((paquetes) => {
                this.setState({paquetes, zona: zone, chosenPackages: []});
              });
            }}
            positions={zone.zonaVertice.map(v => [v.latitud, v.longitud])}
          />
        );
      }
      return null;
    });
  }

  render() {
    const {
      paquetes,
      driver,
      drivers,
      viewport,
      isSaving,
      chosenPackages,
      totalVolume,
      asignacion,
    } = this.state;

    return (
      <Container component="main">
        <h1>Planear ruta</h1>
        <Grid container spacing={2}
          alignItems="flex-end"
          justify="right"
          style={{paddingBottom: 16}}
        >
          <Grid item xs={6}>
            <Autocomplete
              options={drivers}
              getOptionLabel={(option) => option.nombre}
              renderInput={
                (params) => <TextField {...params} variant="outlined" />
              }
              onChange={(e, driver) => this.setState({driver})}
            />
          </Grid>
          
          <Grid item xs={6}>
            <FormControl variant="outlined" fullWidth>
              <Select value={asignacion}
                onChange={(e) => {
                  this.setState({asignacion: e.target.value}, () => {
                    this.loadPackages();
                  });
                }}
              >
                <MenuItem value={0}>Sin asignar</MenuItem>
                <MenuItem value={1}>Recolecciones sin asignar</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} style={{borderRadius: 16}}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <div className="leafleft-container" style={{ borderRadius: 16 }}>
                  <Map center={position} zoom={13} viewport={viewport} style={{ height: '400px' }}>
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    {this.renderMapZones()}
                    {paquetes ? paquetes.map((paquete, index) =>
                      <Marker position={[paquete.latitud, paquete.longitud]}
                        key={index}
                        icon={chosenPackages.indexOf(paquete) !== -1
                          ? markerIcons[paquete.tipoPaqueteId].marked
                          : markerIcons[paquete.tipoPaqueteId].normal
                        }
                        onClick={() => {
                          const index = chosenPackages.indexOf(paquete);
                          if (index !== -1) {
                            chosenPackages.splice(index, 1);
                          } else {
                            chosenPackages.push(paquete);
                          }
                          this.setState({
                            chosenPackages,
                            totalVolume: this.calculateVolume(),
                          });
                        }}
                      />
                    ) : null}
                  </Map>
                </div>
              </Grid>
              <Grid item xs={6}>
                <Paper style={{minHeight: 400}}>
                  <List>
                    {chosenPackages.map((paquete, index) =>
                      <ListItem>
                        <ListItemIcon>
                          <img
                            style={{width: 48, height: 48}}
                            src={typeIcons[paquete.tipoPaqueteId]} />
                        </ListItemIcon>
                        <ListItemText>{paquete.direccionLarga}</ListItemText>
                      </ListItem>
                    )}
                  </List>
                </Paper>
                {chosenPackages && chosenPackages.length > 0
                  ? (
                    <Paper style={{marginTop: 16, padding: 16}}>
                      <div style={{marginBottom: 16}}>
                        Volumen total: {totalVolume ? Math.round(totalVolume * 100) / 100 : 'asda'} m<sup>3</sup>
                      </div>
                      <div>Cabe en</div>
                      <Grid container spacing={1}>
                        {
                          totalVolume >= 0 && totalVolume < 0.123
                            ? (<Grid item>Moto</Grid>) :  null
                        }
                        {
                          totalVolume >= 0 && totalVolume < 2.1
                            ? (<Grid item>Van</Grid>) : null
                        }
                        {
                          totalVolume >= 2.1 && totalVolume < 100
                            ? (<Grid item>Camión</Grid>) : null
                        }
                      </Grid>
                    </Paper>
                  ) : null
                }
              </Grid>
            </Grid>
          </Grid>
          
          <Grid item xs={12}>
            <Button variant="contained" color="primary"
              disabled={!driver || isSaving || chosenPackages.length === 0}
              onClick={this.save.bind(this)}>
                Generar Ruta</Button>
          </Grid>

        </Grid>
      </Container>
    );
  }
}

export default withRouter(RouteAddScreen);