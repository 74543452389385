import React, { useCallback, useMemo, useState, useRef } from 'react';
import {
  Container,
  Table,
  TableContainer,
  Grid,
  Paper,
  Card as DefaultCard,
  CardContent,
  Typography,
  TableRow,
  TableCell,
  TableBody,
  withStyles,
  TextField,
  TableHead,
} from '@material-ui/core';
import {
  Warning as WarningIcon,
  CheckBox as CheckBoxIcon,
} from '@material-ui/icons';

import {
  Box
} from '@material-ui/core';

import Checkbox from '@material-ui/core/Checkbox';

const CardText = (props) => {
  return <div {...props} />;
};

export default function PedidoNuevo({orden, setChecks, checks, units, setUnits}) {
  const [isChecking, setIsChecking] = useState(false);
  const [code, setCode] = React.useState('');
  const [pending, setPending] = useState(
    orden.products?.reduce((acc, i) => {
      for (let index = 0; index < i.quantity; index++) {
        acc.push({
          ...i,
          key: `${i.variant.id}-${index + 1}`,
          quantity: 1,
        });
      }
      return acc;
    }, [])
  );
  const [done, setDone] = useState([]);
  const [checked, setChecked] = useState([]);
  const totalArticlesDone = useMemo(() => {
    return done.reduce((a, i) => a + i.quantity, 0);
  }, [done]);
  const barcodeRef = useRef();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleCode = (e) => {
    e.preventDefault();
    if (code === '') {
      return;
    }
    const unit = orden.products.find((item) => item.sku === code);
    if (unit) {
      if (units?.find((item) => item === code)) {
        setUnits([...units, {code} + 1])
      } else {
        setUnits([...units, {code:1}])
      }
      setChecks({
        ...checks,
        [unit.sku]: true,
        'total': checks['total'] + (100 * unit.quantity),
      });
    } else {
        return;
    }
    setCode('');
  };

  const handleConfirmItem = useCallback((item) => {
    const doneItem = done.filter(e => e.variant.id === item.variant.id)[0];
    if (doneItem) {
      setDone(
        done.map(e => {
          if (e.variant.id === item.variant.id) {
            return {...e, quantity: e.quantity + 1};
          } else {
            return e;
          }
        }
      ));
    } else {
      setDone([...done, item]);
    }
    setSuccessMessage(`Se escaneó el artículo '${item.variant.title}' correctamente.`);
    setErrorMessage('');
    setPending(pending.filter(e => e.key !== item.key));
  }, [pending, done]);

  const handleSubmitBarcode = useCallback(e => {
    e.preventDefault();
    const barcode = e.currentTarget.barcode.value;
    const found = pending.filter(e => e.variant.barcode === barcode)[0];
    if (found) {
      setChecked([...checked, found.key]);
      setTimeout(() => handleConfirmItem(found), 250);
      setSuccessMessage("");
    } else {
      setErrorMessage(`El código '${barcode}' no está en la lista de pendientes.`);
      setSuccessMessage("");
    }
    setCode("");
  }, [pending, barcodeRef]);

  const TableItems = () => {
    return pending.map((item, i) => {
      return (
        <TableRow key={item.id} className={checked.indexOf(item.key) > -1 ? "removing-item" : ""}>
          <TableCell width="10%">{item.sku}</TableCell>
          <TableCell width="45%">{item.title}</TableCell>
          <TableCell width="10%">${parseFloat(100 * 1).toFixed(2)}</TableCell>
          <TableCell width="5%" padding="160px">
            <CheckItem
              size="medium"
              checked={checked.indexOf(item.key) > -1}
              value={item.key}
              onChange={(e) => {
                const key = e.currentTarget.value;
                setChecked([...checked, e.currentTarget.value]);
                setTimeout(() => handleConfirmItem(item), 100);
              }}
              inputProps={{ 'aria-label': 'primary checkbox' }}
              style={{padding: 0}}
            />
          </TableCell>
        </TableRow>
      )
    })
  };

  return (
    <div style={styles.container}>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={8}>
          <Card style={{padding: 16, height: "100%"}}>
            <Typography variant="h5">Cliente</Typography>
            <CardText style={styles.cardContentTextCliente}>
              {orden.receptor}
            </CardText>
            <CardText text style={styles.cardContentText}>
              {orden.destination.name + ' C.P. ' + orden.destination.postalCode + ' Col. ' + orden.destination.colonia}
            </CardText>
            <CardText text style={styles.cardContentText}>
              SDR123456789
            </CardText>
            <Grid container spacing={3}>
              <Grid item>
              <Typography variant="h6">Vigencia</Typography>
                12-07-2023
              </Grid>
              <Grid item>
                <Typography variant="h6">Condiciones</Typography>
                Contado
              </Grid>
              <Grid item>
                <Typography variant="h6">Vendedor</Typography>
                José Pérez
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={4}>
          <div style={{height: "100%"}}>
            <Card style={{padding: 16, height: "100%", display: "flex", flexDirection: "column"}}>
              <Typography variant="h6">Fecha</Typography>
              <div style={{marginBottom: 8}}>{orden.fechaHora.split('T')[0]}</div>
              <Typography variant="h6">Guía</Typography>
              <div>{orden.trackingNumber}</div>
            </Card>
          </div>
        </Grid>
      </Grid>
      
      {(pending?.length ?? 0) ? (
        <React.Fragment>
          <h2 style={{marginBottom: 8}}>{pending?.length ?? 0} artículos pendientes de surtir</h2>
          <TableContainer component={Card}>
            <Table style={styles.table} size="medium">
              <thead>
                <TableRow>
                  <TableCell style={{textAlign: "left"}}>Código</TableCell>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Importe</TableCell>
                  <TableCell>Listo</TableCell>
                </TableRow>
              </thead>
              <TableBody style={{overflow: 'scroll'}}>
                <TableItems />
              </TableBody>
            </Table>
          </TableContainer>
        </React.Fragment>
      ) : null}

        <Paper style={{marginTop: 16, borderRadius: 16}}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <form noValidate onSubmit={handleSubmitBarcode} autoComplete="off" style={{paddingLeft: 16, paddingTop: 16}}>
              <TextField
                inputRef={barcodeRef}
                variant="outlined"
                name="barcode"
                label="Escaneo de código"
                InputLabelProps={{shrink: true}}
                autoFocus
                value={code} 
                onChange={(e) => setCode(e.target.value)} 
                style={{
                  height: 70,
                  borderRadius: 5,
                  fontSize: 30,
                }}
                autofocus
              />
            </form>
            <div style={{display: "flex", marginRight: 16, alignItems: "center"}}
              className={successMessage ? "barcode-message-success" : (errorMessage ? "barcode-message-error" : null)}
            >
              <div style={{marginRight: 8}}>
                {errorMessage ? <WarningIcon /> : (successMessage ? <CheckBoxIcon /> : null)}
              </div>
              <div>
                {successMessage ? successMessage : ""}
                {errorMessage ? errorMessage : ""}
              </div>
            </div>
          </Box>
        </Paper>
        {(done?.length ?? 0) ? (
          <React.Fragment>
            <h2>{totalArticlesDone} artículos surtidos</h2>
            <TableContainer component={Card} style={{marginBottom: 16}}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Código</TableCell>
                    <TableCell>Nombre</TableCell>
                    <TableCell align="right">Unidades</TableCell>
                    <TableCell align="right">Precio unitario</TableCell>
                    <TableCell align="right">Importe</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {done?.map(e =>
                    <TableRow>
                      <TableCell>{e.sku}</TableCell>
                      <TableCell>{e.variant.title}</TableCell>
                      <TableCell align="right">{e.quantity}</TableCell>
                      <TableCell align="right">{e.unitPrice}</TableCell>
                      <TableCell align="right">{e.unitPrice * e.quantity}</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <Table size="small" style={{width: "auto", marginLeft: "auto"}}>
                <TableBody>
                  <TableRow>
                    <TableCell align="right">Subtotal</TableCell>
                    <TableCell style={{width: "130px"}} align="right">
                      100
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="right">Total</TableCell>
                    <TableCell style={{width: "130px"}} align="right">
                      100
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer> 
          </React.Fragment>
        ) : null}
    </div>
  );
};

const CheckItem = withStyles(() => ({
  root: {
    width: 32,
    height: 32,
    '& .MuiSvgIcon-root': {
      width: '1.7em',
      height: '1.7em',
    }
  }
}))(Checkbox);

const Card = withStyles(() => ({
  root: {
    borderRadius: 16
  }
}))(DefaultCard);

const styles = {
  container: {
      
  },
  card: {
    
  },
  card2: {

  },
  card3: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardTitle: {
  },
  cardContent: {
      
  },
  cardContentTitle: {
      
  },
  cardContentSubtitle: {
      
  },
  cardContentText: {
      
  },
  cardContentTextCliente: {
      
  },
  cell: {
      padding: 0,
      textAlign: 'center',
  },
  cellTitle: {
      padding: 0,
      textAlign: 'left'
  },
  table: {
      
  },
  table2: {
      textAlign: 'center',
  },
  cell2: {
      padding: 0,
      textAlign: 'center',
  },
  cell3: {
      padding: 0,
      textAlign: 'center',
      backgroundColor: '#ccc',
  },
};