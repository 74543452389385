import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { withRouter } from "react-router-dom";
import API from '../api';
import {
  CircularProgress,
  Select,
  MenuItem,
  Card,
  CardContent,
  FormControl,
  FormLabel,
} from '@material-ui/core';

const api = API.instance();

class VehicleEditScreen extends React.Component {
  state = {
    'user': '',
    'password': '',
    nombre: '',
    modelo: '',
    model: null,
    placa: '',
    noPoliza: '',
    capacidadM3: '',
    capacidadMinimaM3: '0.0',
    costMinimoPaquete: 0,
    tiposPaquetesElegidos: [],
    vehicleTypes: [],
    tarjetaCirculacionFrenteFile: null,
    tarjetaCirculacionReversoFile: null,

    packagesTypes: null,

    isSaving: false,
    isLoading: true,
  }
  
  constructor(props) {
    super(props);
    this.circulacionFrenteUploader = null;
    this.circulacionReversoUploader = null;
  }

  async save() {
    const {
      isSaving,
      nombre,
      modelo,
      model,
      placa,
      noPoliza,
      tipoVehiculoId,
      tarjetaCirculacion,
      tarjetaCirculacionFrenteFile,
      tarjetaCirculacionReversoFile,
    } = this.state;

    if (isSaving) {
      return;
    }

    this.setState({
      isSaving: true
    });

    try {
      const form = new FormData();
      form.append('typeId', tipoVehiculoId);
      form.append('name', nombre);
      form.append('model', modelo);
      form.append('licensePlate', placa);
      form.append('noPoliza', noPoliza);
      form.append('tarjetaCirculacion', tarjetaCirculacion);
      form.append('tarjetaCirculacionFrente',
        tarjetaCirculacionFrenteFile);
      form.append('tarjetaCirculacionReverso',
        tarjetaCirculacionReversoFile);

      const paquete = await api.saveVehicle(model.vehiculoId, form);

      this.setState({
        isSaving: false,
      }, () => {
        this.props.history.push('/vehicles');
      });
    } catch (e) {
      console.error(e);
      this.setState({
        isSaving: false,
      });
    }
  }

  async delete() {
    const {
      isSaving,
      model,
    } = this.state;

    if (isSaving) {
      return;
    }

    if (!window.confirm("¿Confirmas que deseas eliminarlo?")) {
      return;
    }

    this.setState({
      isSaving: true
    });

    try {
      await api.deleteVehicle(model.vehiculoId);

      this.setState({
        isSaving: false,
      }, () => {
        this.props.history.push('/vehicles');
      });
    } catch (e) {
      console.error(e);
      this.setState({
        isSaving: false,
      });
    }
  }

  async load() {
    try {
      const model = await api.getVehicle(
        this.props.match.params.id
      );

      this.setState({
        model,
        isLoading: false,
        nombre: model.nombre,
        modelo: model.modelo,
        placa: model.placa,
        noPoliza: model.noPoliza,
        tipoVehiculoId: model.tipoVehiculoId,
        tarjetaCirculacion: model.tarjetaCirculacion
      });

    } catch (e) {
      console.error(e);
      this.setState({ isLoading: false });
    }
  }

  async loadVehicleTypes() {
    try {
      const types = await api.getVehicleTypes();
      this.setState({ vehicleTypes: types });
    } catch (e) {
      console.error(e);
      alert("Algo salió mal: " + e.message);
    }
  }

  componentDidMount() {
    this.loadVehicleTypes();
    this.load();
  }

  render() {
    const {
      isSaving,
      nombre,
      modelo,
      model,
      placa,
      noPoliza,
      vehicleTypes,
      tipoVehiculoId,
      capacidadM3,
      capacidadMinimaM3,
      costoMinimoPaquete,
      isLoading,
      packagesTypes,
      tiposPaquetesElegidos,
      tarjetaCirculacion
    } = this.state;

    if (isLoading) {
      return (
        <Container>
          <CircularProgress />
        </Container>
      );
    }

    return (
      <Container component="main">
        <h1>Carro</h1>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <Card>
              <CardContent>
                <Grid container spacing={2}
                  alignItems="flex-end"
                  justify="flex-end"
                  style={{ paddingBottom: 16 }}
                >
                  <Grid item xs={12}>
                    <TextField
                      value={nombre}
                      variant="outlined"
                      fullWidth={true}
                      label="Descripción"
                      style={{ minWidth: 300 }}
                      onChange={(e) => this.setState({ nombre: e.target.value })} />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      value={modelo || ""}
                      variant="outlined"
                      fullWidth={true}
                      label="Modelo"
                      style={{ minWidth: 300 }}
                      onChange={(e) => this.setState({ modelo: e.target.value })} />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      value={placa}
                      variant="outlined"
                      fullWidth={true}
                      label="Placa"
                      style={{ minWidth: 300 }}
                      onChange={(e) => this.setState({ placa: e.target.value })} />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      value={noPoliza}
                      variant="outlined"
                      fullWidth={true}
                      label="Póliza"
                      style={{ minWidth: 300 }}
                      onChange={(e) => this.setState({ noPoliza: e.target.value })} />
                  </Grid>

                  <Grid item xs={12}>
                    <Select
                      variant="outlined"
                      fullWidth={true}
                      value={tipoVehiculoId ? tipoVehiculoId : 0}
                      onChange={
                        (e) => this.setState({ tipoVehiculoId: e.target.value })
                      }
                      renderInput={(params) =>
                        <TextField {...params}
                          label="Tipo de carro"
                          variant="outlined" />
                      }
                    >
                      <MenuItem value={0}>- Nada -</MenuItem>
                      {vehicleTypes ? vehicleTypes.map((item, idx) =>
                        <MenuItem value={item.tipoVehiculoId} key={idx}>
                          {item.nombre}
                        </MenuItem>
                      ) : null}
                    </Select>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      value={tarjetaCirculacion}
                      variant="outlined"
                      fullWidth={true}
                      label="Tarjeta de circulación"
                      style={{ minWidth: 300 }}
                      onChange={(e) => this.setState({ tarjetaCirculacion: e.target.value })} />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant="outlined">
                      <FormLabel htmlFor="file">
                        Imagen de la tarjeta de circulación
                      </FormLabel>
                      <input type="file"
                        onChange={(e) => {
                          this.setState({
                            tarjetaCirculacionFrenteFile: e.target.files[0]
                          })
                        }}
                        id="comprobanteDomicilio"
                        ref={ref => this.circulacionFrenteUploader = ref} />
                    </FormControl>
                    {this.state.model?.tarjetaCirculacionFrente
                      ? (
                        <Grid container>
                          <Grid item>
                            <Button
                              variant="outlined"
                              href={this.state.model?.tarjetaCirculacionFrente}
                              target="_blank"
                            >
                              Ver captura
                            </Button>
                          </Grid>
                          <Grid item>
                            <img src={this.state.model?.tarjetaCirculacionFrente}
                              style={{ width: 100 }} />
                          </Grid>
                        </Grid>
                      ) : null
                    }
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item xs={6}>
                <Button variant="outlined" color="primary"
                  disabled={isSaving}
                  onClick={this.delete.bind(this)}>
                  Eliminar vehículo
                </Button>
              </Grid>
              <Grid item xs={6} style={{textAlign: 'right'}}>
                <Button variant="contained" color="primary"
                  disabled={isSaving}
                  onClick={this.save.bind(this)}>
                  Guardar Información
                </Button>
              </Grid>
              <Grid item>
                {isSaving ? <CircularProgress /> : null}
              </Grid>
            </Grid>

          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withRouter(VehicleEditScreen);