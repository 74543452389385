import React, { useState } from 'react';
import {
  Chip,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
} from '@material-ui/core';
import { DeliveryStatus } from '../models/delivery';

const ids = [6, 3, 10];
const statuses = DeliveryStatus.getList().filter(i => ids.indexOf(i.id) > -1);
const estatusEntregaMap = DeliveryStatus.map;

export default function EstatusEntregaSelect(props) {
  const [value, setValue] = useState(props.value);

  const handleChange = (event) => {
    setValue(event.target.value);
    props.onChange && props.onChange(event.target.value);
  };

  return (
    <Select
      multiple value={value}
      onChange={handleChange}
      renderValue={(x) => x.map(i => i.title).join(',')}
      labelId={props.labelId}
      label={props.label}
      margin={props.margin}
    >
      {statuses.map((status) =>
        <MenuItem key={status.id} value={status}>
          <Checkbox checked={value.indexOf(status) > -1} />
          <ListItemText>
            <Chip
              label={status.title}
              style={{
                backgroundColor: estatusEntregaMap[status.id].color,
                color: 'white'
              }}
            />
          </ListItemText>
        </MenuItem>
      )}
    </Select>
  )
}