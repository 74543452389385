import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import { withRouter } from "react-router-dom";
import { CircularProgress } from '@material-ui/core';
import API from '../api';

// import DasBigLogo from './das-big.png';

const api = API.instance();

class LoginScreen extends React.Component {
  state = {
    'user' : '',
    'password': '',
    isSending: false
  }

  async signIn() {
    if (this.state.isSending) {
      return;
    }

    this.setState({
      isSending: true
    });

    const { email, password } = this.state;

    try {
      const response = await api.logIn(email, password);
      if (response.isSuccess) {
        this.props.history.push('/');
      } else {
        alert(response.message);
      }
    } catch (e) {
      console.error(e);
    }

    this.setState({
      isSending: false
    });
  }

  render() {
    const {
      email,
      password,
      isSending,
    } = this.state;

    return (
      <Container component="main" maxWidth="xs">
        <div style={{textAlign: 'center'}}>
          <img src="/das-big.png" style={{ width: 128, marginBottom: 32 }}
            alt="Logotype" />
          {process.env.REACT_APP_ALERT_TITLE ?? ""}
        </div>
        <Paper style={{ padding: 32, textAlign: "center" }}>
          <form onSubmit={(e) => { e.preventDefault(); }}>
            <div style={{marginBottom: 16}}>
              <TextField
                value={email ?? ""}
                label="Correo electrónico"
                variant="outlined"
                onChange={(e) => this.setState({ email: e.target.value })}
                fullWidth={true} />
            </div>
            <div style={{marginBottom: 16}}>
              <TextField
                value={password}
                type="password"
                label="Contraseña"
                variant="outlined"
                fullWidth={true}
                onChange={(e) => this.setState({password: e.target.value})}
                 />
            </div>
            <Button type="submit" variant="contained" color="primary" onClick={this.signIn.bind(this)}>
              {isSending
                ? (
                  <CircularProgress size={20} color="white" />
                )
                : "Conectarme"
              }
            </Button>
          </form>
        </Paper>
      </Container>
    );
  }
}

export default withRouter(LoginScreen);