import React, { useCallback, Fragment, useState, useRef } from 'react';
import {
  IconButton,
} from '@material-ui/core';
import {
  Edit as EditIcon,
  DeleteForeverOutlined as DeleteIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  Keyboard as KeyboardIcon,
  List as ListIcon,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import FeatureValue from './feature_value';
import FeatureSearcher from './feature_searcher';
import TitleAndDescription from './title_and_description';
import { styles } from '@material-ui/pickers/views/Calendar/Calendar';
import API from '../api';

const api = new API();

export default function FeatureTile(props) {
  const classes = useStyles();
  const {
    feature: f,
    parentId,
    product,
    editing,
    onEdit,
    onChangeFeature,
    onConfirmSelection,
    onCancel,
    onSaved,
    onDeleted,
    options,
    value: defaultValue,
    categoryId,
    ignoreIds,
    saverFunction,
    deleteFunction,
  } = props;

  const [isSaving, setIsSaving] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const customValueRef = useRef();

  const handleEdit = useCallback(e => {
    if (onEdit) {
      const id = parseInt(e.currentTarget.value);
      onEdit(id);
    }
  }, []);

  const handleDelete = useCallback(async (e) => {
    if (isSaving)
      return;
      
    if (!window.confirm("Se borrará de manera definitiva esta característica."
      + " ¿Confirmas que quieres eliminarla?"))
    {
      return;
    }

    setIsSaving(true);

    try {
      if (deleteFunction) {
        await deleteFunction(parentId, f.id);
      } else {
        await api.deleteProductFeatures(product.id, f.id);
      }
      console.log(f);
      if (onDeleted)
        onDeleted(f);
    } catch (e) {
      console.error(`[handleDelete] ${e}`);
    }

    setIsSaving(false);
  }, [product, f, isSaving]);

  const handleSelectFeature = useCallback(f => {
    if (onChangeFeature) {
      onChangeFeature(f);
    }
  });

  const handleOnChangeValue = useCallback(e => {
    setValue(e);
  }, []);

  const handleCancelSelection = useCallback(e => {
    if (onCancel)
      onCancel(parseInt(e.currentTarget.value));
  }, []);

  const handleSave = useCallback(async (e) => {
    let results;
    try {
      if (saverFunction) {
        results = await saverFunction(parentId, [
          {
            featureId: f.id,
            featureOptionId: value,
            value: customValueRef.current?.value,
            isOpen: f.isOpen
          }
        ]);
      } else {
        results = await api.saveProductFeatures(product.id, [
          {
            featureId: f.id,
            featureOptionId: value,
            value: customValueRef.current?.value,
            isOpen: f.isOpen
          }
        ]);
      }
      if (onSaved)
        onSaved(results[0]);
    } catch (e) {
      console.error(`[FeatureTile] ${e}`)
    }
  }, [product, f, value, customValueRef]);

  return (
    <div className={classes.root}>
      <div className={classes.featureTileContainer}>  
      {f.isNew && editing ? (
        <FeatureSearcher
          categoriesIds={[categoryId]}
          onSelect={handleSelectFeature}
          preload={true}
          ignoreIds={ignoreIds}
        />
      ) : (
        <TitleAndDescription
          title={f?.feature?.name ?? f.name}
          description={f.description} />
      )}
      </div>

      <div className={classes.valueContainer}>
        <FeatureValue
          feature={f}
          editing={editing}
          onEdit={onEdit}
          onChange={handleOnChangeValue}
          value={value}
          customValueRef={customValueRef}
          options={options} />
      </div>
      {!editing ? (
        <Fragment>
          <div>
            <IconButton onClick={handleEdit} value={f.id} size="small">
              <EditIcon style={{color: "#999"}} />
            </IconButton>
          </div>
          <div>
            <IconButton onClick={handleDelete} value={f.id} size="small">
              <DeleteIcon style={{color: "#999"}} />
            </IconButton>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div>
            <IconButton onClick={handleSave} value={f.id} size="small">
              <CheckIcon style={{color: "#999"}} />
            </IconButton>
          </div>
          <div>
            <IconButton onClick={handleCancelSelection} value={f.id} size="small">
              <CloseIcon style={{color: "#999"}} />
            </IconButton>
          </div>
        </Fragment>
      )}
      
    </div>
  );
}


const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    paddingRight: 8,
    padding: 0,
    alignItems: "center"
  },
  valueContainer: {
    flexGrow: 0,
    paddingTop: 8,
    paddingBottom: 8,
    paddingRight: 8,
  },
  description: {
    color: "#888",
    fontSize: 12,
    lineHeight: "11px"
  },
  dropdownDialog: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    position: "absolute",
    zIndex: 1000,
    marginBottom: 0, 
    minWidth: 300,
    maxHeight: 400
  },
  featureTileContainer: {
    paddingLeft: 16,
    flexGrow: 1
  },
  showDialog: {
    display: "block"
  },
  hideDialog: {
    display: "none"
  },
  loading: {
    padding: 8
  }
});