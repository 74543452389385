import React, {useEffect, useReducer, useCallback} from 'react';
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  InputLabel,
  Select,
  List,
  ListItem,
  ListItemText,
  Grid,
  Button,
  Dialog,
  DialogContent,
} from '@material-ui/core';
import API from '../api';
import {
  makeStyles
} from '@material-ui/core/styles';
import {
  ArrowDropDown as ArrowDropDownIcon,
  Add as AddIcon,
} from '@material-ui/icons';
import CategorySelect from './category-select';
import { useHistory } from 'react-router';
import { parse, encode } from 'querystring';
import FeatureForm from './feature_form';
import { features } from 'process';

class Action {
  static FETCH = 1;
  static SELECT_CATEGORY = 2;
  static FETCH_CATEGORIES = 3;
  static OPEN_FORM = 4;
  static CLOSE_FORM = 5;
  static EDIT_FEATURE = 6;
  static FETCH_FEATURE = 7;
}

const initialState = {
  features: [],
  categories: [],
  categoryId: 0,
  showFeatureForm: false,
  filters: {
    categoryId: null
  },
  editId: null
};

function reducer(state, action) {
  switch (action.type) {
    case Action.FETCH:
      return {
        ...state,
        features: action.features,
        categories: action.categories ?? state.categories,
        filters: action.categoryId ? {
          ...state.filters,
          categoryId: action.categoryId ?? state.filter.categoryId ?? 0,
        } : state.filters
      };

    case Action.SELECT_CATEGORY:
      return {
        ...state,
        filters: {
          ...state.filters,
          categoryId: action.categoryId
        }
      };

    case Action.FETCH_FEATURE:
      return {
        ...state,
        features: state.features
          .map(i => i.id === action.featureId ? action.feature : i)
      };

    case Action.ADD_FEATURE:
      return {
        ...state,
        features: [...state.features, action.feature]
      }

    case Action.FETCH_CATEGORIES:
      return {...state, categories: action.categories};

    case Action.OPEN_FORM:
      return {...state, showFeatureForm: true};

    case Action.CLOSE_FORM:
      return {...state, editId: null};

    case Action.EDIT_FEATURE:
      return {...state, editId: action.editId};

    default:
      return state;
  }
}

const api = new API();

export default function FeaturesTable() {
  const history = useHistory();
  const qs = parse(history.location.search.replace('?', ''));
  const [state, dispatch] = useReducer(reducer, {...initialState,
    filters: {...initialState.filters,
      categoryId: qs.categoryId
    }
  });

  const fetchData = useCallback(async (args) => {
    try {
      const features = await api.getFeatures({
        categoriesIds: args?.categoryId
          ? [args.categoryId] : ( 
            state.filters?.categoryId ? [state.filters.categoryId] : []
        )
      });
      console.log(features);
      dispatch({
        type: Action.FETCH,
        features,
        categories: args?.categories,
        categoryId: args?.categoryId,
      });
    } catch (e) {
      console.error(`[fetchData] error ${e.message}`);
    }
  }, [state]);

  const handleChangeCategory = useCallback(async (e) => {
    history.push(`?categoryId=${e.target.value}`);
    dispatch({type: Action.SELECT_CATEGORY, categoryId: e.target.value});
  }, [history]);

  const handleChange = useCallback(async (ft) => {
    dispatch({type: Action.FETCH_FEATURE, feature: ft});
  }, []);

  const handleCloseForm = useCallback(e => {
    dispatch({type: Action.CLOSE_FORM});
  }, []);

  const handleCreate = useCallback(async (e) => {
    try {
      const ft = await api.createFeature();
      dispatch({type: Action.ADD_FEATURE, feature: ft});
    } catch (e) {
      alert("Algo salió mal: " + e.message);
    }
  }, [features]);

  const handleSelectItem = useCallback(e => {
    const fid = parseInt(e.currentTarget.getAttribute('data-feature-id'));
    dispatch({type: Action.EDIT_FEATURE, editId: fid});
  }, []);

  const handleDiscardFeature = useCallback(e => {
    dispatch({type: Action.CLOSE_FORM});
  }, []);

  const handleSaveOption = useCallback(e => {
    alert("save option");
  }, []);

  useEffect(() => {
    fetchData();
  }, [state.filters]);

  useEffect(() => {
    async function preload() {
      dispatch({
        type: Action.FETCH_CATEGORIES,
        categories: await api.getCategories()
      });
    }
    preload();
  }, [history]);

  const classes = useStyles();

  return (
    <React.Fragment>
      <h1>Características</h1>
      <Paper style={{borderRadius: 16}}>
        <Grid container wrap="nowrap" spacing={2} style={{padding: "4px 16px 0px 16px"}} alignItems="center">
          <Grid item style={{flexGrow: 1}}>
            <CategorySelect
              categories={state.categories}
              selected={state.filters.categoryId}
              onChange={handleChangeCategory}
              nothingLabel="Todas las categorías" />
          </Grid>
          <Grid item>
            <Button startIcon={<AddIcon />} variant="outlined" onClick={handleCreate}>Crear</Button>
          </Grid>
        </Grid>
        <List>
          {state.features?.map((f, index) => {
            if (f.id === state.editId) {
              return (
                <div style={{borderTop: "solid 3px #c0c0c0", borderBottom: "solid 2px #c0c0c0"}} key={index}>
                  <FeatureForm
                    categories={state.categories}
                    featureId={state.editId}
                    onChange={handleChange}
                    onDiscard={handleDiscardFeature}
                    onSave={handleSaveOption} />
                </div>
              );
            } else {
              return <FeatureItem feature={f} key={f.id} onSelect={handleSelectItem} disabled={state.editId} />;
            }
          })}
        </List>
      </Paper>
    </React.Fragment>
  )
}

function FeatureItem(props) {
  const classes = useStyles();
  const {feature, onSelect, disabled} = props;
  return (
    <ListItem
      button={!disabled}
      onClick={!disabled ? onSelect : null}
      data-feature-id={feature.id}
      className={disabled ? classes.disabledItem : null}
      divider
    >
      <ListItemText>
        {feature.name ? (
          <span>{feature.name}</span>
        ) : (
          <span style={{color: "#777", fontStyle: "italic"}}>Sin nombre</span>
        )}
      </ListItemText>
    </ListItem>
  );
}

const useStyles = makeStyles({
  container: {
    borderRadius: 16
  },
  idCell: {
    width: "50px"
  },
  headerCell: {
    fontWeight: "bold"
  },
  disabledItem: {
    backgroundColor: "#f0f0f0",
    color: "#555"
  }
});