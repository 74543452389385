import React, { useState, useRef, useEffect, useCallback } from 'react';
import {
  Grid,
  Card,
  CardContent,
  TextField,
  Typography,
  FormControl,
  FormLabel,
  Button,
  Select,
  MenuItem,
  InputLabel,
  Avatar,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';
import moment from 'moment';
import momentz from 'moment-timezone';
import { useHistory } from "react-router-dom";
import API, { wwwRoot } from '../api';
import { green, red } from '@material-ui/core/colors';
import Btn from '../components/button';

import {
  Check as CheckIcon,
  NotInterested as NotInterestedIcon,
  DeleteForever as DeleteForeverIcon,
} from '@material-ui/icons';

const tz = moment.tz.guess();
const api = API.instance();

export default function DriverForm({ driver }) {
  const history = useHistory();
  const vehiculo = driver.vehiculo ? driver.vehiculo : {};
  const cliente = driver.cliente ? driver.cliente : {};
  const [isSending, setIsSending] = useState(false);
  const [nombre, setNombre] = useState(driver.nombre);
  const [apellidoPaterno, setApellidoPaterno] = useState(driver.apellidoPaterno);
  const [apellidoMaterno, setApellidoMaterno] = useState(driver.apellidoMaterno);
  const [telefono, setTelefono] = useState(driver.numCelular);
  const [direccion, setDireccion] = useState(driver.direccion);
  const [email, setEmail] = useState(cliente.correo);
  const [password, setPassword] = useState('');
  const [licencia, setLicencia] = useState(driver.licenciaConducir);
  const [marca, setMarca] = useState(vehiculo.marca);
  const [modelo, setModelo] = useState(vehiculo.modelo);
  const [year, setYear] = useState(vehiculo.ano);
  const [color, setColor] = useState(vehiculo.color);
  const [placa, setPlaca] = useState(vehiculo.placa);
  const [poliza, setPoliza] = useState(vehiculo.noPoliza);
  const [tarjetaCirculacion, setTarjetaCirculacion] = useState(vehiculo.tarjetaCirculacion);
  const [zones, setZones] = useState([]);
  const [zoneId, setZoneId] = useState(driver.zonaId);
  const [verificationStatus, setVerificationStatus] = useState(driver.verificationStatus);
  const [referenciaNombre1, setReferenciaNombre1] = useState(cliente.referenciaNombre1);
  const [referenciaNombre2, setReferenciaNombre2] = useState(cliente.referenciaNombre2);
  const [referenciaTelefono1, setReferenciaTelefono1] = useState(cliente.referenciaTelefono1);
  const [referenciaTelefono2, setReferenciaTelefono2] = useState(cliente.referenciaTelefono2);
  const [rfc, setRfc] = useState(cliente.rfc ? cliente.rfc : '');
  const [curp, setCurp] = useState(cliente.curp ? cliente.curp : '');
  const [tipoVehiculoId, setTipoVehiculoId] = useState(
    vehiculo.tipoVehiculoId ? vehiculo.tipoVehiculoId : 1
  );
  const [plusType, setPlusType] = useState(driver?.plusType ?? 'any');
  const [imageUrl, setImageUrl] = useState(driver?.imageUrl);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [showDeletion, setShowDeletion] = useState(false);

  const [fileComprobante, setFileComprobante] = useState(null);
  const [fileLicenciaFrente, setFileLicenciaFrente] = useState(null);
  const [fileLicenciaReverso, setFileLicenciaReverso] = useState(null);
  const [fileTarjetaCirculacionFrente, setFileTarjetaCirculacionFrente] = useState(null);
  const [fileTarjetaCirculacionReverso, setFileTarjetaCirculacionReverso] = useState(null);
  const [fileProfileImage, setFileProfileImage] = useState(null);

  const comprobanteUploader = useRef(null);
  const frenteLicenciaUploader = useRef(null);
  const reversoLicenciaUploader = useRef(null);
  const circulacionFrenteUploader = useRef(null);
  const circulacionReversoUploader = useRef(null);
  const profilePictureUploader = useRef(null);

  const save = async () => {
    var form = new FormData();

    if (isSending) {
      return;
    }

    form.append('nombre', nombre || '');
    form.append('uid', driver.idClientes);
    form.append('vehiculoMarca', marca || '');
    form.append('vehiculoModelo', modelo || '');
    form.append('vehiculoColor', color || '');
    form.append('licenciaConducir', licencia || '');
    form.append('direccion', direccion || '');
    form.append('vehiculoYear', year || '');
    form.append('vehiculoPlaca', placa || '');
    form.append('vehiculoPoliza', poliza || '');
    form.append('tarjetaCirculacion', tarjetaCirculacion || '');
    form.append('comprobanteDomicilio', fileComprobante);
    form.append('licenciaFrente', fileLicenciaFrente);
    form.append('licenciaReverso', fileLicenciaReverso);
    form.append('tarjetaCirculacionFrente', fileTarjetaCirculacionFrente);
    form.append('tarjetaCirculacionReverso', fileTarjetaCirculacionReverso);
    form.append('correo', email || '');
    form.append('rfc', rfc || '');
    form.append('curp', curp || '');
    form.append('tipoVehiculoId', tipoVehiculoId);
    form.append('verificationStatus', verificationStatus || '');
    form.append('profileImage', fileProfileImage || '');
    form.append('zoneId', zoneId?.toString());

    if (password) {
      form.append('contrasenia', password);
    }
    
    // Referencias
    form.append('referenciaNombre1', referenciaNombre1 || '');
    form.append('referenciaNombre2', referenciaNombre2 || '');
    form.append('referenciaTelefono1', referenciaTelefono1 || '');
    form.append('referenciaTelefono2', referenciaTelefono2 || '');
    form.append('plusType', plusType);

    try {
      const response = await api.saveDriver(form);
      if (response.isSuccess) {
        alert("Guardado correctamente.");
      } else {
        alert(response.message);
      }
      setIsSending(false);
    } catch (e) {
      alert("Algo salió mal: " + e.message);
      setIsSending(false);
    }
  };

  const loadZones = async () => {
    try {
      const zones = await api.getZonas({withVertices: false});
      setZones(zones);
    } catch (e) {
      console.error(`[loadZones] error: ${e}`);
    }
  }

  const loadVehicleTypes = async () => {
    try {
      const types = await api.getVehicleTypes();
      setVehicleTypes(types);
    } catch (e) {
      console.error(e);
      alert("Algo salió mal: " + e.message);
    }
  };

  const handleShowDeletion = useCallback((e) => {
    setShowDeletion(true);
  }, []);

  const handleCloseDeletion = useCallback((e) => {
    setShowDeletion(false);
  }, []);

  const handleDelete = useCallback(async (e) => {
    try {
      await api.deleteUser(driver.cliente.idClientes);
      history.replace(`/drivers`);
    } catch (e) {
      console.log(`[handleDelete] ${e}`);
    }
  }, [driver]);

  const handleSelectProfile = useCallback((e) => {
    setFileProfileImage(e.target.files[0]);
  }, []);

  const handleChangePlusType = useCallback((e) => {
    setPlusType(e.target.value);
  })

  useEffect(() => {
    loadZones();
    loadVehicleTypes();
  }, []);

  return (
    <Grid container spacing={2} style={{marginBottom: 16}}>
      <Grid item xs={12} md={9}>
        <Card fullWidth={true}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xl={4} lg={4} xs={12}>
                <TextField fullWidth={true}
                  value={nombre}
                  onChange={(e) => setNombre(e.target.value)}
                  variant="outlined"
                  label="Nombre" />
              </Grid>
              <Grid item xl={4} lg={4} xs={12}>
                <TextField fullWidth={true}
                  value={apellidoPaterno}
                  onChange={(e) => setApellidoPaterno(e.target.value)}
                  variant="outlined"
                  label="Apellido paterno" />
              </Grid>
              <Grid item xl={4} lg={4} xs={12}>
                <TextField fullWidth={true}
                  value={apellidoMaterno}
                  onChange={(e) => setApellidoMaterno(e.target.value)}
                  variant="outlined"
                  label="Apellido materno" />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField fullWidth={true}
                  value={telefono}
                  onChange={(e) => setTelefono(e.target.value)}
                  variant="outlined"
                  label="Número de celular" />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField fullWidth={true}
                  value={curp}
                  onChange={(e) => setCurp(e.target.value)}
                  variant="outlined"
                  label="CURP" />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField fullWidth={true}
                  value={rfc}
                  onChange={(e) => setRfc(e.target.value)}
                  variant="outlined"
                  label="RFC" />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl variant="outlined" label="Asignar a zona" fullWidth={true}>
                  <InputLabel id="zone">Asignar a zona</InputLabel>
                  <Select
                    label="Asignar a Zona"
                    variant="outlined"
                    labelId="zone"
                    value={zoneId ? zoneId : 0}
                    onChange={(e) => setZoneId(e.target.value)}>
                    <MenuItem value={0}>- Sin zona - </MenuItem>
                    {zones.map((z, index) =>
                      <MenuItem value={z.zonaId} key={z.zonaId}>
                        {z.nombre ? z.nombre : `Zona ${z.zonaId}`}
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item={12} md={4}>
                <FormControl variant="outlined" label="Asignar a zona" fullWidth={true}>
                  <InputLabel id="zone">Estado</InputLabel>
                  <Select
                    label="Estado"
                    variant="outlined"
                    labelId="zone"
                    value={verificationStatus ?? 0}
                    fullWidth={true}
                    disableGutters={true}
                    onChange={(e) => setVerificationStatus(e.target.value)}>
                      <MenuItem value={0} dense={true}>
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item>
                            <NotInterestedIcon style={{color: red[500]}} />
                          </Grid>
                          <Grid item>
                            Bloqueado
                          </Grid>
                        </Grid>
                      </MenuItem>
                      <MenuItem value={1} dense={false}>
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item>
                            <CheckIcon style={{color: green[500]}} />
                          </Grid>
                          <Grid item>
                            Activo
                          </Grid>
                        </Grid>
                      </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item={12} md={4}>
                <FormControl variant="outlined" label="Asignar a zona" fullWidth={true}>
                  <InputLabel id="zone">Tipo</InputLabel>
                  <Select variant="outlined" isDense={true} margin="dense"
                    value={plusType}
                    onChange={handleChangePlusType}
                    defaultValue={null}
                  >
                    <MenuItem value="any">Cualquiera</MenuItem>
                    <MenuItem value="alpha">Alfa - Recolección</MenuItem>
                    <MenuItem value="beta">Beta - Entrega</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={3}>
        <Card style={{height: '100%'}}>
          <CardContent style={{ height: '100%' }}>
            <Grid container
              justify="center"
              alignItems="center"
              style={{height: '100%'}}
              direction="column"
              spacing={2}
            >
              <Grid item>
                <Avatar src={driver?.imageUrl ?? null}
                  style={{ width: 128, height: 128 }}
                />
              </Grid>
              <Grid item>
                <input type="file"
                  onChange={handleSelectProfile}
                  id="profileImage"
                  ref={profilePictureUploader} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      
      <Grid item container spacing={2}>
          <Grid item md={6} xs={12}>
            <Card style={{height: '100%'}}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6" butterBottom>
                      Domicilio
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth={true}
                    value={direccion}
                    onChange={(e) => setDireccion(e.target.value)}
                    variant="outlined"
                    label="Calle, número y colonia" />
                  </Grid>
                  
                  <Grid item xs={6}>
                    <FormControl variant="outlined">
                      <FormLabel htmlFor="file">
                        Comprobante de domicilio
                      </FormLabel>
                      <input type="file"
                        onChange={(e) => {
                          setFileComprobante(e.target.files[0]);
                        }}
                        id="comprobanteDomicilio"
                        ref={comprobanteUploader} />
                    </FormControl>
                  </Grid>

                  {driver && driver.cliente && driver.cliente.comprobanteDomicilio
                    ? (
                      <Grid item xs={6}>
                        <Button
                          variant="outlined"
                          href={`${wwwRoot}/Imagen/Docs/${driver.cliente.comprobanteDomicilio}`}
                          target="_blank"
                        >
                          Ver comprobante
                        </Button>
                      </Grid>
                    ) : null
                  }

                  {driver && driver.cliente && driver.cliente.comprobanteDomicilioExpira
                    ? (
                      <Grid item xs={12}>
                        Expira el {
                          momentz.tz(driver.cliente.comprobanteDomicilioExpira, 'utc')
                            .tz(tz)
                            .format('LL')
                        }
                      </Grid>
                    ) : null
                  }
                </Grid>
              </CardContent>
            </Card>
          </Grid>       

          <Grid item md={6} xs={12}>
            <Card fullWidth={true} style={{height: '100%'}}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6" butterBottom>
                      Licencia
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth={true}
                      value={licencia}
                      onChange={(e) => setLicencia(e.target.value)}
                      variant="outlined"
                      label="Licencia de conducir" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant="outlined">
                      <FormLabel htmlFor="comprobanteDomicilio">
                        Licencia de frente
                      </FormLabel>
                      <input type="file"
                        onChange={(e) => {
                          setFileLicenciaFrente(e.target.files[0]);
                        }}
                        id="comprobanteDomicilio"
                        ref={frenteLicenciaUploader} />
                    </FormControl>
                  </Grid>
                  {driver && driver.licenciaFrente
                    ? (
                      <Grid item xs={6}>
                        <Button
                          variant="outlined"
                          href={`${wwwRoot}/Imagen/Docs/${driver.licenciaFrente}`}
                          target="_blank"
                        >
                          Ver frente
                        </Button>
                      </Grid>
                    ) : null
                  }
                  <Grid item xs={6}>
                    <FormControl variant="outlined">
                      <FormLabel htmlFor="reversoLicencia">
                        Reverso de licencia
                      </FormLabel>
                      <input type="file"
                        onChange={(e) => {
                          setFileLicenciaReverso(e.target.files[0]);
                        }}
                        id="reversoLicencia"
                        ref={reversoLicenciaUploader} />
                    </FormControl>
                  </Grid>
                  {driver && driver.licenciaReverso
                    ? (
                      <Grid item xs={6}>
                        <Button
                          variant="outlined"
                          href={`${wwwRoot}/Imagen/Docs/${driver.licenciaReverso}`}
                          target="_blank"
                        >
                          Ver reverso
                        </Button>
                      </Grid>
                    ) : null
                  }
                </Grid>
              </CardContent>
            </Card>
          </Grid>

        </Grid>

      <Grid item xs={12} >
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Vehículo
                </Typography>
              </Grid>
              <Grid item md={4} xs={12}>
                <FormControl variant="outlined" fullWidth={true} label="Tipo de vehículo">
                  <InputLabel id="tipoVehiculo">Tipo de vehículo</InputLabel>
                  <Select
                    labelId="tipoVehiculo"
                    label="Tipo de vehículo"
                    value={tipoVehiculoId}
                    onChange={
                      (e) => setTipoVehiculoId(e.target.value)
                    }
                    renderInput={(params) =>
                      <TextField {...params}
                        label="Tipo de carro"
                        variant="outlined" />
                      }
                    >
                      {vehicleTypes ? vehicleTypes.map((item, idx) =>
                        <MenuItem value={item.tipoVehiculoId}>
                          {item.nombre}
                        </MenuItem>
                      ) : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField fullWidth={true}
                  value={marca}
                  onChange={(e) => setMarca(e.target.value)}
                  variant="outlined"
                  label="Marca" />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField fullWidth={true}
                  value={modelo}
                  onChange={(e) => setModelo(e.target.value)}
                  variant="outlined"
                  label="Modelo" />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField fullWidth={true}
                  value={color}
                  onChange={(e) => setColor(e.target.value)}
                  variant="outlined"
                  label="Color" />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField fullWidth={true}
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                  variant="outlined"
                  label="Año" />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField fullWidth={true}
                  value={placa}
                  onChange={(e) => setPlaca(e.target.value)}
                  variant="outlined"
                  label="Placa" />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField fullWidth={true}
                  value={poliza}
                  onChange={(e) => setPoliza(e.target.value)}
                  variant="outlined"
                  label="Póliza" />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField fullWidth={true}
                  value={tarjetaCirculacion}
                  onChange={(e) => setTarjetaCirculacion(e.target.value)}
                  variant="outlined"
                  label="Tarjeta de circulación" />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl variant="outlined">
                  <FormLabel htmlFor="file">
                    Frente de tarjeta de circulación
                  </FormLabel>
                  <input type="file"
                    onChange={(e) => {
                      setFileTarjetaCirculacionFrente(e.target.files[0]);
                    }}
                    id="circulacionFrente"
                    ref={circulacionFrenteUploader} />
                </FormControl>
                {driver && driver.vehiculo && driver.vehiculo.tarjetaCirculacionFrente
                  ? (
                    <div style={{marginTop: 8}}>
                      <Button
                        variant="outlined"
                        href={`${wwwRoot}/Imagen/Docs/${driver.vehiculo.tarjetaCirculacionFrente}`}
                        target="_blank"
                      >
                        Ver frente
                      </Button>
                    </div>
                  ) : null
                }
              </Grid>
              <Grid item xs={6} md={4}>
                <FormControl variant="outlined">
                  <FormLabel htmlFor="file">
                    Reverso de tarjeta de circulación
                  </FormLabel>
                  <input type="file"
                    onChange={(e) => {
                      setFileTarjetaCirculacionReverso(e.target.files[0]);
                    }}
                    id="circulacionReverso"
                    ref={circulacionReversoUploader} />
                </FormControl>
                {driver && driver.vehiculo && driver.vehiculo.tarjetaCirculacionReverso
                  ? (
                    <div style={{marginTop: 8}}>
                      <Button
                        variant="outlined"
                        href={`${wwwRoot}/Imagen/Docs/${driver.vehiculo.tarjetaCirculacionReverso}`}
                        target="_blank"
                      >
                        Ver reverso
                      </Button>
                    </div>
                  ) : null
                }
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

     <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Referencias
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={referenciaNombre1}
                  onChange={(e) => setReferenciaNombre1(e.target.value)}
                  fullWidth={true}
                  variant="outlined"
                  label="Nombre referencia"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={referenciaTelefono1}
                  onChange={(e) => setReferenciaTelefono1(e.target.value)}
                  fullWidth={true}
                  variant="outlined"
                  label="Teléfono referencia"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={referenciaNombre2}
                  onChange={(e) => setReferenciaNombre2(e.target.value)}
                  fullWidth={true}
                  variant="outlined"
                  label="Nombre referencia"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={referenciaTelefono2}
                  onChange={(e) => setReferenciaTelefono2(e.target.value)}
                  fullWidth={true}
                  variant="outlined"
                  label="Teléfono referencia"
                />
              </Grid>
            </Grid>
          </CardContent> 
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Inicio de sesión
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth={true}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  variant="outlined"
                  inputProps={{form: {autocomplete: 'off'}}}
                  label="Correo electrónico" />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth={true}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  variant="outlined"
                  autoComplete="off"
                  inputProps={{autocomplete: 'new-password', form: {autocomplete: 'off'}}}
                  label="Contraseña" />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      
      <Grid item xs={12} style={{paddingTop: 24}}>
        <Grid container>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<DeleteForeverIcon />}
              onClick={handleShowDeletion}
            >Borrar chofer</Button>
          </Grid>
          <Grid item xs={6} style={{textAlign: "right"}}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setIsSending(true);
                save();
              }}>
              {
                isSending
                  ? (<CircularProgress color="white" />)
                  : "Guardar cambios"
              }
            </Button>
          </Grid>
        </Grid>
        
      </Grid>
      
      <Dialog open={showDeletion} onClose={() => setShowDeletion(false)}>
        <DialogTitle>¿Borrar chofer?</DialogTitle>
        <DialogContent>
          ¿Estás seguro de que quieres borrar al chofer <strong>{nombre}</strong>?
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleCloseDeletion}>
            No, no borrar
          </Button>
          <Btn color="error" onClick={handleDelete}>
            Sí, borrar
          </Btn>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}