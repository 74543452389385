import React, { useCallback, useEffect, useRef, useState } from 'react';
import API from '../../api';
import {
  Container,
  Card,
  CardContent,
  Grid,
  Box,
  Paper,
  Typography,
  TextField,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import {
  ErrorOutline as ErrorOutlineIcon,
  ArrowForward as ArrowForwardIcon,
  Check as CheckIcon,
  Warning as WarningIcon,
  AccessTime as AccessTimeIcon,
} from '@material-ui/icons';
import DeliveryTile from '../../components/delivery-tile';
import { DeliveryStatus } from '../../models/delivery';

const api = API.instance();

export default function ReceptionController() {
  const [tracking, setTracking] = useState('');
  const [loading, setLoading] = useState('');
  const [trackingSearch, setTrackingSearch] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [delivery, setDelivery] = useState(null);
  const [statusMessage, setStatusMessage] = useState('');
  const [succeeded, setSucceeded] = useState(false);
  const [deliveries, setDeliveries] = useState(null);
  const refReading = useRef();

  const handleInputChange = useCallback((e) => {
    setTracking(e.target.value);
    setDelivery(null);
  }, []);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();

    setLoading(true);
    setErrorMessage('');
    setTrackingSearch(tracking);
  }, [tracking]);

  useEffect(() => {
    if (!trackingSearch)
      return;

    async function getit() {
      try {
        const response = await api.putReceiveInCentre(tracking);
        
        setSucceeded(true);
        setDelivery(response.result ?? null);
        setStatusMessage(response.message);
        setTrackingSearch('');
        setSucceeded(response.isSuccess);
        setLoading(false);

        if (response.isSuccess) {
          setDeliveries(deliveries.filter(d => (
            (d.id ?? d.viajeId) !== (
              response.result.viajeId ?? response.result.id
            )
          )));
        } else {
          setErrorMessage(response.message);
        }
      } catch (e) {
        alert("Algo salió mal: " + e.message);
      } finally {
        setLoading(false);
        setTracking('');
      }
    }
    getit();
  }, [trackingSearch]);

  useEffect(() => {
    async function loadPackages() {
      try {
        const response = await api.getDeliveries({
          status: DeliveryStatus.TowardDistributionCenter,
          includeEvents: true,
          includeZones: true,
          includeDriver: true,
          onlyPlus: true
        });
        setDeliveries(response.result);
      } catch (e) {
        console.log(e.message);
      }
    }

    loadPackages();
  }, []);

  return (
    <Container component="main">
      <h1>Recepción de paquetes en centro</h1>
      <Grid container direction="column" spacing={3} style={{marginBottom: 12}}>
        <Grid item>
          <Card>
            <CardContent>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <form onSubmit={handleSubmit}>
                    <TextField
                      maxLength={12}
                      disabled={loading ? true : false}
                      inputRef={refReading}
                      variant="outlined"
                      value={tracking}
                      onChange={handleInputChange}
                      inputProps={{ maxLength: 12 }}
                      label="Número de guía" />
                  </form>
                </Grid>
                {loading ? (
                  <Grid item>
                    <Grid container direction="row" spacing={2}>
                      <Grid item>
                        <CircularProgress size={24} />
                      </Grid>
                      <Grid item>
                        Cargando información.
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  errorMessage ? (
                    <Grid item>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item>
                          <ErrorOutlineIcon />
                        </Grid>
                        <Grid>
                          {errorMessage}
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : null
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {delivery ? (
          <Grid item>
            <Card>
              <CardContent>
                <Box display="flex" direction="row">
                  <Box width={300}>
                    <DeliveryTile delivery={delivery} />
                  </Box>
                  <Box flexGrow={1}>
                    <Grid container direction="column" spacing={3}>
                      <Grid item>
                        <Typography variant="subtitle1" size={1}>
                          Zona de entrega
                        </Typography>
                        <Typography variant="h4" size={1}>
                          {delivery?.destination?.zone?.name
                            ?? `Zona ${delivery?.destination?.zone?.id}`
                          }
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1" size={1}>
                          Estado
                        </Typography>

                        <Typography variant="h5" size={1} color={succeeded ? "success" : "error"}>
                          <Grid container spacing={1} alignItems="center">
                            <Grid item>
                              {succeeded ? <CheckIcon /> : <WarningIcon />}
                            </Grid>
                            <Grid item>
                              {statusMessage}
                            </Grid>
                          </Grid>
                        </Typography>
                        
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ) : null}
      </Grid>
      
      <Typography variant="h5" gutterBottom={true}>
        Paquetes en espera confirmar recepción
      </Typography>
      <Grid item>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell width={10}>ID</TableCell>
                <TableCell>Dirección</TableCell>
                <TableCell>Zona origen</TableCell>
                <TableCell>Zona destino</TableCell>
                <TableCell>Recolector</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {deliveries && deliveries.length > 0 ? deliveries.map(d => {
                return (
                  <TableRow key={d.id ?? d.viajeId}>
                    <TableCell>{d.id ?? d.viajeId}</TableCell>
                    <TableCell>
                      <Grid container>
                        <Grid item>
                          {d.origin?.name ?? d.origenNombre}
                        </Grid>
                        <Grid item>
                          <ArrowForwardIcon style={{fontSize: 12}} />
                        </Grid>
                        <Grid item>
                          {d.destination?.name ?? d.destinoNombre}
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      {d.origin?.zone?.name ?? d.originZone?.name}
                    </TableCell>
                    <TableCell>
                      {d.destination?.zone?.name ?? d.destinationZone?.name}
                    </TableCell>
                    <TableCell>
                      {d.driver?.nombre}
                    </TableCell>
                  </TableRow>
                )
              }) : (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Grid container spacing={2}>
                      <Grid item>
                        <CheckIcon style={{fontSize: 30}} />
                      </Grid>
                      <Grid item>
                        <Typography variant="h6">
                          No hay paquetes pendientes de confirmar recepción.
                        </Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>  
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Container>
  );
}

function ZoneCard({ zone }) {
  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item>
            <AccessTimeIcon style={{fontSize: 64}} />
          </Grid>
          <Grid item>
            <Typography variant="h6">
              {zone?.name ?? zone.nombre}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

