import React from 'react';
import Container from '@material-ui/core/Container';
import { withRouter } from "react-router-dom";
import API from '../api';
import EditIcon from '@material-ui/icons/Edit';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import {
  List,
  Paper,
  Grid,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
} from '@material-ui/core';

const api = API.instance();

class VehiclesTypesScreen extends React.Component {
  state = {
    types: [],
    isLoading: true
  }

  componentDidMount() {
    this.load();
  }

  async load() {
    const update = { isLoading: false };

    try {
      update.types = await api.getVehicleTypes({
        showInactive: this.state.showInactive ? 1 : 0
      });
      console.log(update.types);
    } catch (e) {
      console.error(e);
    }

    this.setState(update);
  }

  async toggleShowInactive() {
    this.setState({
      isLoading: true,
    }, () => {
      this.load();
    });
  }

  render() {
    const {
      types,
      isLoading,
    } = this.state;
    let child;

    if (isLoading) {
      child = (
        <CircularProgress container={<div style={{ padding: 32 }} />} />
      );
    } else {
      child = (
        <React.Fragment>
          <List>
            {
              types.map((vtype, index) => {
                return (
                  <ListItem divider={true} dense={false} key={index}>
                    <ListItemIcon>
                      <AirportShuttleIcon />
                    </ListItemIcon>
                    <ListItemText
                      secondary={
                        vtype.capacidadM3
                          ? <span>
                            Capacidad de caja: {vtype.capacidadM3}
                            m<sup>3</sup>
                            </span>
                          : ''
                      }>
                      {vtype.nombre}
                    </ListItemText>
                    <ListItemSecondaryAction>
                      <IconButton variant="outlined"
                        onClick={
                          () => {
                            console.log(`/vehicle/type/${vtype.tipoVehiculoId}`);
                            this.props.history.push(`/vehicle/type/${vtype.tipoVehiculoId}`)
                          }
                        }>
                        <EditIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              })
            }
          </List>
        </React.Fragment>
      );
    }

    return (
      <Container component="main">
        <Grid container>
          <Grid item sm={6}>
            <h1>Tipos de vehículos</h1>
          </Grid>
          <Grid item sm={6} style={{textAlign: 'right'}}>

          </Grid>
        </Grid>
        <Paper>
          {child}
        </Paper>
        {types
          ? <div style={{ paddingTop: 16, color: 'grey', textAlign: 'center' }}>
            {`${types.length} tipos de vehículos`}
          </div> : null}
      </Container>
    );
  }
}

export default withRouter(VehiclesTypesScreen);
