import React, { useState, useCallback, useRef } from 'react';
import {
  Select,
  Button,
  Grid,
  MenuItem,
  Checkbox,
  CircularProgress,
  TextField,
} from '@material-ui/core';
import {
  makeStyles
} from '@material-ui/core/styles';

import API from '../api';
const api = new API();

export default function FeatureValue(props) {
  const classes = useStyles();
  const {
    feature,
    editing,
    onEdit,
    onChange,
    onChangeText,
    options,
    value,
    openMode,
    customValueRef,
  } = props;
  let child = null;
  let input = null;

  const handleChange = useCallback(e => {
    if (onChange)
      onChange(parseInt(e.target.value));
  }, []);

  switch (feature.dataType) {
    case "color":
      child = (
        <div style={{
          width: 24, height: 24, flexGrow: 0,
          marginLeft: 8,
          backgroundColor: feature.visualValue,
          borderRadius: 6
        }} />
      );
      break;
  }

  if (editing) {
    if (feature.isOpen) {
      input = <TextField
        variant="outlined"
        margin="dense"
        inputRef={customValueRef}
        defaultValue={feature?.customValue ?? feature?.value}
        />;
    } else {
      switch (feature.dataType) {
        case "boolean":
          input = <Checkbox />;
          break;
        
        default:
          input = <Select
            variant="outlined"
            value={value ?? 0}
            onChange={handleChange}
            margin="dense"
            style={{minWidth: 150}}
          >
            {options?.map(o => {
              return (
                <MenuItem value={o.id} key={o.id}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item style={{flexGrow: 1}}>{o.value}</Grid>
                    {feature?.dataType === "color"
                      ? (
                        <div className={classes.colorSquare}
                          style={{backgroundColor: o.visualValue}} />
                      ) : null
                    }
                  </Grid>
                </MenuItem>
              );
            }) ?? null}
          </Select>;
          break;
      }
    }

    return input;
  }

  return (
    <button className={classes.root} onClick={onEdit}>
      <div>
        {feature?.option?.value ?? feature?.customValue ?? feature?.value}
      </div>
      {child}
    </button>
  );
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "inherit",
    padding: 5,
    paddingLeft: 16,
    paddingRight: 16,
    border: "solid 1px #f0f0f0",
    borderRadius: 4,
    cursor: "pointer"
  },
  colorSquare: {
    width: 16,
    height: 16,
    borderRadius: 4
  }
});