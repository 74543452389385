import React, {
  useCallback,
  useEffect,
  useReducer,
  useState,
  Fragment,
} from 'react';
import {
  Container,
  Grid,
  Chip,
  Card as BaseCard,
  CardContent,
  TextField,
  CircularProgress,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  FormControl,
  FormControlLabel,
  InputAdornment,
  OutlinedInput,
  IconButton,
  Avatar,
  Checkbox,
  Button,
  Divider,
  Paper,
  RadioGroup,
  Radio,
  FormLabel,
  ListItemSecondaryAction,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import API from '../../api';

import CategorySelect from '../../components/category-select';
import ButtonWithMessage from '../../components/button-with-message';
import FeatureTile from '../../components/feature_tile';
import NumberFormat from 'react-number-format';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  Search as SearchIcon,
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
  OpenInNew as OpenInNewIcon,
} from '@material-ui/icons';
import VariantsGrid from '../../components/variants_grid';
import VariantEditor from '../../components/variant_editor';
import ImagesUploader from '../../components/images_uploader';

const Money = (props) => {
  return (
    <NumberFormat
      thousandSeparator={true}
      decimalScale={2}
      fixedDecimalScale={2}
      displayType="text"
      prefix="$"
      {...props}
    />
  );
}

const api = API.instance();

const initialState = {
  isLoading: true,
  isSaving: false,
  isSaved: false,

  categories: [],
  product: null,
  editingFeaturesIds: [],
  featuresOptions: {},
  newFeatures: [],
  currentFeaturesIds: [],
  selectedVariant: null,
  params: {},

  // Fields
  title: '',
  unitPrice: '',
  availableQuantity: '',
  description: '',
  isStored: false,
  isSendable: false,
  status: 0,
  categoryId: null
};

const Action = {
  LOAD: 1,
  SELECT_CATEGORY: 2,
  ADD_IMAGE: 3,
  DELETE_IMAGE: 4,
  UPDATE_FIELD: 5,
  START_SAVING: 6,
  SAVE_SUCCESS: 7,
  SELECT_USER: 8,
  ADD_FEATURE: 9,
  UPDATE_FEATURE: 10,
  CANCEL_EDITING_FEATURE: 11,
  SAVED_FEATURE: 12,
  DELETED_FEATURE: 13,
  SELECT_VARIANT: 14,
  BACK_TO_VARIANTS_LIST: 15,
  UPDATE_VARIANT: 16,
  CREATE_VARIANT: 17,
  CHECK_CONDITION: 18
};

function reducer(state, action) {
  switch (action.type) {
    case Action.LOAD:
      console.log(action.params);
      const { product: p, categories: c } = action;
      return { ...state,
        product: p,
        categories: c,
        isLoading: false,
        
        // Fields
        title: p.title,
        unitPrice: p.unitPrice,
        availableQuantity: p.availableQuantity,
        description: p.description,
        user: p.user,
        isStored: p.isStored,
        isSendable: p.isSendable,
        status: p.status,
        sku: p.sku,
        suggestedSku: p.suggestedSku,
        categoryId: action.product?.category?.id,
        brandName: action.product?.brand?.name,
        params: action.params,
        
        currentFeaturesIds: p?.features?.map(i => i.featureId) ?? []
      };
    case Action.SELECT_CATEGORY:
      return { ...state,
        categoryId: action.categoryId,
      };
    case Action.ADD_IMAGE:
      const { images } = state.product;
      return { ...state,
        product: { ...state.product,
          images: [ ...images, action.image ]
        }
      };
    case Action.DELETE_IMAGE:
      return { ...state,
        product: { ...state.product,
          images: state.product.images.filter(
            i => i.id !== action.imageId
          )
        }
      };
    case Action.START_SAVING:
      return { ...state, isSaving: true, isSaved: false };
    case Action.UPDATE_FIELD:
      const { field, value } = action;
      return { ...state, [field]: value };
    case Action.SAVE_SUCCESS:
      return { ...state, isSaving: false, isSaved: true };
    case Action.SELECT_USER:
      return { ...state, user: action.user };
      
    case Action.ADD_FEATURE:
      const tempId = parseInt(Math.random() * 0xFFFFFF);
      const update = { ...state,
        product: {...state.product,
          features: [...(state.product.features ?? []),
            {
              isNew: true,
              id: tempId,
              isTemporal: true,
              isOpen: action.open ? true : false,
              featureId: null,
            }
          ]
        },
        editingFeaturesIds: [...state.editingFeaturesIds, tempId]
      };
      return update;
      
    case Action.UPDATE_FEATURE:
      const features = state.product.features.map(
        i => i.id === action.tempFeature.id ? {
          ...action.feature,
          isTemporal: true,
          isOpen: action.tempFeature.isOpen,
          featureId: action.feature?.id
        } : i
      );
      return {
        ...state,
        product: {
          ...state.product,
          features,
        },
        featuresOptions: {
          ...state.featuresOptions,
          [action.feature.id]: action.options
        },
        editingFeaturesIds: state.editingFeaturesIds.map(
          id => id === action.tempFeature.id ? action.feature.id : id
        ),
        currentFeaturesIds: [...state.currentFeaturesIds, action.feature.id]
      };

    case Action.START_EDITING_FEATURE:
      return {...state,
        editingFeaturesIds: [...state.editingFeaturesIds, action.featureId],
        featuresOptions: {
          ...state.featuresOptions,
          [action.featureId]: action.options
        }
      };
    case Action.CANCEL_EDITING_FEATURE:
      const feature = state.product?.features?.filter(
        i => i.id === action.id
      )[0];
      
      if (feature) {
        return {
          ...state,
          editingFeaturesIds: state.editingFeaturesIds
            .filter(i => i !== action.id),
          product: {
            ...state.product,
            features: feature.isTemporal
              ? state.product.features.filter(
                i => i.id !== action.id
              ) : state.product.features
          },
          currentFeaturesIds: state.currentFeaturesIds.filter(fid => fid !== feature.featureId)
        };
      } else {
        return state;
      }

    case Action.CREATE_VARIANT:
      return {
        ...state,
        product: {
          ...state.product,
          variants: [...state.product.variants, action.variant]
        },
        selectedVariant: action.variant
      };

    case Action.SAVED_FEATURE:
      return {
        ...state,
        editingFeaturesIds: state.editingFeaturesIds
          .filter(id => id !== action.feature.id),
        product: {
          ...state.product,
          features: state.product.features
            .map(f => f.id === action.feature.featureId ? action.feature : f)
        }
      };

    case Action.DELETED_FEATURE:
      return {
        ...state,
        product: {
          ...state.product,
          features: state.product.features
            .filter(i => i.id !== action.feature.id),
          editingFeaturesIds: state.editingFeaturesIds
            .filter(id => id !== action.feature.id),
        },
        currentFeaturesIds: state.currentFeaturesIds
          .filter(id => id !== action.feature.featureId)
      };

    case Action.SELECT_VARIANT:
      return {
        ...state,
        selectedVariant: action.variant,
      };

    case Action.BACK_TO_VARIANTS_LIST:
      return {
        ...state,
        selectedVariant: null
      };

    case Action.UPDATE_VARIANT:
      return {
        ...state,
        product: {
          ...state.product,
          variants: state.product.variants
            .map(v => v.id === action.variant.id ? action.variant : v)
        }
      };

    case Action.CHECK_CONDITION:
      return {
        ...state,
        product: {
          ...state.product,
          condition: action.value
        }
      };

    default:
      return state;
  }
}

function ProductScreen({ match }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleSave = useCallback(async () => {
    if (state.isSaving) {
      return;
    }
    
    dispatch({ type: Action.START_SAVING });

    try {
      await api.saveProduct(
        state.product?.id,
        {
          title: state.title,
          unitPrice: state.unitPrice,
          description: state.description,
          availableQuantity: state.availableQuantity,
          userId: state.user?.id,
          isStored: state.isStored,
          isSendable: state.isSendable,
          categoryId: state.categoryId,
          status: state.status,
          sku: state.sku,
          condition: "new",
          brandName: state.brandName
        }
      );
      dispatch({ type: Action.SAVE_SUCCESS });
    } catch (e) {
      console.log(`[handleSave] Error: ${e}`);
      window.alert(e.toString());
    }
  }, [state]);

  const handleAddFeature = useCallback(() => {
    dispatch({type: Action.ADD_FEATURE});
  }, []);

  const handleAddOpenFeature = useCallback(() => {
    dispatch({type: Action.ADD_FEATURE, open: true});
  }, []);

  const handleOnEditFeature = useCallback(async (featureId) => {
    try {
      const options = await api.getFeatureOptions({ featureId });
      dispatch({
        type: Action.START_EDITING_FEATURE,
        featureId,
        options,
      });
    } catch (e) {
      alert(e.message);
    }
  }, []);

  const handleSelectVariant = useCallback(variant => {
    dispatch({
      type: Action.SELECT_VARIANT,
      variant,
    });
  }, []);

  const handleCreateVariant = useCallback(async () => {
    try {
      const variant = await api.createProductVariant(state.product.id);
      dispatch({type: Action.CREATE_VARIANT, variant});
    } catch (e) {

    }
  }, [state.product]);

  const handleUpdateVariant = useCallback(variant => {
    dispatch({
      type: Action.UPDATE_VARIANT,
      variant,
    });
  }, []);

  const handleChangeCondition = useCallback(e => {
    dispatch({
      type: Action.CHECK_CONDITION,
      value: e.target.value
    });
  }, []);

  useEffect(() => {
    async function getProduct(op) {
      const values = await api.getValues();
      const categories = await api.getCategories();
      const product = await api.getProductById(match.params.id);

      dispatch({
        type: Action.LOAD,
        categories,
        product,
        params: values,
      });
    }
    getProduct();
  }, [match.params.id]);

  const {
    product,
  } = state;

  if (state.isLoading) {
    return ('Loading');
  }

  // Cálculo del precio
  const price = state.unitPrice ?? product?.unitPrice ?? 0;
  const deliveryPrice = state.params.EstimatedDeliveryPrice ?? 0;
  const sellCommission = price * state.params.SaleCommissionRelative + state.params.SaleCommissionFixed;
  const gain = price - sellCommission;

  const initialConfig = {
    namespace: 'MyEditor',
    theme: editorTheme,
    onError,
  };

  return (
    <Container>
      <h1>Producto</h1>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Card style={{height: "100%"}}>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    variant="outlined"
                    label="Título"
                    fullWidth={true}
                    defaultValue={state.title}
                    onBlur={e => dispatch({
                      type: Action.UPDATE_FIELD,
                      field: 'title',
                      value: e.target.value,
                    })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ReactQuill
                    theme="snow"
                    defaultValue={state.description}
                    style={{height: 200, marginBottom: 48}}
                    onChange={value => dispatch({
                      type: Action.UPDATE_FIELD,
                      field: 'description',
                      value: value,
                    })}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    margin="dense"
                    variant="outlined"
                    label="Cantidad disponible"
                    fullWidth={true}
                    defaultValue={state.availableQuantity ?? "1"}
                    onBlur={e => dispatch({
                      type: Action.UPDATE_FIELD,
                      field: 'availableQuantity',
                      value: e.target.value,
                    })}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    margin="dense"
                    variant="outlined"
                    label="Marca"
                    fullWidth={true}
                    defaultValue={state.brandName}
                    onBlur={e => dispatch({
                      type: Action.UPDATE_FIELD,
                      field: 'brandName',
                      value: e.target.value,
                    })}
                  />
                </Grid>
                <Grid item xs={12} container direction="column">
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.isSendable}
                          onChange={(e) => dispatch({
                            type: Action.UPDATE_FIELD,
                            field: 'isSendable',
                            value: e.target.checked
                          })}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label="Puede ser enviando por el vendedor."
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.status === 1}
                          onChange={(e) => dispatch({
                            type: Action.UPDATE_FIELD,
                            field: 'status',
                            value: e.target.checked ? 1 : 0
                          })}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label="El producto está disponible para la venta."
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.isStored}
                          onChange={(e) => dispatch({
                            type: Action.UPDATE_FIELD,
                            field: 'isStored',
                            value: e.target.checked
                          })}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label="Resguardo en bodega"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={4} style={{height: "100%"}}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Grid container direction="column" spacing={1}>
                    {state.categoryId ? (
                      <Grid item>
                        <Typography>Categoría</Typography>
                        <Typography variant="h6">
                          {product?.category?.name ?? "Sin categoría"}
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid item>
                        <CategorySelect
                          categories={state.categories}
                          selected={state.categoryId}
                          onChange={(e) => dispatch({
                            type: Action.SELECT_CATEGORY,
                            categoryId: e.target.value,
                          })}
                        />
                      </Grid>
                    )}

                    <Grid item>
                      {state.isStored ? (
                        <Grid item>
                          <div>SKU</div>
                          <Typography variant="h5">
                            {state.sku ?? state.suggestedSku}
                          </Typography>
                        </Grid>
                      ) : null}
                    </Grid>

                    <Grid item>
                      <Button
                        href={`/products/${product.id}/print-sku`}
                        target="_blank"
                        component="a"
                        variant="outlined"
                        color="primary"
                        endIcon={<OpenInNewIcon />}>
                        Imprimir etiqueta de SKU
                      </Button>
                    </Grid>
                    
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card style={{height: '100%', padding: 16}}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h6">Cliente vendedor</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <UserSelector
                      user={state.user}
                      onChange={user => dispatch({
                        type: Action.SELECT_USER,
                        user,
                      })}
                      onClear={() => dispatch({
                        type: Action.SELECT_USER,
                        user: null
                      })}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>


        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5">Precio</Typography>
        </Grid>

        <Grid item xs={6}>
          <Card>
            <div style={{padding: 16, paddingBottom: 0}}>
            <TextField
                margin="dense"
                variant="outlined"
                label="Precio unitario"
                fullWidth={true}
                defaultValue={state.unitPrice}
                onBlur={e => dispatch({
                  type: Action.UPDATE_FIELD,
                  field: 'unitPrice',
                  value: e.target.value,
                })}
              />
            </div>
            <List dense>
              <ListItem divider>
                <ListItemText>Precio del producto</ListItemText>
                <ListItemSecondaryAction>
                  <Money value={price} />
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem divider>
                <ListItemText>Costo de envío</ListItemText>
                <ListItemSecondaryAction>
                  <Money value={deliveryPrice} />
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem divider>
                <ListItemText
                  secondary={`(Precio x ${state.params.SaleCommissionRelative * 100}%) + $${state.params.SaleCommissionFixed}`}>Comisión por venta</ListItemText>
                <ListItemSecondaryAction>
                  <Money value={sellCommission} />
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem divider>
                <ListItemText>Ganancia por venta</ListItemText>
                <ListItemSecondaryAction>
                  <Money value={gain} />
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5">Características</Typography>
        </Grid>

        <Grid item xs={12}>
          <Paper style={{borderRadius: 16}}>
            <div style={{overflow: "hidden", padding: 8, paddingLeft: 16, color: "#999"}}>
              Se encontraron {product?.features?.length ?? 0} características.
            </div>
            <Divider />
            {product?.features?.map((f, index) => {
              return (
                <Fragment key={index}>
                  <FeatureTile
                    feature={f}
                    editing={state.editingFeaturesIds.indexOf(f.id) > -1}
                    onEdit={handleOnEditFeature}
                    options={state.featuresOptions[f.id?.toString() ?? ""] ?? []}
                    value={f.optionId}
                    categoryId={product?.category?.id}
                    product={product}
                    onChangeFeature={(chosenFeature) => dispatch({
                      type: Action.UPDATE_FEATURE,
                      feature: chosenFeature,
                      options: chosenFeature.options,
                      tempFeature: f,
                    })}
                    onCancel={(id) => dispatch({
                      type: Action.CANCEL_EDITING_FEATURE,
                      id,
                    })}
                    onSaved={(f) => dispatch({
                      type: Action.SAVED_FEATURE,
                      feature: f
                    })}
                    onDeleted={() => dispatch({
                      type: Action.DELETED_FEATURE,
                      feature: f
                    })}
                    ignoreIds={state.currentFeaturesIds}
                  />
                  <Divider />
                </Fragment>
              );
            })}
            <div style={{overflow: "hidden", padding: 8, paddingLeft: 16, color: "#999"}}>
              <Grid container spacing={1}>
                <Grid item>
                  <Button startIcon={<AddIcon />} onClick={handleAddFeature}>
                    Crear campo de opción
                  </Button>
                </Grid>
                <Grid item>
                  <Button startIcon={<AddIcon />} onClick={handleAddOpenFeature}>
                    Crear campo abierto
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5">Imágenes</Typography>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <ImagesUploader
                images={product?.images ?? null}
                productId={product?.id}
                onSelectImage={(image) => dispatch({
                  type: Action.ADD_IMAGE,
                  image,
                })}
                onDeleteImage={(imageId) => dispatch({
                  type: Action.DELETE_IMAGE,
                  imageId,
                })}
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={1}>
            {state.selectedVariant ? (
              <Grid item>
                <Button color="default" startIcon={<ArrowBackIcon />}
                  onClick={() => dispatch({type: Action.BACK_TO_VARIANTS_LIST})}
                >
                  Volver a
                </Button>
              </Grid>
            ) : null}
            <Grid item>
              <Typography variant="h5">Variantes</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          {state.selectedVariant ? (
            <Paper style={{borderRadius: 16}}>
              <VariantEditor
                variant={state.selectedVariant}
                product={product}
                onChange={handleUpdateVariant} />
            </Paper>
          ) : (
            <VariantsGrid
              variants={product?.variants}
              defaultImageUrl={product?.mainImage?.thumbnailUrl}
              onSelect={handleSelectVariant}
              onCreate={handleCreateVariant} />
          )}
          
        </Grid>

        <Grid item xs={12} container align="center">
          <ButtonWithMessage
            color="primary"
            onClick={handleSave}
            isSaving={state.isSaving}
            message={state.isSaved ? "Guardado" : null}
          >Guardar producto</ButtonWithMessage>
        </Grid>
      </Grid>
    </Container>
  );
}

export function UserSelector({
  user,
  onChange,
  onClear,
  floating = false
}) {
  const [value, setValue] = useState('');
  const [users, setUsers] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  const handleSubmit = useCallback(e => {
    e.preventDefault();

    setIsSearching(true);

    const options = {
      search: value,
      resultsPerPage: 5,
      whereHas: ["rackspace"],
      include: ["defaultAccount"]
    };

    api.getUsers(options).then(u => {
      setUsers(u);
      setIsSearching(false);
    }).catch(err => {
      console.log(`submit: ${err}`);
      setIsSearching(false);
    });

    return false;
  }, [value]);

  const handleSelect = useCallback(user => {
    setUsers([]);
    setValue(user?.name ?? "");
    if ('function' === typeof onChange) {
      onChange(user);
    }
  }, [onChange]);

  const handleChange = useCallback(e => {
    const val = e.target.value;
    setValue(val);

    if (!val || val.trim() === '') {
      if ('function' === typeof onClear) {
        onClear();
      }
    }
  }, []);

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <Grid container direction="row" alignItems="center" spacing={1}>
          <Grid item>
            <FormControl
              variant="outlined"
              label="Usuario"
            >
              <OutlinedInput
                margin="dense"
                value={value}
                onChange={handleChange}
                placeholder="Busca un usuario"
                endAdornment={
                  <InputAdornment position="end">
                    <Grid container>
                      <IconButton type="submit" TouchRippleProps={{type: "submit"}} >
                        {isSearching
                          ? <CircularProgress size={16} />
                          : <SearchIcon />}
                      </IconButton>
                    </Grid>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          {user ? (
            <Grid item>
              <CheckCircleOutlineIcon />
            </Grid>
          ) : null}
        </Grid>
      </form>
      <List
        style={floating ? {
          position: "absolute",
          backgroundColor: "white",
          boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.25)",
          zIndex: 100,
          borderBottomLeftRadius: 16,
          borderBottomRightRadius: 16,
          minWidth: "500px",
          display: users && users.length > 0 ? "block" : "none"
        } : {

        }}
      >
        {users && users.length > 0 ? ( 
          users?.map(u =>
            <ListItem button key={u.id} dense={true} onClick={() => handleSelect(u)}>
              <ListItemIcon>
                <Avatar src={u.imageUrl} />
              </ListItemIcon>
              <ListItemText
                primary={`${u.name} (ID: ${u.id})`}
                secondary={u.defaultAccount?.name} 
              />
              <ListItemSecondaryAction>
                <Chip label={`${u.rackSpace?.rack?.name} / ${u.rackSpace?.line} / ${u.rackSpace?.column}`} />
              </ListItemSecondaryAction>
            </ListItem>
          )
        ) : (
          user ? (
            <ListItem disableGutters={true}>
              <ListItemIcon>
                <Avatar src={user.imageUrl} />
              </ListItemIcon>
              <ListItemText
                primary={`${user.name}`}
                secondary={`ID: ${user.id}`}
              />
              <ListItemSecondaryAction>
                <Chip label={`${user.rackSpace?.rack?.name} / ${user.rackSpace?.line} / ${user.rackSpace?.column}`} />
              </ListItemSecondaryAction>
            </ListItem>
          ) : null
        )}
      </List>
    </Fragment>
  )
}

function onError(error) {
  console.error(error);
}

const editorTheme = {
  // Theme styling goes here
  // ...
};

export const Card = withStyles({
  root: {
    borderRadius: 16
  }
})(BaseCard);

export default ProductScreen;