import React, { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import API from '../api';
import { DeliveryPhase, DeliveryStatus } from '../models/delivery';
import { grey, red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  Grid,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  IconButton,
  Button,
} from '@material-ui/core';

import PhaseButton from './buttons/phase';
import PlaceIcon from '../components/place-icon';
import PhaseModal from '../components/modals/phase';
import {
  MoreHoriz as MoreHorizIcon,
  Route as RouteIcon,
  Print as PrintIcon,
  NewReleases as NewReleasesIcon,
  OpenInNew as OpenInNewIcon,
} from '@material-ui/icons';
import RequestDialog from '../components/request-dialog';

const REFRESH_INTERVAL = 3000;
const NEW_INTERVAL = 10;

class State {
  static preparing = 0;
}

const api = API.instance();
const initialState = {
  lastDate: null,
  counter: 1,
  deliveries: [],
  deliveriesBacklog: [],
  reloading: false,
  loadedFirst: false,
  showPhaseModal: false,
  showPhaseDelivery: null,
  preparing: true,
  userState: null,
  loading: true,

  // Filters
  filters: null,

  // Flags
  showRequestDialog: false,
  requestDialogDelivery: null,
  loadingDeliveryRequestId: null,
};

function filterDeliveries(deliveries, filters) {
  return deliveries.filter(d => {
    if (filters?.phaseId === DeliveryPhase.collect) {
      if (!DeliveryPhase.isCollecting(d.estatusEntregaId))
        return false;
      if (filters.zoneId)
        if (d.originZone?.id !== filters?.zoneId)
          return false;
    } else if (filters?.phaseId === DeliveryPhase.deliver) {
      if (!DeliveryPhase.isDelivering(d.estatusEntregaId))
        return false;
      if (filters.zoneId)
        if (d.destinationZone?.id !== filters?.zoneId)
          return false;
    }
    return true;
  });
}

function reducer(state, action) {
  switch (action.type) {
    case Action.newDeliveries:
      // Cuando llegan nuevos envíos más nuevos al último obtenido.
      const { deliveries } = action;
      const newIds = deliveries.map(d => d.id);
      const update = { ...state, loading: false };
      const isEmpty = state.deliveries.length === 0;
      
      if (deliveries.length > 0) {
        const now = (new Date().getTime() / 1000);
        const until = isEmpty ? 0 : now + NEW_INTERVAL;
        update.lastDate = deliveries[0].fechaActualizacion;
        update.deliveriesBacklog = [
          ...deliveries.map(d => ({ ...d, newUntil: until })),
          ...state.deliveriesBacklog
            .filter(d => newIds.indexOf(d.id) === -1)
        ];

        update.deliveries = filterDeliveries(
          update.deliveriesBacklog,
          state.filters
        );
      }

      return update;

    case Action.filter:
      return {
        ...state,
        filters: action.filters,
        deliveries: filterDeliveries(
          state.deliveriesBacklog,
          action.filters
        )
      };

    case Action.showPhaseModal:
      return {
        ...state,
        showPhaseModal: true,
        showPhaseDelivery: action.delivery
      };

    case Action.closePhaseModal:
      return { ...state, showPhaseModal: false };
      
    case Action.startReloading:
      return { ...state, loading: true };
    
    case Action.loadFirst:
      return { ...state, firstLoaded: true };

    case Action.loadRequestDialog:
      return { ...state, loadingDeliveryRequestId: action.deliveryId };

    case Action.showRequestDialog:
      return {
        ...state,
        showRequestDialog: true,
        requestDialogDelivery: action.delivery
      };

    case Action.closeRequestDialog:
      return { ...state, showRequestDialog: false };

    default:
      return state;
  }
}

class Action {
  static newDeliveries = 1;
  static startReloading = 2;
  static loadFirst = 3;
  static filter = 4;
  static showPhaseModal = 5;
  static closePhaseModal = 6;
  static loadRequestDialog = 7;
  static showRequestDialog = 8;
  static closeRequestDialog = 9;
}

export default function DeliveriesChecklist({
  filters,
  onNewData,
  zoneId,
}) {
  const [state, dispatch] = useReducer(reducer,
    initialState, st => ({ ...st, filters })
  );
  const lastDateRef = useRef(state.lastDate);
  const filtersRef = useRef(state.filters);
  lastDateRef.current = state.lastDate;
  filtersRef.current = state.filters;

  const handlePhaseClick = useCallback(async (evt) => {
    const e = evt.currentTarget;
    const deliveryId = e.getAttribute('data-delivery-id');

    try {
      const delivery = await api.getDeliveryById(deliveryId, {
        include: ['responsible', 'bitacore', 'zones']
      });
      console.log(delivery);
      dispatch({
        type: Action.showPhaseModal,
        delivery
      });
    } catch (err) {
      alert("No se puede cargar el viaje: " + err.toString());
    }

  }, []);

  const handleClosePhaseModal = useCallback(e => {
    dispatch({ type: Action.closePhaseModal });
  }, []);

  const handleShowAssignation = useCallback((delivery) => {
    dispatch({ type: Action.loadRequestDialog, deliveryId: delivery.id });
  }, []);

  const fetchDelivery = useCallback(async (e) => {
    dispatch({
      type: Action.showRequestDialog,
      delivery: await api.getDeliveryById(state.loadingDeliveryRequestId, {
        include: ['products']
      })
    });
  }, [state.loadingDeliveryRequestId]);

  useEffect(() => {
    const st = { timer: null, mounted: false };

    async function refresh() {
      try {
        const response = await api.getDeliveries({
          lastDate: lastDateRef.current,
          scope: "MesaControl",
          sortBy: 'lastUpdate',
          include: [
            'groupedPlaces',
            'phases',
            'zones',
            'products',
          ]
        });

        dispatch({
          type: Action.newDeliveries,
          deliveries: response.result
        });
      } catch (e) {
        console.error(e);
      }
      st.timer = setTimeout(refresh, REFRESH_INTERVAL);
    };

    st.timer = setTimeout(refresh, 0);

    return () => clearTimeout(st.timer);
  }, []);

  useEffect(() => {
    if (state.deliveries.length > 0) {
      if (state.firstLoaded) {
        // playAlert();
      } else {
        dispatch({ type: Action.loadFirst });
      }
    }
  }, [state.deliveries]);

  useEffect(() => {
    if (filters) {
      dispatch({ type: Action.filter, filters });
    }
  }, [filters]);

  useEffect(() => {
    if (onNewData) {
      onNewData(state.deliveriesBacklog);
    }
  }, [state.deliveriesBacklog, onNewData]);

  useEffect(() => {
    if (state.loadingDeliveryRequestId) {
      fetchDelivery();
    }
  }, [state.loadingDeliveryRequestId]);

  if (state.loading) {
    return (
      <Card>
        Cargando...
      </Card>
    );
  }

  return (
    <React.Fragment>
      <TableContainer component={Paper} elevation={2} style={{borderRadius: 16, marginTop: 8, marginBottom: 16}}>
        <Table size="small"> 
          <TableHead>
            <TableRow>
              <TableCell width={50}></TableCell>
              <TableCell>Origen / Destino</TableCell>
              <TableCell>Productos</TableCell>
              <TableCell align="center" width={50}>Recol.</TableCell>
              <TableCell align="center" width={50}>Bodega</TableCell>
              <TableCell align="center" width={50}>Ruta</TableCell>
              <TableCell align="center" width={50}>Entrega</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(state.deliveries?.length ?? 0) > 0 ? (
              state.deliveries?.map((d, index) =>
                <DeliveryRow
                  delivery={d}
                  key={index}
                  onPhaseClick={handlePhaseClick}
                  onAction={handleShowAssignation}
                  />
              )
            ) : <NotFoundRow />}
          </TableBody>
        </Table>
      </TableContainer>
      <PhaseModal
        open={state.showPhaseModal}
        onClose={handleClosePhaseModal}
        delivery={state.showPhaseDelivery} />
      <RequestDialog
        title="Traslado de productos y asignación a chofer"
        open={state.showRequestDialog}
        delivery={state.requestDialogDelivery}
        onClose={(e, reason) => {
          if (reason !== "backdropClick")
            dispatch({ type: Action.closeRequestDialog });
        }}
        collectProducts={true}
      />
    </React.Fragment>
  )
}

function DeliveryRow({ delivery, onPhaseClick, onPrint, onAction }) {
  const d = delivery;
  const now = new Date().getTime() / 1000;
  const className = (
    now < d.newUntil
      ? 'delivery-monitor-item-new'
      : 'delivery-monitor-item'
  );
  const status = useMemo(() => {
    return DeliveryStatus.getStatusWithId(delivery.status);
  }, [delivery]);

  const handleAction = useCallback(() => {
    onAction(delivery);
  }, [delivery]);

  return (
    <TableRow className={className}>
      <TableCell style={{fontSize: 12, padding: "4px 8px 4px 16px"}}>
        <Grid container>
          <Grid item style={{lineHeight: "10px"}}>
            { d.trackingNumber } <small>(ID: {d.id})</small>
          </Grid>
          <Grid item style={{lineHeight: "7px", fontSize: 10, color: "#888", marginTop: 3}}>
            {status.title}
          </Grid>
        </Grid>
      </TableCell>
      <TableCell valign="middle" style={{padding: "0px 8px 0px 8px"}}>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <PlaceIcon kind={PlaceIcon.origin} size={8} />
          </Grid>
          <Grid item style={{ lineHeight: "14px", fontSize: 12}}>
            <span style={{marginRight: 4}}>{ d.origin?.name }</span>
            {d.origin?.postalCode
              ? <SmallChip marginRight={4} text={`CP ${d.origin?.postalCode}`} />
              : null}
            {d.origin?.colonia
              ? <SmallChip marginRight={4} text={`Col. ${d.origin?.colonia}`} />
              : null}
          </Grid>
        </Grid>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <PlaceIcon kind={PlaceIcon.destination} size={8} />
          </Grid>
          <Grid item style={{ lineHeight: "14px", fontSize: 12}}>
            <span style={{marginRight: 4}}>{ d.destination?.name }</span>
            {d.destination?.postalCode
              ? <SmallChip marginRight={4} text={`CP ${d.destination?.postalCode}`} />
              : null}
            {d.destination?.colonia
              ? <SmallChip marginRight={4} text={`Col. ${d.destination?.colonia}`} />
              : null}
          </Grid>
        </Grid>
      </TableCell>
      <TableCell style={{padding: "0px 8px 0px 8px"}}>
        <ul>
          {(d.products?.length ?? 0) > 0 ? d.products?.map((p, index) =>
            <li key={index} style={{fontSize: 12}}>{p.quantity} x {p.title} <SmallChip text={`SKU: ${p.sku}`} /></li>
          ) : (
            <div style={{color: "#c0c0c0"}}>{d.collectDescription ?? "N/A"}</div>
          )}
        </ul>
      </TableCell>
      {delivery.status === DeliveryStatus.storedToBeAssigned ? (
        <TableCell colSpan={4} style={{textAlign: "center", padding: "0px"}}>
          <Button size="small" variant="outlined"
            onClick={handleAction}
            startIcon={<NewReleasesIcon className="blinking-icon danger" />}
            >Debe asignarse</Button>
        </TableCell>
      ) : (
        <React.Fragment>
          <PhaseCell delivery={d} field="collect" onClick={onPhaseClick} />
          <PhaseCell delivery={d} field="group" onClick={onPhaseClick} />
          <PhaseCell delivery={d} field="route" onClick={onPhaseClick} />
          <PhaseCell delivery={d} field="deliver" onClick={onPhaseClick} />
        </React.Fragment>
      )}
      
      <TableCell style={{padding: "4px 8px 4px 8px"}}>
        <IconButton size="small"
          component={"a"}
          href={`/deliveries/${delivery.id}/print-tracking`}
          target="_blank"
        ><PrintIcon /></IconButton>
      </TableCell>
    </TableRow>
  );
}

function PhaseCell({ delivery, field, onClick }) {
  return (
    <TableCell padding="none" style={{textAlign: "center"}}>
      <PhaseButton
        delivery={delivery}
        phaseName={field}
        fullWidth
        onClick={onClick} />
    </TableCell>
  );
}

function SmallChip({ text, marginRight = 0, block = false, center }) {
  const styles = useStyles();
  if (block) {
    return <div className="small-chip"
      style={{marginRight, textAlign: center ? "center" : "left"}}
      >{text}</div>;
  }
  return (
    <span className="small-chip" style={{marginRight}}>{ text }</span>
  )
}

function NotFoundRow() {
  const styles = useStyles();
  return (
    <TableRow>
      <TableCell colSpan={6}>
        <div className={styles.notFoundRow}>
          No hay nada que mostrar aquí.
        </div>
      </TableCell>
    </TableRow>
  );
}

const useStyles = makeStyles({
  notFoundRow: {
    padding: 96,
    textAlign: "center",
    fontSize: 24,
    color: grey[600]
  },
});