import React from 'react';
import DeliveryTile from './delivery-tile';
import NumberFormat from "react-number-format";
import { green, red, orange } from '@material-ui/core/colors';
import Btn from '../components/button';
import {
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  Typography,
  Chip,
  Grid,
  Card,
  CardContent,
  MenuItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';

import {
  Phone as PhoneIcon,
  Person as PersonIcon,
  MailOutline as MailOutlineIcon,
  Check as CheckIcon,
  DeleteForever as DeleteForeverIcon,
} from '@material-ui/icons';

const Km = (props) => (
  <NumberFormat
    thousandSeparator={true}
    decimalScale={1}
    suffix="km"
    displayType="text"
    {...props}
  />
);

export default function RequestCard({
	delivery: d,
	drivers,
	isCancelling,
	onSelectDriver,
	onAssign,
	onCancel,
	selectedDriverId,
	assigning,
	cancellable,
}) {
	return (
	  <Card key={d.id}>
			<CardContent>
				<Grid container spacing={2}>
					<Grid item>
						<Grid container spacing={1}>
						<Grid item xs={6}>
							<Typography color="textSecondary">Información de Entrega</Typography>
							<DeliveryTile delivery={d} />
						</Grid>
						<Grid item xs={6}>
							<Grid container>
							<Grid item>
								<Typography color="textSecondary">Información de Cliente</Typography>
							</Grid>
			
							<Grid item xs={12}>
								<Grid container spacing={1}>
								<Grid item>
									<PersonIcon style={{ fontSize: 16 }} />
								</Grid>
								<Grid item>
									{d?.user?.name ?? ""} {d?.user?.lastName ?? ""}
								</Grid>
								</Grid>
							</Grid>
			
							<Grid item xs={12}>
								<Grid container spacing={1}>
								<Grid item>
									<MailOutlineIcon style={{ fontSize: 16 }} />
								</Grid>
								<Grid item>
									{d?.user?.email ?? ""}
								</Grid>
								</Grid>
							</Grid>
			
							<Grid item xs={12}>
								<Grid container spacing={1}>
								<Grid item>
									<PhoneIcon style={{ fontSize: 16 }} />
								</Grid>
								<Grid item>
									{d?.user?.phone ?? ""}
								</Grid>
								</Grid>
							</Grid>
							</Grid>
						</Grid>
						</Grid>
					</Grid>
			
					<Grid item xs={12}>
						<FormControl variant="outlined" fullWidth={true}>
						<InputLabel htmlFor="driver-select">Choferes activos</InputLabel>
						<Select
							value={selectedDriverId || 0}
							labelId="driver-select"
							label="Asignar a chofer"
							onChange={e => onSelectDriver(e.target.value, d.id)}
						>
							<MenuItem value={0}>- Elige un chofer -</MenuItem>
							{drivers.map(driver => (
							<MenuItem value={driver.id} key={driver.id}>
								<ListItemText>
								{driver.name}
								</ListItemText>
								<ListItemSecondaryAction>
								{
									driver.distance
									? (
										<Chip
										size="small"
										label={
											<Km value={driver.distance / 1000} prefix={"A "} />
										}
										style={{
											color: "white",
											marginRight: 16,
											fontWeight: "bold",
											backgroundColor: driver.distance < 4000
											? green[400]
											: (driver.distance < 10000
												? orange[400]
												: red[400]
											)
										}}
										/>
									) : null
								}
								</ListItemSecondaryAction>
							</MenuItem>
							))}
						</Select>
						<FormHelperText>Distancia entre el chofer y el punto de recolección.</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item>
						<Btn color="success"
						isLoading={assigning}
						onClick={() => onAssign(d.id)}
						disabled={assigning}
						startIcon={<CheckIcon />}
						>
						{assigning ? "Asignando" : "Asignar"}
						</Btn>
					</Grid>
					{cancellable
						? (
							<Grid item>
								<Btn color="error"
								isLoading={isCancelling}
								onClick={() => onCancel(d.id)}
								disabled={isCancelling}
								startIcon={<DeleteForeverIcon />}
								>
								{assigning ? "Cancelando" : "Cancelar"}
								</Btn>
							</Grid>
						) : null
					}
				</Grid>
			</CardContent>
	  </Card>
	);
}