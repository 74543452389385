import React from 'react';
import { Button, CircularProgress, Badge, IconButton } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
  Check as CheckIcon,
  CheckCircle as CheckCircleIcon,
  CircleOut as CircleIcon,
  PriorityHigh as PriorityHighIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
} from '@material-ui/icons';
import { green, red, grey, teal } from '@material-ui/core/colors';

const BADGE_ANCHOR = {vertical: 'bottom', horizontal: 'right'};

const PhaseButton = withStyles({
  root: {
    
  },
  label: {
    justifyContent: "start",
    alignItems: "start"
  },
})(props => {
  const { phaseName, delivery, onClick } = props;
  const { phases } = delivery;
  const phase = phases[phaseName];
  const innerProps = {
    "data-delivery-id": delivery?.id?.toString(),
    "data-phase-name": phaseName ?? "",
    style: {padding: 4, cssCursor: "pointer"},
    onClick,
  };

  return (
    <IconButton {...innerProps}>
      <CheckState
        state={checkStateForDelivery(delivery, phaseName)}
        followingStatus={phase?.followingStatus}
        loading={props?.loading} />
    </IconButton>
  )
});

function PhaseIcon(props) {
  const color = props?.state === CheckState.stateChecked
    ? green[400] : grey[400];
  const style = {color: color};
  switch (props.state) {
    case CheckState.stateChecked:
      return <CheckCircleIcon style={style} />;
    case CheckState.stateFailed:
      return <PriorityHighIcon style={style} />;
    case CheckState.statePending:
      return <RadioButtonUncheckedIcon style={style} />;
    default:
      return null;
  }
}

export function CheckState({ state, loading, followingStatus }) {
  const styles = useCheckStyle();
  state = state ?? CheckState.statePending;

  return (
    loading
      ? <CircularProgress className={styles.loading} />
      : <PhaseIcon state={state} attended={followingStatus === 'attended'} />
  )
}

CheckState.statePending = 0;
CheckState.stateChecked = 1;
CheckState.stateFailed  = 2;  

function checkStateForDelivery(delivery, kind) {
  if (delivery?.phases && delivery.phases[kind]) {
    if (delivery.phases[kind].success) {
      return CheckState.stateChecked;
    } else {
      return CheckState.stateFailed;
    }
  }
    
  return CheckState.statePending;
}

const CheckStateBadge = withStyles({
  colorSecondary: {
    backgroundColor: teal[300],
  },
  badge: {
    padding: "0px",
  }
})(Badge);

const useCheckStyle = makeStyles({
  loading: {
    position: 'absolute',
    width: 20,
    height: 20,
    colorPrimary: "blue"
  },
});

export default PhaseButton;