import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { withRouter } from "react-router-dom";
import API from '../api';
import {
  CircularProgress,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListSubheader,
  Checkbox,
} from '@material-ui/core';

const api = API.instance();

class VehicleTypeEditScreen extends React.Component {
  state = {
    'user': '',
    'password': '',
    nombre: '',
    tipo: null,
    capacidadM3: '',
    capacidadMinimaM3: '0.0',
    costMinimoPaquete: 0,
    tiposPaquetesElegidos: [],

    packagesTypes: null,

    isSaving: false,
    isLoading: true
  }

  async save() {
    const {
      isSaving,
      nombre,
      capacidadM3,
      capacidadMinimaM3,
      costoMinimoPaquete,
      tiposPaquetesElegidos,
      tipo,
    } = this.state;

    if (isSaving) {
      return;
    }

    this.setState({
      isSaving: true
    });

    try {
      const tipos = tiposPaquetesElegidos.map((tpe) => {
        return {
          tipoVehiculoId: tipo.tipoVehiculoId,
          tipoPaqueteId: tpe
        };
      });

      console.log(tipos);

      await api.saveVehicleType({
        tipoVehiculoId: tipo.tipoVehiculoId,
        nombre,
        capacidadM3,
        capacidadMinimaM3,
        costoMinimoPaquete,
        tipoVehiculoTipoPaquete: tipos
      });

      this.setState({
        isSaving: false,
      }, () => {
        this.props.history.push('/vehicles/types');
      });
    } catch (e) {
      console.error(e);
      this.setState({
        isSaving: false,
      });
    }
  }

  async load() {
    try {
      const tipo = await api.getVehicleType(
        this.props.match.params.id
      );

      this.setState({
        tipo,
        isLoading: false,
        nombre: tipo.nombre,
        capacidadM3: tipo.capacidadM3,
        capacidadMinimaM3: tipo.capacidadMinimaM3,
        costoMinimoPaquete: tipo.costoMinimoPaquete,
        tiposPaquetesElegidos: tipo.tipoVehiculoTipoPaquete.map((tvtp) => {
          return tvtp.tipoPaqueteId
        })
      });

    } catch (e) {
      console.error(e);
      this.setState({isLoading: false});
    }
  }

  async loadPackagesTypes() {
    try {
      const types = await api.getPackagesTypes();
      this.setState({packagesTypes: types});
    } catch (e) {
      console.error(e);
      alert("Algo salió mal: " + e.message);
    }
  }

  componentDidMount() {
    this.loadPackagesTypes();
    this.load();
  }

  checkPaquete(e, pt) {
    const { tiposPaquetesElegidos } = this.state;

    if (e.target.checked) {
      if (tiposPaquetesElegidos.indexOf(pt.tipoPaqueteId) === -1) {
        tiposPaquetesElegidos.push(pt.tipoPaqueteId);
      }
    } else {
      let index = tiposPaquetesElegidos.indexOf(pt.tipoPaqueteId);
      if (index !== -1) {
        tiposPaquetesElegidos.splice(index, 1);
      }
    }

    this.setState({tiposPaquetesElegidos});
  }

  render() {
    const {
      isSaving,
      nombre,
      capacidadM3,
      capacidadMinimaM3,
      costoMinimoPaquete,
      isLoading,
      packagesTypes,
      tiposPaquetesElegidos,
    } = this.state;

    if (isLoading) {
      return (
        <Container>
          <CircularProgress />
        </Container>
      );
    }

    return (
      <Container component="main" maxWidth={"md"}>
        <h1>Tipo de vehículo</h1>
        <Grid container spacing={3}>
          <Grid item md={6}>
            <Grid container spacing={2}
              alignItems="flex-end"
              justify="flex-end"
              style={{ paddingBottom: 16 }}
            >
              <Grid item xs={12}>
                <TextField
                  value={nombre}
                  variant="outlined"
                  fullWidth={true}
                  label="Nombre"
                  style={{ minWidth: 300 }}
                  onChange={(e) => this.setState({ nombre: e.target.value })} />
              </Grid>

              <Grid item xs={12}>
                <Grid container>
                  <Grid item="item" xs={6}>
                    <TextField value={capacidadM3}
                      variant="outlined"
                      label={"Capacidad en M3"}
                      onChange={(e) => {
                        this.setState({ capacidadM3: e.target.value })
                      }}
                    />
                  </Grid>

                  <Grid item="item" xs={6}>
                    <TextField value={capacidadMinimaM3}
                      variant="outlined"
                      label={"Capacidad mínima en M3"}
                      onChange={(e) => {
                        this.setState({ capacidadMinimaM3: e.target.value })
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item="item" xs={12}>
                <TextField value={costoMinimoPaquete}
                  variant="outlined"
                  label={"Costo mínimo por paquete $"}
                  style={{ minWidth: 300 }}
                  onChange={(e) => {
                    this.setState({ costoMinimoPaquete: e.target.value })
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={6} xs={12}>
            <Paper>
              {packagesTypes !== null
                ? (
                  <List subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                      Tipos de paquetes que puede entregar
                    </ListSubheader>
                  }>
                    {packagesTypes.map((pt, index) => {
                      return (
                        <ListItem divider={true} key={index}>
                          <ListItemText
                            secondary={
                              <span>
                                {pt.volumenMinimo} - {pt.volumenMaximo} m<sup>3</sup>
                              </span>
                            }
                          >
                            {pt.nombre}</ListItemText>
                          <ListItemSecondaryAction>
                            <Checkbox
                              onChange={(e) => { this.checkPaquete(e, pt); }}
                              checked={
                                tiposPaquetesElegidos
                                  && tiposPaquetesElegidos.indexOf(
                                    pt.tipoPaqueteId
                                  ) !== -1
                              }
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })}
                  </List>
                ) : null
              }
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Button variant="contained" color="primary"
                  disabled={isSaving}
                  onClick={this.save.bind(this)}>
                  Guardar Información
                </Button>
              </Grid>
              <Grid item>
                {isSaving ? <CircularProgress /> : null}
              </Grid>
            </Grid>

          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withRouter(VehicleTypeEditScreen);