import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import {
  CircularProgress,
  Grid,
  List,
  Paper,
  Container,
  ListItem,
  ListItemText,
  Card,
  CardContent,
  Typography,
  IconButton,
  ListItemSecondaryAction,
  ListItemIcon,
  DialogContent,
  Dialog,
  DialogActions,
  TextField,
  Chip,
  ButtonBase,
  Button as DefaultButton,
  DialogTitle,
  withStyles,
  Menu,
  MenuItem,
} from '@material-ui/core';
import API from '../api';
import { DeliveryStatus } from '../models/delivery';
import {
  CheckBox,
  Print as PrintIcon,
  Error as ErrorIcon,
  CheckCircle as CheckCircleIcon,
  Check as CheckIcon,
  OpenInNew as OpenInNewIcon,
  CropFree as CropFreeIcon,
  KeyboardReturn as KeyboardReturnIcon,
  GridOn as GridOnIcon,
  Apps as AppsIcon,
  ArrowBack as ArrowBackIcon,
  Info as InfoIcon,
  MoreHoriz as MoreHorizIcon,
  RemoveCircle as RemoveCircleIcon,
} from '@material-ui/icons';
import DeliveryTile from '../components/delivery-full-tile';
import BasicButton from '../components/button';
import AssignDeliveryForm from '../components/assign-delivery-form';
import { red, green } from '@material-ui/core/colors';
import Button from '../components/button';
import moment from 'moment';
import { Tabs } from '../components/tabs';

export default function StorageControlScreen() {
  const [lastDate, setLastDate] = useState(null);
  const [pause, setPause] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [doneIds, setDoneIds] = useState([]);
  const [loadingIds, setLoadingIds] = useState([]);
  const [currentDelivery, setCurrentDelivery] = useState(null);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [deliveryPutInZone, setDeliveryPutInZone] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPrintedMenu, setOpenPrintedMenu] = useState(false);

  // Lists
  const [deliveries, setDeliveries] = useState([]);
  const printableDeliveries = useMemo(() => {
    return deliveries.filter(d => !d.printed);
  }, [deliveries]);
  const printedDeliveries = useMemo(() => {
    return deliveries.filter(d => d.printed);
  }, [deliveries]);

  const [showPrintDialog, setShowPrintDialog] = useState(false);
  const [showPutInZoneDialog, setShowPutInZoneDialog] = useState(false);
  const inputScanRef = useRef();

  const fetchNew = useCallback(async (e) => {
    if (pause) {
      return;
    }

    try {
      const response = await API.instance().getDeliveries({
        status: [DeliveryStatus.preparingDelivery],
        lastDate: lastDate,
        include: ['groupedPlaces', 'products', 'zones'],
      });
      const newDeliveries = response.result;
      if (!isMounted) {
        setIsMounted(true);
      }
      setLastDate(newDeliveries
        .map(d => d.fechaActualizacion)
        .reduce((a, b) => b > a ? b : a, null)
      );
      const tempDeliveries = [...deliveries];
      newDeliveries.forEach((delivery) => {
        const foundIndex = tempDeliveries.findIndex(d => d.id === delivery.id);
        if (foundIndex > -1) {
          tempDeliveries[foundIndex] = delivery;
        } else {
          tempDeliveries.splice(0, 0, delivery);
        }
      });
      setDeliveries(tempDeliveries);
    } catch (e) {
      console.log(`[StorageControlScreen.fetchNew] ${e}`);
    }
  }, [lastDate, pause, isMounted, deliveries]);

  const handleClosePrintDialog = useCallback(() => {
    setShowPrintDialog(false);
  }, []);

  const handleOnPrinted = useCallback((update) => {
    setDeliveries(deliveries.map(d => {
      if (d.id === update.id) {
        return update;
      } else {
        return d;
      }
    }))
  }, [deliveries]);

  const handleSelectDelivery = useCallback((e) => {
    const deliveryId = parseInt(e.currentTarget.getAttribute('data-delivery-id'));
    const delivery = deliveries.find(d => d.id === deliveryId);
    setCurrentDelivery(delivery);
    setShowPrintDialog(true);
  }, [deliveries]);

  const handleOpenMenu = useCallback(e => {
    setAnchorEl(e.currentTarget);
    setOpenPrintedMenu(true);
  }, []);

  const handleCloseMenu = useCallback(e => {
    setOpenPrintedMenu(false);
  }, []);

  const handleSubmitTracking = useCallback(async (e) => {
    e.preventDefault();
    const trackingNumber = inputScanRef.current.value;
    inputScanRef.current.value = "";

    setDeliveryPutInZone(null);

    try {
      await API.instance().putDeliveryInZone({trackingNumber});
      setDeliveryPutInZone(deliveries.filter(d => d.trackingNumber === trackingNumber)[0]);
      setDeliveries(deliveries.filter(d => d.trackingNumber !== trackingNumber));
    } catch (e) {
      setShowPutInZoneDialog(null);
      setErrorMessage(e.message);
      console.log(`[handleSubmit] ${e}`);
    }
  }, [inputScanRef, deliveries]);

  const handleChangeTab = useCallback((index) => {
    setSelectedTabIndex(index);
  }, []);

  useEffect(() => {
    const fetchInterval = setInterval(fetchNew, 5000);
    fetchNew();

    return () => {
      clearInterval(fetchInterval);
    };
  }, []);

  useEffect(()  => {
    console.log(`lastDate = ${lastDate}`);
  }, [lastDate]);

  useEffect(()  => {
    console.log(`deliveries = ${deliveries.length}`);
  }, [deliveries]);

  if (!isMounted) {
    return <LoadingView />;
  }

  return (
    <Container>
      <h1 style={{flexGrow: 1}}>Abastecer pedidos</h1>
      <div style={{marginBottom: 16}}>
        <Tabs selectedIndex={selectedTabIndex} onChange={handleChangeTab} items={[
          {label: "Imprimir", icon: PrintIcon, counter: printableDeliveries.length},
          {label: "Colocar en zona", icon: AppsIcon, counter: printedDeliveries.length},
          // {label: "Devoluciones", icon: KeyboardReturnIcon}
        ]} />
      </div>
      {selectedTabIndex === 1 ? (
        <Paper style={{backgroundColor: "#e9e9e9", borderRadius: 16, marginBottom: 16, padding: 16, display: "flex", alignItems: "center"}}>
          <img src="barcode-scan.svg" style={{width: 48, height: 48, marginRight: 16}} />
          <form onSubmit={handleSubmitTracking} style={{flexGrow: 1, flex: 1, backgroundColor: "white", width: "%50"}}>
            <ScanField autoFocus variant="outlined" fullWidth inputRef={inputScanRef}  />
          </form>
          <div style={{flexGrow: 1, flex: 1}}>
            {deliveryPutInZone ? (
              <div className="beater"
                style={{display: "flex", flexDirection: "row", marginLeft:  16, color: "#555",
                  background: green[700],
                  padding: 8,
                  borderRadius: 8,
                  color: "white",
                  fontSize: 17,
                  width: "auto",
                  alignItems: "center"
                }}
              >
                <CheckCircleIcon style={{fontSize: 25}} />
                <div style={{paddingLeft: 8, lineHeight: "20px"}} >
                  La guía <strong>{deliveryPutInZone.trackingNumber}</strong> fue colocada en la zona 
                  <strong> {deliveryPutInZone.destinationZone?.name}</strong>.
                </div>
              </div>
            ) : (
              errorMessage ? (
                <div className="beater"
                  style={{display: "flex", flexDirection: "row", marginLeft:  16, color: "#555",
                    background: green[700],
                    padding: 8,
                    borderRadius: 8,
                    color: "white",
                    fontSize: 17,
                    width: "auto",
                    alignItems: "center"
                  }}
                >
                  <CheckCircleIcon style={{fontSize: 25}} />
                  <div style={{paddingLeft: 8, lineHeight: "20px"}} >
                    La guía <strong>{deliveryPutInZone.trackingNumber}</strong> fue colocada en la zona 
                    <strong> {deliveryPutInZone.destinationZone?.name}</strong>.
                  </div>
                </div>
              ) : (
                <div style={{display: "flex", flexDirection: "row", marginLeft:  16, color: "#555"}}>
                  <InfoIcon />
                  <div style={{paddingLeft: 8}}>
                    Las guías escaneadas serán automáticamente <br />marcadas como en rack de zona.
                  </div>
                </div>
              )
            )}
          </div>
        </Paper>
      ) : null}
      <Paper style={{overflow: "hidden", borderRadius: 16}}>
        {selectedTabIndex === 0 ? (
          printableDeliveries.length ? (
            <List dense>
              {printableDeliveries.map((delivery) =>
                <Tile delivery={delivery} onSelect={handleSelectDelivery} />
              )}
            </List>
          ) : ( 
            <Card>
              <CardContent>
                <Grid container alignItems="center" justifyContent="center">
                  <Typography>No hay entregas qué imprimir.</Typography>
                </Grid>
              </CardContent>
            </Card>
          )
        ) : (
          printedDeliveries.length ? (
            <Grid container direction="column">
              <Grid item>
                <List dense>
                  {printedDeliveries.map((delivery) =>
                    <Tile delivery={delivery} onMore={handleOpenMenu} />
                  )}
                </List>
                <Menu
                  open={openPrintedMenu}
                  onClose={handleCloseMenu}
                  anchorEl={anchorEl}
                  anchorOrigin={{horizontal: "left"}}
                  anchorPosition={{left: 0}}>
                  <MenuItem dense>
                    <ListItemIcon>
                      <PrintIcon />
                    </ListItemIcon>
                    Reimprimir
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid>
          ) : (
            <Card>
              <CardContent>
                <Grid container alignItems="center" justifyContent="center">
                  <Typography>No hay pedidos por colocar en zona.</Typography>
                </Grid>
              </CardContent>
            </Card>
          )
        )}
      </Paper>
      
      <DeliveryPrintDialog
        open={showPrintDialog}
        onClose={handleClosePrintDialog}
        onPrinted={handleOnPrinted}
        delivery={currentDelivery} />
    </Container>
  );
}

function LoadingView() {
  return (
    <Grid container alignItems="center" justifyContent="center">
      <CircularProgress />
    </Grid>
  );
}

function DeliveryPrintDialog({
  open,
  onClose,
  delivery,
  onPrinted,
}) {
  const [trackingErrorMessage, setTrackingErrorMessage] = useState("");
  const [trackingSuccessMessage, setTrackingSuccessMessage] = useState("");
  const barcodeRef = useRef();
  const handlePrint = useCallback(async () => {
    try {
      await API.instance().markDeliveryAsPrinted(delivery.id);
      window.frames["label-print"].focus(); 
      window.frames["label-print"].print();
      if ('function' === typeof onPrinted)
        onPrinted({...delivery, printed: moment().format()});
    } catch (e) {
      console.log(`[handlePrint] ${e}`);
    }
  }, [delivery]);

  useEffect(() => {
    if (open) {
      setTrackingErrorMessage(null);
      setTrackingSuccessMessage(null);
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <DialogContent>
        <Grid container direction="row" spacing={2}>
          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <iframe
                  id="frame-label-print"
                  name="label-print"
                  src={`/deliveries/${delivery?.id}/print-tracking?noAutoprint=1`}
                  style={{border: "none", height: 385, width: 385}}
                />
              </Grid>
              <Grid item style={{textAlign: "center"}}>
                <DefaultButton variant="contained" startIcon={<PrintIcon />} onClick={handlePrint}>Imprimir</DefaultButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        
      </DialogContent>
      <DialogActions>
        
      </DialogActions>
    </Dialog>
  );
}

function Tile({
  delivery,
  onSelect,
  onMore,
}) {
  return (
    <ListItem divider button={onSelect ? true : false} data-delivery-id={delivery.id} onClick={onSelect}>
      <ListItemText
        secondary={`${delivery?.products[0]?.sku} - ${delivery?.products[0]?.title}`}
      >
        {delivery?.trackingNumber} - {delivery.destinationZone?.name}
      </ListItemText>
      {delivery?.printed ? (
        <ListItemSecondaryAction>
          <Grid container direction="row" spacing={1}>
            <Grid item>
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <Chip label={
                    <Grid container spacing={1}>
                      <Grid item>
                        <CheckIcon style={{fontSize: 12}} />
                      </Grid>
                      <Grid item>
                        Impresa
                      </Grid>
                    </Grid>
                  } size="small" />
                </Grid>
                <Grid item>
                  <small style={{fontSize: 9}}>{delivery?.printed}</small>
                </Grid>
              </Grid>

            </Grid>
            {/*<Grid item>
              <IconButton size="small" onClick={onMore}><MoreHorizIcon /></IconButton>
            </Grid>*/}
          </Grid>
        </ListItemSecondaryAction>
      ) : null}
    </ListItem>
  );
}

const ScanField = withStyles({
  root: {
    backgroundColor: "#f9f9f9 !important",
    fontSize: "20px !important"
  },
})(TextField);