import React, { useCallback, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  DialogContentText,
} from '@material-ui/core';
import CategorySelect from "./category-select";

export default function CategorySelectDialog(props) {
  const {open, categories, onChange, onClose, onOk, okTitle, description} = props;
  const [categoryId, setCategoryId] = useState(null);

  const handleConfirmSelection = useCallback(e => {
    if ('function' === typeof onOk) {
      onOk(categoryId);
    }
  }, [categoryId]);

  const handleChange = useCallback(e => {
    setCategoryId(e.target.value);
  }, []);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <DialogContentText>
          <Grid container spacing={2} direction="column">
            <Grid item>
              {description}
            </Grid>
            <Grid item>
              <CategorySelect categories={categories}
                selected={categoryId}
                onChange={handleChange} />
            </Grid>
            <Grid item>
              <Button color="primary" variant="contained" onClick={handleConfirmSelection} disabled={!categoryId}>
                {okTitle ?? "OK"}
              </Button>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}