import { useReducer } from "react";

const initialState = {
  notificationCount: 0,
  pendingRequests: [1, 2],
  willLoadPendingRequests: false,
  requestsBeingCancelled: []
};

function reducer(state, action) {
  switch (action.type) {
    case "updateNotificationCount":
      return {...state, notificationCount: action.count};
    case "refreshPendingRequests":
      return {...state, pendingRequests: action.requests};
    case "startLoadingRequests":
      return {...state, willLoadPendingRequests: true };
    default:
      return state;
  }
}

export default function useMainReducer() {
  return useReducer(reducer, initialState);
}