import React, {useRef} from 'react';
import ReactToPrint from 'react-to-print';

import {
  Button,
  Card,
  Table,
  Grid,
  Container,
} from '@material-ui/core';

import Logo from '../../assets/logo_pedidos.png';

// TODO: descomentar para migrar a material-ui
export const PrintPedido = (({orden, checks, setPrint, setChecks}) => {

    const componentRef = useRef();

    const Pedido = React.forwardRef((props, ref) => {

        return (
            <div ref={ref} style={styles.container}>
                <Grid tag="h5" style={styles.cardTitle}>
                    <img src={Logo} alt="Logo" style={{width: 200}} />
                </Grid>
                <Container>
                    <Grid style={{display: 'flex', justifyContent: 'space-between'}}>
                        <Grid>
                            <Table style={styles.table}>
                                <thead>
                                    <tr>
                                        <th style={styles.cell3}>Cliente</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={styles.cellTitle}>
                                            <div style={styles.cardContentTextCliente}>
                                                {orden.receptor}
                                            </div>
                                            <div text style={styles.cardContentText}>
                                                {orden.destination.name + ' C.P. ' + orden.destination.postalCode + ' Col. ' + orden.destination.colonia}
                                            </div>
                                            <div text style={styles.cardContentText}>
                                                SDR123456789
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Grid>
                        <Grid >
                            <Table style={styles.table}>
                                <thead>
                                    <tr>
                                        <th style={styles.cell3}>Fecha</th>
                                        <th style={styles.cell3}>Pedido</th>                                      
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={styles.cell}>
                                            <div style={styles.cardContentText}>
                                                {orden.fechaHora.split('T')[0]}
                                            </div>
                                        </td>
                                        <td style={styles.cell}>
                                            <div style={styles.cardContentText}>
                                                {orden.trackingNumber}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Grid>
                    </Grid>
                </Container>
                <Container>
                    <Grid style={{display: 'flex', justifyContent: 'space-between'}}>
                        <Grid>
                            <Table style={styles.table}>
                                <thead>
                                    <tr>
                                        <th style={styles.cell3}>Vigencia</th>
                                        <th style={styles.cell3}>Condiciones</th>
                                        <th style={styles.cell3}>Vendedor</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={styles.cell}>
                                            <div style={styles.cardContentText}>
                                                12-07-2023
                                            </div>
                                        </td>
                                        <td style={styles.cell}>
                                            <div style={styles.cardContentText}>
                                                Contado
                                            </div>
                                        </td>
                                        <td style={styles.cell}>
                                            <div style={styles.cardContentText}>
                                                Jose Perez
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Grid>
                    </Grid>
                </Container>    
                <Container>
                    <Table style={styles.table}>
                        <thead>
                            <tr>
                                <th style={styles.cell3}>Codigo</th>
                                <th style={styles.cell3}>Nombre</th>
                                <th style={styles.cell3}>Unidades</th>
                                <th style={styles.cell3}>Precio</th>
                                <th style={styles.cell3}>Importe</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orden.products.map((item) => (
                                <tr>
                                    <td style={styles.cell2} width="10%">{item.sku}</td>
                                    <td style={styles.cell2} width="45%">{item.title}</td>
                                    <td style={styles.cell2} width="5%">{item.quantity}</td>
                                    <td style={styles.cell2} width="10%">$100.00</td>
                                    <td style={styles.cell2} width="10%">${parseFloat(100 * item.quantity).toFixed(2)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Container>
                <Container style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Table style={styles.table2}>
                        <tbody>
                            <tr>
                                <th style={styles.cell3}>Subtotal</th>
                                <td style={styles.cell}>
                                ${parseFloat(checks['total'] * 0.84).toFixed(2)}
                                </td>
                            </tr>
                            <tr>
                                <th style={styles.cell3}>IVA 16%</th>
                                <td style={styles.cell}>
                                  ${parseFloat(checks['total'] * 0.16).toFixed(2)}
                                </td>
                            </tr>
                            <tr>
                                <th style={styles.cell3}>Total</th>
                                <td style={styles.cell}>
                                  ${parseFloat(checks['total']).toFixed(2)}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Container>
        </div>
        );
    });

    return (
        <>
        <Pedido ref={componentRef} />
        <Container>
            <Grid style={{display: 'flex', justifyContent: 'space-between'}}>
                <Grid>
                    <ReactToPrint
                        trigger={() => <Button variant="contained" color="primary" style={{marginRight: 16}}>
                                            Imprimir
                                        </Button>}
                        content={() => componentRef.current}
                        onAfterPrint={() => setPrint(false)}
                    />
                </Grid>
                <Grid>
                    <Button variant="contained" color="secondary" onClick={() => setPrint(false)}>  
                        Cerrar
                    </Button>
                </Grid>
            </Grid>
        </Container>
        </>
        );
    });

    const styles = {
        container: {
            marginTop: 24,
            padding: 16,
            maxWidth: 1000,
        },
        card: {
            padding: 16,
            marginBottom: 16,
            width: '100%',
            border: '1px solid #000',
        },
        card2: {
            padding: 16,
            marginBottom: 16,
            width: '50%',
            border: '1px solid #000',
        },
        card3: {
            padding: 16,
            marginBottom: 16,
            width: '100%',
            border: '1px solid #000',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        cardTitle: {
            marginBottom: 16,
        },
        cardContent: {
            padding: 0,
        },
        cardContentTitle: {
            padding: 16,
            marginBottom: 16,
        },
        cardContentSubtitle: {
            padding: 16,
            marginBottom: 16,
        },
        cardContentText: {
            padding: 2,
            marginBottom: 2,
        },
        cardContentTextCliente: {
            padding: 2,
            marginBottom: 15,
            fontSize: 16,
        },
        cell: {
            border: '1px solid #000',
            padding: 0,
            textAlign: 'center',
        },
        cellTitle: {
            padding: 0,
            textAlign: 'left'
        },
        table: {
            maxWidth: 800,
            border: '1px solid #000', 
        },
        table2: {
            width: '17%',
            border: '1px solid #000',
            textAlign: 'center',
        },
        cell2: {
            padding: 0,
            textAlign: 'center',
        },
        cell3: {
            padding: 0,
            textAlign: 'center',
            backgroundColor: '#ccc',
        },
    };

    export default PrintPedido;