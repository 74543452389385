import React, { useCallback, useState } from "react";
import {
  Grid,
  TextField,
  Button
} from '@material-ui/core';
import {
  Add as AddIcon
} from '@material-ui/icons';

export function FeatureOptionInlineForm(props) {
  const {onChange, onSave} = props;
  const [value, setValue] = useState("");

  const handleNameChange = useCallback(e => setValue(e.target.value), []);

  const handleSubmit = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();
    if ('function' === typeof onSave) {
      onSave({value});
    }
  }, [value]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <TextField variant="outlined" margin="dense" label="Nueva opción" defaultValue=""
            onChange={handleNameChange}
          />
        </Grid>
        <Grid item>
          <Button variant="outlined" endIcon={<AddIcon />} color="default" type="submit">Agregar</Button>
        </Grid>
      </Grid>
    </form>
  );
}