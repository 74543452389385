import React from 'react';
import Container from '@material-ui/core/Container';
import { withRouter } from "react-router-dom";
import API from '../api';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import BusinessIcon from '@material-ui/icons/Business';

import {
  List,
  Paper,
  Grid,
  Button,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
} from '@material-ui/core';

const api = API.instance();

class SendersScreen extends React.Component {
  state = {
    redirect: null,
    paqueteria: 0,
    address: '',
    place: null,
    guia: null,
    items: [],
    isCreating: false,

    isLoading: true,
  }

  componentDidMount() {
    api.attachHistory(this.props.history, '/login');
    this.load();
  }

  async load() {
    const update = { isLoading: false };

    try {
      update.items = await api.getSenders();
    } catch (e) {
      console.error(e);
    }

    this.setState(update);
  }

  async register() {
    const { isCreating } = this.state;

    if (isCreating) {
      return;
    }

    this.setState({isCreating: true});

    const nombre = prompt('¿Nombre del cliente?');

    if (!nombre) {
      this.setState({isCreating: false});
      return;
    }

    try {
      const item = await api.createSender({
        name: nombre
      });
      const items = this.state.items;
      items.push(item);
      this.setState({
        items,
        isCreating: false
      })
    } catch (e) {
      console.error(e);
      this.setState({isCreating: false});
    }
  }

  render() {
    const {
      items,
      isLoading,
      isCreating,
    } = this.state;
    let child;

    if (isLoading) {
      child = (
        <CircularProgress container={<div style={{ padding: 32 }} />} />
      );
    } else {
      child = (
        <React.Fragment>
          <List>
            {
              items.map((item, index) => {
                return (
                  <ListItem divider={true} dense={false} key={index}>
                    <ListItemIcon><BusinessIcon /></ListItemIcon>
                    <ListItemText>{item.name}</ListItemText>
                    <ListItemSecondaryAction>
                      <IconButton variant="outlined"
                        onClick={
                          () => {
                            this.props.history.push(
                              `/sender/${item.senderId}`
                            )
                          }
                        }>
                        <EditIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              })
            }
          </List>
        </React.Fragment>
      );
    }

    return (
      <Container component="main">
        <Grid container>
          <Grid item sm={6}>
            <h1>Clientes de envío</h1>
          </Grid>
          <Grid item sm={6} style={{textAlign: 'right'}}>
            <Button variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              disabled={isCreating}
              onClick={this.register.bind(this)}>
              Registrar
            </Button>
          </Grid>
        </Grid>
        <Paper>
          {child}
        </Paper>
        {items
          ? <div style={{ paddingTop: 16, color: 'grey', textAlign: 'center' }}>
            {`${items.length} clientes de envio`}
          </div> : null}
      </Container>
    );
  }
}

export default withRouter(SendersScreen);