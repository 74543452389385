import React, {useReducer, Fragment, useCallback, useEffect} from 'react';
import {
  Button,
  Divider,
  Grid,
} from '@material-ui/core';
import FeatureTile from './feature_tile';
import {
  Add as AddIcon
} from '@material-ui/icons';
import API from '../api';

const api = new API();

const Action = {
  LOAD: 1,
  SELECT_CATEGORY: 2,
  ADD_IMAGE: 3,
  DELETE_IMAGE: 4,
  UPDATE_FIELD: 5,
  START_SAVING: 6,
  SAVE_SUCCESS: 7,
  SELECT_USER: 8,
  ADD_FEATURE: 9,
  UPDATE_FEATURE: 10,
  CANCEL_EDITING_FEATURE: 11,
  SAVED_FEATURE: 12,
  DELETED_FEATURE: 13,
  SELECT_VARIANT: 14
};

const initialState = {
  features: [],
  editingFeaturesIds: [],
  featuresOptions: {},
  featuresIds: []
};

function reducer(state, action) {
  switch (action.type) {
    case Action.START_EDITING_FEATURE:
      return {...state,
        editingFeaturesIds: [...state.editingFeaturesIds, action.featureId],
        featuresOptions: {
          ...state.featuresOptions,
          [action.featureId]: action.options
        }
      };

    case Action.ADD_FEATURE:
      const tempId = parseInt(Math.random() * 0xFFFFFF);
      return { ...state,
        features: [...(state.features ?? []),
          {
            isNew: true,
            id: tempId,
            isTemporal: true,
            isOpen: action.open ? true : false,
            featureId: null
          }
        ],
        editingFeaturesIds: [...state.editingFeaturesIds, tempId]
      };

    case Action.UPDATE_FEATURE:
      return {
        ...state,
        features: (state.features ?? []).map(
          i => i.id === action.tempFeature.id ? {
            ...action.feature,
            isTemporal: true,
            isOpen: action.tempFeature.isOpen,
            featureId: action.feature?.id
          } : i
        ),
        featuresOptions: {
          ...state.featuresOptions,
          [action.feature.id]: action.options
        },
        editingFeaturesIds: state.editingFeaturesIds.map(
          id => id === action.tempFeature.id ? action.feature.id : id
        ),
        featuresIds: [...state.featuresIds, action.feature.featureId]
      };

    case Action.SAVED_FEATURE:
      return {
        ...state,
        editingFeaturesIds: state.editingFeaturesIds
          .filter(id => id !== action.feature.id),
        features: state.features
          .map(f => f.id === action.feature.featureId ? action.feature : f)
      };
    
    case Action.CANCEL_EDITING_FEATURE:
      const feature = state.features?.filter(
        i => i.id === action.featureId
      )[0];
      
      if (feature) {
        return {
          ...state,
          editingFeaturesIds: state.editingFeaturesIds
            .filter(i => i !== action.featureId),
          features: feature.isTemporal
            ? state.features.filter(
              i => i.id !== action.featureId
            ) : state.features,
          featuresIds: state.featuresIds.filter(id => id !== action.featureId)
        };
      } else {
        return state;
      }

    case Action.DELETED_FEATURE:
      return {
        ...state,
        features: state.features
          .filter(i => i.id !== action.feature.id),
        editingFeaturesIds: state.editingFeaturesIds
          .filter(id => id !== action.feature.id),
        featuresIds: state.featuresIds
          .filter(id => id !== action.feature.id),
      };

    default:
      return state;
  }
}

export default function FeaturesList(props) {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    product: props.product,
    features: props.features,
    featuresIds: props.features?.filter(i => i.featureId).map(i => i.featureId),
  });
  const {saverFunction, deleteFunction, parentId} = props;

  const handleOnEditFeature = useCallback(async (featureId) => {
    try {
      const options = await api.getFeatureOptions({ featureId });
      dispatch({
        type: Action.START_EDITING_FEATURE,
        featureId,
        options,
      });
    } catch (e) {
      alert(e.message);
    }
  }, []);

  const handleAddFeature = useCallback(() => {
    dispatch({type: Action.ADD_FEATURE});
  }, []);

  const handleAddOpenFeature = useCallback(() => {
    dispatch({type: Action.ADD_FEATURE, open: true});
  }, []);

  useEffect(() => {
    console.log(props.features);
    console.log(
      props.features?.filter(i => i.featureId).map(i => i.featureId)
    );
  }, [props.features]);

  return (
    <Fragment>
      <div style={{overflow: "hidden", padding: 8, paddingLeft: 16, color: "#999"}}>
        Se encontraron {state.features?.length ?? 0} características.
      </div>
      <Divider />

      {state.features?.map((f, index) => {
        return (
          <Fragment key={index}>
            <FeatureTile
              feature={f}
              parentId={parentId}
              editing={state.editingFeaturesIds.indexOf(f.id) > -1}
              onEdit={handleOnEditFeature}
              options={state.featuresOptions[f.id?.toString() ?? ""] ?? []}
              value={f.optionId}
              categoryId={state.product?.category?.id}
              product={state.product}
              saverFunction={saverFunction}
              deleteFunction={deleteFunction}
              onChangeFeature={(chosenFeature) => dispatch({
                type: Action.UPDATE_FEATURE,
                feature: chosenFeature,
                options: chosenFeature.options,
                tempFeature: f
              })}
              onCancel={(featureId) => dispatch({
                type: Action.CANCEL_EDITING_FEATURE,
                featureId,
              })}
              onSaved={(f) => dispatch({
                type: Action.SAVED_FEATURE,
                feature: f
              })}
              onDeleted={() => dispatch({
                type: Action.DELETED_FEATURE,
                feature: f
              })}
              ignoreIds={state.featuresIds}
            />
            <Divider />
          </Fragment>
        );
      })}
      <div style={{overflow: "hidden", padding: 8, paddingLeft: 16, color: "#999"}}>
        <Grid container direction="row">
          <Grid item>
            <Button startIcon={<AddIcon />} onClick={handleAddFeature}>
              Crear campo de opción
            </Button>
          </Grid>
          <Grid item>
            <Button startIcon={<AddIcon />} onClick={handleAddOpenFeature}>
              Crear campo abierto
            </Button>
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );
}