import 'date-fns';
import React from 'react';
import Container from '@material-ui/core/Container';
import { withRouter } from "react-router-dom";
import API, { wwwRoot } from '../api';
import RoomIcon from '@material-ui/icons/Room';
import RequestDialog from '../components/request-dialog';
import {
  List,
  Paper,
  Card,
  CardContent,
  Grid,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
  TextField,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Menu,
  Chip,
  Avatar,
  Link,
  Button,
  Popover,
  Typography,
} from '@material-ui/core';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import PackageTypeSelect from '../components/packagetype-select';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PrintIcon from '@material-ui/icons/Print';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { PackageDataModal } from '../components/packagedata';
import PackageMonitorDialog from '../components/package-monitor-dialog';
import { DeliveryPaymentOrigin, DeliveryStatus } from '../models/delivery';
import EstatusEntregaSelect from '../components/estatus-entrega-select';
import DriverPopover from '../components/driver-popover';
import momentz from 'moment-timezone';
import { parse, encode } from 'querystring';
import DateFnsUtils from '@date-io/date-fns';
import { DeliveryTile } from './routes';
import DeliveryFullTile from '../components/delivery-full-tile';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import CancelIcon from '@material-ui/icons/Cancel';

import {
  blue,
} from '@material-ui/core/colors';

import {
  FiberManualRecord as FiberManualRecordIcon
} from '@material-ui/icons';

const MAPS_URL = 'https://www.google.com/maps/search/';

const api = API.instance();
const estatusEntregaMap = DeliveryStatus.map;
const tz = momentz.tz.guess();

class DeliveriesListScreen extends React.Component {
  state = {
    redirect: null,
    paqueteria: 0,
    address: '',
    place: null,
    guia: null,
    paquetes: [],
    filtered: null,
    search: '',
    zonas: [],
    zona: null,
    packageTypes: [],
    packageType: null,
    vehicleType: null,
    vehicleTypes: [],
    showDataModal: false,
    chosenPackageId: null,
    asignacion: -1,
    showPackageMonitor: false,
    packageIdMonitor: null,
    asignaciones: [
      {id: -1, title: '- Todos -'},
      {id: 0, title: "Sin asignar a repartidor"},
      {id: 1, title: "Asignados a repartidor"},
    ],
    senders: [],
    statuses: [],
    showDriverPopOver: false,
    driverPopOverId: null,
    popoverChofer: null,
    popoverAnchorEl: null,
    drivers: [],
    driverId: 0,
    fecha: 1,
    queryParams: {},
    selectedDate: new Date(),

    isLoading: true,

    // Request dialg
    requestedDelivery: null,
    showRequestModal: false,
    selectedDriverId: null
  }

  searchTimeout = null;

  constructor() {
    super();
    this.handleCloseRequestModal = this.handleCloseRequestModal.bind(this);
    this.handleOpenRequestModal = this.handleOpenRequestModal.bind(this);
  }

  componentDidMount() {
    const queryParams = parse(this.props.location.search.replace('?', ''));

    api.attachHistory(this.props.history, '/login');

    this.setState({
      queryParams,
      driverId: queryParams.driverId || null,
      fecha: queryParams.fecha ? parseInt(queryParams.fecha) : 1
    }, () => {
      this.loadSenders();
      this.loadVehicleTypes();
      this.loadPackageTypes();
      this.loadCatalogs();
      this.load();
    });
  }

  async loadPackageTypes() {
    api.getPackagesTypes().then(pt => {
      this.setState({packageTypes: pt});
    });
  }

  async loadVehicleTypes() {
    api.getVehicleTypes().then(pt => {
      this.setState({vehicleTypes: pt});
    });
  }

  async loadSenders() {
    api.getSenders().then((senders) => {
      this.setState({senders});
    });
  }

  async load() {
    const update = {isLoading: false};
    const {
      zona,
      packageType,
      vehicleType,
      searchText,
      asignacion,
      sender,
      statuses,
      driverId,
      fecha,
      selectedDate,
    } = this.state;

    const options = {
      top: 300,
      zoneId: zona ? zona.zonaId : null,
      tipoPaqueteId: packageType ? packageType.tipoPaqueteId : null,
      tipoVehiculoId: vehicleType ? vehicleType.tipoVehiculoId : null,
      search: searchText ? searchText : null,
      asignados: asignacion !== null && asignacion > -1 ? asignacion : null,
      senderId: sender ? sender.senderId : null,
      estatusEntrega: statuses ? statuses.map(e => e.id) : null,
      driverId: driverId ? driverId : null,
      sortBy: 'date_desc'
    };

    if (fecha === 1) {
      options.fromDate = momentz(selectedDate).startOf('day')./*tz('utc').*/format()
      options.toDate = momentz(selectedDate).endOf('day')./*tz('utc').*/format();
    } else if (fecha === 2) {
      options.fromDeliverDate = momentz(selectedDate).startOf('day').tz('utc').format();
      options.toDeliverDate = momentz(selectedDate).endOf('day').tz('utc').format();
    }

    try {
      update.paquetes = await api.getPaquetes(options);
    } catch (e) {
      console.error(e);
    }

    this.setState(update);
  }

  async loadCatalogs() {
    api.getChoferes().then((drivers) => {
      this.setState({drivers});
    });
  }

  async doSearch(searchText) {
    if (this.state.searchText === searchText) {
      this.setState({
        isLoading: true
      }, () => {
        this.load();
      });
    }
  }

  handleCloseRequestModal() {
    this.setState({
      showRequestModal: false
    });
  }

  handleOpenRequestModal(delivery) {
    this.setState({
      showRequestModal: true,
      requestedDelivery: delivery
    });
  }

  async handleCancelDelivery(delivery) {
    const question = `¿Quieres cancelar el envío (ID: ${delivery.id || delivery?.viajeId})`
      + ` desde ${delivery?.origin?.name || delivery?.origenNombre}`
      + ` hasta ${delivery?.destination?.name || delivery?.destinoNombre}`
      + `?`;

    if (window.confirm(question)) {
      const id = delivery?.id || delivery?.viajeId;
      try {
        const response = await api.cancelDeliveryAsAdmin(id, { asRole: "admin" });
        if (response.isSuccess) {
          alert(`Se canceló el envió ${id} correctamente`);
          window.location.reload();
        } else {
          alert(response.message);
        }
      } catch (e) {
        alert("Algo salió mal: " + e.message);
      }
    }
  }

  renderSearch() {
    const { history, location } = this.props;
    const {
      packageType,
      packageTypes,
      vehicleTypes,
      vehicleType,
      searchText,
      asignacion,
      asignaciones,
      showPackageMonitor,
      sender,
      senders,
      statuses,
      driverId,
      drivers,
      fecha,
      queryParams,
      selectedDate,
    } = this.state;

    const load = this.load.bind(this);

    return (
      <Grid container spacing={1}>
        <Grid item xs={12} md={6} lg={3}>
          <FormControl variant="outlined" fullWidth={true} margin="dense">
            <InputLabel id="filter-zone">Chofer</InputLabel>
            <Select
              variant="outlined"
              labelId="filter-zone"
              value={driverId ? driverId: 0}
              label="Chofer"
              margin="dense"
              onChange={(e) => {
                history.replace(
                  location.pathname
                    + "?"
                    +encode({...queryParams, driverId: e.target.value})
                )
                this.setState({
                  driverId: e.target.value
                }, () => {
                  this.load();
                });
              }}
            >
              <MenuItem value={0}>- Todos -</MenuItem>
              {drivers ? drivers.map((item, index) =>
                <MenuItem value={item.choferId} key={index}>
                  {item.nombre ? item.nombre : `Zona ${item.choferId}`}
                </MenuItem>
              ) : null}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <FormControl variant="outlined" fullWidth={true} margin="dense">
            <InputLabel id="filter-zone">Tipos de vehículo</InputLabel>
            <Select
              variant="outlined"
              labelId="filter-zone"
              margin="dense"
              value={vehicleType ? vehicleType : 0}
              label="Tipos de vehículo"
              onChange={(e) => {
                this.setState({
                  vehicleType: e.target.value,
                  isLoading: true
                }, () => {
                  this.load();
                });
              }}
            >
              <MenuItem value={0}>- Todas -</MenuItem>
              {vehicleTypes ? vehicleTypes.map((item, index) =>
                <MenuItem value={item} key={index}>
                  {item.nombre ? item.nombre : ``}
                </MenuItem>
              ) : null}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <FormControl variant="outlined" fullWidth={true} margin="dense">
            <InputLabel id="filter-package-type">Tipo de paquete</InputLabel>
            <PackageTypeSelect
              items={packageTypes}
              variant="outlined"
              margin="dense"
              labelId="filter-package-type"
              value={packageType ? packageType : 0}
              label="Zona"
              onChange={(value) => {
                console.log("ENTRA AL VALUE");
                this.setState({
                  packageType: value,
                  isLoading: true
                }, () => load());
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <FormControl variant="outlined" fullWidth={true} margin="dense">
            <InputLabel id="filter-estatuses">Estatus</InputLabel>
            <EstatusEntregaSelect
              value={statuses}
              margin="dense"
              labelId="filter-estatuses"
              label="Estatus de entrega"
              onChange={(statuses) => {
                this.setState({
                  statuses,
                  isLoading: true
                }, () => {
                  this.load();
                });
              }} />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={2} lg={2}>
          <FormControl variant="outlined" fullWidth={true} margin="dense">
            <InputLabel id="filter-asignacion">Tipo fecha</InputLabel>
            <Select
              variant="outlined"
              labelId="filter-asignacion"
              value={fecha}
              margin="dense"
              label="Tipo fecha"
              onChange={(e) => {
                history.replace(
                  location.pathname
                  + "?"
                  + encode({ ...queryParams, fecha: e.target.value })
                )
                this.setState({
                  fecha: e.target.value,
                  isLoading: true
                }, () => {
                  this.load();
                });
              }}
            >
              <MenuItem value={1}>
                De registro
              </MenuItem>
              <MenuItem value={2}>
                De entrega
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4} lg={4}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              inputVariant="outlined"
              variant="inline"
              fullWidth={true}
              format="dd/MM/yyyy"
              id="date-picker-inline"
              label="Fecha"
              margin="dense"
              value={selectedDate}
              onChange={(date) => {
                this.setState({
                  selectedDate: date,
                  isLoading: true
                }, () => {
                  history.replace(
                    location.pathname
                    + "?"
                    + encode({ ...queryParams, date: momentz(date).format() })
                  );
                  this.load();
                });
              }}
              KeyboardButtonProps={{'aria-label': 'change date'}}
            />
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <FormControl variant="outlined" fullWidth={true} margin="dense">
            <InputLabel id="filter-asignacion">Asignación a repartidor</InputLabel>
            <Select
              variant="outlined"
              labelId="filter-asignacion"
              value={asignacion}
              margin="dense"
              label="Asignación a repartidor"
              onChange={(e) => {
                this.setState({
                  asignacion: e.target.value,
                  isLoading: true
                }, () => {
                  this.load();
                });
              }}
            >
              {asignaciones.map((item, index) =>
                <MenuItem value={item.id} key={index}>
                  {item.title}
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <TextField
            value={searchText ? searchText : ''}
            placeholder="Encuentra algo"
            style={{ marginBottom: 16 }}
            // margin="dense"
            variant="outlined"
            fullWidth={true}
            onChange={(e) => {
              this.setState({ searchText: e.target.value });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button variant="contained" color="primary"
                    onClick={(e) => {
                      this.load();
                    }}
                  >
                    Buscar
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    );
  }

  renderDataModal() {
    return (
      <PackageDataModal
        open={this.state.showDataModal}
        packageId={this.state.chosenPackageId}
        onClose={() => this.setState({showDataModal: false})} />
    );
  }

  render() {
    const {
      isLoading,
      search,
      showPackageMonitor,
      packageIdMonitor,
      showDriverPopOver,
      popoverAnchorEl,
      driverPopOverId,
    } = this.state;
    let child;
    let paquetes;
    const now = momentz.tz('utc');
    
    if (search) {
      const text = search.toLowerCase();
      paquetes = this.state.paquetes.filter((item) => {
        return item.numeroGuia.toLowerCase() === text
          || item.direccion.toLowerCase().indexOf(text) !== -1
          || item.direccionLarga.toLowerCase().indexOf(text) !== -1;
      });
    } else {
      paquetes = this.state.paquetes;
    }

    if (isLoading) {
      child = (
        <CircularProgress container={<div style={{padding: 32}} />} />
      );
    } else {
      child = (
        
          <Grid container spacing={3}>
            {
              (paquetes && paquetes.length > 0) ? paquetes.map((paquete, index) => {
                const viaje = paquete.viaje ? paquete.viaje : null;
                let status;

                if (paquete.viaje) {
                  status = DeliveryStatus.map[paquete.viaje.estatusEntregaId];
                }

                return (
                  <Grid item xs={12} key={index}>
                    <Card>
                      <CardContent>
                        <Grid container wrap="nowrap">
                          <Grid item style={{ flexGrow: 1 }}>{/* Left part */}
                            <DeliveryFullTile
                              delivery={paquete?.viaje}
                              showPaymentInfo={true}
                              paymentLayout={"super"}
                              showClient={true}
                              showDriver={true}
                            />
                            <Grid spacing={1} container style={{ marginTop: 8 }}>
                              {paquete.viaje
                                ? (
                                  <React.Fragment>
                                    <Grid item>
                                      <Chip
                                        size="small"
                                        label={
                                          estatusEntregaMap[paquete.viaje.estatusEntregaId]?.title
                                            || `Estatus desconocido (${paquete.viaje.estatusEntregaId})`
                                        }
                                        style={{
                                          backgroundColor: estatusEntregaMap[paquete.viaje.estatusEntregaId]?.color,
                                          color: 'white'
                                        }}
                                      />
                                    </Grid>
                                    {paquete.viaje.fechaActualizacion
                                      ? (
                                        <Grid item>
                                          <Chip
                                            size="small"
                                            label={
                                              momentz
                                                .tz(paquete.viaje.fechaActualizacion, 'utc')
                                                .tz(tz)
                                                .format('DD/MM/YYYY HH:mm')
                                            }
                                          />
                                        </Grid>
                                      ) : null
                                    }
                                  </React.Fragment>
                                ) : null
                              }
                              {(paquete.viaje && paquete.viaje.chofer)
                                ? (
                                  <React.Fragment>
                                    <Grid item>
                                      <Chip
                                        size="small"
                                        onClick={async (evt) => {
                                          this.setState({ popoverAnchorEl: evt.currentTarget });
                                          const chofer = await api.getChofer(
                                            paquete.viaje.chofer.choferId
                                          );
                                          this.setState({
                                            driverPopOverId: chofer,
                                            showDriverPopOver: true
                                          });
                                        }}
                                        avatar={
                                          <Avatar src={paquete.viaje.chofer.imageUrl} />
                                        }
                                        label={
                                          `${paquete.viaje.chofer.nombre} ${paquete.viaje.chofer.apellidoPaterno}`
                                        }
                                        variant="outlined"
                                      />
                                    </Grid>
                                    <DriverPopover
                                      open={showDriverPopOver}
                                      anchorEl={popoverAnchorEl}
                                      driver={driverPopOverId}
                                      onClose={() => this.setState({ showDriverPopOver: false })}
                                    />
                                  </React.Fragment>
                                ) : null
                              }
                            </Grid>
                          </Grid>

                          <Grid item style={{ flexGrow: 0 }}>{/* Right part */}
                            <PopupState variant="popover" popupId="demo-popup-menu">
                              {(popupState) => (
                                <React.Fragment>
                                  <IconButton variant="outlined" {...bindTrigger(popupState)}>
                                    <MoreHorizIcon />
                                  </IconButton>
                                  <Menu {...bindMenu(popupState)}>
                                    <MenuItem
                                      onClick={() => {
                                        this.setState({
                                          chosenPackageId: null
                                        }, () => {
                                          this.setState({
                                            showDataModal: true,
                                            chosenPackageId: paquete.paqueteId
                                          }, () => {  
                                            popupState.close();
                                          });
                                        })
                                      }}
                                    >
                                      <ListItemIcon>
                                        <AssignmentIcon />
                                      </ListItemIcon>
                                      Detalle del paquete
                                    </MenuItem>
                                    <MenuItem
                                      component={Link}
                                      onClick={() =>
                                        this.setState({
                                          showPackageMonitor: true,
                                          packageIdMonitor: paquete.paqueteId,
                                        }, () => {
                                          popupState.close();
                                        })
                                      }
                                      // href={`${MAPS_URL}?api=1&query=${paquete.latitud},${paquete.longitud}`}
                                      target="_blank"
                                    >
                                      <ListItemIcon>
                                        <RoomIcon />
                                      </ListItemIcon>
                                      Ver en mapa
                                    </MenuItem>
                                    {paquete?.viaje?.isAssignable === true ? (
                                      <MenuItem
                                        component={Link}
                                        onClick={() => {
                                          this.handleOpenRequestModal(paquete.viaje);
                                        }}
                                        target="_blank"
                                      >
                                        <ListItemIcon>
                                          <SwapHorizIcon />
                                        </ListItemIcon>
                                        Reasignar
                                      </MenuItem>
                                    ) : null}
                                    {paquete?.viaje.isCancellableByAdmin ? (
                                      <MenuItem
                                        component={Link}
                                        onClick={() => this.handleCancelDelivery(paquete.viaje)}
                                        target="_blank"
                                      >
                                        <ListItemIcon>
                                          <CancelIcon />
                                        </ListItemIcon>
                                        Cancelar envío
                                      </MenuItem>
                                    ) : null}
                                    {/*<MenuItem
                                      component={Link}
                                      onClick={popupState.close}
                                      href={`/package/${paquete.paqueteId}/print`}
                                      target="_blank"
                                      color="default"
                                    >
                                      <ListItemIcon>
                                        <PrintIcon />
                                      </ListItemIcon>
                                      Imprimir QR
                                    </MenuItem>*/}
                                  </Menu>
                                </React.Fragment>
                              )}
                            </PopupState>

                            




                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                )
              }) : (
                <div style={{padding: 32, alignItems: 'center'}}>Sin resultados</div>
              )
            }
        </Grid>
      );
    }

    return (
      <Container component="main">
        <h1>Envíos</h1>
        {this.renderSearch()}
        {paquetes
          ? <div style={{ paddingBottom: 8, color: 'grey', textAlign: 'center' }}>
            {`${paquetes.length} paquetes encontrados`}
          </div> : null}
        {child}
        {this.renderDataModal()}
        <PackageMonitorDialog
          open={showPackageMonitor}
          onClose={() => {
            this.setState({showPackageMonitor: false}, () => {
              setTimeout(() => this.setState({packageIdMonitor: null}), 1000);
            })
          }}
          packageId={packageIdMonitor}
          viewport={this.props.viewport} />
        <RequestDialog
          cancellable={false}
          delivery={this.state.requestedDelivery}
          open={this.state.showRequestModal}
          onClose={this.handleCloseRequestModal}
          title="Reasignación de chofer"
          assignTitle="Reasignar"
        />
      </Container>
    );
  }
}

function ChipSection() {

}

export default withRouter(DeliveriesListScreen);