import React, { useEffect, useState } from 'react';
import {
  Select,
  MenuItem,
  ListItemText,
  FormControl,
  InputLabel,
} from '@material-ui/core';

function CategorySelect({
  categories,
  selected,
  onChange,
  nothingLabel,
}) {
  const [categoriesMap, setCategoriesMap] = useState({});

  useEffect(() => {
    if (categories && categories.length > 0) {
      const map = {};
      categories.forEach(c => map[c.id] = c);
      setCategoriesMap(map);
    }
  }, [categories]);

  return (
    <FormControl variant="outlined" fullWidth={true} margin="dense" helpertext="Some important text">
      <InputLabel id="filter-zone">Categoría</InputLabel>
      <Select
        variant="outlined"
        label="Categorías"
        renderValue={(i) => categoriesMap ? categoriesMap[i]?.name : ""}
        value={selected ?? 0}
        onChange={onChange}
      >
        {nothingLabel ? (
          <MenuItem value={0}>
            <ListItemText primary={nothingLabel} />
          </MenuItem>
        ) : null}
        {categories?.map(c =>
          <MenuItem value={c.id} key={c.id}>
            <ListItemText primary={c.name} />
          </MenuItem>
        ) ?? null}
      </Select>
    </FormControl>
  );
}

export default CategorySelect;