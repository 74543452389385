import React, {
  useCallback,
  useEffect,
  useReducer,
  useMemo,
} from 'react';
import {
  Container,
  Grid,
  FormControl,
  OutlinedInput,
  InputAdornment,
  CircularProgress,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Paper,
  Chip,
} from '@material-ui/core';
import {
  makeStyles,
  withStyles,
} from '@material-ui/core/styles';
import API from '../../api';
import { useHistory } from "react-router-dom";
import { parse, encode } from 'querystring';
import NumberFormat from 'react-number-format';
import { SmallAvatar } from '../../components/avatars';
import UserDialog from '../../components/user-dialog';
import {
  Search as SearchIcon,
  MoreHoriz as MoreHorizIcon,
  Add as AddIcon,
  Edit as EditIcon,
} from '@material-ui/icons';
import { UserRoleType } from '../../models/user';

const Money = (props) => {
  return (
    <NumberFormat
      thousandSeparator={true}
      decimalScale={2}
      fixedDecimalScale={2}
      displayType="text"
      prefix="$"
      {...props}
    />
  );
}

const resultsPerPage = 50;
const api = API.instance();
const defaultOptions = {
  include: ['balance'],
  roles: ['client'],
  resultsPerPage,
};

const initialState = {
  users: [],
  isSearching: false,
  search: null,
  searchText: '',
  showDetail: false,
  user: null,
  page: 1,
  lastOptions: null,
  endOfPages: false,
  roles: []
};

function reducer(state, action) {
  switch (action.type) {
    case 'inputSearch':
      return { ...state, searchText: action.text };
    case 'startSearching':
      return {
        ...state,
        isSearching: true,
        search: state.searchText,
      };
    case 'replaceUsers':
      return {
        ...state,
        users: action.users,
        isLoading: false,
        page: 1,
        isSearching: false,
        endOfPages: false
      };
    case 'appendUsers':
      return {
        ...state,
        users: [ ...(state.users), ...(action.users) ],
        isLoading: false,
        page: state.page + 1,
        endOfPages: action?.users?.length === 0
      };
    case 'closeDetail':
      return { ...state, showDetail: false };
    case 'openDetail':
      return { ...state, showDetail: true };
    case 'loadUserDetail':
      return { ...state, user: action.user };
    case 'fillRoles':
      return { ...state, roles: action.roles };
    default:
      return state;
  }
}

function UsersManageScreen({
  location,
}) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();
  const params = parse(location.search.replace('?', ''));
  const userRoles = useMemo(() => API.instance().getSessionRoles(), []);
  const isGlobal = useMemo(() => {
    return userRoles.indexOf(UserRoleType.SUPERADMIN) > -1
      || userRoles.indexOf(UserRoleType.USER_MANAGER) > -1;
  }, []);

  const getUsers = (op) => {
    const nextPage = op?.next === true;
    let page = nextPage ? (state.page + 1) : 1;
    const options = {
      search: state.search,
      page,
      include: ['balance', 'roles'],
      roles: isGlobal ? ['admin'] : null,
      resultsPerPage,
    };

    api.getUsers(options).then((u) => {
      if (nextPage) {
        dispatch({
          type: 'appendUsers',
          users: u,
          page,
        });
      } else {
        dispatch({ type: 'replaceUsers', users: u });
      }
    }).catch((e) => {
      console.error(e);
    });
  };

  const onSubmit = useCallback((e) => {
    dispatch({ type: 'startSearching' });
    e.preventDefault();
    history.replace(`${location.pathname}?${
      encode({...params, search: state.searchText})
    }`);
    // getUsers();
  }, []);

  const handleLoadMore = useCallback(() => {
    if (state.isLoading) {
      return;
    }
    getUsers({ next: true });
  }, [state]);

  const openDetail = useCallback((user) => {
    dispatch({ type: 'openDetail' });

    api.getUser(user.id, {include: ['balance', 'lastDeposits', 'roles']})
      .then((u) => {
        dispatch({ type: 'loadUserDetail', user: u });
      }).catch(e => {
        console.log(`[openDetail] error: ${e}`);
      })
  }, []);

  useEffect(() => getUsers(), []);
  useEffect(() => getUsers(), [state.search]);
  useEffect(() => {
    async function getRoles() {
      const roles = await api.getRoles();
      dispatch({ type: 'fillRoles', roles: roles });
    }
    getRoles();
  }, []);

  const {
    user,
    users,
    isLoading,
    showDetail,
    isSearching,
    endOfPages,
  } = state;

  return (
    <Container>
      <h1>Usuarios de sistema</h1>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container direction="row" spacing={2}>
            <Grid item style={{flexGrow: 1}}>
              <form onSubmit={onSubmit}>
                <FormControl
                  variant="outlined"
                  label="Asignar a zona"
                  fullWidth={true}
                >
                  <OutlinedInput
                    style={{ backgroundColor: 'white' }}
                    placeholder="Escribe un criterio de búsqueda"
                    variant="outlined"
                    startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
                    value={state.searchText ?? ''}
                    onChange={(e) => dispatch({
                      type: 'inputSearch',
                      text: e.target.value
                    })}
                    endAdornment={
                      <InputAdornment position="start">
                        <Button
                          variant={isSearching ? "outlined" : "contained"}
                          color="primary"
                          disableElevation
                        >
                          {isSearching ? (
                            <CircularProgress size={24} />
                          ) : "Buscar"}
                        </Button>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </form>
            </Grid>
            <Grid item>
              <Button component="a" href="/users/new" variant="contained" style={{height: "100%"}} startIcon={<AddIcon />}>
                Crear
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}
            elevation={3}
            style={{borderRadius: 16, marginTop: 8, marginBottom: 16}}
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell><strong>Nombre</strong></TableCell>
                  <TableCell><strong>ID</strong></TableCell>
                  <TableCell><strong>Contacto</strong></TableCell>
                  <TableCell><strong>Roles</strong></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users?.map((u, idx) =>
                  <TableRow key={idx}>
                    <TableCell width={40}>
                      <SmallAvatar src={u.imageUrl} />
                    </TableCell>
                    <TableCell>
                      {u.fullName}
                    </TableCell>
                    <TableCell>
                      {u.id}
                    </TableCell>
                    <TableCell>
                      <div>{u.email}</div>
                      <div>{u.phone}</div>
                    </TableCell>
                    <TableCell>
                      <Grid container spacing={1}>
                        {u.roles?.map(role =>
                          <Grid item key={`${u.id}_chip_${role.id}`}>
                            <RoleChip label={role?.name} color="primary" />
                          </Grid>
                        ) ?? null}
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <IconButton component="a" href={`/users/${u.id}/edit`}>
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      
      <UserDialog
        roles={state.roles}
        open={showDetail}
        onClose={() => dispatch({ type: 'closeDetail' })}
      />
    </Container>
  );
}

const RoleChip = withStyles({
  root: {
    padding: 0,
    paddingBottom: 0,
    fontSize: 10,
    height: 16,
  },
  outlined: {
    color: "black",
    borderColor: "black"
  }
})((props) => <Chip size="small" variant="outlined" {...props} />);

export default UsersManageScreen;