import React, { useCallback, useEffect, useState } from 'react';
import {
  TextField,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  MenuItem,
  Checkbox,
  Button,
  Grid,
  Select,
  CircularProgress,
  FormControl,
  InputLabel,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { WithStyles } from '@material-ui/core';
import { FeatureOptionInlineForm } from './feature_option_forms';
import API from '../api';
import {
  Add as AddIcon,
  NewReleases as NewReleasesIcon
} from '@material-ui/icons';

const api = new API();

export default function FeatureForm(props) {
  const {categories, featureId, onDiscard, onChange} = props;
  const [loading, setLoading] = useState(true);
  const [feature, setFeature] = useState(null);
  const [newCategories, setNewCategories] = useState([]);
  const [newOptions, setNewOptions] = useState([]);
  const [categoriesIds, setCategoriesIds] = useState([]);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    try {
      const ft = await api.saveFeature(featureId, {
        name: feature.name,
        featureCategories: categoriesIds?.map(cid => ({
          featureId: featureId,
          categoryId: cid,
        }))
      }, {newOptions});
      setFeature(ft);
      setNewOptions([]);
      onChange(ft);
    } catch (e) {
      alert(`Algo salió mal: ${e.message}`);
    }
  }, [featureId, feature, newOptions, categoriesIds]);

  const handleName = useCallback(e => {
    setFeature({...feature, name: e.target.value})
  }, [feature]);

  const handleCheck = useCallback(e => {
    const id = parseInt(e.currentTarget.getAttribute('data-category-id'));
    
    if (categoriesIds.indexOf(id) === -1) {
      setCategoriesIds([...categoriesIds, id]);
      setNewCategories([...newCategories, id]);
    } else {
      setCategoriesIds(categoriesIds.filter(cid => cid !== id));
      setNewCategories(newCategories.filter(cid => cid !== id));
    }
  }, [feature, categoriesIds]);

  const handleDiscard = useCallback(e => {
    if ('function' === typeof onDiscard) {
      onDiscard();
    }
  }, [onDiscard]);

  const handleSaveOption = useCallback(option => {
    setNewOptions([
      ...newOptions,
      option,
    ]);
  }, [newOptions]);

  useEffect(() => {
    if (featureId) {
      async function load() {
        try {
          const feature = await api.getFeature(featureId);
          setFeature(feature);
          setCategoriesIds(feature?.categoriesIds ?? []);
        } catch (e) {
          console.error(e);
          alert("No se pudo cargar la caracteristica.");
        }
        setLoading(false);
      }
      load();
    }
  }, [featureId]);

  if (loading) {
    return (
      <div style={{padding: 16}}>
        <CircularProgress />
      </div>
    );
  }
  
  return (
    <form onSubmit={handleSubmit}>
      <div style={{padding: "8px 0 8px 16px", borderBottom: "solid 1px #e0e0e0"}}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <FormControl variant="outlined">
              <InputLabel htmlFor="featureName" label="Nombre" />
              <TextField label="Nombre" variant="outlined" margin="dense"
                id="featureName"
                defaultValue={feature?.name}
                onBlur={handleName}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl variant="outlined">
              <InputLabel id="feature-datatype-id-label">Tipo de dato</InputLabel>
              <Select margin="dense" labelId="feature-datatype-id-label" label="Tipo de dato"
                defaultValue={feature?.dataType ?? 0}
                style={{minWidth: 150}}
              >
                <MenuItem value={0}>Cualquiera</MenuItem>
                <MenuItem value="color">Color</MenuItem>
                <MenuItem value="boolean">Sí/No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl variant="outlined">
              <InputLabel htmlFor="featureDescription">Descripción</InputLabel>
              <TextField label="Descripción"
                id="featureDescription" variant="outlined" margin="dense" />
            </FormControl>
          </Grid>
        </Grid>
      </div>
      <Grid container>
        <Grid item xs={6}>
          <div style={{marginTop: 16, paddingLeft: 16}}>Opciones</div>
          <List>
            {feature?.options?.map((o, index) => <OptionItem option={o} dataType={feature.dataType} key={`notnew-${index}`} />)}
            {newOptions?.map((o, index) => <OptionItem option={o} isNew key={`new-${index}`} />)}
          </List>
          <div style={{padding: "8px 0 12px 16px", borderTop: "solid 1px #e0e0e0", borderBottom: "solid 1px #e0e0e0"}}>
            <FeatureOptionInlineForm onSave={handleSaveOption} />
          </div>
        </Grid>
        <Grid item xs={6} style={{borderLeft: "solid 1px #e0e0e0"}}>
          <div style={{marginTop: 16, paddingLeft: 16}}>Asignado a categorías</div>
          <List>
            {categories?.map(cat => {
              const checked = categoriesIds?.indexOf(cat.id) > -1;
              return (
                <ListItem dense button onClick={handleCheck}
                  key={cat.id} data-category-id={cat.id}>
                  <ListItemIcon>
                    <Checkbox
                      checked={checked}
                      color={newCategories.indexOf(cat.id) > -1 ? "primary" : "default"}
                      data-category-id={cat.id} />
                  </ListItemIcon>
                  {cat.name}
                </ListItem>
              );
            })}
          </List>
        </Grid>
      </Grid>
      <div style={{padding: 16, paddingTop: 16, borderTop: "solid 1px #e0e0e0"}}>
        <Grid container spacing={2}>
          <Grid item>
            <Button variant="contained" color="primary" type="submit">Guardar cambios</Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="default" onClick={handleDiscard}>
              Descartar
            </Button>
          </Grid>
        </Grid>
      </div>
    </form>
  );
}

function OptionItem({ option, dataType, isNew }) {
  return (
    <ListItem button style={{
      backgroundColor: isNew ? green[50] : null
    }}>
      {dataType === "color" ? (
        <OptionItemIcon>
          <div style={{
            width: 24, height: 24, borderRadius: 24,
            backgroundColor: option.visualValue
          }} />
        </OptionItemIcon>
      ) : null}
      {option.value}
      {isNew ? (
        <ListItemSecondaryAction>
          <NewReleasesIcon style={{width: "20px", color: green[400]}} />
        </ListItemSecondaryAction>
      ) : null}
    </ListItem>
  )
}

const OptionItemIcon = withStyles({
  root: {
    minWidth: 0,
    marginRight: 12
  }
})(ListItemIcon)