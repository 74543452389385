export class UserRoleType {
  static CLIENT = "EndUser";
  static DASHBOARD_MASTER = "DashboardMaster";
  static DRIVER = "Driver";
  static COLLECTOR = "DriverCollector";
  static DELIVERER = "DriverDeliverer";
  static ZONE_MONITOR = "ZoneMonitor";
  static SELLER = "Seller";
  static STORER = "Storer";
  static USER_MANAGER = "UserManager";
  static SUPERADMIN = "SuperAdmin";
  static DRIVERS_MANAGER = "DriverManager";
  static BRANCH_ADMIN = "BranchAdmin";
  static BRANCH_OPERATOR = "BranchOperator";
  static ACCOUNT_OWNER = "AccountOwner";
}
