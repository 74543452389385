import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

export default function TitleAndDescription(props) {
  const classes = useStyles();
  const { title, description } = props;

  return (
    <div>
      {title}
      <div className={classes.description}>
        {description}
      </div>
    </div>
  );
}

const useStyles = makeStyles({
  description: {
    color: "#888",
    fontSize: 12,
    lineHeight: "11px"
  }
});