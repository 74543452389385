import React, {
  useCallback,
  useEffect,
  useReducer,
  useMemo,
} from 'react';
import {
  Container,
  Grid,
  Box,
  Avatar,
  Card,
  FormControl,
  FormLabel,
  OutlinedInput,
  InputAdornment,
  CircularProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TableFooter,
  Typography,
  TextField,
  Select,
  ListItem,
  ListItemText,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import API from '../../api';
import { useHistory } from "react-router-dom";
import { parse, encode } from 'querystring';
import NumberFormat from 'react-number-format';
import {
  Search as SearchIcon,
  MoreHoriz as MoreHorizIcon,
  Print as PrintIcon,
  Add as AddIcon,
  Edit as EditIcon,
} from '@material-ui/icons';
import Button from '../../components/button';
import CategorySelect from '../../components/category-select';
import CategorySelectDialog from '../../components/category_select_dialog';
import { UserRoleType } from '../../models/user';

const Money = (props) => {
  return (
    <NumberFormat
      thousandSeparator={true}
      decimalScale={2}
      fixedDecimalScale={2}
      displayType="text"
      prefix="$"
      {...props}
    />
  );
}

const api = API.instance();

const initialState = {
  products: [],
  isSearching: false,
  search: null,
  searchText: '',
  showDetail: false,
  user: null,
  page: 1,
  lastOptions: null,
  showCreateDialog: false,
  categories: [],
  mounted: false,
  account: null,
  preloading: true,
  branches: [],
  fetching: false,
  filter: {
    search: '',
    branchId: null
  }
};

class Action {
  static REPLACE_PRODUCTS = "replaceProducts";
  static INPUT_SEARCH = "inputSearch";
  static START_SEARCHING = "startSearching";
  static BRANCHES_LOADED = "branchesLoaded";
  static SELECT_BRANCH = "selectBranch";
  static PRELOADED = "preloaded";
}

function reducer(state, action) {
  switch (action.type) {
    case Action.INPUT_SEARCH:
      return { ...state, searchText: action.text };
    case Action.START_SEARCHING:
      return {
        ...state,
        isSearching: true,
        filter: {...state.filter, search: state.searchText}
      };
    case Action.REPLACE_PRODUCTS:
      return {
        ...state,
        products: action.products,
        isSearching: false,
        fetching: false,
        preloading: false,
        ...(!state.mounted ? {mounted: true} : null),
      };
    case 'fetchUserState':
      return {
        ...state,
        account: action?.userState?.user?.defaultAccount
      };
    case Action.BRANCHES_LOADED:
      return {
        ...state,
        branches: action.branches,
      };
    case Action.PRELOADED:
      return {
        ...state,
        preloading: false
      };
    case Action.SELECT_BRANCH:
      return {
        ...state,
        filter: {...state.filter, branchId: action.branchId},
        products: null,
        fetching: true
      };
    case 'closeDetail':
      return { ...state, showDetail: false };
    case 'openDetail':
      return { ...state, showDetail: true };
    case 'loadUserDetail':
      return { ...state, user: action.user };
    case 'showCreateDialog':
      return { ...state, showCreateDialog: true };
    case 'closeCreateDialog':
      return { ...state, showCreateDialog: false };
    case 'fetchCategories':
      return { ...state, categories: action.categories };
    default:
      return state;
  }
}

function ProductsScreen({
  location,
}) {
  const roles = useMemo(() => {
    return API.instance().getSessionRoles();
  }, []);
  const isGlobal = useMemo(() => {
    return roles.indexOf(UserRoleType.SUPERADMIN) > -1;
  }, [roles]);
  const params = parse(location.search.replace('?', ''));
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    filter: {
      ...initialState.filter,
      branchId: parseInt(params.sucursal ?? "0"),
      search: params.search ?? ''
    },
    searchText: params.search ?? ''
  });
  const history = useHistory();

  const getProducts = useCallback(async (e) => {
    try {
      const { branchId, search } = state.filter;
      console.log(`[getProducts] account = ${state.account?.id}`);
      const products = await api.getProducts({
        limit: 50,
        sortName: "LatestFirst",
        search: search,
        accountId: state.accountId,
        branchId: branchId,
      });
      dispatch({ type: Action.REPLACE_PRODUCTS, products });
    } catch (e) {
      console.log(`[ProductsScreen.getProducts] ${e}`);
    }
  }, [state.account, state.filter]);

  const loadCategories = useCallback(async (e) => {
    try {
      const categories = await api.getCategories();
      dispatch({type: 'fetchCategories', categories});
    } catch (e) {
      console.log(`[getCategories] ${e}`);
    }
  }, []);

  const loadBranches = useCallback(async (e) => {
    try {
      const branches = await API.instance().getBranches();
      dispatch({type: Action.BRANCHES_LOADED, branches});
    } catch (e) {
      console.log(`[ProductsScreen.loadBranches] ERROR: ${e.message}`);
    }
  }, []);

  const onSubmit = useCallback((e) => {
    dispatch({ type: Action.START_SEARCHING });
    e.preventDefault();
    history.replace(`${location.pathname}?${
      encode({...params, search: state.searchText})
    }`);
  }, [history, location.pathname, params, state.searchText]);

  const handleOpenCreateForm = useCallback((e) => {
    dispatch({type: 'showCreateDialog'});
  }, []);

  const handleAdd = useCallback(categoryId => {
    api.createProduct({categoryId}).then(r => {
      history.push(`/product/${r.id}`);
    }).catch(err => {
      alert(`Error: ${err}`);
    });
  }, [history]);

  const handleCloseCreateDialog = useCallback(e => {
    dispatch({type: 'closeCreateDialog'});
  }, []);

  const handleChangeBranch = useCallback((e) => {
    const params = new URLSearchParams(history.location.search);
    const branchId = parseInt(e.target.value);
    if (branchId) {
      params.set('sucursal', branchId);
    } else {
      params.delete('sucursal');
    }
    history.replace(`/products?` + params.toString());
    dispatch({type: Action.SELECT_BRANCH, branchId});
  }, [history]);

  const loadState = useCallback(async () => {
    try {
      const userState = await API.instance().getUserState({
        include: ['defaultAccount']
      });
      dispatch({type: 'fetchUserState', userState});
    } catch (e) {
      console.log(`[ProductsScreen.loadState] ERROR: ${e.message}`);
    }
  }, []);

  const preload = useCallback(async () => {
    loadBranches();
    await loadState();
    await loadCategories();
    // await getProducts();
    dispatch({type: Action.PRELOADED});
  }, []);

  useEffect(() => {
    getProducts();
  }, [state.filter]);

  useEffect(() => {
    preload();
  }, []);

  const {
    products,
    isSearching,
  } = state;

  return (
    <Container>
      <h1>Productos {!isGlobal ? state.account?.name : null}</h1>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box display="flex">
            <Box flexGrow={1}>
              <form onSubmit={onSubmit}>
                <FormControl
                  variant="outlined"
                  label="Asignar a zona"
                  fullWidth={true}
                >
                  <OutlinedInput
                    margin="dense"
                    style={{ backgroundColor: 'white', paddingRight: 0 }}
                    placeholder="Encontrar por nombre, sku, o marca"
                    variant="outlined"
                    startAdornment={<InputAdornment><SearchIcon /></InputAdornment>}
                    value={state.searchText ?? ''}
                    onChange={(e) => dispatch({
                      type: Action.INPUT_SEARCH,
                      text: e.target.value
                    })}
                    endAdornment={
                      <InputAdornment>
                        {isSearching ? (
                          <CircularProgress size={24} />
                        ) : (
                          <Button
                            variant={isSearching ? "outlined" : "contained"}
                            color="primary"
                            disableElevation
                            height={26}
                            style={{marginBottom: 0}}
                            type="submit"
                          >
                            Buscar
                          </Button>
                        )}
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <FormControl variant="outlined" style={{marginTop: 16, width: 300}} margin="dense">
                  <InputLabel id="sucursal-label">Disponibles en sucursal</InputLabel>
                  <Select
                    labelId="sucursal-label"
                    id="sucursal-selection"
                    value={state.filter.branchId}
                    onChange={handleChangeBranch}
                    label="Disponibles en sucursal"
                    disabled={state.fetching}
                    margin="dense"
                  >
                    <MenuItem value={0}>
                    Ninguna en particular
                    </MenuItem>
                    {state?.branches?.map(e =>
                      <MenuItem value={e.id}>
                        {e.name}
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </form>
            </Box>
            <Box style={{width: 16}}></Box>
            <Box>
              <Button color="primary" onClick={handleOpenCreateForm} style={{height: "100%"}}>
                <AddIcon />
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell><strong>Nombre</strong></TableCell>
                  <TableCell><strong>Precio Unitario</strong></TableCell>
                  <TableCell><strong>Unidades disponibles</strong></TableCell>
                  <TableCell><strong>ID</strong></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(!products || !products.length) && state.mounted ? (
                  <TableRow>
                    <TableCell colSpan={6} style={{padding: 32, textAlign: "center"}}>
                      {state.fetching ? (
                        <CircularProgress />
                      ) : "No hay nada aquí"}
                    </TableCell>
                  </TableRow>
                ) : null}
                {products?.map((p, idx) =>
                  <TableRow key={idx}>
                    <TableCell width={40}>
                      <Avatar src={p.mainImage?.thumbnailUrl ?? null} style={{width: 48, height: 48}} />
                    </TableCell>
                    <TableCell>
                      {p.title}
                      <Typography color="textSecondary">{p?.sku}</Typography>
                    </TableCell>
                    <TableCell>
                      <Money value={p?.unitPrice ?? 0} />
                    </TableCell>
                    <TableCell>
                      {p.availableQuantity ?? 0}
                    </TableCell>
                    <TableCell>
                      {p.id}
                    </TableCell>
                    <TableCell>
                      <Grid container justifyContent="flex-end">
                        <Grid item>
                          <IconButton href={`/product/${p.id}`} size="small">
                            <EditIcon />
                          </IconButton>
                        </Grid>
                        <Grid item>
                          <IconButton href={`/products/${p.id}/print-sku`} target="_blank" size="small">
                            <PrintIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={6}>

                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </Card>
        </Grid>
      </Grid>
      <CategorySelectDialog
        okTitle="Crear producto"
        description="Elige una categoría a la que pertencerá el producto."
        open={state.showCreateDialog}
        onClose={handleCloseCreateDialog}
        onOk={handleAdd}
        categories={state.categories} />
    </Container>
  );
}

export default ProductsScreen;