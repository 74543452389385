import React, {
  useState
} from 'react';
import { withRouter } from "react-router-dom";
import {
  Container,
  TextField,
  Grid,
  Divider,
  Button,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';

function RegistrateScreen(props) {
  const [isSending, setIsSending] = useState(false);
  const [nombre, setNombre] = useState('');
  const [apellidoPaterno, setApellidoPaterno] = useState('');
  const [apellidoMaterno, setApellidoMaterno] = useState('');
  const [telefono, setTelefono] = useState('');
  const [direccion, setDireccion] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [acceptTOS, setAcceptTOS] = useState(false);

  return (
    <Container maxWidth="xs" style={{paddingBottom: 48}}>
      <div style={{marginTop: 32, textAlign: 'center'}}>
        <img src="/das-big.png" alt="big das company logo" style={{width: 96}} />
      </div>
      <h1>Registro de Repartidor</h1>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField fullWidth={true}
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
            variant="outlined"
            label="Nombre" />
        </Grid>
        <Grid item xs={6}>
          <TextField fullWidth={true}
            value={apellidoPaterno}
            onChange={(e) => setApellidoPaterno(e.target.value)}
            variant="outlined"
            label="Apellido paterno" />
        </Grid>
        <Grid item xs={6}>
          <TextField fullWidth={true}
            value={apellidoMaterno}
            onChange={(e) => setApellidoMaterno(e.target.value)}
            variant="outlined"
            label="Apellido materno" />
        </Grid>
        <Divider />
      </Grid>
      <Grid item xs={12} style={{borderTop: 'solid #c0c0c0 1px', marginTop: 16, paddingTop: 16}}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField fullWidth={true}
              value={telefono}
              onChange={(e) => setTelefono(e.target.value)}
              variant="outlined"
              label="Número de celular" />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth={true}
              value={direccion}
              onChange={(e) => setDireccion(e.target.value)}
              variant="outlined"
              label="Dirección" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{borderTop: 'solid #c0c0c0 1px', marginTop: 16, paddingTop: 16}}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField fullWidth={true}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              variant="outlined"
              label="Correo electrónico" />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth={true}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              variant="outlined"
              label="Contraseña" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{marginTop: 8}}>
        <FormControlLabel
          control={
            <Checkbox checked={acceptTOS}
              onChange={(e) => setAcceptTOS(e.target.checked)} />
          }
          label={
            <span>
              Acepto los <a href="#">Términos y condiciones</a>
            </span>
          }
        />
      </Grid>
      <Grid item xs={12} style={{paddingTop: 24}}>
        <Button
          variant="contained"
          disabled={isSending}
          color="primary"
          fullWidth={true}
          onClick={() => {
            setIsSending(true);
          }}>
          Completar Registro
        </Button>
      </Grid>
    </Container>
  )
}

export default withRouter(RegistrateScreen);