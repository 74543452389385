import React from 'react';
import UserForm from '../components/user_form';
import { useParams } from "react-router-dom";

export default function UserEditScreen() {
  const params = useParams();

  return (
    <UserForm title="Editar usuario" redirectTo="/users" userId={params.userId} />
  );
}