import React, {
  useState
} from 'react';
import {
  Select,
  MenuItem,
} from '@material-ui/core';

function PackageTypeSelect(props) {
  const [value, setValue] = useState(props.value ? props.value : 0);
  const items = props.items ? props.items : [];

  function selectItem(event) {
    setValue(event.target.value);
    if ('function' === typeof props.onChange) {
      props.onChange(event.target.value);
    }
  }

  return (
    <Select
      label="Tipo de paquete"
      variant="outlined"
      labelId="tipoPaquete"
      value={value}
      onChange={selectItem}
      margin={props.marign}
    >
      <MenuItem value={0}>- Sin elegir -</MenuItem>
      {items ? items.map((item, index) =>
        <MenuItem value={item} key={index}>
          {item.nombre}
        </MenuItem>
      ) : null}
    </Select>
  );
}

export default PackageTypeSelect;