import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import momentz from 'moment-timezone';
import API from '../api';
import qs from 'qs';
import {
  Container,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Grid,
  Card,
  Paper,
  CardContent,
  Table,
  TableContainer,
  TableHead,
  Divider,
} from '@material-ui/core';
import { grey, lightGreen, cyan, red } from '@material-ui/core/colors';
import {
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
import { DeliveryPhase } from '../models/delivery';
import DeliveriesChecklist from '../components/deliveries-checklist';
import { useDeliveriesStatsUpdater } from '../hooks/deliveries-stats-updater';
import StatPanel from '../components/stats/panel';
import {
  Storefront as StorefrontIcon,
  SwapVerticalCircleOutlined as SwapVerticalCircleOutlinedIcon,
  Star as StarIcon,
  LocalOfferOutlined as LocalOfferOutlinedIcon,
} from '@material-ui/icons';

const api = API.instance();

const phases = [
  { id: DeliveryPhase.collect, title: "Recolección" },
  { id: DeliveryPhase.deliver, title: "Entrega" }
];

export default function MesaControlScreen({
  location,
}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [userState, setUserState] = useState(null);
  const [zones, setZones] = useState(null);
  const [phaseId, setPhaseId] = useState(null);
  const [filters, updateFilters] = useParamFilters(location);
  const [counters, setCounters] = useCounters();
  const [openReceptions, setOpenReceptions] = useState(false);
  const [_, setDeliveries] = useState([]);
  const [lastDate, setLastDate] = useState(momentz());
  const {
    stats,
    byZones: statsByZones,
    updateStats
  } = useDeliveriesStatsUpdater({
    requestOptions: {
      scope: 'MesaControl'
    }
  });

  const updateCounters = useCallback(deliveries => {
    const update = { ...counters };

    update.phases[DeliveryPhase.collect] = deliveries
      .filter(d => DeliveryPhase.isCollecting(d.estatusEntregaId))
      .length;
    update.phases[DeliveryPhase.deliver] = deliveries
      .filter(d => DeliveryPhase.isDelivering(d.estatusEntregaId))
      .length;

    if (deliveries.length > 0) {
      update.zones = deliveries?.reduce((zones, delivery) => {
        let zoneId;

        if (phaseId === DeliveryPhase.collect) {
          zoneId = delivery?.originZone?.id;
        } else if (phaseId === DeliveryPhase.deliver) {
          zoneId = delivery?.destinationZone?.id;
        }

        if (!zones) {
          zones = {[zoneId]: 0}; 
        }

        if (!zones[zoneId]) {
          zones[zoneId] = 1;
        } else {
          zones[zoneId] += 1;
        }

        return zones;
      }, {});
    }

    setCounters(update);
  }, [counters]);

  const handleNewData = useCallback(deliveries => {
    updateCounters(deliveries);
    setDeliveries(deliveries);
  }, []);

  const zone = zones?.find(z => z.zonaId === filters?.zoneId);
  
  useEffect(() => {
    setLastDate(momentz());
  }, [stats]);

  useEffect(() => {
    async function firstLoad() {
      try {
        const response = await api.getUserState();
        setUserState(response);
        setIsLoading(false);
      } catch (e) {
        console.error(`[firsLoad] Error: ${e}`);
      }
    }

    firstLoad();
  }, []);

  if (isLoading) {
    return (
      <Container style={{marginTop: 24, padding: 16, textAlign: 'center'}}>
        Cargando...
      </Container>
    );
  }

  const spacing = 1;

  return (
    <Container style={styles.container}>
      <Grid container direction="row" spacing={spacing} style={{marginBottom: 8 * spacing}}>

        <Grid item xs={6}>
          <Grid container direction="column">
            <Grid item xs={12}>
              <h1 className={classes.title}>Same day</h1>
            </Grid>
            <Grid item>
              <Paper style={{borderRadius: 16, marginBottom: 8}}>
                <Grid container direction="column">
                  <Grid item style={{marginBottom: 8 * spacing}}>
                    <StatPanel
                      component="div"
                      padding="4px 8px 0px 16px"
                      description={
                        "Producto vendido que se recoge y va a bodega para generar ruta "
                        + "de entrega el mismo díade 1:00 a 8:00pm."
                      }
                      items={[
                        {label: "Bodega", value: stats?.byTime?.lastday?.inCentre ?? 0},
                        null,
                        {label: "Entregas", value: stats?.byTime?.lastday?.completed ?? 0},
                        {label: "Cancelados", value: stats?.byTime?.lastday?.cancelled ?? 0},
                      ]}
                      icons={[
                        <StorefrontIcon className={classes.icon} />,
                        <SwapVerticalCircleOutlinedIcon className={classes.icon} />,
                        <StarIcon className={classes.icon} />
                      ]}
                    />
                  </Grid>

                  <Divider />

                  <Grid item style={{marginBottom: 8 * spacing}}>
                    <StatPanel
                      component="div"
                      padding="4px 8px 0px 16px"
                      description={
                        "Producto vendido que se recoge y va a bodega para generar ruta "
                        + "de entrega el mismo díade 1:00 a 8:00pm."
                      }
                      items={[
                        {label: "Entrada", value: stats?.byTime?.sameday?.requested ?? 0},
                        {label: "Recolección", value: stats?.byTime?.sameday?.collected ?? 0},
                        null,
                        null,
                      ]}
                      icons={[
                        <LocalOfferOutlinedIcon className={classes.icon} />,
                        <StorefrontIcon className={classes.icon} />
                      ]}
                    />
                  </Grid>
                  <Divider />
                  <Grid item style={{marginBottom: 8 * spacing}}>
                    <StatPanel
                      component="div"
                      padding="4px 8px 0px 16px"
                      description={
                        "Producto que llega a bodega para seccionar la zona y generar"
                        + " la ruta de entrega de 1:00 a 8:00pm."
                      }
                      items={[
                        {label: "Salida", value: stats?.byTime?.sameday?.collected},
                        null,
                        {label: "Entrega", value: stats?.byTime?.sameday?.completed},
                        {label: "Cancelados", value: stats?.byTime?.sameday?.cancelled},
                      ]}
                      icons={[
                        <StorefrontIcon className={classes.icon} />,
                        <SwapVerticalCircleOutlinedIcon className={classes.icon} />,
                        <StarIcon className={classes.icon} />
                      ]}
                    />
                  </Grid>
                </Grid>

              </Paper>
            </Grid>
            <Grid item xs={12}>
              <h1 className={classes.title}>Next day</h1>
            </Grid>
            <Grid item>
              <StatPanel
                description={
                  "Producto vendido que se recoge y va a bodega para generar "
                  + "de entrega al día siguiente."
                }
                items={[
                  {label: "Entrada", value: stats?.byTime?.nextday?.inCentre},
                  {label: "Recolección", value: stats?.byTime?.nextday?.collected},
                  null,
                  null,
                ]}
                icons={[
                  <LocalOfferOutlinedIcon className={classes.icon} />,
                  <StorefrontIcon className={classes.icon} />
                ]}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={12}>
              <h1 className={classes.title}>Globales</h1>
            </Grid>
            <Grid item style={{marginBottom: 8 * spacing}} xs={12}>
              <StatPanel
                pre={
                  <div style={{paddingBottom: 8}}>
                    <div style={{fontSize: 15}}>{lastDate?.format('DD/MM/YYYY hh:mm:ss a')}</div>
                    <div style={{color: "#777", fontSize: 13}}>Última actualización</div>
                  </div>
                }
                items={[
                  {
                    label: "Recolectados",
                    value: stats?.general?.collected ?? 0,
                  },
                  {
                    label: "Entregados",
                    value: stats?.general?.completed ?? 0
                  },
                  {
                    label: "Cancelados",
                    value: stats?.general?.cancelled ?? 0
                  },
                  {
                    label: "Guías",
                    value: stats?.pending ?? 0
                  },
                ]}
                description={
                  "Acumulado de todos los envíos hechos del período"
                }
              />
            </Grid>

            <Grid item xs={12}>
              <h1 className={classes.title}>Zona {stats?.fromZone?.zone?.shortName ?? ""}</h1>
            </Grid>

            <Grid item xs={12} style={{marginBottom: 8 * spacing}}>
              <Grid container spacing={spacing}>
                <Grid item xs={6}>
                  <StatPanel
                    pre={<div style={{marginBottom: 4, fontSize: 12}}>8:00 a.m. a 12:00 p.m.</div>}
                    items={[
                      {label: "Solic.", value: stats?.fromZone?.requested},
                      {label: "Recol.", value: stats?.fromZone?.collected},
                      {label: "Cancel.", value: stats?.fromZone?.cancelled ?? "X"},
                    ]}
                    description="Recolecciones en zona actual."
                  />
                </Grid>
                <Grid item xs={6}>
                  <StatPanel
                    pre={<div style={{marginBottom: 4, fontSize: 12}}>8:00 a.m. a 12:00 p.m.</div>}
                    items={[
                      {label: "Bod", value: stats?.toAfternoon?.receivedInCentre ?? 0},
                      ...(stats?.toMorning?.zones?.map(zone => ({
                        label: zone.shortName ?? "Z",
                        value: zone.counter,
                      })) ?? [])
                    ]}
                    description="Entregas en otras zonas."
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={spacing}>
                <Grid item xs={6}>
                  <StatPanel
                    pre={<div style={{marginBottom: 4, fontSize: 12}}>2:00 p.m. a 8:00 p.m.</div>}
                    items={[
                      {label: "Solic.", value: 0},
                      {label: "Recol.", value: 0},
                      {label: "Cancel.", value: 0},
                    ]}
                    description="Recolecciones en zona actual."
                  />
                </Grid>
                <Grid item xs={6}>
                  <StatPanel
                    pre={<div style={{marginBottom: 4, fontSize: 12}}>2:00 p.m. a 8:00 p.m.</div>}
                    items={[
                      {label: "Ent", value: stats?.toAfternoon?.receivedInCentre},
                      ...(stats?.toAfternoon?.zones?.map(zone => ({
                        label: zone.shortName ?? "Z",
                        value: zone.counter,
                      })) ?? [])
                    ]}
                    description="Entregas en otras zonas."
                  />
                </Grid>
              </Grid>
            </Grid>
          
          </Grid>
          
        </Grid>
      </Grid>

      {userState ? (
        <DeliveriesChecklist
          filters={filters}
          onNewData={handleNewData}
          zoneId={userState.defaultManageZone?.id}
        />
      ) : null}
    </Container>
  )
}

function useParamFilters(location) {
  const history = useHistory();
  const params = qs.parse(location.search.substring(1));
  const filters = {};
  
  if (params.phaseId) filters.phaseId = parseInt(params.phaseId);
  if (params.zoneId) filters.zoneId = parseInt(params.zoneId);

  const [filtersState, setFilters] = useState(filters);

  useEffect(() => {
    if (filtersState) {
      history.replace(location.pathname + '?' + qs.stringify(filtersState));
    }
  }, [filtersState]);

  return [
    filtersState,
    (values) => setFilters({ ...filters, ...values })
  ];
}

function useCounters() {
  return useState({
    phases: {
      [DeliveryPhase.collect]: 0,
      [DeliveryPhase.delivery]: 0,
    },
    zones: {}
  });
}

const styles = createStyles({
  container: {
    paddingTop: 24
  },
  counterValue: {
    flexGrow: 1,
    textAlign: 'right'
  }
});

function StatusChip({ status, label, fullWidth }) {
  const classes = useStyles();
  const props = {
    label,
    size: 'small',
    className: `${classes[`${status}Chip`]} `
      + `${classes.chip} `
      + (fullWidth ? `${classes.fullWidth} ` : '')
  };

  return <Chip {...props} />;
}

const useStyles = makeStyles({
  title: {
    color: "#444",
    fontSize: 16,
    lineHeight: "16px",
    marginTop: 0,
    marginBottom: 4,
    textAlign: "center"
  },
  fullWidth: {
    width: '100%'
  },
  borderBottom: {
    borderBottom: "solid 1px #efefef"
  },
  cellFlex: {
    display: 'flex'
  },
  textLeft: {
    textAlign: 'left'
  },
  counter: {
    fontSize: 24,
    fontWeight: 'bold'
  },
  itGrows: {
    flexGrow: 1,
  },
  textCenter: {
    textAlign: 'center'
  },
  root: {
    textAlign: 'left'
  },
  label: {
    width: 80,
    borderColor: "black"
  },
  labelContainer: {
    flexGrow: 0
  },
  chip: {
    color: "white",
    fontSize: 18,
    fontWeight: 'bold',
    minWidth: 40
  },
  successChip: {
    backgroundColor: lightGreen[300]
  },
  infoChip: {
    backgroundColor: cyan[500]
  },
  errorChip: {
    backgroundColor: red[300]
  },
  blackChip: { backgroundColor: 'black' },
  grid: {
    width: '100%',
    alignItems: 'center',
    textAlign: 'center',
    flexWrap: 'nowrap',
  },
  headerCell: {
    padding: 0,
  },
  smallText: {
    fontSize: 12
  },
  cellBlue: {
    backgroundColor: cyan[500]
  },
  scheduleLabel: {
    fontSize: 14,
    color: "#555"
  },
  statCard: {
    padding: 8,
    paddingLeft: 16,
    paddingRight: 16,
    borderRadius: "16px"
  },
  icon: {
    fontSize: 20,
    color: "#555"
  },
  zoneCounter: {
    fontWeight: 'bold'
  }
});

