export class PaymentStatus {
  static depositRequested = 1;
}

export class PaymentMethod {
  static accountBalance = 2;
  static cash = 1;

  static getTitle(id) {
    switch (id) {
      case 2: return 'Fondos de cuenta';
      case 1: return 'Efectivo';
      default: return null;
    }
  }
}

export class PaymentType {
  static deposit = 1;

  static getTitle(id) {
    switch (id) {
      case 1: return 'Solicitado';
      case 2: return 'Acreditado';
      case 3: return 'Rechazado';
      default: return 'Estatus desconocido';
    }
  }
}