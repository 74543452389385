import React, { useEffect, useState } from 'react';
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
} from '@material-ui/core';
import {
  NavigateNext as NavigateNextIcon,
  LocaLoffer as LocaLOfferIcon,
  Launch as LaunchIcon,
} from '@material-ui/icons';
import API from '../api';

const api = new API();

export default function SpaceDetailDialog({
  space,
  open,
  onClose,
}) {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    async function loadProducts() {
      try {
        const products = await api.getProducts({userId: space?.owner?.id});
        console.log(products);
        setProducts(products);
      } catch (e) {
        alert("[loadProducts] Algo salió mal: " + e.message);
      }
    }
    loadProducts();
  }, [space]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle>
        Espacio {space?.code}
      </DialogTitle>
      <DialogContent>
        <Typography color="subtite1" variant="subtitle2">
          Propietario
        </Typography>
        <Typography variant="h6">
          {space?.owner?.name ?? "Usuario si nombre"}
        </Typography>
        <div style={{marginTop: 32}}>
          <Typography variant="small" color="grey">
            Productos ({space?.productsCounter} encontrados)
          </Typography>
        </div>
      </DialogContent>
      <List>
        {products?.map(product => {
          return (
            <ListItem component="a" button divider dense href={`/product/${product.id}`} target="_blank">
              <ListItemIcon>
                <Avatar source={product?.mainImage?.thumbnailUrl} />
              </ListItemIcon>
              <ListItemText
                secondary={`${product.availableQuantity} disponibles`}>
                  {product?.title}</ListItemText>
              <ListItemSecondaryAction>
                <LaunchIcon style={{color: "#666"}} />
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
      <DialogActions>
        <Button onClick={onClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
}