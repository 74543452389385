import React, { useCallback } from 'react';
import {
  Button,
  Grid,
  Chip,
  Paper,
} from '@material-ui/core';
import {
  grey,
  red,
} from '@material-ui/core/colors';  
import {
  withStyles,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
import {
  ToggleButton,
} from '@material-ui/lab';

export function Tabs({
  items,
  spacing = 2,
  padding = 0,
  selectedIndex = 0,
  onChange,
}) {
  const handleChange = useCallback(e => {
    const name = e.currentTarget.getAttribute('data-name');
    const index = parseInt(e.currentTarget.getAttribute('data-index'));
    if (onChange) {
      onChange(index, name);
    }
  }, [onChange]);

  return (
    <Grid container flex spacing={spacing} direction="row" style={{padding, display: "flex"}}>
      {items?.map(({label, icon = null, counter = 0}, index) =>
        <Grid item style={{flexGrow: 1, flex: 1}}>
          <Tab
            label={label}
            selected={index === selectedIndex}
            onSelect={handleChange}
            index={index}
            counter={counter}
            icon={icon} />
        </Grid>
      )}
    </Grid>
  );
}

function Tab({label, selected, icon, name, onSelect, counter = null, index = null}) {
  const Icon = icon;
  return (
    <TabButton selected={selected} style={{width: "100%"}} data-name={name} data-index={index} onClick={onSelect} component={Paper}>
      <Grid container alignItems="center" justifyContent="center" spacing={2}>
        <Grid item flex flexGrow={1}>{icon ? <Icon /> : null}</Grid>
        <Grid item flex flexGrow={1}>{label}</Grid>
        {counter ? (
          <Grid item flex flexGrow={1}>
            <AlertChip label={counter} size="small" />
          </Grid>
        ) : null}
      </Grid>
    </TabButton>
  );
}

const TabButton = withStyles({
  root: {
    border: "none",
    color: "black",
    borderRadius: 16,
    backgroundColor: "white",
    lineHeight: "16px"
  },
  selected: {
    backgroundColor: "#444 !important",
    color: "white !important"
  },
})(ToggleButton);

const AlertChip = withStyles({
  root: {
    backgroundColor: red[500],
    color: "white",
    fontWeight: "bold"
  }
})(Chip);