import React, { useCallback, useEffect, useState } from 'react';
import API from '../api';
import {
  Container,
  TableBody,
  TableRow,
  TableCell,
  Modal,
  Grid,
  Card as DefaultCard,
  Paper,
  Table,
  TableContainer,
  TableHead,
  Button,
  Divider,
  Box,
  Dialog,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import {
  makeStyles, withStyles,
} from '@material-ui/core/styles';

import NewWindow from 'react-new-window';

import PedidoNuevo from './printables/pedidoNuevo';

import { DeliveryStatus } from '../models/delivery';
import PrintPedido from './printables/printPedido';

const api = API.instance();

const useStyles = makeStyles((theme) => ({
  cardTitle: {
    marginBottom: 16,
  },
  cardContent: {
    padding: 0,
  },
  cardContentTitle: {
    padding: 16,
    marginBottom: 16,
  },
  cardContentSubtitle: {
    padding: 16,
    marginBottom: 16,
  },
  cardContentText: {
    padding: 2,
    marginBottom: 2,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  table: {
    
  },
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  headerContainer: {
    // display: 'flex'
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(1),
  }
}));

export default function OperadorScreen({
  location
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [orden, setOrden] = useState(null);
  const [checks, setChecks] = useState([]);
  const [lastDate, setLastDate] = useState(null);
  const [pedidos, setPedidos] = useState([]);
  const [units, setUnits] = useState([
    {name: 1},
  ]);
  const [print, setPrint] = useState(false);

  const getPedidos = useCallback(async () => {
    try {
      const response = await API.instance().getDeliveries({
        status: [DeliveryStatus.preparingDelivery],
        lastDate: lastDate,
        include: ['groupedPlaces', 'products', 'zones'],
      });
      setPedidos(response.result);
      setIsLoading(false);
    } catch (e) {
      console.error(`[getPedidos] Error: ${e}`);
    }
  }, [lastDate]);

  useEffect(() => {
    getPedidos();
  }, []);

  const handleOpen = (pedido) => {
    setOpen(true);
    setOrden(pedido);
    setChecks(
      ...checks,
      
      pedido.products.reduce((acc, item) => {
        acc[item.sku] = false;
        return acc;
      },
      {'total': 0},
      )
    )};

  const handleClose = () => {
    setOpen(false);
    //setOrden(null);
    //setChecks([]);
  };

  if (isLoading) {
    return (
      <Container style={{marginTop: 24, padding: 16, textAlign: 'center'}}>
        Cargando...
      </Container>
    );
  }

  const spacing = 1;

  return (
    <Container style={styles.container}>
      <Grid container spacing={spacing}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <h1 style={{textAlign: 'left'}}>Nuevos Pedidos</h1>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Pedido</TableCell>
                    <TableCell>Cliente</TableCell>
                    <TableCell>Direccion </TableCell>
                    <TableCell align="right">Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pedidos.map((pedido) => (
                    <TableRow key={pedido.trackingNumber} onClick={() => handleOpen(pedido)}>
                      <TableCell component="th" scope="row">
                        {pedido.trackingNumber}
                      </TableCell>
                      <TableCell>{pedido.receptor}</TableCell>
                      <TableCell>{pedido.destination.name + ' C.P. ' + pedido.destination.postalCode + ' Col. ' + pedido.destination.colonia}</TableCell>
                      <TableCell align="right">${parseFloat(pedido.total).toFixed(2)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
            </Table>
          </TableContainer>      
        </Grid>
      </Grid>
      <Dialog 
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={null}
        maxWidth="lg"
        fullWidth
        scroll="body"
        PaperProps={{style: {borderRadius: 24}}}
      >
        <DialogContent style={{backgroundColor: "#f0f0f0"}}>
          <PedidoNuevo orden={orden} checks={checks} setChecks={setChecks} />
          {print ?
            <NewWindow onUnload={() => setPrint(false)} title='Pedido' name='Pedido'>
              <PrintPedido orden={orden} checks={checks} setChecks={setChecks} setPrint={setPrint} units={units} setUnits={setUnits} />
            </NewWindow>
          : null}
          <Divider />
        </DialogContent>
        <DialogActions>
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Button variant="contained" color="primary" onClick={() => setPrint(true)} style={{marginRight: 16}}>
              Imprimir
            </Button>
            <Button variant="contained" color="secondary" onClick={handleClose} style={{marginRight: 16}}>
              Cerrar
            </Button>
            <Button variant="contained" color="primary" disabled={Object.values(checks).includes(false)}>
              Procesar
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Container>
  )
}

const styles = {
  container: {
    marginTop: 24,
    padding: 16,
    textAlign: 'center',
  },
  card: {
    padding: 16,
    marginBottom: 16,
  },
  card2: {
    padding: 16,
    marginBottom: 16,
  },
  card3: {
    padding: 16,
    marginBottom: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardTitle: {
    marginBottom: 16,
  },
  cardContent: {
    padding: 0,
  },
  cardContentTitle: {
    padding: 16,
    marginBottom: 16,
  },
  cardContentSubtitle: {
    padding: 16,
    marginBottom: 16,
  },
  cardContentText: {
    padding: 2,
    marginBottom: 2,
  }
}