import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Container,
  Grid,
  Card,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
} from '@material-ui/core';
import { UserSelector } from './market/product';
import BaseButton from '../components/button';
import { Tabs } from '../components/tabs';
import API from '../api';
import {
  Print as PrintIcon,
  Apps as AppsIcon,
  LocalOffer as LocalOfferIcon,
  NoteAdd as NoteAddIcon,
  PhotoLibrary as PhotoLibraryIcon,
} from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { UserRoleType } from '../models/user';

function cellStyle(field) {
  return {
    backgroundColor: field?.changed ? "#cdffbf" : "transparent"
  };
}

export default function ProductsImporterScreen({
  match: {
    params: {
      subimporter = null
    }
  }
}) {
  const history = useHistory();
  const [user, setUser] = useState(null);
  const [account, setAccount] = useState(null);
  const [sending, setSending] = useState(false);
  const fileRef = useRef();
  const imagesFileRef = useRef();
  const [items, setItems] = useState(null);
  const [totals, setTotals] = useState({});
  const [filehash, setFilehash] = useState(null);
  const [tabIndex, setTabIndex] = useState(subimporter === "images" ? 1 : 0);
  const [uploadPercent, setUploadPercent] = useState(0.0);
  const roles = useMemo(() => {
    return API.instance().getSessionRoles();
  }, []);
  const isGlobal = useMemo(() => {
    return roles.indexOf(UserRoleType.SUPERADMIN) > -1;
  }, [roles]);

  const handleSelectUser = useCallback(user => {
    setUser(user);
  }, []);

  const handlePreview = useCallback(() => {
    setSending(true);
  }, []);

  const handleSaveChanges = useCallback(async () => {
    if (sending) return;
    setSending(true);
    try {
      console.log(`accountId = ${account.id}`);
      await API.instance().importProducts({accountId: account.id, filehash});
      alert("Productos importados correctamente.");
      setItems(null);
    } catch (e) {
      alert(`Algo salió mal: ${e}`);
    }
    setSending(false);
  }, [sending, filehash, account]);
  
  const handleSubmitPreview = useCallback(async (e) => {
    e.preventDefault();
    try {
      const result = await API.instance().previewProductsImports({
        accountId: account.id,
        file: fileRef.current.files[0]
      });
      
      setItems(result.items);
      setTotals(result.totals);
      setFilehash(result.filehash);
      setSending(false);
    } catch (err) {
      console.debug(`[handleSubmitPreview] ERROR: ${err}`);
      alert(err.toString());
    }
  }, [account]);

  const handleSubmitImages = useCallback(async (e) => {
    e.preventDefault();
    if (sending) return;
    setSending(true);

    try {
      const result = await API.instance().importProductsImages({
        accountId: account.id,
        file: imagesFileRef.current.files[0],
        onProgress: async (percent) => {
          setUploadPercent(percent);
        }
      });
      alert(`Se cargaron ${result.totals.new} y se omitieron ${result.totals.unchanged}`);
    } catch (e) {
      console.log(`[handleSubmitImages] ERROR: ${e}`);
    }
    setSending(false);
  }, [account, imagesFileRef, sending]);

  const handleChangeTab = useCallback(index => {
    if (index === 0) {
      history.replace("/products-importer");
    } else {
      history.replace("/products-importer/images");
    }
    setTabIndex(index);
  }, [history]);

  const load = useCallback(async () => {
    try {
      const state = await API.instance().getUserState({
        include: ['defaultAccount']
      });
      setUser(state.user.defaultAccount?.owner);
      setAccount(state.user.defaultAccount);
    } catch (e) {
      console.log(`[ProductsImporterScreen.load] ERROR: ${e.message}`);
    }
  }, []);

  useEffect(() => {
    load();
  }, []);

  return (
    <Container style={{paddingBottom: 32}}>
      <h1>Importador de productos</h1>
      <div style={{marginBottom: 16}}>
        <Tabs selectedIndex={tabIndex} onChange={handleChangeTab} items={[
          {label: "Importar Excel", icon: NoteAddIcon, counter: null},
          {label: "Importar imágenes", icon: PhotoLibraryIcon, counter: null},
          // {label: "Devoluciones", icon: KeyboardReturnIcon}
        ]} />
      </div>

      {tabIndex === 0 ? (
        <React.Fragment>
          {!items ? (
            <Card style={{padding: 16, borderRadius: 16.}}>
              <form style={{marginTop: 4}} onSubmit={handleSubmitPreview}>
                <Grid container style={{display: "flex",}}>
                  {isGlobal ? (
                    <Grid item style={{flex: 1, width: "50%", paddingRight: 16}}>
                      <Typography>Selecciona el cliente y el archivo de excel de productos que se importará.</Typography>
                      <UserSelector
                        user={user}
                        onChange={handleSelectUser}
                        onClear={() => alert("cleared")}
                      />
                    </Grid>
                  ) : (
                    null
                  )}
                  <Grid item style={{flex: 1, width: "50%"}}>
                    <Typography>Carga el archivo de excel.</Typography>
                    <input type="file" ref={fileRef} />
                  </Grid>
                </Grid>
                <div style={{paddingTop: 16, textAlign: "right"}}>
                  <BaseButton isLoading={sending} variant="contained" type="submit" color="primary" onClick={handlePreview}>Subir y revisar</BaseButton>
                </div>
              </form>
            </Card>
          ) : (
            <Card style={{padding: 16, paddingTop: 8, paddingBottom: 8, borderRadius: 16.}}>
              <div style={{textAlign: "right", display: "flex", alignItems: "center"}}>
                <div style={{width: 100, textAlign: "left"}}>
                  <div style={{fontSize: 13}}>Total</div>
                  <div style={{fontSize: 20, fontWeight: "bold"}}>{totals.all ?? 0}</div>
                </div>
                <div style={{width: 100, textAlign: "left"}}>
                  <div style={{fontSize: 13}}>Nuevos</div>
                  <div style={{fontSize: 20, fontWeight: "bold"}}>{totals.new}</div>
                </div>
                <div>
                  <div style={{width: 100, textAlign: "left", fontSize: 13}}>Cambios</div>
                  <div style={{textAlign: "left", fontSize: 20, fontWeight: "bold"}}>{totals.changed}</div>
                </div>
                <div>
                  <div style={{width: 100, textAlign: "left", fontSize: 13}}>Sin cambios</div>
                  <div style={{textAlign: "left", fontSize: 20, fontWeight: "bold"}}>{totals.unchanged}</div>
                </div>
                <div style={{flexGrow: 1}}>
                  <BaseButton
                    isLoading={sending}
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={!(totals.changed > 0 || totals.new > 0)}
                    onClick={handleSaveChanges}>Guardar cambios</BaseButton>
                </div>
              </div>
            </Card>
          )}

          <TableContainer component={Card} style={{marginTop: 16, borderRadius: 16}}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Código</TableCell>
                  <TableCell>Clave</TableCell>
                  <TableCell>Título</TableCell>
                  <TableCell>Precio</TableCell>
                  <TableCell>Marca</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items?.map(item => {
                  return (
                    <TableRow>
                      <TableCell style={cellStyle(item?.uniqueId)}>{item.uniqueId?.value}</TableCell>
                      <TableCell style={cellStyle(item?.productCode)}>{item.productCode?.value}</TableCell>
                      <TableCell style={cellStyle(item?.description)}>{item.description?.value}</TableCell>
                      <TableCell style={cellStyle(item?.price)}>{item.price?.value}</TableCell>
                      <TableCell style={cellStyle(item?.brand)}>{item.brand?.value}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </React.Fragment>
      ) : (
        <Card style={{borderRadius: 16, padding: 16}}>
          <form onSubmit={handleSubmitImages}>
            
            {isGlobal ? (
              <React.Fragment>
                <Typography>1. Selecciona el cliente y el archivo de excel de productos que se importará.</Typography>
                <UserSelector
                  user={user}
                  onChange={handleSelectUser}
                  onClear={() => alert("cleared")}
                />
              </React.Fragment>
            ) : (
              null
            )}

            <div>1. Elige un archivo .zip con imágenes con el identificador único de cada producto.</div>
            <input type="file" ref={imagesFileRef} style={{marginTop: 16}} />
            <div style={{paddingTop: 16, textAlign: "right", display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
              {sending ? <div style={{marginRight: 16}}>
                {uploadPercent === 1 ? (
                  'Procesando imágenes...'
                ) : (
                  Math.round(uploadPercent * 100) + '%'
                )}
              </div> : null}
              <BaseButton
                isLoading={sending}
                variant="contained"
                type="submit"
                color="primary"
                disabled={sending}
                // disabled={!imagesFileRef.current.files.length}
              >Cargar imágenes</BaseButton>
            </div>
          </form>
        </Card>
      )}
    </Container>
  );
}

function ExcelImporter() {
  /*return (
    
  );*/
}