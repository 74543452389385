import React from 'react';

export default function PlaceIcon({ kind, size = 16 }) {
  const style = {
    ...styles.placeIcon,
    width: size,
    height: size,
  };

  if (kind === PlaceIcon.origin)
    style.borderRadius = `${size}px`;

  return (
    <div style={style}>
    </div>
  );
}

PlaceIcon.origin = 1;
PlaceIcon.destination = 2;

const styles = {
  placeIcon: {
    backgroundColor: 'black'
  },
};