import React, { useState, useEffect, useRef } from 'react';
import { CircularProgress } from '@material-ui/core';
import moment from 'moment';
import momentz from 'moment-timezone';
import 'moment/locale/es';
import {
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core/styles';
import {
  Modal,
  Grid,
  TextField,
  Button,
  Divider,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import {
  Map,
  Marker,
  Popup,
  TileLayer,
} from 'react-leaflet';
import L from 'leaflet'
import API from '../api';
import { DeliveryStatus } from '../models/delivery';

const api = API.instance();
const position = [29.087069, -110.960013];
const refreshRate = 30;
const timezone = moment.tz.guess();

export const MarkedIcon = new L.Icon({
  iconUrl: '/package-24-a-chosen.png',
  iconRetinaUrl: '/package-24-a-chosen.png',
  iconAnchor: [24, 8],
  iconSize: [48, 48],
  shadowSize: [68, 95],
  shadowAnchor: [20, 92],
})

export const CarIcon = new L.Icon({
  iconUrl: '/car-48-outlined.png',
  iconRetinaUrl: '/car-48-outlined@2x.png',
  iconAnchor: [24, 8],
  iconSize: [48, 48],
  shadowSize: [68, 95],
  shadowAnchor: [20, 92],
});

export const CheckIcon = new L.Icon({
  iconUrl: '/MarkCheck.png',
  iconRetinaUrl: '/MarkCheck@2x.png',
  iconAnchor: [24, 8],
  iconSize: [48, 48],
  shadowSize: [68, 95],
  shadowAnchor: [20, 92],
});

export default function PackageMonitorDialog(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [paquete, setPaquete] = useState(null);
  const [isReloading, setIsReloading] = useState(false);
  const [timerSeconds, setTimerSeconds] = useState(30);
  const { innerWidth, innerHeight } = window;
  const [timer, setTimer] = useState(null);
  const mapOptions = {
    center: paquete ? [paquete.latitud, paquete.longitud] : position,
    zoom: 13,
    viewport: props.viewport,
    // ref: (ref) => this.map = ref
    style: {
      height: innerHeight * 0.7,
    }
  };
  const refPopupCar = useRef();
  const refPopupDestination = useRef();

  const reload = () => {
    setIsReloading(true);
    api.getPaquete(props.packageId).then((p) => {
      setPaquete(p);
      setIsReloading(false);
    });
  };

  const renderMap = () => {
    return (
      <Map {...mapOptions}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <Marker
          width={16}
          icon={MarkedIcon}
          onclick={() => {
            // console.log(index);
          }}
          position={[paquete.latitud, paquete.longitud]}
        >
          <Popup ref={refPopupDestination} autoClose={false}>
            <strong>Dirección</strong><br />
            {paquete.direccion}
          </Popup>
        </Marker>
        {(
          paquete && paquete.viaje
            && paquete.viaje.chofer 
            && paquete.viaje.chofer.ultimaUbicacion
            && paquete.viaje.estatusEntregaId !== DeliveryStatus.delivered
        )
          ? (
            <Marker
              width={16}
              icon={CarIcon}
              onclick={() => {
                // console.log(index);
              }}
              position={[
                paquete.viaje.chofer.ultimaUbicacion.latitud,
                paquete.viaje.chofer.ultimaUbicacion.longitud
              ]}
            >
              <Popup ref={refPopupCar} autoClose={false}>
                <strong>Repartidor</strong><br />
                {paquete.viaje.chofer.nombre} {paquete.viaje.chofer.apellidoPaterno}
                <br />
                <strong>Último reporte</strong><br />
                {momentz
                  .tz(paquete.viaje.chofer.ultimaUbicacion.fechaCreacion, 'utc')
                  .tz(timezone).calendar()
                }
                <br />
              </Popup>
            </Marker>
          ) : null
        }
        {
          (paquete && paquete.viaje
            && paquete.viaje.latitudEntrega
            && paquete.viaje.longitudEntrega
            && paquete.viaje.estatusEntregaId === DeliveryStatus.delivered
          )
          ? (
            <Marker
              width={16}
              icon={CheckIcon}
              zIndexOffset={1000}
              onclick={() => {
                // console.log(index);
              }}
              position={[
                paquete.viaje.latitudEntrega,
                paquete.viaje.longitudEntrega
              ]}
            >
              <Popup ref={refPopupCar} autoClose={false}>
                Lugar donde se entregó
              </Popup>
            </Marker>
          ) : null
        }
      </Map>
    );
  }

  useEffect(() => {
    if (props.packageId) {
      setIsLoading(true);
      api.getPaquete(props.packageId).then((p) => {
        setPaquete(p);
        setIsLoading(false);
      });
    } else {
      
    }
  }, [props.packageId]);

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullWidth={true}
      maxWidth="xl"
    >
      <DialogTitle>
        Mapa
      </DialogTitle>
      <DialogContent>
        {isLoading
          ? <CircularProgress />
          : (paquete ? renderMap() : null)}
      </DialogContent>
      <DialogActions>
        {isReloading
          ? (<CircularProgress color="secondary" size={24} />)
          : <Button color="success" onClick={reload}>Actualizar</Button>
        }
        <Button color="primary" onClick={props.onClose}>Regresar</Button>
      </DialogActions>
    </Dialog>
  );
}