import React, { createContext, useEffect, useReducer } from 'react';
import './App.css';
import {
  Switch,
  Route,
  Redirect,
  BrowserRouter as Router,
} from 'react-router-dom';

import LoginScreen from './screens/login';
import DashboardScreen from './screens/dashboard';
import PackageAddScreen from './screens/package-add';
import CollectAddScreen from './screens/collect-add';
import DeliveriesListScreen from './screens/deliveries-list';
import PackageTypesScreen from './screens/package-types';
import PackageTypeEditScreen from './screens/package-type-edit';
import PackagePrintScreen from './screens/printables/package-print';
import RouteAddScreen from './screens/route-add';
import ZonesScreen from './screens/zones';
import ZoneAddScreen from './screens/zone-add';
import DriversScreen from './screens/drivers';
import DriverEditScreen from './screens/driver-edit';
import VehiclesTypeScreen from './screens/vehicles-types';
import VehicleTypeEditScreen from './screens/vehicle-type-edit';
import VehicleEditScreen from './screens/vehicle-edit';
import VehiclesScreen from './screens/vehicles';
import SendersScreen from './screens/senders';
import SenderEditScreen from './screens/sender-edit';
import RoutesScreen from './screens/routes';
import RegistrateScreen from './screens/registrate';
import DriverRegisterScreen from './screens/driver-register';
import PaymentScreen from './screens/payment';
import ParametersScreen from './screens/parameters';
import RatesScreen from './screens/rates';
import ClientsScreen from './screens/clients.js';
import SolicitudesScreen from './screens/solicitudes';
import SkuLabelScreen from './screens/printables/sku_label';
import DepositsScreen from './screens/deposits';
import ProductsScreen from './screens/market/products';
import CategoriesScreen from './screens/market/categories';
import ProductScreen from './screens/market/product';
import CategoryScreen from './screens/market/category';
import DeliveriesReceptionScreen from './screens/delivery/reception';
import UsersManageScreen from './screens/manage/users';
import TrackingLabelScreen from './screens/printables/tracking_label';
import API from './api';
import AppBar from './components/appbar';
import useMainReducer from './reducers/main';
import MesaControlScreen from './screens/mesa-control';
import SpacesScreen from './screens/spaces';
import UserNewScreen from './screens/user_new';
import UserEditScreen from './screens/user_edit';
import StorageControlScreen from './screens/storage_control';
import ProductsImporterScreen from './screens/products-importer';
import OperadorScreen from './screens/operador';
import {
  ThemeProvider,
  createTheme,
} from '@material-ui/core/styles';
import FeaturesScreen from './screens/manage/features';
import ProductsInoutScreen from './screens/products_inout';
import BranchesScreen from './screens/branches';

const theme = createTheme({
  palette: {
    primary: {
      main: '#F21C7F'
    },
    secondary: {
      main: '#F35234'
    },
    black: {
      main: 'blue'
    },
    info: {
      main: '#33ccff'
    },
    error: {
      main: '#ff0000'
    },
    danger: {
      main: '#ff0000'
    }
  },
  status: {
    danger: '#ff0000'
  }
});

const PrivateRoute = ({component: Component, ...rest}) => {
  const [state, dispatch] = useMainReducer();

  return (
    <Route {...rest}
      render={(props) => {
        if (API.instance().isLoggedIn()) {
          return <AppBar content={
            <Component {...props}
              globalState={state}
              globalDispatch={dispatch} />
            }
            state={state}
            dispatch={dispatch} />;
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  )
};

const PrivateStandaloneRoute = ({component: Component, ...rest}) => {
  const [state, dispatch] = useMainReducer();

  return (
    <Route {...rest}
      render={(props) => {
        if (API.instance().isLoggedIn()) {
          return <Component {...props}
            globalState={state}
            globalDispatch={dispatch} />;
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  )
};

function App() {
  Notification.requestPermission();

  const root = (
    <Router>
      <Switch>
        <Route path="/login">
          <LoginScreen />
        </Route>
        <PrivateRoute path="/package/add" component={PackageAddScreen} />
        <PrivateRoute path="/collect/add" component={CollectAddScreen} />
        <PrivateRoute path="/package/type/:id" component={PackageTypeEditScreen} />
        <Route path="/package/:id/print" component={PackagePrintScreen} />
        <PrivateRoute path="/packages/types" component={PackageTypesScreen} />
        <PrivateRoute path="/deliveries/reception" component={DeliveriesReceptionScreen} />
        <Route path="/deliveries/:id/print-tracking" component={TrackingLabelScreen} />
        <PrivateRoute path="/deliveries" component={DeliveriesListScreen} />
        <PrivateStandaloneRoute path="/payment/printable" component={PaymentScreen} />
        <PrivateRoute path="/payment" component={PaymentScreen} />
        <PrivateRoute path="/route/add" component={RouteAddScreen} />
        <PrivateRoute path="/routes" component={RoutesScreen} />
        <PrivateRoute path="/zones" component={ZonesScreen} />
        <PrivateRoute path="/zone/add" component={ZoneAddScreen} />
        <PrivateRoute path="/drivers" component={DriversScreen} />
        <PrivateRoute path="/driver/register" component={DriverRegisterScreen} />
        <PrivateRoute path="/driver/:id" component={DriverEditScreen} />
        <PrivateRoute path="/vehicles/types" component={VehiclesTypeScreen} />
        <PrivateRoute path="/vehicle/type/:id" component={VehicleTypeEditScreen} />
        <PrivateRoute path="/vehicle/:id" component={VehicleEditScreen} />
        <PrivateRoute path="/vehicles" component={VehiclesScreen} />
        <PrivateRoute path="/senders" component={SendersScreen} />
        <PrivateRoute path="/sender/:id" component={SenderEditScreen} />
        <PrivateRoute path="/parameters" component={ParametersScreen} />
        <PrivateRoute path="/rates" component={RatesScreen} />
        <PrivateRoute path="/clients" component={ClientsScreen} />
        <PrivateRoute path="/solicitudes" component={SolicitudesScreen} />
        <PrivateRoute path="/deposits" component={DepositsScreen} />
        <Route path="/products/:id/print-sku" component={SkuLabelScreen} />
        <PrivateRoute path="/product/:id" component={ProductScreen} />
        <PrivateRoute path="/productos/entradas" component={ProductsInoutScreen} />
        <PrivateRoute path="/products" component={ProductsScreen} />
        <PrivateRoute path="/categories" component={CategoriesScreen} />
        <PrivateRoute path="/category/:id" component={CategoryScreen} />
        <PrivateRoute path="/mesa-control" component={MesaControlScreen} />
        <Route path="/registrate" component={RegistrateScreen} />
        <PrivateRoute path="/mapa" component={DashboardScreen} exact />
        <PrivateRoute path="/users/:userId/edit" component={UserEditScreen} />
        <PrivateRoute path="/users/new" component={UserNewScreen} />
        <PrivateRoute path="/users" component={UsersManageScreen} exact />
        <PrivateRoute path="/features" component={FeaturesScreen} exact />
        <PrivateRoute path="/storage" component={SpacesScreen} exact />
        <PrivateRoute path="/sucursal/salidas" component={OperadorScreen} />
        <PrivateRoute path="/sucursales" component={BranchesScreen} />
        <PrivateRoute path="/almacen" component={StorageControlScreen} />
        <PrivateRoute path="/products-importer/:subimporter?" component={ProductsImporterScreen} />
        <PrivateRoute path="/" component={MesaControlScreen} />
      </Switch>
    </Router>
  );

  return (
    <ThemeProvider theme={theme}>
      {root}
    </ThemeProvider>
  )
}

export default App;
