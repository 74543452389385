import React from 'react';

export default function PlusChip() {
  return (
    <div style={{
      background: 'linear-gradient(#826617, #b89942)',
      display: 'inline-block',
      color: "white",
      borderRadius: 9,
      padding: "0 8px"
    }}>
      DAS Plus
    </div>
  );
}