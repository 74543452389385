import React, { useCallback, useEffect, useState } from "react";
import {
  makeStyles
} from '@material-ui/core/styles';
import API from '../../api';
import { useParams } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import {
  Grid,
} from '@material-ui/core';

export default function SkuLabelScreen(props) {
  const [product, setProduct] = useState(null);
  const [loading, setIsLoading] = useState(true);
  const [imageQr, setImageQr] = useState(null);
  const [imageBarcode, setImageBarcode] = useState(null);

  const params = useParams();
  const classes = useStyles();

  const handleLoadQr = useCallback(() => {
    setImageBarcode(
      API.instance().getBarcode128Url(product.sku, {
        width: 219,
        height: 80
      })
    );
  }, [product]);

  const handleLoadBarcode = useCallback(e => {
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (params.id) {
      async function load() {
        try {
          const d = await API.instance().getProductById(params.id);
          setImageQr(API.instance().getQrUrl(d.sku));
          setProduct(d);
        } catch (e) {
          alert("croquetas o pescadito: " + e.message);
        }
      }

      load();
    }
  }, [params.id, imageQr]);

  useEffect(() => {
    if (!loading) {
      setTimeout(() => window.print(), 200);
    }
  }, [loading]);

  return (
    <div className={classes.main}>
      <div className={classes.inner}>
        {loading ? (
          <div className={classes.loading}>
            <div className={classes.loading}>
              <CircularProgress color="inherit" />
              <div className={classes.loadingText}>Cargando<br />etiqueta</div>
            </div>
          </div>
        ) : null}

        <div className={`${classes.title} ${classes.separated}`}>
          {product?.title}
        </div>
        <div className={classes.barcodeContainer}>
          <img src={imageBarcode} onLoad={handleLoadBarcode} />
        </div>
        <div className={classes.skuContainer}>
          SKU: {product?.sku}
        </div>
        <div className={classes.separated} style={{padding: 8}}>
          <Grid container justifyContent="center" spacing={2} style={{fontWeight: "bold"}}>
            <Grid item>
              <div className={classes.spaceLabel}>Rack</div>
              <div className={classes.spaceValue}>{product?.user?.rackSpace?.rack?.name}</div>
            </Grid>
            <Grid item>
              <div className={classes.spaceLabel}>Lin.</div>
              <div className={classes.spaceValue}>{product?.user?.rackSpace?.line}</div>
            </Grid>
            <Grid item>
              <div className={classes.spaceLabel}>Col</div>
              <div className={classes.spaceValue}>{product?.user?.rackSpace?.column}</div>
            </Grid>
          </Grid>
        </div>
        <div className={classes.qrContainer}>
          <img src={imageQr} onLoad={handleLoadQr} style={{width: "150px"}} />
        </div>
      
      </div>
    </div>
  )
}

const useStyles = makeStyles({
  main: {
    boxSizing: "border-box",
    padding: 16,
    display: "flex",
    width: 219,
  },
  title: {
    padding: 8,
    display: "flex",
    textAlign: "center",
    fontWeight: "bold",
    justifyContent: "center"
  },
  inner: {
    border: "solid 4px black",
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
  },
  separated: {
    borderBottom: "solid 5px black"
  },
  loading: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  },
  loadingText: {
    color: "black",
    marginTop: 16,
    textAlign: "center",
    fontWeight: "bold",
  },
  nanoImg: {
    width: 1,
    height: 1
  },
  barcodeContainer: {
    padding: 8,
    textAlign: "center",
    borderBottom: "solid 5px black"
  },
  skuContainer: {
    padding: 8,
    textAlign: "center",
    borderBottom: "solid 5px black",
    fontWeight: "bold"
  },
  qrContainer: {
    padding: 16,
    textAlign: "center",
  },
  header: {
    
  },
  loading: {
    position: "absolute",
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    width: 219,
    height: 438,
    boxSizing: "content-box"
  },
  spaceLabel: {
    textAlign: "center",
    fontSize: 13
  },
  spaceValue: {
    textAlign: "center",
    fontSize: 20
  }
});