import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import moment from 'moment';
import momentz from 'moment-timezone';
import {
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core/styles';
import {
  Modal,
  Grid,
  TextField,
  Button,
  Divider,
  Chip,
  Table,
  Avatar,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  List,
  Card,
  CardContent,
  Typography,
  ListItem,
  ListItemText,
  Dialog,
  Paper,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import API, { wwwRoot, baseURL } from '../api';
import { DeliveryStatus, DeliveryPaymentOrigin } from '../models/delivery';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PhoneIcon from '@material-ui/icons/Phone';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import DriverPopover from './driver-popover';
import { green } from '@material-ui/core/colors';

const MAPS_URL = 'https://www.google.com/maps/search/';
const api = API.instance();
const tz = moment.tz.guess();

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    fontSize: 17
  };
}

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      width: "90%",
      backgroundColor: theme.palette.background.paper,
      // border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
);

export function PackageDataModal(props) {
  const classes = useStyles();
  const [isSaving, setIsSaving] = useState(false);
  const [isSavingDeposit, setIsSavingDeposit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [placa, setPlaca] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [modalStyle] = useState(getModalStyle);
  const [paquete, setPaquete] = useState(null);
  const [isConfirmingReturn, setIsConfirmingReturn] = useState(false);
  const [driverDataId, setDriverDataId] = useState(null);
  const [showDriverData, setShowDriverData] = useState(false);
  const [driverDataAnchor, setDriverDataAnchor] = useState(null);

  const handleReturnToStorage = () => {
    if (isConfirmingReturn) {
      return;
    }

    if (window.confirm("¿Confirmas que el paquete ya se encuentra en la bodega?")) {
      setIsConfirmingReturn(true);

      api.updateDeliveryStatus(
        paquete.viaje.viajeId,
        DeliveryStatus.backInStorage
      ).then(response => {
        if(response.isSuccess) {
          setPaquete({
            ...paquete,
            viaje: {...paquete.viaje,
              estatusEntregaId: response.result.viaje.estatusEntregaId,
              chofer: response.result.viaje.chofer,
              bitacora: response.result.viaje.bitacora.map(b => ({
                ...b,
                fecha: momentz.tz(b.fecha, 'utc').tz(tz)
              }))
            }
          });
        } else {
          window.alert("Algo salió mal: " + response.message);
        }
        setIsConfirmingReturn(false);
      }).catch((error) => {
        window.alert("Algo salió mal: " + error.message);
        setIsConfirmingReturn(false);
      });
    }
  };

  const handleConfirmDeposit = async (e) => {
    if (isSavingDeposit) {
      return;
    }

    setIsSavingDeposit(true);

    try {
      const response = await api.markDepositAsPaid(paquete.viaje.viajeId);
      if (response.isSuccess) {
        setPaquete({...paquete,
          viaje: {...paquete.viaje,
            paymentDepositStatus: 1
          }
        })
      } else {
        alert(response.message);
      }
    } catch (e) {
      alert(e.message);
      console.log(`[handleConfirmDeposit] Error: ${e}`);
    }

    setIsSavingDeposit(false);
  };

  const renderLoading = () => {
    return (
      <Grid container spacing={1} alignItems="center" justify="center">
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    )
  };

  const renderBitacora = () => {
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={50}><strong>Estatus</strong></TableCell>
              <TableCell width={50} align="center"><strong>Fecha</strong></TableCell>
              <TableCell><strong>Descripción</strong></TableCell>
              <TableCell><strong>Usuario</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paquete.viaje.bitacora.map((item) => {
              const status = DeliveryStatus.map[item.estatusEntregaId];

              return (
                <TableRow key={item.bitacoraViajeId}>
                  <TableCell align="center">
                    <NotificationsIcon color={status?.color} style={{color: status?.color}} />
                  </TableCell>
                  <TableCell>
                    <div>{item.fecha.format('DD/MM/YYYY')}</div>
                    <div>{item.fecha.format('hh:mm:ss')}</div>
                  </TableCell>
                  <TableCell>{item.descripcion}</TableCell>
                  <TableCell>
                    <Grid container>
                      <Chip
                        variant="outlined"
                        onClick={
                          (item.usuario && item.usuario.chofer)
                            ? (async (evt) => {
                              setDriverDataAnchor(evt.currentTarget);
                              const chofer = await api.getChofer(
                                item.usuario.chofer.choferId
                              );
                              setDriverDataId(chofer);
                              setShowDriverData(true);
                            }) : null
                        }
                        avatar={
                          <Avatar
                            src={`${wwwRoot}/Imagen/users/${item.usuario.idClientes}.jpg`}
                          />
                        }
                        label={item.usuario
                          ? item.usuario.nombre
                          : null
                        }
                      />
                    </Grid>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderPayment = () => {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <h3>Información de cobro y depósito</h3>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Card>
            <CardContent>
              <Grid container>
                <Grid item xs={6}>
                  <Typography color="textSecondary">
                    Tarjeta de depósito
                  </Typography>
                  {paquete.viaje.depositCard}
                </Grid>

                <Grid item xs={6}>
                  {
                    paquete.viaje.paymentDepositStatus === 1 ? (
                      <Chip
                        style={{ backgroundColor: green[500], color: "white" }}
                        label={"Pago depositado"} />
                    ) : (
                      <Button variant="contained" color="secondary" onClick={handleConfirmDeposit}>
                        {isSavingDeposit ? (
                          <CircularProgress />
                        ) : "Confirmar depósito"}
                      </Button>
                    )
                  }
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </React.Fragment>
    );
  };

  const renderCollect = () => {
    const colProps = {
      xl: 3,
      lg: 3,
      md: 4,
      sm: 6,
      xs: 12
    };

    const viaje = paquete.viaje;

    return (
      <React.Fragment>
        <Grid item xs={12}>
          <h3>Información de recolección</h3>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Card style={{ height: '100%' }}>
            <CardContent>
              <Grid container>
                <Grid item {...colProps}>
                  <Typography color="textSecondary">
                    Dirección
                  </Typography>
                  {viaje.origenNombre}
                </Grid>
                <Grid item {...colProps}>
                  <Typography color="textSecondary">
                    Cliente
                  </Typography>
                  {viaje.origenNombrePersona}
                  <div>No. de Cliente</div>
                  <div>
                    <strong>{viaje?.user?.id}</strong>
                  </div>
                </Grid>
                <Grid item {...colProps}>
                  <Typography color="textSecondary">
                    Teléfono
                  </Typography>
                  {viaje.origenTelefono}
                </Grid>
                <Grid item {...colProps}>
                  <Typography color="textSecondary">
                    Ubicación
                  </Typography>
                  <div>{viaje.origenLatitud},{viaje.origenLongitud}</div>
                  <Button
                    variant="outlined"
                    color="default"
                    target="_blank"
                    href={`${MAPS_URL}?api=1&query=${viaje.origenLatitud},${viaje.origenLongitud}`}>
                    Ver en mapa
                  </Button>
                </Grid>
                <Grid item {...colProps}>
                  <Typography color="textSecondary">
                    Distancia
                  </Typography>
                  <div>
                  {
                    viaje?.estimation?.distance
                      ?? viaje?.distanciaAprox
                  } km
                  </div>
                </Grid>
                <Grid item {...colProps}>
                  <Typography color="textSecondary">
                    Tiempo estimado
                  </Typography>
                  <div>
                    {Math.round((
                      viaje?.estimation?.duration
                        ?? viaje?.estimatedDuration
                        ?? 0
                    ) / 60)} min
                  </div>
                </Grid>
                <Grid item {...colProps}>
                  <Typography color="textSecondary">
                    Costo del envío
                  </Typography>
                  <div>
                    ${
                      (viaje?.productPrice || viaje?.paquete?.productPrice || 0)
                        + (
                          viaje?.estimation?.price?.price
                            ?? viaje?.deliveryPrice
                            ?? 0
                        )
                    } MXN
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </React.Fragment>
    );
  }

  const renderDelivery = () => {
    const colProps = {
      xl: 3,
      lg: 3,
      md: 4,
      sm: 6,
      xs: 12
    };

    const delivery = (paquete && paquete.viaje) ? paquete.viaje : null;

    return (
      <React.Fragment>
        <Grid item xs={12}>
          <h3>Información de entrega</h3>
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <Card style={{height: '100%'}}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item {...colProps}>
                  <Typography color="textSecondary">
                    Estatus
                  </Typography>
                  <StatusChip
                    status={paquete.viaje ? paquete.viaje.estatusEntregaId : null}
                  />
                </Grid>

                {paquete.viaje.chofer
                  ? (
                    <Grid item container spacing={2} {...colProps} alignItems="center">
                      <Grid item xs={3}>
                        <Avatar
                          style={{width: 48, height: 48}}
                          src={`${wwwRoot}/Imagen/users/`
                              + `${paquete.viaje.chofer.idClientes}.jpg`}
                        />
                      </Grid>
                      <Grid item xs={9}>
                        <Typography color="textSecondary">Chofer</Typography>
                        {paquete.viaje.chofer.nombre}
                          &nbsp;{paquete.viaje.chofer.apellidoPaterno}
                          &nbsp;{paquete.viaje.chofer.apellidoMaterno}
                      </Grid>
                    </Grid>
                  ) : null
                }

                <Grid item {...colProps}>
                  <Typography color="textSecondary">Última actualización</Typography> 
                  {momentz.tz(paquete.viaje.fechaActualizacion, 'utc')
                    .tz(tz)
                    .format('DD MMM YYYY hh:mm')}
                </Grid>

                {paquete.viaje.estatusEntregaId === DeliveryStatus.delivered
                  ? (
                    <React.Fragment>
                      <Grid item {...colProps}>
                        <Typography color="textSecondary">Quién recibió</Typography>
                        {paquete.viaje.nombreRecibio}
                      </Grid>
                      <Grid item {...colProps}>
                        <Typography color="textSecondary">Entregado el</Typography>
                        {
                          momentz.tz(paquete.viaje.fechaEntrega, 'utc')
                            .tz(tz)
                            .format('DD MMM YYYY hh:mm')
                        }
                      </Grid>
                    </React.Fragment>
                  ) : null
                }

                {paquete.viaje.latitudEntrega
                  ? (
                    <Grid item {...colProps}>
                      <Typography color="textSecondary">Entregado en ubicación</Typography>
                      {paquete.viaje.latitudEntrega}, {paquete.viaje.longitudEntrega}
                      <Button
                        variant="outlined"
                        color="default"
                        target="_blank"
                        href={`${MAPS_URL}?api=1&query=${paquete.viaje.latitudEntrega},${paquete.viaje.longitudEntrega}`}>
                        Ver en mapa
                      </Button>
                    </Grid>
                  ) : null
                }

                {paquete.viaje.razon
                  ? (
                    <Grid item {...colProps}>
                      <Typography color="textSecondary">Razón</Typography>
                      {paquete.viaje.Razon}
                    </Grid>
                  ) : null
                }
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item container xs={12} md={4} lg={3}>
          <Grid item xs={12}>
            <Card style={{height: '100%'}}>
              <CardContent style={{textAlign: "center"}}>
                {paquete.viaje.firmaArchivo
                  ? (
                    <img src={`${wwwRoot}/Imagen/Firmas/${paquete.viaje.firmaArchivo}`} />    
                  ) : "No hay firma."
                }
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          {
            delivery && delivery.estatusEntregaId === DeliveryStatus.notDelivered
              ? (
                <Button variant="contained"
                  color="primary"
                  startIcon={<AssignmentTurnedInIcon />}
                  onClick={handleReturnToStorage}>
                  {isConfirmingReturn
                    ? <CircularProgress color="white" size={24} />
                    : "Confirmar regreso a bodega"
                  }
                </Button>
              ) : null
          }
        </Grid>
          
        <Grid item xs={12}>
          <h3>Bitácora</h3>
        </Grid>
        <Grid item xs={12}>
          {renderBitacora()}
        </Grid>
      </React.Fragment>
    );
  }

  const renderInfo = () => {
    const colProps = {
      xl: 3,
      lg: 3,
      md: 4,
      sm: 6,
      xs: 12
    };
    return (
      <Grid container spacing={1} alignItems="stretch" alignContent="stretch">
        <Grid item xs={12} md={12} lg={12}>
          <Card style={{height: '100%'}}>
            <CardContent>
              <Grid container spacing={4}>
                <DataColumn title="Identificador del envío" value={paquete?.viaje?.viajeId} />
                <DataColumn title="Fecha de alta"
                  value={momentz.tz(paquete.fechaHora, 'utc').tz(tz).format('DD MMM YYYY hh:mm')}
                />
                <DataColumn title="Dirección" value={paquete.direccionLarga} />
                  {(paquete.viaje && paquete.viaje.chofer)
                    ? (
                      <Grid item container spacing={2} {...colProps} alignItems="center">
                          <Grid item xs={3}>
                            <Avatar
                              style={{ width: 48, height: 48 }}
                              src={`${wwwRoot}/Imagen/users/`
                                + `${paquete.viaje.chofer.idClientes}.jpg`}
                            />
                          </Grid>
                          <Grid item xs={9}>
                            <Typography color="textSecondary">Chofer</Typography>
                            {paquete.viaje.chofer.nombre}
                                      &nbsp;{paquete.viaje.chofer.apellidoPaterno}
                                      &nbsp;{paquete.viaje.chofer.apellidoMaterno}
                          </Grid>
                        </Grid>
                      ) : "No asignado a chofer"
                  }
                <DataColumn title="Número de guía" value={paquete.numeroGuia} />
                <DataColumn title="Tipo de paquete" value={paquete.tipoPaquete.nombre} />
                <DataColumn title="Peso"
                  value={paquete.peso != null ? `${paquete.peso}kg` : 'No especificado'}
                />
                {paquete.guiaOriginal
                  ? <DataColumn title="Guía original" value={paquete.guiaOriginal} />
                  : null
                }
                {paquete.sender
                  ? <DataColumn title="Cliente" value={paquete.sender.name} />
                  : null
                }
                <DataColumn title="Quién recibe" value={paquete.nombreRecibe} />
                <DataColumn title="Teléfono de contacto" value={paquete.telefonoRecibe} />
                <DataColumn title="Ubicación a entregar"
                  value={
                    <React.Fragment>
                      {paquete.latitud}, {paquete.longitud}
                      <Button
                        variant="outlined"
                        color="default"
                        target="_blank"
                        href={`${MAPS_URL}?api=1&query=${paquete.latitud},${paquete.longitud}`}>
                        Ver en mapa
                      </Button>
                    </React.Fragment>
                  } />
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {paquete && paquete.viaje && paquete.viaje.paymentOrigin === DeliveryPaymentOrigin.atDelivery
          ? renderPayment()
          : null}
        {paquete.viaje && paquete.viaje.origenNombre ? renderCollect() : null}
        {paquete.viaje ? renderDelivery() : null}
      </Grid>
    );
  }

  const renderContent = () => {
    if (isLoading) {
      return renderLoading();
    } else {
      return renderInfo();
    }
  };

  useEffect(() => {
    if (props.packageId) {
      setIsLoading(true);

      api.getPaquete(props.packageId).then((paquete) => {
        if (paquete.viaje) {
          paquete.viaje.bitacora.forEach(b => {
            b.fecha = momentz.tz(b.fecha, 'utc').tz(tz)
          });
        }

        setPaquete(paquete);
        setIsLoading(false);
      });
    }
  }, [props.packageId]);

  return (
    <Dialog
      PaperProps={{
        style: {
          background: '#f0f0f0',
          boxShadow: 'none'
        }
      }}
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      scroll="body"
      maxWidth="md"
    >
      <DialogTitle>
        Información de paquete
      </DialogTitle>
      <DialogContent>
        {renderContent()}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={props.onClose}>Regresar</Button>
      </DialogActions>
      <DriverPopover
        driver={driverDataId}
        open={showDriverData}
        onClose={() => setShowDriverData(false)}
        anchorEl={driverDataAnchor} />
    </Dialog>
  );
}

function StatusChip(props) {
  let status = DeliveryStatus.map[props.status ? props.status : null];

  if (!status) {
    status = {color: "grey", title: "Sin asignar"};
  }

  return (
    <Chip style={{backgroundColor: status.color, color: "white"}}
      label={status.title} />
  );
}

const dataColumnSizes = {xl: 3, lg: 3, md: 4, sm: 6, xs: 12};

function DataColumn(props) {
  return (
    <Grid item {...dataColumnSizes}>
      <Typography
        color="textSecondary">{props.title}</Typography>
      {props.value}
    </Grid>
  );
}