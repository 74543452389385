import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { withRouter } from "react-router-dom";
import API from '../api';
import {
  CircularProgress,
} from '@material-ui/core';

const api = API.instance();

class PackageTypeEditScreen extends React.Component {
  state = {
    'user': '',
    'password': '',
    name: '',
    peso: null,
    pesoMaximo: null,
    model: null,
    volumenMinimo: null,
    volumenMaximo: null,

    isSaving: false,
    isLoading: true
  }

  async save() {
    const {
      isSaving,
      model,
      name,
      peso,
      codigo,
      pesoMaximo,
      volumenMinimo,
      volumenMaximo,
    } = this.state;

    if (isSaving) {
      return;
    }

    console.log(model);

    this.setState({
      isSaving: true
    });

    try {
      const paquete = await api.savePackageType({
        tipoPaqueteId: model.tipoPaqueteId,
        nombre: name,
        peso,
        pesoMaximo,
        volumenMaximo,
        volumenMinimo,
        codigo,
      });

      console.log(paquete);
      this.setState({
        isSaving: false,
      }, () => {
        this.props.history.push('/packages/types');
      });
    } catch (e) {
      console.error(e);
      this.setState({
        isSaving: false,
      });
    }
  }

  async load() {
    try {
      const model = await api.getPackageType(
        this.props.match.params.id
      );

      this.setState({
        model,
        isLoading: false,
        name: model.nombre,
        peso: model.peso,
        pesoMaximo: model.pesoMaximo,
        volumenMinimo: model.volumenMinimo,
        volumenMaximo: model.volumenMaximo,
        codigo: model.codigo
      });

    } catch (e) {
      console.error(e);
      this.setState({ isLoading: false });
    }
  }

  componentDidMount() {
    api.attachHistory(this.props.history, '/login');
    this.load();
  }

  render() {
    const {
      isSaving,
      name,
      peso,
      codigo,
      volumenMinimo,
      volumenMaximo,
      isLoading,
    } = this.state;

    if (isLoading) {
      return (
        <Container>
          <CircularProgress />
        </Container>
      );
    }

    return (
      <Container component="main" maxWidth={"md"}>
        <h1>Tipo de paquete</h1>
        <Grid container spacing={2}
          alignItems="flex-end"
          justify="flex-end"
          style={{ paddingBottom: 16 }}
        >
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item>
                <TextField
                  value={name}
                  variant="outlined"
                  label="Nombre"
                  onChange={(e) => this.setState({ name: e.target.value })} />
              </Grid>
              <Grid item>
                <TextField
                  value={codigo !== null ? codigo : ''}
                  variant="outlined"
                  label="Código de guía"
                  onChange={(e) => this.setState({ codigo: e.target.value })} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <TextField
              value={peso !== null ? peso : ''}
              variant="outlined"
              label="Peso mínimo"
              style={{ minWidth: 300 }}
              onChange={(e) => this.setState({ peso: e.target.value })} />
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item>
                <TextField
                  value={volumenMinimo !== null ? volumenMinimo : ''}
                  variant="outlined"
                  label="Volumen mínimo (m3)"
                  style={{ minWidth: 300 }}
                  onChange={(e) => this.setState({
                    volumenMinimo: e.target.value
                  })} />
              </Grid>
              <Grid item>
                <TextField
                  value={volumenMaximo !== null ? volumenMaximo : ''}
                  variant="outlined"
                  label="Volumen máximo (m3)"
                  style={{ minWidth: 300 }}
                  onChange={(e) => this.setState({
                    volumenMaximo: e.target.value
                  })} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Button variant="contained" color="primary"
                  disabled={isSaving}
                  onClick={this.save.bind(this)}>
                  Guardar Información
                </Button>
              </Grid>
              <Grid item>
                {isSaving ? <CircularProgress /> : null}
              </Grid>
            </Grid>

          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withRouter(PackageTypeEditScreen);