import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Button } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import NumberFormat from 'react-number-format';

const Money = (props) => {
  return (
    <NumberFormat
      thousandSeparator={true}
      decimalScale={2}
      fixedDecimalScale={2}
      displayType="text"
      prefix="$"
      {...props}
    />
  );
}

export default function VariantsGrid(props) {
  const {
    variants,
    onSelect,
    onCreate,
    defaultImageUrl,
  } = props;

  const classes = withStyles();

  return (
    <Grid container spacing={2}>
      {variants?.map((v, index) => <VariantTile
        defaultImageUrl={defaultImageUrl}
        variant={v}
        key={index}
        onSelect={onSelect}
      />)}
      <Grid item xs={3}>
        <Paper className={classes.addButtonContainer}>
          <Button startIcon={<AddIcon />} onClick={onCreate}>
            Crear nueva
          </Button>
        </Paper>
      </Grid>
    </Grid>
  );
}

function VariantTile(props) {
  const classes = withStyles();
  const {variant, onSelect, defaultImageUrl} = props;
  let imageUrl;

  if ((variant?.images?.length ?? 0) > 0) {
    imageUrl = variant.images[0].thumbnailUrl;
  } else {
    imageUrl = defaultImageUrl;
  }

  return (
    <Grid item xs={3}>
      <Paper className={classes.variantTile}>
        <button className={classes.variantTileInner} onClick={onSelect?.bind(null, variant)}>
          {variant.name}
          <div className={classes.variantThumbnailContainer}>
            <img className={classes.variantThumbnail} src={imageUrl} />
          </div>
          <div>
            <Money value={variant.unitPrice ?? 0} />
          </div>
          <div>
            {variant?.availableQuantity} unidades
          </div>
          <div className={classes.featuresContainer}>
            {variant?.features?.map((f, i) => <FeaturePill feature={f} key={i} />)}
          </div>
        </button>
      </Paper>
    </Grid>
  )
}

function FeaturePill(props) {
  const { feature } = props;
  const classes = withStyles();
  return (
    <div className={classes.featurePill}>
      {feature.dataType === "color" && feature.visualValue
        ? <div
            style={{backgroundColor: feature.visualValue}}
            className={classes.featurePillDot} />
        : null
      }
      {feature.optionValue}
    </div>
  );
}

const withStyles = makeStyles({
  variantTile: {
    color: "#333",
    borderBottom: "solid 1px #e0e0e0",
    borderRadius: 16,
    height: "100%",
    overflow: "hidden"
  },
  variantTileInner: {
    padding: "8px 12px",
    borderStyle: "none",
    borderWidth: 0,
    backgroundColor: "transparent",
    width: "100%",
    height: "100%",
    cursor: "pointer"
  },
  variantThumbnailContainer: {
    padding: 8,
  },
  variantThumbnail: {
    width: "100%",
    aspectRatio: 1,
    borderRadius: 8,
    maxWidth: 150
  },
  featurePill: {
    borderWidth: 1,
    borderRadius: "12px",
    borderColor: "#f0f0f0",
    borderWidth: 1,
    borderStyle: "solid",
    padding: "1px 7px",
    marginRight: 5,
    fontSize: 10,
    color: "#555",
    marginBottom: 4,
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  featurePillDot: {
    width: 6,
    height: 6,
    marginRight: 4,
    borderRadius: 4
  },
  featuresContainer: {
    display: "flex",
    paddingBottom: 0, 
    paddingTop: 4,
    justifyContent: "center",
    paddingLeft: 8,
    paddingRight: 8,
    flexWrap: "wrap"
  },
  addButtonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    borderRadius: 16,
  },
  addButton: {
    width: "100%"
  }
});