import React, { useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import {
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core/styles';
import {
  Modal,
  Grid,
  TextField,
  Button,
} from '@material-ui/core';
import API from '../api';

const api = API.instance();

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    fontSize: 17
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
);

export default function DeliveryInfoModal(props) {
  const classes = useStyles();
  const [isSaving, setIsSaving] = useState(false);
  const [placa, setPlaca] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [modalStyle] = useState(getModalStyle);
  const { driver, onAssign } = props;

  const handleSave = () => {
    if (isSaving) {
      return;
    }

    setIsSaving(true);
    setErrorMessage('');

    console.log(driver);

    api.asignarVehiculo(
      driver.choferId,
      placa
    ).then((r) => {
      if (r.success) {
        if (onAssign) {
          onAssign(r.result.vehiculo);
        }
      } else {
        setErrorMessage(
          r.message
        );
      }
      setIsSaving(false);
    }).catch((error) => {
      console.error(error);
      setErrorMessage(
        'Algo salió mal, inténtalo de nuevo más tarde.'
      );
      setIsSaving(false);
    })
  };

  if (!driver) {
    return null;
  }

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div
        style={modalStyle}
        className={classes.paper}
      >
        <h3>Asignar carro a repartidor</h3>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{fontSize: 18}}>
            {driver.nombre}
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={placa}
              variant="outlined"
              label="Placa del carro"
              fullWidth={true}
              onChange={(e) => setPlaca(e.target.value)}
            />
          </Grid>
          {
            errorMessage
              ? (
                <Grid item xs={12} style={{color: 'red'}}>
                  {errorMessage}
                </Grid>
              ) : null
          }
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}>
              {isSaving
                ? <CircularProgress />
                : <span>Asignar carro</span>
              }
            </Button>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
}