import React, { useState, useRef } from 'react';
import moment from 'moment';
import momentz from 'moment-timezone';
import 'moment/locale/es';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import {
  Map,
  Marker,
  Polyline,
  Popup,
  TileLayer,
} from 'react-leaflet';
import L from 'leaflet'
import { DeliveryStatus } from '../models/delivery';

const position = [29.087069, -110.960013];
const timezone = moment.tz.guess();

export const MarkedIcon = new L.Icon({
  iconUrl: '/package-24-a-chosen.png',
  iconRetinaUrl: '/package-24-a-chosen.png',
  iconAnchor: [24, 8],
  iconSize: [48, 48],
  shadowSize: [68, 95],
  shadowAnchor: [20, 92],
})

export const BlueBoxIcon = new L.Icon({
  iconUrl: '/package-24-b-chosen.png',
  iconRetinaUrl: '/package-24-b-chosen.png',
  iconAnchor: [24, 8],
  iconSize: [48, 48],
  shadowSize: [68, 95],
  shadowAnchor: [20, 92],
})

export const CarIcon = new L.Icon({
  iconUrl: '/car-48-outlined.png',
  iconRetinaUrl: '/car-48-outlined@2x.png',
  iconAnchor: [24, 8],
  iconSize: [48, 48],
  shadowSize: [68, 95],
  shadowAnchor: [20, 92],
});

export const CheckIcon = new L.Icon({
  iconUrl: '/MarkCheck.png',
  iconRetinaUrl: '/MarkCheck@2x.png',
  iconAnchor: [24, 8],
  iconSize: [48, 48],
  shadowSize: [68, 95],
  shadowAnchor: [20, 92],
});

export default function DeliveryRouteMap(props) {
  const [isReloading, setIsReloading] = useState(false);
  const [timerSeconds, setTimerSeconds] = useState(30);
  const { innerWidth, innerHeight } = window;
  const [timer, setTimer] = useState(null);
  const mapOptions = {
    center: position,
    zoom: 13,
    viewport: props.viewport,
    // ref: (ref) => this.map = ref
    style: {
      height: innerHeight * 0.7,
    }
  };
  const refPopupCar = useRef();
  const refPopupDestination = useRef();

  const renderMap = () => {
    console.log(props);
    return (
      <Map {...mapOptions}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {props.mapRoute ?
          props.mapRoute.viajes.map((d) => {
            return (
              <React.Fragment>
                <Polyline
                  positions={[
                    [d.origenLatitud, d.origenLongitud],
                    [d.destinoLatitud, d.destinoLongitud],
                  ]}
                  color="black"
                />
                <Marker
                  width={16}
                  icon={BlueBoxIcon}
                  onclick={() => {
                    // console.log(index);
                  }}
                  position={[d.origenLatitud, d.origenLongitud]}
                >
                  <Popup ref={refPopupDestination} autoClose={false}>
                    <strong>Dirección</strong><br />
                    {d.origenNombre}
                  </Popup>
                </Marker>
                <Marker
                  width={16}
                  icon={d.estatusEntregaId === DeliveryStatus.delivered
                    ? CheckIcon
                    : MarkedIcon
                  }
                  onclick={() => {
                    // console.log(index);
                  }}
                  position={[d.destinoLatitud, d.destinoLongitud]}
                >
                  <Popup ref={refPopupDestination} autoClose={false}>
                    <strong>Dirección</strong><br />
                    {d.destinoNombre}
                  </Popup>
                </Marker>
              </React.Fragment>
            );
          }) : null
        }
        {
          props.mapRoute
            && props.mapRoute.chofer
            && props.mapRoute.chofer.lastPosition
          ? (
            <Marker
              width={16}
              icon={CarIcon}
              position={[
                props.mapRoute.chofer.lastPosition.latitude,
                props.mapRoute.chofer.lastPosition.longitude
              ]}
            >
              <Popup ref={refPopupDestination} autoClose={false}>
                <strong>Chofer</strong><br />
                {props.mapRoute.chofer.nombre}
                <div>
                  {momentz.tz(props.mapRoute.chofer.lastPosition.timestamp, 'utc')
                    .tz(timezone).format()}
                </div>
              </Popup>
            </Marker>
          ) : null
        }
      </Map>
    );
  }
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullWidth={true}
      maxWidth="xl"
    >
      <DialogTitle>
        Mapa
      </DialogTitle>
      <DialogContent>
        {renderMap()}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={props.onClose}>Regresar</Button>
      </DialogActions>
    </Dialog>
  );
}