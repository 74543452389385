import React, {
  useCallback,
  useEffect,
  useReducer,
} from 'react';
import {
  Container,
  Grid,
  Box,
  Avatar,
  Card,
  FormControl,
  OutlinedInput,
  InputAdornment,
  CircularProgress,
  // Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TableFooter,
  Typography,
} from '@material-ui/core';
import API from '../../api';
import { useHistory } from "react-router-dom";
import { parse, encode } from 'querystring';
import {
  Search as SearchIcon,
  MoreHoriz as MoreHorizIcon,
  Add as AddIcon,
} from '@material-ui/icons';
import Button from '../../components/button';

const api = API.instance();

const initialState = {
  categories: [],
  isSearching: false,
  search: null,
  searchText: '',
  showDetail: false,
  user: null,
  page: 1,
  lastOptions: null,
};

class Action {
  static REPLACE_RESULTS = "replaceResults";
  static INPUT_SEARCH = "inputSearch";
  static START_SEARCHING = "startSearching";
}

function reducer(state, action) {
  switch (action.type) {
    case Action.INPUT_SEARCH:
      return { ...state, searchText: action.text };
    case Action.START_SEARCHING:
      return {
        ...state,
        isSearching: true,
        search: state.searchText,
      };
    case Action.REPLACE_RESULTS:
      return {
        ...state,
        categories: action.categories,
        isLoading: false,
        isSearching: false,
      };
    case 'closeDetail':
      return { ...state, showDetail: false };
    case 'openDetail':
      return { ...state, showDetail: true };
    case 'loadUserDetail':
      return { ...state, user: action.user };
    default:
      return state;
  }
}

function CategoriesScreen({
  location,
}) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();
  const params = parse(location.search.replace('?', ''));

  const getCategories = (op) => {
    const nextPage = op?.next === true;
    let page = nextPage ? (state.page + 1) : 1;

    api.getCategories().then((u) => {
      dispatch({ type: Action.REPLACE_RESULTS, categories: u });
    }).catch((e) => {
      console.error(e);
    });
  };

  const onSubmit = useCallback((e) => {
    dispatch({ type: 'startSearching' });
    e.preventDefault();
    history.replace(`${location.pathname}?${
      encode({...params, search: state.searchText})
    }`);
  }, []);

  const handleCreate = useCallback(e => {
    api.createCategory().then(r => {
      history.push(`/category/${r.id}`);
    }).catch(err => {
      alert(`Error: ${err}`);
    });
  }, []);

  useEffect(() => getCategories(), []);
  useEffect(() => getCategories(), [state.search])

  const {
    categories,
    isSearching,
  } = state;

  return (
    <Container>
      <h1>Categorías</h1>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box display="flex">
            <Box flexGrow={1}>
              <form onSubmit={onSubmit}>
                <FormControl
                  variant="outlined"
                  label="Asignar a zona"
                  fullWidth={true}
                >
                  <OutlinedInput
                    style={{ backgroundColor: 'white' }}
                    placeholder="Escribe un criterio de búsqueda"
                    variant="outlined"
                    startAdornment={<InputAdornment><SearchIcon /></InputAdornment>}
                    value={state.searchText ?? ''}
                    onChange={(e) => dispatch({
                      type: Action.INPUT_SEARCH,
                      text: e.target.value
                    })}
                    endAdornment={
                      <InputAdornment>
                        <Button
                          variant={isSearching ? "outlined" : "contained"}
                          color="primary"
                          disableElevation
                        >
                          {isSearching ? (
                            <CircularProgress size={24} />
                          ) : "Buscar"}
                        </Button>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </form>
            </Box>
            <Box style={{width: 16}}></Box>
            <Box>
              <Button onClick={handleCreate}><AddIcon /></Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell><strong>Nombre</strong></TableCell>
                  <TableCell><strong>ID</strong></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {categories?.map((c, idx) =>
                  <TableRow key={idx}>
                    <TableCell width={40}>
                      <Avatar
                        src={c.thumbnailImageUrl ?? null}
                        style={{width: 48, height: 48}} />
                    </TableCell>
                    <TableCell>
                      {c.name}
                      <Typography color="textSecondary">/{c.slug}</Typography>
                    </TableCell>
                    <TableCell>
                      {c.id}
                    </TableCell>
                    <TableCell>
                      <IconButton href={`/category/${c.id}`}>
                        <MoreHorizIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={6}>

                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default CategoriesScreen;