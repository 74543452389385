import React, { useState, useCallback, useEffect } from 'react';
import {
  Container,
  Card,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import API from '../api';

export default function BranchesScreen() {
  const [branches, setBranches] = useState(null);

  const fetchBranches = useCallback(async () => {
    //try {
      const items = await API.instance().getManageableBranches();
      setBranches(items);
    //} catch (e) {
      //console.log(`[BranchesScreen.fetchBranches] ${e}`);
    //}
  }, []);

  useEffect(() => {
    fetchBranches();
  }, []);

  return (
    <Container>
      <h1>Sucursales</h1>
      <List component={Card} style={{borderRadius: 16}}>
        {branches?.map(branch =>
          <ListItem key={branch.id} button>
            <ListItemText>{branch.name}</ListItemText>
          </ListItem>
        )}
      </List>
    </Container>
  );
}