import React, { useCallback, useEffect, useState } from 'react';
import {
  Grid,
  Paper,
  Container,
  TextField,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox,
} from '@material-ui/core';
import Button from '../components/button';
import { useHistory } from "react-router-dom";
import API from '../api';

export default function UserForm(props) {
  const {
    userId,
    isNew,
    title,
    roles: predefinedRoles,
    redirectTo,
  } = props;
  
  const history = useHistory();
  const [user, setUser] = useState({roles: []});
  const [saving, setSaving] = useState(false);
  const [roles, setRoles] = useState(predefinedRoles ?? null);
  const [isLoading, setIsLoading] = useState(!isNew);

  // Events & handlers
  const handleChange = useCallback((e) => {
    const ct = e.currentTarget;
    setUser({ ...user, [ct.name]: ct.value });
  }, [user]);

  const handleCheck = useCallback((e) => {
    const id = parseInt(e.currentTarget.value);
    if (user?.roles?.indexOf(id) === -1) {
      setUser({ ...user, roles: [...user.roles, id] });
    } else {
      setUser({ ...user, roles: user?.roles?.filter(i => i !== id) ?? [] })
    }
  }, [user]);

  const handleSubmit = useCallback(async (e) => {
    console.log("entra");
    e.preventDefault();
    if (saving)
      return;

    setSaving(true);
    
    try {
      await API.instance().saveUser(userId, user);
      console.log(redirectTo);
      if (redirectTo)
        history.push(redirectTo);
    } catch (e) {
      alert(e.message);
    }
    setSaving(false);
  }, [user, saving, redirectTo]);

  useEffect(() => {
    if (!isNew) {
      async function preload() {
        try {
          const u = await API.instance().getUser(userId, {
            include: ["roles"]
          });
          setUser({ ...u, roles: u.roles?.map(r => r.id) });
        } catch (e) {
          console.log(`[UserForm/preload] ${e.message}`);
        }
        setIsLoading(false);
      }
      preload();
    } else {
      // Nada
    }
  }, [isNew, userId]);

  useEffect(() => {
    console.log(user);
  }, [user]);

  useEffect(() => {
    if (!roles) {
      async function preloadRoles() {
        try {
          setRoles(await API.instance().getRoles());
        } catch (e) {
          console.log(`[USerForm/preloadRoles] ${e.message}`);
        }
      }
      preloadRoles();
    }
  }, [roles]);

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Container>
      {title ? <h1>{title}</h1> : null}
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Paper style={{borderRadius: 16, padding: 16}}>
              <Grid container direction='row' spacing={3}>
                <Grid item xs={6}>
                  <Field
                    label="Correo electrónico"
                    name="email"
                    onBlur={handleChange}
                    defaultValue={user?.email ?? ""} />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    label="Contraseña"
                    name="password"
                    type="password"
                    onBlur={handleChange}
                    defaultValue={""} />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    label="Nombre completo"
                    name="name"
                    onBlur={handleChange}
                    defaultValue={user?.name ?? ""} />
                </Grid>
                <Grid item xs={12}>
                  <h4>Roles</h4>
                  <List>
                  {roles?.map(role =>
                    <ListItem container item="true"
                      button={role.editable}
                      xs={12}
                      key={role.id}
                      divider={true}
                      dense={true}
                    >
                      <ListItemText>
                        {role.name}
                      </ListItemText>
                      <ListItemSecondaryAction>
                        <Checkbox
                          value={role.id}
                          disabled={!role.editable}
                          checked={user?.roles?.indexOf(role.id) !== -1}
                          onChange={handleCheck}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  )}
                </List>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Button color="primary" type="submit" isLoading={saving}>
              {isNew ? "Crear" : "Guardar"} usuario
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}

function Field(props) {
  return (
    <TextField {...props} fullWidth
      variant="outlined"
      size="small"
    />
  );
}