import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { pink, orange, green, cyan, red, grey } from '@material-ui/core/colors';
import {
  Button,
  CircularProgress,
} from '@material-ui/core';

export default function ColorButton({
  children,
  color,
  disabled,
  variant,
  onClick,
  isLoading,
  startIcon,
  href,
  fullWidth = false,
  endIcon,
  height = null,
  type: buttonType,
}) {
  const classes = useStyles();
  let cls;

  switch (color) {
    case "success": cls = disabled ? classes.containedSuccessDisabled : classes.containedSuccess; break;
    case "error": cls = classes.containedError; break;
    case "primary": cls = classes.containedPrimary; break;
    default: break;
  }

  return (
    <Button
      onClick={onClick}
      variant={variant || "contained"}
      disabled={disabled || false}
      href={href}
      type={buttonType}
      endIcon={endIcon}
      classes={{
        contained: cls,
        label: classes.label
      }}
      style={{
        height,
      }}
      startIcon={
        isLoading ? (
          <CircularProgress color="white" size="24px" />
        ) : startIcon
      }
      fullWidth={fullWidth}
    >
      {children}
    </Button>
  );
}

const useStyles = makeStyles((theme) => createStyles({
  containedPrimary: {
    background: `linear-gradient(
      to bottom right,
      ${pink[500]} 30%,
      ${orange[500]} 120%
    )`,
  },
  containedSuccess: {
    background: `linear-gradient(to bottom right, ${green[700]} 30%, ${cyan[600]} 120%)`
  },
  containedSuccessDisabled: {
    background: `linear-gradient(to bottom right, ${grey[400]} 30%, ${grey[200]} 120%)`,
  },
  containedError: {
    background: `linear-gradient(to bottom right, ${red[700]} 30%, ${red[300]} 120%)`
  },
  containedWhite: {
    backgroundColor: "white"
  },
  label: {
    color: '#ffffff'
  },
  whiteButton: {
    root: {
       backgroundColor: "white"
    }
  }
}));