import React from 'react';
import Container from '@material-ui/core/Container';
import { withRouter } from "react-router-dom";
import API from '../api';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import {
  List,
  Paper,
  Grid,
  Button,
  Badge,
  Avatar,
  Select,
  IconButton,
  ListItem,
  MenuItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
} from '@material-ui/core';
import AsignarVehiculo from '../components/asignarvehiculo';

const api = API.instance();

const OnlineBadge = withStyles((t) =>
  createStyles({
    badge: {
      backgroundColor: green[500],
      color: "white"
    }
  })
)(Badge);

class DriversScreen extends React.Component {
  state = {
    redirect: null,
    paqueteria: 0,
    address: '',
    place: null,
    guia: null,
    drivers: [],
    isCreating: false,
    showAsignar: false,
    driver: null,
    plusType: 0,

    isLoading: true,
  }

  componentDidMount() {
    this.load();
    
    this.handleChangePlusType = this.handleChangePlusType.bind(this);
  }

  async reload() {
    this.setState({ isLoading: true }, ()=> {
      this.load();
    });
  }

  async load() {
    const update = { isLoading: false };

    try {
      const response = await api.getDrivers({
        plusType: this.state.plusType != 0 ? this.state.plusType : null
      });
      update.drivers = response.result;
    } catch (e) {
      console.error(e);
    }

    this.setState(update);
  }

  async register() {
    const { isCreating } = this.state;

    if (isCreating) {
      return;
    }

    this.setState({isCreating: true});

    const nombre = prompt('¿Nombre de la persona?');

    if (!nombre) {
      this.setState({isCreating: false});
      return;
    }

    try {
      const driver = await api.createChofer({
        PhoneToken: '',
        idClientesNavigation: {
          nombre,
          phoneToken: ''
        },
        assignationMode: 2
      });
      const drivers = this.state.drivers;
      drivers.push(driver);
      this.setState({
        drivers,
        isCreating: false
      })
    } catch (e) {
      console.error(e);
      this.setState({isCreating: false});
    }
  }

  async handleChangePlusType(e) {
    this.setState({ plusType: e.target.value }, () => {
      this.reload();
    });
  }

  render() {
    const {
      drivers,
      plusType,
      isLoading,
      isCreating,
      showAsignar,
    } = this.state;
    let child;

    if (isLoading) {
      child = (
        <CircularProgress container={<div style={{ padding: 32 }} />} />
      );
    } else {
      child = (
        <React.Fragment>
          <List>
            {
              drivers.map((driver, index) => {
                return (
                  <ListItem divider={true} dense={false} key={index}>
                    <ListItemIcon>
                      {
                        driver.state && driver.state.active
                          ? (
                            <OnlineBadge badgeContent=""
                              anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                            >
                              <Avatar src={driver.imageUrl} />
                            </OnlineBadge>
                          ) : (
                            <Avatar src={driver.imageUrl} />
                          )
                      }
                      
                    </ListItemIcon>
                    <ListItemText
                      secondary={
                        driver.vehicle
                          ? (
                            `${driver.vehicle.model}, `
                            + `${driver.vehicle.plate}`
                          ) : null
                      }
                    >{driver.name ?? driver.nombre ?? ""}</ListItemText>
                    <ListItemSecondaryAction>
                      <IconButton variant="outlined"
                        onClick={
                          () => {
                            console.log(driver);
                            this.props.history.push(`/driver/${driver.id}`)
                          }
                        }>
                        <EditIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              })
            }
          </List>
        </React.Fragment>
      );
    }

    return (
      <Container component="main">
        <h1>Conductores</h1>
        <Grid item sm={12} container spacing={2}>
          <Grid item sm={6}>
            <Select variant="outlined" isDense={true} margin="dense"
              value={plusType}
              onChange={this.handleChangePlusType}
              defaultValue={null}
            >
              <MenuItem value={0}>Sin especificar</MenuItem>
              <MenuItem value="alpha">Alfa - Recolectores</MenuItem>
              <MenuItem value="beta">Beta - Repartidores</MenuItem>
              <MenuItem value="any">Ambos tipos</MenuItem>
            </Select>
          </Grid>
          <Grid item sm={6} style={{textAlign: 'right'}} grow={1}>
            <Button variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              disabled={isCreating}
              onClick={this.register.bind(this)}>
              Registrar
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              {child}
            </Paper>
          </Grid>
        </Grid>

        {drivers
          ? <div style={{ paddingTop: 16, color: 'grey', textAlign: 'center' }}>
            {`${drivers.length} conductores`}
          </div> : null}
        
        <AsignarVehiculo
          open={showAsignar}
          driver={this.state.driver}
          onClose={() => this.setState({showAsignar: false})}
          onAssign={(v) => {
            this.state.driver.vehiculo = v;
            this.setState({
              showAsignar: false,
              drivers: drivers
            })
          }}
        />
      </Container>
    );
  }
}

export default withRouter(DriversScreen);