import React, { Fragment } from 'react';
import Button from './button';
import {
  Grid
} from '@material-ui/core';
import { red, black } from '@material-ui/core/colors';

import {
  Check as CheckIcon,
  Error as ErrorIcon,
} from '@material-ui/icons';

const palettes = {
  'error': {
    color: red[500],
    icon: ErrorIcon,
  },
  'default': {
    color: 'inherit',
    icon: CheckIcon,
  }
};

function ButtonWithMessage({
  children,
  color,
  disabled,
  variant,
  onClick,
  isLoading,
  startIcon,
  message,
  messageColor,
}) {
  const palette = palettes[messageColor] ?? palettes['default'];
  const Icon = palette.icon;

  return (
    <Grid container spacing={1} direction="row"
      alignItems="center"
    >
      <Grid item>
        <Button
          color={color}
          disabled={disabled}
          variant={variant}
          onClick={onClick}
          isLoading={isLoading}
          startIcon={startIcon}
        >{children}</Button>
      </Grid>
      {message ? (
        <Fragment>
          <Grid item style={{display: 'flex'}}>
            <Icon style={{ color: palette.color }} />
          </Grid>
          <Grid item style={{
            color: palette.color,
            fontWeight: 'bold'
          }}>{message}</Grid>
        </Fragment>
      ) : null}
    </Grid>
  )
}

export default ButtonWithMessage;